import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AsyncStatePagination } from './AsyncPagination';
import { requestPending } from '../utils/status';
import { GET_ALL_ORGANIZATIONS } from '../redux/constants';
import { useResetAllPaginations } from '../hooks/useResetAllPaginations';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { VscOrganization } from 'react-icons/vsc';
import { Palette } from './utils';
import AsyncStatePaginationValue from './AsyncPagination/AsyncStatePaginationValue';
import { getOrganization } from '../redux/actions/organization';
import { setGlobalOrganization } from '../helper/store';

const GlobalOrgDropDown = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { resetAllPaginations } = useResetAllPaginations();

  const { AllOrganizations, getAllStatus, globalOrgId } = useSelector(
    (state) => state.Organization
  );

  const handleSetGlobalOrgId = (org) => {
    setGlobalOrganization(org);
    dispatch(getOrganization({ id: org.id }));
  };

  const renderData = (t) => (data) => {
    return <AsyncStatePaginationValue text={data.name} showIcon className="p-1" />;
  };

  return (
    <>
      <AsyncStatePagination
        icon={<VscOrganization size={23} style={{ color: Palette.HAZY_BLUE }} />}
        dataState={AllOrganizations}
        defaultText={t('Select Organization')}
        keyPrefix={'organization'}
        selectValue={(selectedValue) => (selectedValue?.name ? selectedValue.name : '')}
        onSelect={(data) => {
          handleSetGlobalOrgId(data);
          resetAllPaginations();
          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );
        }}
        renderMethod={renderData(t)}
        eventState={getAllStatus}
        events={{
          pending: requestPending(GET_ALL_ORGANIZATIONS)
        }}
        selected={globalOrgId}
        sync
      />
    </>
  );
};

export default GlobalOrgDropDown;
