import { createReducer } from '@reduxjs/toolkit';
import {
  SET_MODE,
  SET_SIDEBAR,
  SET_ASIDE,
  SET_TOAST,
  SET_IS_ON_DASHBOARD,
  SET_IS_ON_REPORTS,
  SET_SIDEBAR_TYPE
} from '../constants';

const initialState = {
  sidebarShow: 'responsive',
  sidebarType: 'default',
  asideShow: false,
  darkMode: false,
  toastMessage: '',
  toastShow: false,
  toastTimeout: 3000,
  modal: '',
  notificationHeader: '',
  isOnDashBoard: true,
  isOnReports: true
};

export default createReducer(initialState, {
  [SET_MODE]: (state, { payload }) => ({
    ...state,
    darkMode: payload.darkMode
  }),

  [SET_SIDEBAR]: (state, { payload }) => ({
    ...state,
    sidebarShow: payload.sidebarShow
  }),

  [SET_SIDEBAR_TYPE]: (state, { payload }) => ({
    ...state,
    sidebarType: payload.sidebarType
  }),

  [SET_ASIDE]: (state, { payload }) => ({
    ...state,
    asideShow: payload.asideShow
  }),

  [SET_IS_ON_DASHBOARD]: (state, { payload }) => ({
    ...state,
    isOnDashBoard: payload
  }),
  [SET_IS_ON_REPORTS]: (state, { payload }) => ({
    ...state,
    isOnReports: payload
  }),

  [SET_TOAST]: (state, { payload }) => ({
    ...state,
    toastShow: payload.toastShow,
    toastMessage: payload.toastMessage,
    modal: payload.modal,
    notificationHeader: payload.notificationHeader
  })
});
