export const BookingStatus = {
  New: 0,
  Open: 1,
  Pending: 2,
  Cancelled: 3,
  Closed: 4,
  Completed: 5,
  Reserved: 6,
  Booked: 7,
  Enabled: 8,
  Disabled: 9,
  Send: 10,
  Processing: 11,
  Error: 12,
  In_Progress: 13,
  Deleted: 14,
  Archived: 17
};
