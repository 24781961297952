import { createReducer } from "@reduxjs/toolkit";
import {
  SEARCH_BUSINESSES,
  ADD_BUSINESS,
  REMOVE_BUSINESS,
  EDIT_BUSINESS,
  BUSINESS_SELECTED,
  GET_BUSINESS,
  GET_BUSINESSES,
  SET_BUSINESSES_LOGO,
  CLEAR_BUSINESS,
  CLEAR_ERROR,
  UN_SET_BUSINESSES_LOGO
} from "../constants";
import {
  requestSuccess,
  requestFail,
  requestPending
} from "../../utils/status";

const initialState = {
  businesses: [],
  business: {},
  error: "",
  selectedBusiness: null,
  status: "INIT",
  logo: ""
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [CLEAR_BUSINESS]: (state, { payload }) => ({
    ...state,
    business: {}
  }),

  [UN_SET_BUSINESSES_LOGO]: (state, { payload }) => ({
    ...state,
    logo: payload.logo
  }),

  [BUSINESS_SELECTED]: (state, { payload }) => ({
    ...state,
    business: payload.business
  }),

  [requestSuccess(EDIT_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_BUSINESS),
    error: null
  }),

  [requestPending(EDIT_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_BUSINESS)
  }),

  [requestFail(EDIT_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_BUSINESS),
    error: payload?.data?.message
  }),

  [requestSuccess(REMOVE_BUSINESS)]: (state) => ({
    ...state,
    status: requestSuccess(REMOVE_BUSINESS),
    error: null
  }),

  [requestPending(REMOVE_BUSINESS)]: (state) => ({
    ...state,
    status: requestPending(REMOVE_BUSINESS)
  }),

  [requestFail(REMOVE_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REMOVE_BUSINESS),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_BUSINESS)]: (state, { payload }) => ({
    ...state,
    business: payload,
    status: requestSuccess(GET_BUSINESS),
    error: null
  }),

  [requestPending(GET_BUSINESS)]: (state) => ({
    ...state,
    status: requestPending(GET_BUSINESS)
  }),

  [requestFail(GET_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_BUSINESS),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_BUSINESSES)]: (state, { payload }) => ({
    ...state,
    businesses: payload,
    status: requestSuccess(GET_BUSINESSES),
    error: null
  }),

  [requestPending(GET_BUSINESSES)]: (state) => ({
    ...state,
    status: requestPending(GET_BUSINESSES)
  }),

  [requestFail(GET_BUSINESSES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_BUSINESSES),
    error: payload?.data?.message
  }),

  [requestSuccess(ADD_BUSINESS)]: (state, { payload }) => ({
    ...state,
    businesses: payload,
    status: requestSuccess(ADD_BUSINESS),
    error: null
  }),

  [requestPending(ADD_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_BUSINESS)
  }),

  [requestFail(ADD_BUSINESS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_BUSINESS),
    error: payload?.data?.message
  }),

  [requestSuccess(SET_BUSINESSES_LOGO)]: (state, { payload }) => ({
    ...state,
    logo: payload.filename,
    status: requestSuccess(SET_BUSINESSES_LOGO),
    error: null
  }),

  [requestPending(SET_BUSINESSES_LOGO)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SET_BUSINESSES_LOGO)
  }),

  [requestFail(SET_BUSINESSES_LOGO)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SET_BUSINESSES_LOGO),
    logo: ""
  })
});
