import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CCol, CDropdown, CDropdownItem, CDropdownMenu, CRow } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../redux/actions/notifications';
import { setToast } from '../redux/actions/window';
import LoadingAvatar from '../components/LoadingAvatar';
import { changeOpenedModal } from '../redux/actions/headerModal';

import { getCommentId, getService } from '../redux/actions/services';
import { getParAMBooking } from '../redux/actions/bookingParAm';
import { getOffer } from '../redux/actions/offers';
import moment from 'moment';
import i18next from 'i18next';

import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../utils/colorCode';
import { isEmpty } from 'lodash';

import { ReactComponent as GrataIconNotif } from '../assets/icons/grataIconNotif.svg';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Fonts, Palette } from '../components/utils';
import { FaBell, FaAngleRight } from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { HeaderNavButton, HeaderNavButtonIcon, HeaderNavButtonIconBadge } from './HeaderComponents';
import { handleLinkNotification } from '../helper';

const TheHeaderDropdownMssg = () => {
  const [activeTab, setActiveTab] = useState('general');
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { receivedNotification, status, pagedNotifications } = useSelector(
    (state) => state.notification
  );
  const newNotification = localStorage.getItem('newNotification') || '';

  const { profile } = useSelector((state) => state.profile);

  const [filteredNotifications, setFilteredNotifications] = useState([]);

  moment.locale(i18next.resolvedLanguage);

  const dispatch = useDispatch();
  const unread = true;
  useEffect(() => {
    if (Object.keys(receivedNotification).length) {
      dispatch(
        setToast({
          toastShow: true,
          toastMessage: receivedNotification.body,
          notificationHeader: receivedNotification.title,
          modal: 'notification'
        })
      );
    }
    dispatch(getNotifications());
  }, [receivedNotification, dispatch]);

  useEffect(() => {
    if (pagedNotifications?.length > 0 && !isEmpty(profile))
      setFilteredNotifications(
        pagedNotifications?.filter((notif) =>
          moment(notif?.create_date).isSameOrAfter(profile?.create_date)
        )
      );
  }, [pagedNotifications, profile]);

  const NotificationsList = ({ data }) => {
    return data.length > 0 ? (
      <NotificationDiv>
        {data.map((notif, index) => (
          <StyledCDropdownItem
            onClick={() => handleLinkNotification(notif)}
            key={index}
            href="#"
            className={unread ? 'unread border-bottom' : 'border-bottom'}>
            <StyledMessageContainer>
              <GrataIconNotif className="messageImage" />
              <div className="messageColumn">
                <span className="messageDesc">{notif.content}</span>
                <span className="messageDate">
                  {notif.create_date && moment(notif.create_date).format('M/D/YYYY [at] h:mm A')}
                </span>
              </div>
              <FaAngleRight className="arrowIcon" />
            </StyledMessageContainer>
          </StyledCDropdownItem>
        ))}
      </NotificationDiv>
    ) : (
      <EmptyNotificationContainer>
        <p className="empty-notification-text">{'No new notifications'}</p>
      </EmptyNotificationContainer>
    );
  };

  return (
    <StyledCDropdown inNav className="c-header-nav-item mx-2" direction="down">
      <HeaderNavButton caret={false}>
        <HeaderNavButtonIcon>
          <FaBell size={25} style={{ zIndex: 4 }} />
        </HeaderNavButtonIcon>
        {receivedNotification?.title ||
          (newNotification && newNotification.length > 0 && JSON.parse(newNotification)?.title && (
            <HeaderNavButtonIconBadge />
          ))}
      </HeaderNavButton>

      <CDropdownMenu className="pt-0" placement="bottom-end">
        <StyledHeaderCDropdownItem>
          <p className="title">{t('Notifications')}</p>
          <div className="iconsContainer">
            <StyledGearButton
              size={24}
              disabled={location.pathname === '/settings'}
              onClick={() => {
                if (location.pathname === '/settings') return;

                history.push('/settings');
              }}
            />
          </div>
        </StyledHeaderCDropdownItem>

        {status === 'GET_NOTIFICATIONS_PENDING' ? (
          <LoadingAvatar color={LOADING_BLUE} />
        ) : (
          <>
            <NotificationContainer>
              <TabContent>
                <NotificationsList
                  data={filteredNotifications.filter(
                    (item) => item.type_id != 1 || item.type_id != 6
                  )}
                />
              </TabContent>
            </NotificationContainer>

            {location.pathname !== '/notifications' && (
              <StyledNoNoNotificationsButton to="/notifications">
                View All Notifications
              </StyledNoNoNotificationsButton>
            )}
          </>
        )}
      </CDropdownMenu>
    </StyledCDropdown>
  );
};

const StyledCDropdown = styled(CDropdown)`
  .dropdown-menu {
    min-width: 343px;
    border-radius: 30px;
    padding: 24px !important;
  }
`;

// Tabs
export const TabList = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

export const Tab = styled.button`
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  border-bottom: 3px solid ${(props) => (props.isActive ? Palette.OCEANIC_HOVER : 'transparent')};
  &:focus {
    outline: none;
  }
`;

const StyledNoNoNotificationsButton = styled(Link)`
  display: flex;
  justify-content: center;
  color: ${Palette.CUSTOM_GRAY};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  :hover {
    cursor: pointer;
    color: ${Palette.CUSTOM_GRAY};
  }
`;

export const StyledTabLabel = styled.span`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;
const Content = styled.div`
  padding: 0 !important;
  margin: 0 !important;
`;

const TabContent = ({ children }) => <Content>{children}</Content>;

//--------------------------------------------

const StyledHeaderCDropdownItem = styled.div`
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-bottom: 28px !important;

  .title {
    padding: 0 !important;
    margin: 0 !important;
    color: ${Palette.BLACK};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .iconsContainer {
    display: flex;
    gap: 6px;

    .configHeaderIcon {
      color: ${Palette.BLACK_50};
      width: 30px;
      height: 30px;
    }
  }
`;

// Styled CDropdownItem
const StyledCDropdownItem = styled(CDropdownItem)`
  cursor: default;
  padding: 0 !important;
  margin: 0 !important;

  padding-top: 8px !important;

  &:active,
  &:focus {
    background: none !important;
    color: #455164;
  }

  strong.viewAllMsgs {
    cursor: pointer;
  }
`;

// Notification Div and Empty Notification Container
const NotificationDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 440px;
  margin-bottom: 15px;
  // background-color: red;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  .unread {
    //background: rgba(43, 79, 124, 0.08) !important;
  }
`;

const StyledGearButton = styled(IoMdSettings)`
  :hover {
    color: ${(props) => (props.disabled ? 'inherit' : Palette.BORDER_OUTLINE)} !important;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  }
`;

const StyledMessageContainer = styled.div`
  max-width: 295px;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  gap: 15px;

  :hover {
    cursor: pointer;
  }

  .messageImage {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    border-radius: 10px;
  }

  .messageColumn {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .messageDesc {
    white-space: normal;
    color: ${Palette.BLACK};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .messageDate {
    white-space: normal;
    color: ${Palette.RUSTY_ORANGE};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  .arrowIcon {
    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;
  }
`;

const StyledViewMoreCDropdownItem = styled(CDropdownItem)`
  .viewAllNotif {
    margin: 0 auto;

    color: ${Palette.CUSTOM_GRAY};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

const EmptyNotificationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .empty-notification-text {
    margin: 1em 0;
  }
`;

const NotificationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
`;

export default TheHeaderDropdownMssg;
