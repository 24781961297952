import React, { useState } from 'react';
import { CModalBody, CImg } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import { getFullDateWithOutTime } from '../../../helper';
import LoadingAvatar from '../../../components/LoadingAvatar';
import {
  org_property_mode_mapping,
  org_property_provider_mapping,
  org_service_mode_mapping,
  org_service_provider_mapping,
  org_tenant_mode_mapping,
  org_tenant_provider_mapping
} from '../../../utils/mappings';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../utils/colorCode';
import Configurations from './Configurations';
import { imageString } from '../../../helper';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../settings/SettingsItem';
import { FaCalendar, FaLock, FaUser } from 'react-icons/fa';
import { Palette } from '../../../components/utils';
import { openModal } from '../../../helper/modals';
import { IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineEmail } from 'react-icons/md';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { ImageContainer, StyledImage } from '../../settings/SettingsImages';
import { getLockProviderNameByID } from '../../../utils/utils';

const OrganizationDetails = () => {
  const { t } = useTranslation();
  const organzationLabels = {
    org_name: t('Organization name'),
    email_address: t('Email address'),
    contact_person: t('Contact person'),
    configuration: t('Configuration'),
    tenant_provider: t('Resident provider'),
    tenant_mode: t('Resident mode'),
    property_provider: t('Property provider'),
    property_mode: t('Property mode'),
    service_provider: t('Service provider'),
    service_mode: t('Service mode'),
    create_date: t('Create date')
  };

  const { modal } = useSelector((state) => state.headerModal);
  const { organization, status } = useSelector((state) => state.Organization);
  const dispatch = useDispatch();
  const [errorImage, setErrorImage] = useState('');

  const onClose = () => {
    setErrorImage('');
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  return modal === 'OrganizationDetail' ? (
    <StyledModal show={modal === 'OrganizationDetail'} onClose={onClose} closeOnBackdrop={true}>
      <ModalHeader title={organization.name} />
      {status === 'GET_ORGANIZATION_PENDING' ? (
        <LoadingAvatar color={LOADING_BLUE} />
      ) : (
        <>
          <StyledModalBody>
            <div className="flex justify-content-center w-100">
              <ImageContainer borderRadius="0px">
                <StyledImage
                  objectFit="contain"
                  src={errorImage === '' ? imageString(organization.logo) : errorImage}
                />
              </ImageContainer>
            </div>

            <SettingsItem>
              <MdOutlineEmail size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.email_address}</SettingsItemTitle>
                <SettingsItemSubTitle>{organization.email}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaUser size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.contact_person}</SettingsItemTitle>
                <SettingsItemSubTitle>{organization.contact}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaCalendar size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.create_date}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {getFullDateWithOutTime(organization.create_date)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            {organization && (
              <>
                <SettingsItem>
                  <IoSettingsOutline size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>Configuration</SettingsItemTitle>
                  </SettingsItemContent>
                </SettingsItem>
                <Configurations values={organization} fieldName={'config'} isReadOnly isEdit />
              </>
            )}

            <SettingsItem>
              <FaLock size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>Lock Provider</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {getLockProviderNameByID(organization.config.lock_provider)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.tenant_provider}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {org_tenant_provider_mapping.find((item) => item.id === organization.prov_tenant)
                    ?.value === -1
                    ? 'None'
                    : org_tenant_provider_mapping.find(
                        (item) => item.id === organization.prov_tenant
                      )?.value || ''}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.tenant_mode}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {org_tenant_mode_mapping.find((item) => item.id === organization.prov_tenant_mode)
                    ?.value === -1
                    ? 'None'
                    : org_tenant_mode_mapping.find(
                        (item) => item.id === organization.prov_tenant_mode
                      )?.value || ''}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.property_provider}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {org_property_provider_mapping.find(
                    (item) => item.id === organization.prov_property
                  )?.value === -1
                    ? 'None'
                    : org_property_provider_mapping.find(
                        (item) => item.id === organization.prov_property
                      )?.value || ''}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.property_mode}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {org_property_mode_mapping.find(
                    (item) => item.id === organization.prov_property_mode
                  )?.value === -1
                    ? 'None'
                    : org_property_mode_mapping.find(
                        (item) => item.id === organization.prov_property_mode
                      )?.value || ''}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.service_provider}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {org_service_provider_mapping.find(
                    (item) => item.id === organization.prov_service
                  )?.value === -1
                    ? 'None'
                    : org_service_provider_mapping.find(
                        (item) => item.id === organization.prov_service
                      )?.value || ''}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{organzationLabels.service_mode}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {org_service_mode_mapping.find(
                    (item) => item.id === organization.prov_service_mode
                  )?.value === -1
                    ? 'None'
                    : org_service_mode_mapping.find(
                        (item) => item.id === organization.prov_service_mode
                      )?.value || ''}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </StyledModalBody>

          <ModalButtons
            leftButtonText={'Edit'}
            leftButtonClick={() => openModal('editOrg')}
            rightButtonText={'Add new building'}
            rightButtonClick={() => openModal('addBuilding')}
          />
        </>
      )}
    </StyledModal>
  ) : null;
};

export default OrganizationDetails;
