import { CDropdownToggle } from '@coreui/react';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';

export const DropdownToggle = styled(CDropdownToggle)`
  justify-content: space-between;
  border-radius: 9999px;
  width: 150px;
  display: flex;
  align-items: center;
`;

export const StyledDateText = styled.p`
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0;
  ${({ cssOverride }) => cssOverride};
`;
