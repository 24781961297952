import React, { useEffect, useState } from 'react';
import { setToast } from '../../../../redux/actions/window';
import { getDDUnits } from '../../../../redux/actions/unit';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { CForm, CFormGroup, CCol } from '@coreui/react';
import * as Yup from 'yup';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import ModalFooter from '../../../../components/ModalFooter';
import { useTranslation } from 'react-i18next';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../../settings/SettingsModals';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { ErrorText } from '../../../../components/styled/ErrorComponents';
import { Select } from '../../../../components/ui/Select';
import { Icon } from '../../../../helper/icons';
import { ReactComponent as CheckBlue } from '../../../../assets/icons/check-blue.svg';
import { ReactComponent as PlusBlue } from '../../../../assets/icons/plus-blue.svg';
import { addSuite } from '../../../../redux/actions/suites';
import { getBuildingSuites } from '../../../../redux/actions/building';

const AddSuite = () => {
  const { t } = useTranslation();
  const unitLabels = {
    building: t('Building'),
    unit_no: t('Unit number'),
    floor_no: t('Floor'),
    bedrooms: t('Bedrooms'),
    bathrooms: t('Bathrooms'),
    area: t('Area'),
    resident: t('Resident'),
    square_feet: t('Sq.ft'),
    monthly_rent: t('Monthly Rent'),
    primary_resident: t('Primary Resident'),
    co_residents: t('Co-Residents')
  };
  const dispatch = useDispatch();
  const { building, suitesPagination } = useSelector((state) => state.building);
  const { error, status, unitsForDD } = useSelector((state) => state.unit);
  const { modal } = useSelector((state) => state.headerModal);
  const searchText = useSelector((state) => state.suites.searchText);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = function (values) {
    return Yup.object().shape({
      unit_num: Yup.string()
        .matches(/^([a-zA-Z0-9\s]+)$/, t('Incorrect Format,only alphanumeric values'))
        .required(t('Unit Number is required')),
      area: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999999, t('Not more than 6 characters'))
        .typeError(t('Only numbers'))
        .notRequired(),
      floor: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999, t('Not more than 3 characters'))
        .typeError(t('Only numbers'))
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const initialValues = {
    unit_num: '',
    area: undefined,
    rooms: undefined
  };

  const onSubmit = (values, createUnitUser) => {
    setIsSubmitting(true);

    const subValues = {
      name: values.unit_num,
      area: values.area || 0,
      owner: 0,
      org_id: building?.org_id,
      building_id: building?.id,
      units: values.rooms
    };

    dispatch(
      addSuite({
        body: subValues,
        success: () => {
          dispatch(
            getBuildingSuites({
              orgId: building?.org_id,
              buildingId: building?.id,
              page: suitesPagination?.currentPage,
              searchText,
              finally: () => {
                setIsSubmitting(false);
                dispatch(
                  changeOpenedModal({
                    modal: ''
                  })
                );
                dispatch(
                  setToast({
                    toastShow: true,
                    toastMessage: t('Unit has been successfully created!')
                  })
                );
              }
            })
          );
        },
        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: res?.data?.message || t('Unit Creation Failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );

    setIsSubmitting(false);
  };

  const handleCancel = () => {
    onClose();
  };

  const tagRender = (props) => {
    const { label } = props;
    return (
      <div
        style={{
          marginInlineEnd: 8
        }}>
        <span>{label}</span>
      </div>
    );
  };

  useEffect(() => {
    if (building.id) {
      dispatch(
        getDDUnits({
          id: building.id
        })
      );
    }
  }, [building]);

  return (
    modal === 'addSuite' && (
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {({
          values,
          errors,
          dirty,
          isValid,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <CForm onSubmit={handleSubmit} noValidate name="AddSuiteForm">
            <StyledModal
              show={modal === 'addSuite'}
              onClose={onClose}
              style={{ maxWidth: '450px' }}
              closeOnBackdrop={false}>
              <ModalHeader title={t('Add New Unit')} />
              <SettingsModalBoldText cssOverride={'color: gray; margin-top: -20px'}>
                {building.name}
              </SettingsModalBoldText>
              <StyledModalBody>
                <CFormGroup row>
                  <CCol>
                    <CFormGroup>
                      <InputSeparator>
                        <SettingsModalBoldText>Name</SettingsModalBoldText>
                        <SettingsModalInput
                          label={unitLabels.unit_no}
                          type="string"
                          id="unit_num"
                          name="unit_num"
                          placeholder={t('Unit Name...')}
                          valid={!errors.unit_num && touched.unit_num}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.unit_num}
                          error={errors.unit_num}
                          includedSearchIcon={false}
                          cssOverride={{
                            padding: '0px 10px 0px 20px',
                            height: '56px'
                          }}
                        />
                      </InputSeparator>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>Area</SettingsModalBoldText>
                      <SettingsModalInput
                        label={unitLabels.area}
                        type="number"
                        id="area"
                        name="area"
                        placeholder={t('Area...')}
                        valid={!errors.area && touched.area}
                        onChange={(e) =>
                          e.target.value === 0 || e.target.value === ''
                            ? setFieldValue('area', undefined)
                            : setFieldValue('area', parseInt(e.target.value))
                        }
                        onBlur={handleBlur}
                        value={values.area}
                        error={errors.area}
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                        includedSearchIcon={false}
                        cssOverride={{
                          padding: '0px 10px 0px 20px',
                          height: '56px'
                        }}
                      />
                      <ErrorText>{errors.area}</ErrorText>
                    </InputSeparator>
                  </CCol>
                </CFormGroup>

                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>Rooms</SettingsModalBoldText>
                      <Select
                        showSearch={false}
                        mode="multiple"
                        tagRender={tagRender}
                        placeholder="Select Room"
                        style={{
                          width: '100%'
                        }}
                        height="auto"
                        options={unitsForDD.map((unit) => {
                          return {
                            value: unit.id,
                            label: unit.unit_num
                          };
                        })}
                        menuItemSelectedIcon={(icon) => {
                          return icon.isSelected ? (
                            <Icon icon={CheckBlue} />
                          ) : (
                            <Icon icon={PlusBlue} />
                          );
                        }}
                        id="rooms"
                        name="rooms"
                        onChange={(value) => {
                          setFieldValue('rooms', value);
                        }}
                        cssOverride={
                          '.ant-select-selector {padding: 10px 20px !important; min-height: 56px !important;}'
                        }
                      />
                      <ErrorText>{errors.user_id}</ErrorText>
                    </InputSeparator>
                  </CCol>
                </CFormGroup>
              </StyledModalBody>
              <ModalFooter
                text={t('Add')}
                error={error}
                handleCancel={handleCancel}
                onClick={() => {}}
                status={status === 'ADD_UNIT_PENDING' ? true : false}
                className={!dirty || !isValid ? '' : 'btn-primary'}
                disabled={isSubmitting || !dirty || !isValid}
                style={
                  isSubmitting || !dirty || !isValid
                    ? {
                        cursor: 'not-allowed'
                      }
                    : { cursor: 'pointer' }
                }
              />
            </StyledModal>
          </CForm>
        )}
      </Formik>
    )
  );
};

export default AddSuite;
