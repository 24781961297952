import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const loadingIcon = <LoadingOutlined style={{fontSize: 24}}/>;

export const DefaultLoadingIcon = ({ className }) => <Spin indicator={loadingIcon} className={className}/>

export const DefaultSpinLoading = () => {
  return (
    <Spin tip="Loading">
      <div/>
    </Spin>
  )
}
