const OrgSearchFields = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Contact person", value: "contact" }
]

const BookingSeachFields = [
  {label: "Title", value: "title"},
  {label: "Book date", value: "book_date::text"}
]

const OffersSearchFields = [
  {label: "Title", value: "title"},
  {label: "Date published", value: "create_date::text"},
  {label: "Expire date", value: "expire_date::text"}
]

const ServiceSearchFields = [
  {label: "Subject", value: "subject"},
  {label: "Create date", value: "create_date::text"}
]

const BuildingsearchFields = [
  {label: "Name", value: "name"},
  {label: "State", value: "state"},
  {label: "Zip", value: "zip"},
  {label: "Address", value: "address"},
  {label: "City", value: "city"},
  {label: "Country", value: "country"}
]

const UsersSearchFields = [
  {label: "First Name", value: "first_name"},
  {label: "Last Name", value: "last_name"},
  {label: "Mobile Phone Number", value: "mobile_phone"},
  {label: "Home Phone Number", value: "home_phone"},
  {label: "Email", value: "email"},
  {label: "Unit Number", value: "unit.unit_num"}
]

const ENTITY = {
  organization: "organization",
  building: "building",
  user: "user",
  booking: "booking",
  request: "request",
  offer: "offer"
}

export {
  BookingSeachFields,
  OffersSearchFields,
  ServiceSearchFields,
  BuildingsearchFields,
  UsersSearchFields,
  OrgSearchFields,
  ENTITY
}
