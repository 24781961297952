import React from 'react';
import styled from 'styled-components';
import { Palette } from '../utils/palette';

export function Button({
  isPrimary,
  text,
  disabled,
  onClick,
  cssOverride,
  height,
  width,
  icon,
  type,
  ...props
}) {
  return (
    <ButtonBody
      isPrimary={isPrimary}
      onClick={onClick}
      disabled={disabled}
      height={height}
      width={width}
      cssOverride={cssOverride}
      type={type}
      {...props}>
      {text}
      {icon}
    </ButtonBody>
  );
}

export const ButtonBody = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font: normal normal 600 18px 'Open Sans', sans-serif;
  border: 1px solid ${Palette.HAZY_BLUE};
  background-color: ${({ isPrimary }) => (isPrimary ? Palette.HAZY_BLUE : Palette.WHITE)};
  color: ${({ isPrimary }) => (isPrimary ? Palette.WHITE : Palette.HAZY_BLUE)};
  height: ${({ height }) => height || '44px'};
  width: ${({ width }) => width || '100%'};

  &:not(:disabled):hover {
    color: ${Palette.OCEANIC};
    * {
      color: ${Palette.OCEANIC};
    }
  }

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ cssOverride }) => cssOverride};
`;
