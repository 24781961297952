import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { file } from "./file";
import { logoNegative } from "./logo-negative";
import {
  dashboard,
  organization,
  buildings,
  users,
  bookings,
  services,
  dotSidebar,
  news,
  deals,
  reports,
  deliveryCodes
} from "./SideBarIcons";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cibHubspot,
  cilChart,
  cilSpa,
  cilSun,
  cilMoon,
  cilUser,
  cilCog,
  cilDevices,
  cilCommentSquare,
  cilFingerprint,
  cilNotes,
  cilBuilding,
  cilApps,
  cilViewQuilt,
  cilMonitor,
  cilNewspaper,
  cilDescription,
  cilPeople,
  cilMediaRecord,
  cilLayers,
  cilSettings,
  cilLocationPin,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cilCalendar,
  cibHighly,
  cilClock,
  cilBolt,
  cilCommentBubble,
  cilPhone,
  cilTrash,
  cilLockLocked,
  cilChevronLeft,
  cilArrowThickFromBottom,
  cilArrowCircleLeft,
  cilArrowThickLeft,
  cilSend
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    file,
    dashboard,
    organization,
    buildings,
    users,
    bookings,
    services,
    dotSidebar,
    news,
    deals,
    reports,
    logoNegative,
    deliveryCodes
  },
  {
    cilSend,
    cilLockLocked,
    cilArrowThickFromBottom,
    cilPhone,
    cibHubspot,
    cilCommentBubble,
    cilBolt,
    cilClock,
    cibHighly,
    cilChevronLeft,
    cilChart,
    cilSpa,
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilUser,
    cilCog,
    cilDevices,
    cilTrash,
    cilCommentSquare,
    cilFingerprint,
    cilNotes,
    cilBuilding,
    cilApps,
    cilViewQuilt,
    cilMonitor,
    cilNewspaper,
    cilDescription,
    cilPeople,
    cilMediaRecord,
    cilLayers,
    cilSettings,
    cilLocationPin,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cilCalendar,
    cilArrowCircleLeft,
    cilArrowThickLeft
  }
);
