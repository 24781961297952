import React from "react"

const Logo = ({ ...rest }) => {
  return (
    <svg width="100" height="32" viewBox="0 0 100 32" fill="white" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0)">
        <path d="M49.6798 16.0148V15.476C49.6798 15.1819 49.44 14.9434 49.1442 14.9434H41.3511C41.0552 14.9434 40.8155 15.1819 40.8155 15.476V17.4788C40.8155 17.7731 41.0552 18.0115 41.3511 18.0115H45.2325C45.5699 18.0115 45.8249 18.3202 45.71 18.6357C45.0704 20.3904 43.3092 21.5667 41.1338 21.5667C38.024 21.5667 35.6486 19.156 35.6486 15.9905C35.6486 12.8249 37.9504 10.4386 41.0359 10.4386C42.6863 10.4386 44.1587 11.0679 45.3111 12.2381C45.5255 12.4559 45.8776 12.4812 46.1032 12.2747L47.7903 10.73C47.9987 10.539 48.0229 10.218 47.8298 10.0115C46.1771 8.24334 43.6921 7.19995 41.0359 7.19995C35.9671 7.19995 32 11.0473 32 15.9905C32 20.9336 35.9915 24.7809 41.0849 24.7809C46.0312 24.7809 49.6798 21.0553 49.6798 16.0148Z" />
        <path d="M51.8823 24.2462C51.8823 24.5403 52.1222 24.7789 52.418 24.7789H54.7015C54.9973 24.7789 55.2371 24.5403 55.2371 24.2462V16.7006C55.2371 16.6165 55.2566 16.5334 55.2974 16.4597C55.9448 15.291 57.0646 14.6734 58.3225 14.6734C58.6367 14.6734 58.9507 14.7055 59.2483 14.7663C59.6291 14.8441 60.0365 14.577 60.0365 14.1905V12.4014C60.0365 12.2102 59.9355 12.0278 59.7545 11.9634C59.4712 11.8627 59.1295 11.8181 58.6408 11.8C57.5967 11.8 56.6848 12.116 55.954 12.7187C55.6979 12.9299 55.2371 12.7654 55.2371 12.4346C55.2371 12.2455 55.083 12.0923 54.8928 12.0923H52.418C52.1222 12.0923 51.8823 12.3307 51.8823 12.6249V24.2462Z" />
        <path d="M70.7578 24.2666C70.7578 24.4556 70.912 24.609 71.1022 24.609H73.528C73.8238 24.609 74.0636 24.3704 74.0636 24.0763V12.6034C74.0636 12.2573 73.7367 12.0031 73.3989 12.0864L70.9366 12.6941C70.7913 12.7299 70.6386 12.7027 70.5102 12.6261C69.5455 12.05 68.3993 11.752 67.2317 11.752C63.5341 11.752 60.6445 14.601 60.6445 18.2536C60.6445 21.9061 63.5096 24.7794 67.1338 24.7794C68.2354 24.7794 69.2715 24.5025 70.175 23.9618C70.4213 23.8145 70.7578 23.9808 70.7578 24.2666ZM63.9504 18.2536C63.9504 16.1593 65.5421 14.5766 67.6724 14.5766C68.7904 14.5766 69.8233 14.9571 70.5534 15.6197C70.6561 15.7129 70.7088 15.847 70.7088 15.9853V20.4972C70.7088 20.6355 70.6558 20.7697 70.5538 20.8638C69.812 21.5485 68.8382 21.9304 67.6724 21.9304C65.5666 21.9304 63.9504 20.3234 63.9504 18.2536Z" />
        <path d="M78.4433 21.0068C78.4433 23.4905 79.9125 24.7812 82.7531 24.7812C83.3674 24.7812 84.2728 24.662 84.9682 24.4948C85.1963 24.4399 85.3487 24.2325 85.3487 23.9991V22.4033C85.3487 22.0397 84.9905 21.7867 84.6322 21.8588C84.2818 21.9294 83.9487 21.9564 83.5611 21.9564C82.2634 21.9564 81.7981 21.5181 81.7981 20.325V15.2327C81.7981 14.9385 82.0378 14.7 82.3337 14.7H84.8866C85.1823 14.7 85.4221 14.4616 85.4221 14.1674V12.4324C85.4221 12.1382 85.1823 11.8997 84.8866 11.8997H82.3337C82.0378 11.8997 81.7981 11.6613 81.7981 11.3671V8.57293C81.7981 8.23131 81.4792 7.978 81.1442 8.0534L78.8607 8.5672C78.6167 8.62214 78.4433 8.83784 78.4433 9.08673V11.3671C78.4433 11.6613 78.2035 11.8997 77.9076 11.8997H76.3589C76.0631 11.8997 75.8232 12.1382 75.8232 12.4324V14.1674C75.8232 14.4616 76.0631 14.7 76.3589 14.7H77.9076C78.2035 14.7 78.4433 14.9385 78.4433 15.2327V21.0068Z" />
        <path d="M96.4887 24.2666C96.4887 24.4556 96.6429 24.609 96.8331 24.609H99.2588C99.5546 24.609 99.7945 24.3704 99.7945 24.0763V12.6034C99.7945 12.2573 99.4677 12.0031 99.1298 12.0864L96.6674 12.6941C96.5223 12.7299 96.3694 12.7027 96.2412 12.6261C95.2765 12.05 94.1302 11.752 92.9626 11.752C89.2649 11.752 86.3755 14.601 86.3755 18.2536C86.3755 21.9061 89.2404 24.7794 92.8646 24.7794C93.9662 24.7794 95.0023 24.5025 95.9058 23.9618C96.1521 23.8145 96.4887 23.9808 96.4887 24.2666ZM89.6812 18.2536C89.6812 16.1593 91.2729 14.5766 93.4033 14.5766C94.5212 14.5766 95.5542 14.9571 96.2842 15.6197C96.3869 15.7129 96.4398 15.847 96.4398 15.9853V20.4972C96.4398 20.6355 96.3868 20.7697 96.2847 20.8638C95.5429 21.5485 94.5691 21.9304 93.4033 21.9304C91.2974 21.9304 89.6812 20.3234 89.6812 18.2536Z" />
      </g>
      <path d="M24.1266 27.1324C23.7828 26.5481 23.0113 26.3717 22.4026 26.6856C20.5394 27.6463 18.4179 28.1899 16.167 28.1899C13.7922 28.1899 11.5615 27.5848 9.6262 26.5232C9.01415 26.1875 8.22114 26.3575 7.87054 26.9533L7.1604 28.1602C6.84452 28.697 7.00547 29.3863 7.55265 29.6961C10.0878 31.1317 13.0296 31.953 16.167 31.953C19.1584 31.953 21.972 31.2063 24.4249 29.8924C24.9884 29.5906 25.1606 28.8897 24.8396 28.3441L24.1266 27.1324Z" />
      <path d="M20.4219 22.2512C20.1512 21.8144 19.5657 21.6531 19.0555 21.822C18.1484 22.1222 17.1717 22.2857 16.154 22.2857C15.0331 22.2857 13.9619 22.0873 12.9786 21.7264C12.4607 21.5363 11.8518 21.6925 11.5734 22.1417L11.2595 22.6482C10.9697 23.1158 11.1488 23.7183 11.6814 23.9314C13.0539 24.4803 14.5658 24.7842 16.154 24.7842C17.6144 24.7842 19.0103 24.5272 20.2925 24.0591C20.8432 23.8581 21.0367 23.2432 20.741 22.766L20.4219 22.2512Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9999 19.2C21.3018 19.2 25.5999 14.9019 25.5999 9.6C25.5999 4.29806 21.3018 0 15.9999 0C10.698 0 6.3999 4.29806 6.3999 9.6C6.3999 14.9019 10.698 19.2 15.9999 19.2ZM15.9999 15.3786C19.1914 15.3786 21.7785 12.7914 21.7785 9.6C21.7785 6.40855 19.1914 3.82137 15.9999 3.82137C12.8085 3.82137 10.2213 6.40855 10.2213 9.6C10.2213 12.7914 12.8085 15.3786 15.9999 15.3786Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7059 0C15.6041 0 15.52 0.0712888 15.52 0.161472C15.52 2.45037 17.6153 4.30588 20.2 4.30588C22.7847 4.30588 24.88 2.45037 24.88 0.161472C24.88 0.0712888 24.796 0 24.6942 0H15.7059Z" />
      <defs>
        <clipPath id="clip0">
          <rect width="68" height="17.6" fill="white" transform="translate(32 7.19995)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default Logo