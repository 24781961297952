import React from 'react';
import { CCol, CRow } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { getUnit } from '../redux/actions/unit';
import { setToast } from '../redux/actions/window';
import { useTranslation } from 'react-i18next';
import { AccountStatus } from '../views/users/constant';
import { Button } from './ui/Button';
import styled from 'styled-components';
import { openModal } from '../helper/modals';
import { ModalButtons } from './Modals';
import { editUser } from '../helper';
import { RiPencilFill } from 'react-icons/ri';
import { Palette } from './utils';

const DetailsModalTitleButtons = ({
  user,
  editText,
  inviteText,
  modalName,
  className,
  style,
  handleClick,
  colClassName,
  handleInvite,
  unit,
  disabled
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleActivities = (id) => {
    dispatch(
      changeOpenedModal({
        modal: 'unitStatistics',
        success: () => {
          dispatch(
            getUnit({
              id
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('failed to get unit data!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const showSendInvite = () => {
    return (
      handleInvite &&
      !(user?.status_id === AccountStatus.ENABLED || user?.status_id === AccountStatus.PENDING)
    );
  };

  return (
    <>
      {modalName === 'users' ? (
        <Container>
          {showSendInvite() && (
            <Button
              text={'Resend Invite'}
              onClick={() => {
                handleInvite(user.id);
              }}
            />
          )}
        </Container>
      ) : modalName === 'units' ? (
        <>
          <ModalButtons
            leftButtonText={t('Edit')}
            leftButtonClick={() => openModal('editRoom')}
            rightButtonText={t('Activities')}
            rightButtonClick={() => handleActivities(unit?.id)}
          />
        </>
      ) : modalName === 'suites' ? (
        <>
          <CRow className={className} onClick={handleClick}>
            <CCol className={'flex justify-content-center mt-3'}>
              <Button isPrimary={true} disabled={disabled} text={t('Okay')} />
            </CCol>
          </CRow>
        </>
      ) : (
        <>
          <CRow className={className} onClick={handleClick}>
            <CCol className={'flex justify-content-center mt-3'}>
              <Button
                disabled={disabled}
                text={t('Edit')}
                icon={<RiPencilFill size={18} color={Palette.HAZY_BLUE} />}
                cssOverride={'gap: 5px'}
                width={'150px'}
              />
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: end;
  gap: 18px;
`;

export default DetailsModalTitleButtons;
