import React, { useEffect, useState } from 'react';
import {
  clearUnitsCSVFile,
  clearUnitError,
  addUnitsCSVFile,
  getUnits,
  setUnitPagination
} from '../../../../redux/actions/unit';
import { setToast } from '../../../../redux/actions/window';
import { useDispatch, useSelector } from 'react-redux';
import 'react-dropzone-uploader/dist/styles.css';
import {
  CModalFooter
} from '@coreui/react';
import * as Yup from 'yup';
import {
  getAllBuildings,
  getBuildingSuites,
  getBuildings
} from '../../../../redux/actions/building';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserRole } from '../../../../views/users/constant';
import { useHistory } from 'react-router-dom';
import { delay } from 'lodash';
import { AVAILABLE_TABS } from '..';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { InputSeparator, SettingsModalBoldText } from '../../../settings/SettingsModals';
import { ErrorText } from '../../../../components/utils/AvatarCropper';
import { Button } from '../../../../components/ui/Button';
import styled from 'styled-components';
import { Select, SelectItem } from '../../../../components/ui/Select';

const AddUnitsFromCSVModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { roleId } = useSelector((state) => state.auth);
  const { allBuildings, building, suitesPagination } = useSelector((state) => state.building);
  const { AllOrganizations, orgConfig } = useSelector((state) => state.Organization);
  const { modal, data: dataWithChosenTab } = useSelector((state) => state.headerModal);
  const { unitsCSVFile, error, pagination } = useSelector((state) => state.unit);
  const [loading, setLoading] = useState(false);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);


  const schema = Yup.object().shape({
    building_id: Yup.string(t('Select a building'))
      .required(t('Select a building'))
      .test('is-valid-building', t('Select a building'), (value) => {
        return value && parseInt(value) >= 1;
      }).nullable(),
    organization: Yup.string().test(
      'org-required',
      t('Select an organization'),
      (value, context) => {
        if (roleId == UserRole.SUPER_ADMIN) {
          return value != null && value != '' && parseInt(value) > 0;
        }
        return true;
      }
    )
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    resetField,
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      organization: Boolean(building.org_id) ? building.org_id : '',
      building_id: Boolean(building?.id) ? building.id : '-1'
    }
  });

  const watchOrganization = watch('organization');

  useEffect(() => {
    if (watchOrganization) {
      dispatch(
        getAllBuildings({
          id: parseInt(watchOrganization)
        })
      );
    }
  }, [watchOrganization]);

  useEffect(() => {
    if (AllOrganizations.length > 0 && dataWithChosenTab?.chosenTab === AVAILABLE_TABS.SUITE) {
      setFilteredOrganizations(
        AllOrganizations.filter((org) => org?.config?.suite_enabled)
      );
    } else if (AllOrganizations.length > 0) {
      setFilteredOrganizations(AllOrganizations);
    }
  }, [AllOrganizations]);

  const onSubmit = (values) => {
    setLoading(true);
    const data = new FormData();
    if (dataWithChosenTab?.chosenTab === AVAILABLE_TABS.SUITE) {
      data.append('type', 6);
    } else {
      data.append('type', 5);
    }

    data.append('doc', unitsCSVFile);
    // data.append('email', '');
    data.append('org', values.organization);
    data.append('building', values.building_id);

    dispatch(
      addUnitsCSVFile({
        body: data,
        success: () => {
          delay(() => {
            if (orgConfig?.config?.suite_enabled) {
              dispatch(getBuildingSuites({
                orgId: building.org_id,
                buildingId: building.id,
                page: suitesPagination?.currentPage,
                finally: () => {
                  fetchNewData();
                }
              }));
            } else {
              fetchNewData();
            }
          }, 2000);
        },
        fail: () => {
          setLoading(false);
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Units creation from CSV failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const fetchNewData = () => {

    dispatch(
      setToast({
        toastShow: true,
        toastMessage: dataWithChosenTab?.chosenTab === AVAILABLE_TABS.UNITS && orgConfig?.config?.suite_enabled ? 'Rooms have been successfully added!' : 'Units have been successfully added!'
      })
    );

    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );

    dispatch(clearUnitsCSVFile());
    const buildingIdFromUrl = history.location.pathname.split('/')[2];

    dispatch(
      getUnits({
        id: parseInt(buildingIdFromUrl),
        page: 1
      })
    );
    dispatch(
      setUnitPagination({
        pagination: {
          ...pagination,
          sort: 'floor',
          sortOrder: 'asc'
        }
      })
    );
    dispatch(
      getBuildings({
        id: 0,
        page: 1
      })
    );
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearUnitsCSVFile());
    dispatch(clearUnitError());
  };

  return (
    <StyledModal show={modal === 'AddUnitsFromCSV'} onClose={onClose} closeOnBackdrop={false}>
      <ModalHeader title={dataWithChosenTab?.chosenTab === AVAILABLE_TABS.UNITS && orgConfig?.config?.suite_enabled ? t('Add New Rooms') : t('Add New Units')} />
      <form name="addUnitssFromCSVForm" onSubmit={handleSubmit(onSubmit)}>
        <StyledModalBody>
          {roleId === UserRole.SUPER_ADMIN && (
            <InputSeparator>
              <SettingsModalBoldText htmlFor="country">{t('Organization')}</SettingsModalBoldText>
              <Controller
                control={control}
                name="organization"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Select
                    onBlur={onBlur}
                    onChange={(e) => {
                      setValue('building_id', null);
                      onChange(e);
                    }}
                    custom
                    placeholder={'Select organization'}
                    id="organization"
                    value={value}
                    error={error}>
                    <SelectItem value={-1}>{t('Select organization')}</SelectItem>

                    {filteredOrganizations &&
                      filteredOrganizations.map((item, index) => (
                        <SelectItem value={item.id} key={index}>
                          {item.name}
                        </SelectItem>
                      ))}
                  </Select>
                )}
              />

              {errors.organization && <ErrorText>{errors?.organization.message}</ErrorText>}
            </InputSeparator>
          )}
          <InputSeparator>
            <SettingsModalBoldText htmlFor="Building_ID">{t('Building')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="building_id"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => {
                return (
                  <Select
                    disabled={roleId !== 99 ? false : !getValues('organization')}
                    name="building_id"
                    id="building_id"
                    placeholder={'Please select'}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    custom
                    error={error}>
                    {allBuildings?.length > 0 ? (
                      <SelectItem value={'-1'}>{t('Please select')}</SelectItem>
                    ) : (
                      <SelectItem value={'-1'}>{t('No Buildings Available')}</SelectItem>
                    )}

                    {allBuildings.map((building) => (
                      <SelectItem key={building.id} value={building.id}>
                        {building.name}
                      </SelectItem>
                    ))}
                  </Select>
                );
              }}
            />
            {errors.building_id && <ErrorText>{errors?.building_id.message}</ErrorText>}
          </InputSeparator>

          <CModalFooter>
            {error && <ErrorText>{error}</ErrorText>}

            <ButtonContainer>
              <Button disabled={loading} text={t('Cancel')} onClick={onClose} width={'100px'} />

              <Button
                text={t('Add')}
                isPrimary
                disabled={!Boolean(unitsCSVFile) || loading}
                type="submit"
                width={'100px'}
              />
            </ButtonContainer>
          </CModalFooter>
        </StyledModalBody>
      </form>
    </StyledModal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export default AddUnitsFromCSVModal;
