import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsModalBoldText, SettingsModalBorder } from './SettingsModals';
import AvatarCropper from '../../components/utils/AvatarCropper';
import { updateProfileData } from '../../redux/actions/updateUserProfile';
import { generateImageURLObj } from '../../helper';
import { closeActiveModal, openModal } from '../../helper/modals';
import { ModalButtons, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';

const CreateProfileImageModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updateUserProfile = useSelector((state) => state.updateUserProfile);
  const { profile } = useSelector((state) => state.profile);
  const { modal } = useSelector((state) => state.headerModal);

  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const editor = useRef(null);

  const onClickNext = () => {
    if (newAvatar && newAvatar.image) {
      editor.current.getImageScaledToCanvas().toBlob((blob) => {
        dispatch(
          updateProfileData({
            avatar: {
              blobURL: generateImageURLObj(blob),
              blobData: blob,
              blobName: newAvatar.name
            }
          })
        );
      });
    }
    openModal('CreateProfileModalFinal');
  };

  const onAvatarCancel = () => {
    dispatch(
      updateProfileData({
        avatar: {}
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'CreateProfileImageModal'}
      closeOnBackdrop={false}
      onClose={closeActiveModal}>
      <ModalHeader title={t('Edit Profile')} />

      <StyledModalBody>
        <SettingsModalBoldText>{t('Add Profile Image')}</SettingsModalBoldText>
        <SettingsModalBorder cssOverride={'height: 500px;'} className="pt-2">
          <AvatarCropper
            name={'user-profile-edit'}
            avatar={
              updateUserProfile.avatar.blobURL ? updateUserProfile.avatar.blobURL : profile.avatar
            }
            newAvatarState={{
              newAvatar,
              setNewAvatar
            }}
            editorRef={editor}
            setFormValue={() => {}}
            rounded
            isEdit
            showMaximumFileSizeMessage={false}
            onCancel={() => onAvatarCancel()}
          />
        </SettingsModalBorder>
      </StyledModalBody>

      <ModalButtons
        leftButtonText={'Back'}
        leftButtonClick={() => openModal('CreateProfileModal')}
        rightButtonText={'Done'}
        rightButtonClick={onClickNext}
      />
    </StyledModal>
  );
};

export default CreateProfileImageModal;
