import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsModalBoldText, SettingsModalBorder } from '../../settings/SettingsModals';
import AvatarCropper from '../../../components/utils/AvatarCropper';
import { closeActiveModal, openModal } from '../../../helper/modals';
import { updateOrganizationData } from '../../../redux/actions/addOrganization';
import { generateImageURLObj } from '../../../helper';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';

const AddOrganizationModalStep3 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const addOrganization = useSelector((state) => state.addOrganization);
  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const editor = useRef(null);

  const onSubmit = () => {
    if (newAvatar && newAvatar.image) {
      editor.current.getImageScaledToCanvas().toBlob((blob) => {
        dispatch(
          updateOrganizationData({
            avatar: {
              blobURL: generateImageURLObj(blob),
              blobData: blob,
              blobName: newAvatar.name
            }
          })
        );
      });
    }
    openModal('AddOrganizationModalStep4');
  };

  const onAvatarCancel = () => {
    dispatch(
      updateOrganizationData({
        avatar: {}
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'AddOrganizationModalStep3'}
      closeOnBackdrop={false}
      onClose={closeActiveModal}>
      <ModalHeader title={t('Add Organization')} />

      <StyledModalBody>
        <SettingsModalBoldText>{t('Add Organization Image')}</SettingsModalBoldText>
        <SettingsModalBorder cssOverride={'height: 500px;'} className="pt-2">
          <AvatarCropper
            name={'add-organization-image'}
            avatar={addOrganization.avatar.blobURL}
            newAvatarState={{
              newAvatar,
              setNewAvatar
            }}
            editorRef={editor}
            setFormValue={() => {}}
            rounded
            isEdit
            showMaximumFileSizeMessage={false}
            onCancel={() => onAvatarCancel()}
          />
        </SettingsModalBorder>
      </StyledModalBody>
      <ModalButtons
        leftButtonText={t('Back')}
        leftButtonClick={() => openModal('AddOrganizationModalStep2')}
        rightButtonText={t('Next')}
        rightButtonClick={onSubmit}
      />
    </StyledModal>
  );
};

export default AddOrganizationModalStep3;
