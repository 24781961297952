import React from "react";
import "spinkit/spinkit.min.css";
import { LOADING_BLUE } from "../utils/colorCode";

const LoadingAvatar = ({ color = LOADING_BLUE, height = "16vh" }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height }}
    >
      <table>
        <tbody>
          <tr>
            <td className="table table-align-middle mb-0">
              <div className="sk-grid">
                <div className="sk-grid-cube"></div>
                <div
                  className="sk-grid-cube"
                  style={{ background: color }}
                ></div>
                <div
                  className="sk-grid-cube"
                  style={{ background: color }}
                ></div>
                <div
                  className="sk-grid-cube"
                  style={{ background: color }}
                ></div>
                <div className="sk-grid-cube"></div>
                <div
                  className="sk-grid-cube"
                  style={{ background: color }}
                ></div>
                <div
                  className="sk-grid-cube"
                  style={{ background: color }}
                ></div>
                <div
                  className="sk-grid-cube"
                  style={{ background: color }}
                ></div>
                <div className="sk-grid-cube"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LoadingAvatar;
