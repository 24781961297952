import rsf, { firebaseApp } from "../../firebase";
import { put, all, fork } from "redux-saga/effects";
import {
  showMessage,
  setRegistrationToken,
  registerFCMToken
} from "../actions/notifications";
import {
  GET_NOTIFICATIONS,
  GET_PAGED_NOTIF,
  REQUEST_PERMISSION
} from "../constants";
import apiCall from "../../utils/apiCall";
import { takeLatest } from "redux-saga/effects";

const getNotifications = apiCall({
  type: GET_NOTIFICATIONS,
  method: "get",
  path: "/notifications"
});

const getPagedNotif = apiCall({
  type: GET_PAGED_NOTIF,
  method: "get",
  path: ({ id }) => `/notifications?page=${id}`
});

export function* getNotification() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(GET_PAGED_NOTIF, getPagedNotif);
  yield takeLatest(REQUEST_PERMISSION, requestPermissionSaga);
}

function* requestPermissionSaga() {
  const messaging = firebaseApp.messaging();

  try {
    const token = yield messaging.getToken();
    yield put(setRegistrationToken(token));
  } catch (error) {
    console.warn("Notifications blocked");
  }
}

function* messageHandlerSaga() {
  yield fork(rsf.messaging.syncMessages, {
    successActionCreator: showMessage
  });
}

export function* receiveNotifications() {
  yield requestPermissionSaga();

  yield all([
    fork(messageHandlerSaga),
    rsf.messaging.syncToken({
      successActionCreator: setRegistrationToken
    })
  ]);
}
