import styled from 'styled-components';

// Tailwinds 'animate-pulse'
export const Skeleton = styled.div`
  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  height: ${({ height }) => height || '64px'};
  width: ${({ width }) => width || '64px'};
  border-radius: ${({ radius }) => radius || '0'};

  background-color: hsl(210 3% 85%);
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  ${({ cssOverride }) => cssOverride};
`;
