import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsModalBoldText, SettingsModalBorder } from '../../settings/SettingsModals';
import AvatarCropper from '../../../components/utils/AvatarCropper';
import { openModal } from '../../../helper/modals';
import { generateImageURLObj } from '../../../helper';
import { updateUserTypeData } from '../../../redux/actions/addUserType';
import { addUserTypeTitle, closeAddUserTypeModal } from '../ManageUserComponents';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';

// TODO: This entire component can be reused. Don't even need to break it down into smaller components (unless things change).

const AddNewUserTypeModalStep3 = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const addUserType = useSelector((state) => state.addUserType);
  const dispatch = useDispatch();
  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const editor = useRef(null);

  const onSubmit = () => {
    if (newAvatar && newAvatar.image) {
      editor.current.getImageScaledToCanvas().toBlob((blob) => {
        dispatch(
          updateUserTypeData({
            avatar: {
              blobURL: generateImageURLObj(blob),
              blobData: blob,
              blobName: newAvatar.name
            }
          })
        );
      });
    }
    openModal('AddNewUserTypeModalStep4');
  };

  const onAvatarCancel = () => {
    dispatch(
      updateUserTypeData({
        avatar: {}
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'AddNewUserTypeModalStep3'}
      closeOnBackdrop={false}
      onClose={() => closeAddUserTypeModal(addUserType.editingID)}>
      <ModalHeader
        title={addUserTypeTitle(addUserType)}
        onCloseCallback={() => closeAddUserTypeModal(addUserType.editingID)}
      />

      <StyledModalBody>
        <SettingsModalBoldText>{t('Add Profile Image')}</SettingsModalBoldText>
        <SettingsModalBorder cssOverride={'height: 500px;'} className="pt-2">
          <AvatarCropper
            name={'add-user-image'}
            avatar={addUserType.avatar.blobURL}
            newAvatarState={{
              newAvatar,
              setNewAvatar
            }}
            editorRef={editor}
            setFormValue={() => {}}
            rounded
            isEdit
            showMaximumFileSizeMessage={false}
            onCancel={() => onAvatarCancel()}
          />
        </SettingsModalBorder>
      </StyledModalBody>
      <ModalButtons
        leftButtonText={t('Back')}
        leftButtonClick={() => openModal('AddNewUserTypeModalStep2')}
        rightButtonText={t('Next')}
        rightButtonClick={onSubmit}
      />
    </StyledModal>
  );
};

export default AddNewUserTypeModalStep3;
