import { takeEvery, put } from "redux-saga/effects";
import axios from "axios";
import {
  SEND_WELCOME_EMAILS
} from "../constants";
import apiCall from "../../utils/apiCall";

const emailActivateUsers = apiCall({
  type: SEND_WELCOME_EMAILS,
  method: "post",
  path: "/user/activate"
})

export default function* welcomeEmailSaga () {
  yield takeEvery(SEND_WELCOME_EMAILS,emailActivateUsers)
}
