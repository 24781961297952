export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_IS_ON_REPORTS = 'SET_IS_ON_REPORTS';

export const ADD_BOOKING = 'ADD_BOOKING';
export const GET_BOOKINGS = 'GET_BOOKINGS';
export const GET_BOOKING = 'GET_BOOKING';
export const REMOVE_BOOKING = 'REMOVE_BOOKING';
export const SEARCH_BOOKINGS = 'SEARCH_BOOKINGS';
export const FILTER_BOOKINGS = 'FILTER_BOOKINGS';

export const CLEAR_STATUS = 'CLEAR_STATUS';
export const SEARCH_BOOK_BY_TYPE = 'SEARCH_BOOK_BY_TYPE';
export const SEARCH_BOOK_BY_DATE_RANGE = 'SEARCH_BOOK_BY_DATE_RANGE';

export const SEARCH_BOOK_AMENITIES = 'SEARCH_BOOK_AMENITIES';
export const SEARCH_BOOK_PARKINGS = 'SEARCH_BOOK_PARKINGS';
export const SEARCH_BOOK_ELEVATOR = 'SEARCH_BOOK_ELEVATOR';
export const GET_PARKING_PAR_AM_BOOKINGS = 'GET_PARKING_PAR_AM_BOOKINGS';

export const GET_SELECTED_BOOKINGS = 'GET_SELECTED_BOOKINGS';

export const GET_AMENITY_PAR_AM_BOOKINGS = 'GET_AMENITY_PAR_AM_BOOKINGS';
export const GET_ELEVATOR_PAR_AM_BOOKINGS = 'GET_ELEVATOR_PAR_AM_BOOKINGS';
export const RESET_BOOKING_PAGINATION = 'RESET_BOOKING_PAGINATION';

export const SET_BOOKING_SEARCH = 'SET_BOOKING_SEARCH';
export const GET_PAR_AM_BOOKING = 'GET_PAR_AM_BOOKING';
export const EDIT_PAR_AM_BOOKING = 'EDIT_PAR_AM_BOOKING';
export const ADD_PAR_AM_BOOKING = 'ADD_PAR_AM_BOOKING';
export const EDITING_PAR_AM_BOOKING = 'EDITING_PAR_AM_BOOKING';
export const GET_AVAILABLE_TIME = 'GET_AVAILABLE_TIME';
export const SET_PARAM_ERROR = 'SET_PARAM_ERROR';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const BOOKING_FROM_AMENITY = 'BOOKING_FROM_AMENITY';
export const CLEAR_PAR_AM_BOOKING = 'CLEAR_PAR_AM_BOOKING';

//booking amenities
export const CREATE_AMENITY_BOOKING = 'CREATE_AMENITY_BOOKING';
export const EDITING_BOOKING = 'EDITING_BOOKING';
export const CANCEL_AMENITY_BOOKING = 'CANCEL_AMENITY_BOOKING';
export const EDIT_AMENITY_BOOKING = 'EDIT_AMENITY_BOOKING';
export const SHOW_EDIT_AMENITY_BOOKING_MODAL = 'SHOW_EDIT_AMENITY_BOOKING_MODAL';
export const SHOW_CANCEL_AMENITY_BOOKING_MODAL = 'SHOW_CANCEL_AMENITY_BOOKING_MODAL';
export const ALL_AMENITY_BOOKINGS = 'ALL_AMENITY_BOOKINGS';
export const GET_AMENITY_TYPES = 'GET_AMENITY_TYPES';
//booking amenities

//conversation sent actions
export const SET_DUPLICATE_TEMP_NAME = 'SET_DUPLICATE_TEMP_NAME';
export const CHANGE_SENT_PAGE = 'CHANGE_SENT_PAGE';
export const SHOW_MODAL_FOR_SEND_MESSAGE = 'SHOW_MODAL_FOR_SEND_MESSAGE';
export const SENT_MESSAGES = 'SENT_MESSAGES';
export const SENT_MESSAGE_DETAILS = 'SENT_MESSAGE_DETAILS';
export const GET_ALL_TEMPLATES_DATA = 'GET_ALL_TEMPLATES_DATA';
export const GET_CATEGORY_MAPPING = 'GET_CATEGORY_MAPPING';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const CHANGED_TEMPLATE_DATA = 'CHANGED_TEMPLATE_DATA';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGE_BODY = 'GET_MESSAGE_BODY';
export const SET_RECIPIENTS_LENGTH = 'SET_RECIPIENTS_LENGTH';
export const SEND_DRAFT_TEMPLATE = 'SEND_DRAFT_TEMPLATE';

//conversation sent actions

//Modal Open Constant

export const SET_MODAL = 'SET_MODAL';
export const SET_MODAL_sTACK = 'SET_MODAL_sTACK';

//this will be used everywehre to get all the tempaltes.
export const ALL_TEMPLATES = 'ALL_TEMPLATES';

//conversation template actions
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const TEMPLATE_SELECTED = 'TEMPLATE_SELECTED';
export const TEMPLATE_DATA = 'TEMPLATE_DATA';
export const IS_MODAL_VISIBLE = 'IS_MODAL_VISIBLE';
export const EDIT_THIS_TEMPLATE = 'EDIT_THIS_TEMPLATE';
export const SHOW_MODAL_FOR_TEMPLATE = 'SHOW_MODAL_FOR_TEMPLATE';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SELECT_USER = 'SELECT_USER';
export const SET_TREE_DATA = 'SET_TREE_DATA';
export const SET_PARSED_TEMPLATE = 'SET_PARSED_TEMPLATE';
export const SEND_TEMPLATE = 'SEND_TEMPLATE';
export const CLEAR_TREE = 'CLEAR_TREE';
export const SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA';
export const CHANGE_SHOW_BUTTON = 'CHANGE_SHOW_BUTTON';
export const SEND_TEMPLATE_IMAGE = 'SEND_TEMPLATE_IMAGE';
export const SET_SELECTIION_TEXT = 'SET_SELECTIION_TEXT';
export const SET_PINNED_NEWS = 'SET_PINNED_NEWS';
export const SET_TITLE = 'SET_TITLE';
export const SET_IS_FETCHING_TEMPLATES = 'SET_IS_FETCHING_TEMPLATES';
export const SET_SELECT_USER_HELPERS = 'SET_SELECT_USER_HELPERS';
export const GET_DRAFT_DETAILS = 'GET_DRAFT_DETAILS';
export const UPDATE_DRAFT_DETAILS = 'UPDATE_DRAFT_DETAILS';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const RESET_DRAFT_DETAILS = 'RESET_DRAFT_DETAILS';

//conversation template actions
export const ADD_SERVICE_REQUEST_CACHE_USER = 'ADD_SERVICE_REQUEST_CACHE_USER';
export const INVITE_USER = 'INVITE_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_USERS_FROM_CSV = 'ADD_USERS_FROM_CSV';
export const CLEAR_USER = 'CLEAR_USER';
export const EDIT_USER = 'EDIT_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USER = 'GET_USER';
export const SEARCH_USERS = 'SEARCH_USERS';
export const BUILDING_USERS = 'BUILDING_USERS';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const SET_USER = 'SET_USER';
export const SET_USERS_FROM_CSV = 'SET_USERS_FROM_CSV';
export const CLEAR_USERS_FROM_CSV = 'CLEAR_USERS_FROM_CSV';
export const SET_USERS_CSV_FILE = 'SET_USERS_CSV_FILE';
export const CLEAR_USERS_CSV_FILE = 'CLEAR_USERS_CSV_FILE';
export const CLEAR_AVATAR = 'CLEAR_AVATAR';
export const GET_USER_DEVICE_SLOTS = 'GET_USER_DEVICE_SLOTS';
export const SET_USER_DEVICE_SLOTS = 'SET_USER_DEVICE_SLOTS';
export const SET_USERS_ACTIVATED = 'SET_USERS_ACTIVATED';
export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const RESET_USER_PAGINATION = 'RESET_USER_PAGINATION';
export const NOTIFY_UNIT_PIN = 'NOTIFY_UNIT_PIN';
export const SET_UNITS_CSV_FILE = 'SET_UNITS_CSV_FILE';
export const CLEAR_UNITS_CSV_FILE = 'CLEAR_UNITS_CSV_FILE';
export const ADD_UNITS_FROM_CSV = 'ADD_UNITS_FROM_CSV';
export const SET_UNIT_PAGINATION = 'SET_UNIT_PAGINATION';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_CONFLICTING_DATES = 'GET_CONFLICTING_DATES';
export const CLEAR_CONFLICTING_DATES_DATA = 'CLEAR_CONFLICTING_DATES_DATA';
export const GET_RESERVATIONS = 'GET_RESERVATIONS';
export const CLEAR_RESERVATIONS = 'CLEAR_RESERVATIONS';

export const SET_ORG_ID = 'SET_ORG_ID';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORG_CONFIG = 'GET_ORG_CONFIG';
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION';
export const GET_ALL_UNITS_IN_ORG = 'GET_ALL_UNITS_IN_ORG';
export const GET_PM_ANALYTICS = 'GET_PM_ANALYTICS';
export const GET_PM_ANALYTICS_PENDING = 'GET_PM_ANALYTICS_PENDING';
export const GET_PM_ACTIVITIES = 'GET_PM_ACTIVITIES';
export const GET_PM_ACTIVITIES_PENDING = 'GET_PM_ACTIVITIES_PENDING';
export const SET_PM_DETAILS = 'SET_PM_DETAILS';

export const GET_ALL_ORGANIZATIONS = 'GET_ALL_ORGANIZATIONS';
export const SET_ALL_ORG_STATUS = 'SET_ALL_ORG_STATUS';

export const SEARCH_ORGANIZATIONS = 'SEARCH_ORGANIZATIONS';
export const SET_GLOBAL_ORG_ID = 'SET_GLOBAL_ORG_ID';
export const SET_GLOBAL_ORG = 'SET_GLOBAL_ORG';
export const UPLOAD_ORG_LOGO = 'UPLOAD_ORG_LOGO';

export const SEARCH_BUSINESSES = 'SEARCH_BUSINESSES';
export const ADD_BUSINESS = 'ADD_BUSINESS';
export const REMOVE_BUSINESS = 'REMOVE_BUSINESS';
export const EDIT_BUSINESS = 'EDIT_BUSINESS';
export const BUSINESS_SELECTED = 'BUSINESS_SELECTED';
export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_BUSINESSES = 'GET_BUSINESSES';
export const SET_BUSINESSES_LOGO = 'SET_BUSINESSES_LOGO';
export const UN_SET_BUSINESSES_LOGO = 'UN_SET_BUSINESSES_LOGO';
export const CLEAR_BUSINESS = 'CLEAR_BUSINESS';

export const SEARCH_BUILDINGS = 'SEARCH_BUILDINGS';
export const ADD_BUILDING = 'ADD_BUILDING';
export const SET_BUILDING_IMAGE = 'SET_BUILDING_IMAGE';
export const GET_BUILDING_UNITS = 'GET_BUILDING_UNITS';
export const GET_BUILDING_DEVICES = 'GET_BUILDING_DEVICES';
export const SET_GLOBAL_BUILDING = 'SET_GLOBAL_BUILDING';
export const GET_BUILDING_SUITES = 'GET_BUILDING_SUITES';
export const SET_GLOBAL_BUILDING_ID = 'SET_GLOBAL_BUILDING_ID';
export const EDIT_BUILDING = 'EDIT_BUILDING';
export const CLEAR_BUILDING = 'CLEAR_BUILDING';
export const REMOVE_BUILDING = 'REMOVE_BUILDING';
export const GET_BUILDING = 'GET_BUILDING';
export const GET_EDITED_BUILDINGS = 'GET_EDITED_BUILDINGS';
export const GET_BUILDINGS = 'GET_BUILDINGS';
export const GET_ALL_BUILDINGS = 'GET_ALL_BUILDINGS';
export const SET_CACHED_BUILDINGS = 'SET_CACHED_BUILDINGS';
export const SET_LOADING_CACHED_BUILDINGS = 'SET_LOADING_CACHED_BUILDINGS';

export const SET_ALL_BUILDINGS = 'SET_ALL_BUILDINGS';
export const INCREMENT_BUILDING_EVENT_COUNT = 'INCREMENT_BUILDING_EVENT_COUNT';
export const RESET_GLOBAL_BUILDINGS = 'RESET_GLOBAL_BUILDINGS';
export const SET_GLOBAL_BUILDING_STATUS = 'SET_GLOBAL_BUILDING_STATUS';

export const SET_BUILDING_AVATAR = 'SET_BUILDING_AVATAR';

export const GET_DD_UNITS = 'GET_DD_UNITS';
export const CLEAR_UNIT_CREATION_RESPONSE = 'CLEAR_UNIT_CREATION_RESPONSE';
export const SET_UNIT_DATA = 'SET_UNIT_DATA';
export const ADD_UNIT = 'ADD_UNIT';
export const GET_DOOR_PIN = 'GET_DOOR_PIN';
export const EDIT_UNIT = 'EDIT_UNIT';
export const REMOVE_UNIT = 'REMOVE_UNIT';
export const GET_UNIT = 'GET_UNIT';
export const GET_SUITE = 'GET_SUITE';
export const GET_UNITS = 'GET_UNITS';
export const FILTER_UNITS = 'FILTER_UNITS';
export const CLEAR_BUILDINGS = 'CLEAR_BUILDINGS';

export const SET_AMENITY_TYPE = 'SET_AMENITY_TYPE';
export const GET_AMENITY = 'GET_AMENITY';
export const REMOVE_AMENITY = 'REMOVE_AMENITY';
export const GET_AMENITIES = 'GET_AMENITIES';
export const ADD_AMENITIES = 'ADD_AMENITIES';
export const EDIT_AMENITIES = 'EDIT_AMENITIES';
export const CLEAR_AMENITY = 'CLEAR_AMENITY';
export const FILTER_AMENITIES = 'FILTER_AMENITIES';
export const MODAL_SHOW_AMENITY_DETAIL = 'MODAL_SHOW_AMENITY_DETAIL';
export const SET_FILTERED_AMENITIES = 'SET_FILTERED_AMENITIES';
export const CLEAR_FILTERED_AMENITIES = 'CLEAR_FILTERED_AMENITIES';
export const CLEAR_AMENITIES = 'CLEAR_AMENITIES';

export const ADD_AVATAR = 'ADD_AVATAR';
export const EDIT_AVATAR = 'EDIT_AVATAR';
export const GET_AVATAR = 'GET_AVATAR';

export const SET_IS_ON_DASHBOARD = 'SET_IS_ON_DASHBOARD';
export const SET_TOAST = 'SET_TOAST';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_SIDEBAR_TYPE = 'SET_SIDEBAR_TYPE';
export const SET_ASIDE = 'SET_ASIDE';
export const SET_MODE = 'SET_MODE';

export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICE_COMMENTS = 'GET_SERVICES_COMMENTS';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';
export const SET_SERVICE_SEARCH = 'SET_SERVICE_SEARCH';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_COMMENT_ID = 'GET_COMMENT_ID';
export const ADD_COMMENT_DYNAMIC = 'ADD_COMMENT_DYNAMIC';
export const FILTER_SERVICES = 'FILTER_SERVICES';
export const SERVICE_CATEGORY = 'SERVICE_CATEGORY';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const COMPLETE_SERVICE = 'COMPLETE_SERVICE';
export const SET_ASSIGNED_OPERATOR_PROCESS = 'SET_ASSIGNED_OPERATOR_PROCESS';
export const RESET_SERVICE_PAGINATION = 'RESET_SERVICE_PAGINATION';
export const CLEAR_SERVICE_LIST = 'CLEAR_SERVICE_LIST';
export const SET_SERVICE_STATUS_FILTER = 'SET_SERVICE_STATUS_FILTER';

export const SET_CURRENT_AMENITY_TYPE = 'SET_CURRENT_AMENITY_TYPE';

export const GET_PROFILE = 'GET_PROFILE';
export const GET_SETTINGS = 'GET_SETTINGS';
export const SET_PREVIOUS_PROFILE_IMG = 'SET_PREVIOUS_PROFILE_IMG';
export const GET_PROFILE_AVATAR = 'GET_PROFILE_AVATAR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const UPLOAD_PROFILE_AVATAR = 'UPLOAD_PROFILE_AVATAR';

export const LOG_IN = 'LOG_IN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_EMAIL = 'SET_EMAIL';
export const LOG_OUT = 'LOG_OUT';
export const FETCH_REFRESH_TOKEN = 'FETCH_REFRESH_TOKEN';
export const CHANGE_LOGIN_PAGE = 'CHANGE_LOGIN_PAGE';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const CONFIRM_OTP = 'CONFIRM_OTP';
export const SET_LOGIN_TRUE = 'SET_LOGIN_TRUE';
export const SET_LOGIN_FALSE = 'SET_LOGIN_FALSE';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';

export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_ID_TOKEN = 'SET_ID_TOKEN';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_ROLE_ID = 'SET_ROLE_ID';

export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_STATE = 'GET_STATE';
export const CLEAR_COUNTRY = 'CLEAR_COUNTRY';
// offers
export const SET_OFFER_TREE_DATA = 'SET_OFFER_TREE_DATA';

export const SET_IS_EDITING = 'SET_IS_EDITING';
export const ADD_OFFER = 'ADD_OFFER';
export const SET_OFFER_TEMP = 'SET_OFFER_TEMP';
export const SET_OFFER_PAGE = 'SET_OFFER_PAGE';
export const CLEAR_LOGO = 'CLEAR_LOGO';
export const DELETE_OFFER = 'DELETE_OFFER';
export const ADD_LOGO = 'ADD_LOGO';
export const EDIT_OFFER = 'EDIT_OFFER';
export const GET_OFFERS = 'GET_OFFERS';
export const CLEAR_OFFER = 'CLEAR_OFFER';
export const GET_OFFER = 'GET_OFFER';
export const SEARCH_OFFERS = 'SEARCH_OFFERS';
export const GET_ACTIVE_OFFERS = 'GET_ACTIVE_OFFERS';
export const GET_EXPIRED_OFFERS = 'GET_EXPIRED_OFFERS';
export const GET_SUBMISSIONED_OFFERS = 'GET_SUBMISSIONED_OFFERS';
export const CHANGE_TAB = 'CHANGE_TAB';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const GET_PAGED_NOTIF = 'GET_PAGED_NOTIF';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_REGISTRATION_TOKEN = 'SET_REGISTRATION_TOKEN';
export const MARK_NOTIFICATIONS_AS_READ = 'MARK_NOTIFICATIONS_AS_READ';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIF_PAGE = 'SET_NOTIF_PAGE';

export const REGISTER_FCM_TOKEN = 'REGISTER_FCM_TOKEN';
export const SET_FCM_TOKEN = 'SET_FCM_TOKEN';
export const UPDATE_MY_SETTINGS = 'UPDATE_MY_SETTINGS';

export const LOGGED_IN_USER_AVATAR = 'LOGGED_IN_USER_AVATAR';
export const NON_LOGGED_IN_USER_AVATAR = 'NON_LOGGED_IN_USER_AVATAR';
export const SET_LOGGED_IN_USER_AVATAR = 'SET_LOGGED_IN_USER_AVATAR';

//Reports
export const GET_LOGS = 'GET_LOGS';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_ACTIVATION_LOGS = 'GET_ACTIVATION_LOGS';
export const GET_REQUEST_TURN_AROUND_TIME = 'GET_REQUEST_TURN_AROUND_TIME';
export const SET_CSV_URL = 'SET_CSV_URL';
export const SET_CSV_FIELDS = 'SET_CSV_FIELDS';
export const GET_TOTAL_METRICS = 'GET_TOTAL_METRICS';
export const GET_DEALS_STATS = 'GET_DEALS_STATS';
export const GET_DEAL_STATS = 'GET_DEAL_STATS';
export const OPEN_DEAL_STATS_MODAL = 'OPEN_DEAL_STATS_MODAL';
export const CLOSE_REPORTS_MODAL = 'CLOSE_REPORTS_MODAL';

// Search
export const SEARCH = 'SEARCH';
export const FILTERS = 'FILTERS';
export const SET_SEARCH_FIELD = 'SET_SEARCH_FIELD';
export const SET_SHOULD_CLEAR_QUERY = 'SET_SHOULD_CLEAR_QUERY';

// TODO: Remove this in the future and move all filters under the filters object
export const SET_SEARCH_STATE = 'SET_SEARCH_STATE';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const REMOVE_SEARCH_FILTER = 'REMOVE_SEARCH_FILTER';
export const SET_SEARCH_COLUMN_FILTER = 'SET_SEARCH_COLUMN_FILTER';
export const SET_SEARCH_PAGINATION = 'SET_SEARCH_PAGINATION';
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER';
export const RESET_SEARCH_FILTER = 'RESET_SEARCH_FILTER';

export const REQUEST_PERMISSION = 'REQUEST_PERMISSION';

// Email Selected
export const SELECT_EMAIL_RECIPIENT = 'SELECT_EMAIL_RECIPIENT';
export const RESET_EMAIL_RECIPIENT = 'RESET_EMAIL_RECIPIENT';
export const SEND_WELCOME_EMAILS = 'SEND_WELCOME_EMAILS';
export const SELECT_ALL_RECIPIENTS = 'SELECT_ALL_RECIPIENTS';
export const REMOVE_SELECTED_RECIPIENTS = 'REMOVE_SELECTED_RECIPIENTS';

// Security
export const GET_COMMON_ACCESS = 'GET_COMMON_ACCESS';

// Global push notification to all users
export const SEND_ALL_USERS_NOTIFICATION = 'SEND_ALL_USERS_NOTIFICATION';

export const GET_DELIVERY_CODES = 'GET_DELIVERY_CODES';
export const ADD_DELIVERY_CODE = 'ADD_DELIVERY_CODE';
export const REMOVE_DELIVERY_CODE = 'REMOVE_DELIVERY_CODE';

export const GET_DEVICES_FOR_SUITES = 'GET_DEVICES_FOR_SUITES';
export const ADD_NEW_SUITE = 'ADD_NEW_SUITE';
export const RESET_CSV_SUITES = 'RESET_CSV_SUITES';
export const SET_CSV_SUITES = 'SET_CSV_SUITES';
export const DELETE_A_SUITE = 'DELETE_A_SUITE';
export const GET_A_SUITE = 'GET_A_SUITE';
export const EDIT_A_SUITE = 'EDIT_A_SUITE';
export const RESET_SUITE_IN_BUILDING = 'RESET_SUITE_IN_BUILDING';
export const SET_SEARCH_TEXT_SUITE = 'SET_SEARCH_TEXT_SUITE';
export const REMOVE_SEARCH_TEXT_SUITE = 'REMOVE_SEARCH_TEXT_SUITE';
