import React, { useState } from 'react';
import 'rc-time-picker/assets/index.css';
import { CModalBody } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import { LOADING_BLUE } from '../../utils/colorCode';
import LoadingAvatar from '../../components/LoadingAvatar';
import { useTranslation } from 'react-i18next';
import ModalEditBooking from './ModalEditBooking';
import { clearAmenity } from '../../redux/actions/amenities';
import { clearBookingError, editingParAmBooking } from '../../redux/actions/bookingParAm';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { ModalHeader, StyledModal } from '../../components/Modals';

const EditModalContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.headerModal);
  const [firstLoading, setFirstLoading] = useState(true);

  const onClose = () => {
    setFirstLoading(true);
    dispatch(clearAmenity());
    dispatch(editingParAmBooking({ editBooking: false }));
    dispatch(changeOpenedModal({ modal: '' }));
    dispatch(clearBookingError());
  };

  return (
    <div className="">
      {modal === 'editBookingLoading' ||
        (modal === 'editBooking' && (
          <StyledModal
            show={modal === 'editBookingLoading' || modal === 'editBooking'}
            size=""
            closeOnBackdrop={false}
            onClose={onClose}>
            <ModalHeader title={t('Edit Booking')} />
            {modal === 'editBookingLoading' && (
              <CModalBody>
                <LoadingAvatar color={LOADING_BLUE} />
              </CModalBody>
            )}
            {modal === 'editBooking' && (
              <ModalEditBooking
                firstLoading={firstLoading}
                setFirstLoading={setFirstLoading}
                onClose={onClose}
              />
            )}
          </StyledModal>
        ))}
    </div>
  );
};

export default EditModalContent;
