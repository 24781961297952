import { CButton } from "@coreui/react";
import React from "react";

const LoadingButton = ({
  text,
  styling = {},
  onClick = () => {},
  showLoader,
  cstmClass = "btn-primary",
  disabled = false,
  type = "button"
}) => {
  const styles = {
    loadingBtn: {
      width: "15px",
      height: "15px",
      marginRight: "5px"
    }
  };

  return (
    <CButton className={cstmClass} onClick={onClick} disabled={disabled} type={type} style={styling}>
      <div
        style={styles.loadingBtn}
        className={showLoader ? "spinner-border" : "d-none"}
        role="status"
      >
        <span className="visually-hidden"></span>
      </div>
      {text}
    </CButton>
  );
};

export default LoadingButton;
