import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_PROFILE,
  CHANGE_PASSWORD,
  EDIT_PROFILE,
  UPLOAD_PROFILE_AVATAR,
  GET_PROFILE_AVATAR,
  REGISTER_FCM_TOKEN,
  SET_FCM_TOKEN,
  RESET_PASSWORD,
  UPDATE_MY_SETTINGS,
  GET_SETTINGS
} from '../constants';
import apiCall from '../../utils/apiCall';

const changePassword = apiCall({
  type: CHANGE_PASSWORD,
  method: 'post',
  path: '/my/password'
});

const editProfile = apiCall({
  type: EDIT_PROFILE,
  method: 'put',
  path: '/my/profile'
});

const getProfileAvatar = apiCall({
  type: GET_PROFILE_AVATAR,
  method: 'get',
  path: ({ id }) => `/images/${id}`,
  isFormData: true,
  isBlob: true
});

const uploadProfileAvatar = apiCall({
  type: UPLOAD_PROFILE_AVATAR,
  method: 'post',
  path: '/images',
  isFormData: true
});

const getProfile = apiCall({
  type: GET_PROFILE,
  method: 'get',
  path: '/my/profile'
});

const registerMyFCMToken = apiCall({
  type: REGISTER_FCM_TOKEN,
  method: 'post',
  path: '/my/register'
});

const setMyFCMToken = apiCall({
  type: SET_FCM_TOKEN,
  method: 'put',
  path: '/my/settings'
});

const getSettings = apiCall({
  type: GET_SETTINGS,
  method: 'get',
  path: '/my/settings'
});

const updateMySettings = apiCall({
  type: UPDATE_MY_SETTINGS,
  method: 'put',
  path: '/my/settings',
  isFormData: true
});

const resetPassword = apiCall({
  type: RESET_PASSWORD,
  method: 'post',
  path: '/reset-password'
});

export default function* profileSaga() {
  yield takeLatest(GET_PROFILE, getProfile);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(UPLOAD_PROFILE_AVATAR, uploadProfileAvatar);
  yield takeEvery(GET_PROFILE_AVATAR, getProfileAvatar);
  yield takeLatest(REGISTER_FCM_TOKEN, registerMyFCMToken);
  yield takeLatest(SET_FCM_TOKEN, setMyFCMToken);
  yield takeEvery(GET_SETTINGS, getSettings);
  yield takeEvery(UPDATE_MY_SETTINGS, updateMySettings);
  yield takeEvery(RESET_PASSWORD, resetPassword);
}
