import {
  ADD_BUILDING,
  EDIT_BUILDING,
  GET_BUILDING,
  GET_BUILDINGS,
  GET_BUILDING_UNITS,
  REMOVE_BUILDING,
  SEARCH_BUILDINGS,
  GET_ALL_BUILDINGS,
  SET_BUILDING_IMAGE,
  SET_GLOBAL_BUILDING,
  SET_CACHED_BUILDINGS,
  GET_DELIVERY_CODES,
  ADD_DELIVERY_CODE,
  REMOVE_DELIVERY_CODE,
  GET_BUILDING_SUITES,
  GET_BUILDING_DEVICES
} from '../constants';

import apiCall, { HTTP_METHOD } from '../../utils/apiCall';
import { takeEvery, takeLatest } from 'redux-saga/effects';

const addBuilding = apiCall({
  type: ADD_BUILDING,
  method: 'post',
  path: '/buildings'
});

// todo: change params - allow bat to be customized
const getBuildingsDevices = apiCall({
  type: GET_BUILDING_DEVICES,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/devices?o=${id}&bat=1`
});

const getBuildingUnits = apiCall({
  type: GET_BUILDING_UNITS,
  method: 'get',
  path: ({ id }) => `/buildings/${id}/units`
});

const editBuilding = apiCall({
  type: EDIT_BUILDING,
  method: 'put',
  path: ({ id }) => `/buildings/${id}`
});

const getBuilding = apiCall({
  type: GET_BUILDING,
  method: 'get',
  path: ({ id }) => `/buildings/${id}`
});

const getBuildings = apiCall({
  type: GET_BUILDINGS,
  method: 'get',
  path: ({ id, page = 1 }) => {
    let url = `/orgs/${id}/buildings?page=${page}`;
    return url;
  }
});

const getAllBuildings = apiCall({
  type: GET_ALL_BUILDINGS,
  method: 'get',
  path: ({ id }) => {
    let url = `/orgs/${id}/buildings`;
    return url;
  }
});

const setCachedBuildings = apiCall({
  type: SET_CACHED_BUILDINGS,
  method: 'get',
  path: ({ id }) => {
    let url = `/orgs/${id}/buildings?s=y`;
    return url;
  }
});

const setGlobalBuilding = apiCall({
  type: SET_GLOBAL_BUILDING,
  method: 'get',
  path: ({ id, page = 1 }) => `/orgs/${id}/buildings?page=${page}`
});

const setbuildingImg = apiCall({
  type: SET_BUILDING_IMAGE,
  method: 'post',
  path: '/images',
  isFormData: true
});

const removeBuilding = apiCall({
  type: REMOVE_BUILDING,
  method: 'delete',
  path: ({ id }) => `/buildings/${id}`
});

const searchBuildings = apiCall({
  type: SEARCH_BUILDINGS,
  method: 'get',
  newUrl: true,
  path: ({ query, page = 1, perpage = 20, search_col = null, org = 0 }) => {
    let url = `/search?entity=building&q=${query}&page=${page}&per_page=${perpage}`;
    if (search_col) {
      url += `&search_col=${search_col}`;
    }
    if (org > 0) {
      url += `&org=${org}`;
    }
    return url;
  }
});

const getDeliveryCodes = apiCall({
  type: GET_DELIVERY_CODES,
  method: 'get',
  path: ({ orgId, buildingId }) => {
    let url = `/delivery-codes`;

    if (!isNaN(orgId)) {
      url += `?org=${orgId}`;
    }

    if (!isNaN(orgId) && !isNaN(buildingId)) {
      url += `&building=${buildingId}`;
    }

    return url;
  }
});

const addDeliveryCode = apiCall({
  type: ADD_DELIVERY_CODE,
  method: 'post',
  path: () => {
    let url = '/delivery-codes';
    return url;
  }
});

const removeDeliveryCode = apiCall({
  type: REMOVE_DELIVERY_CODE,
  method: 'delete',
  path: ({ id }) => {
    let url = `/delivery-codes/${id}`;
    return url;
  }
});

const getSuitesByOrgAndBuilding = apiCall({
  type: GET_BUILDING_SUITES,
  method: 'get',
  path: ({ orgId, buildingId, page = 1, per_page = 10, searchText }) => {
    let url = '/search?entity=suite&q=';
    if (searchText && searchText !== '') {
      url += searchText;
    }
    url += `&page=${page}&per_page=${per_page}&building=${buildingId}&org=${orgId}`;
    return url;
  }
});

export default function* buildingSaga() {
  yield takeLatest(REMOVE_BUILDING, removeBuilding);
  yield takeLatest(SEARCH_BUILDINGS, searchBuildings);
  yield takeLatest(ADD_BUILDING, addBuilding);
  yield takeLatest(EDIT_BUILDING, editBuilding);
  yield takeLatest(GET_BUILDINGS, getBuildings);
  yield takeLatest(GET_BUILDING_DEVICES, getBuildingsDevices);
  yield takeLatest(GET_BUILDING, getBuilding);
  yield takeLatest(GET_BUILDING_UNITS, getBuildingUnits);
  yield takeLatest(SET_GLOBAL_BUILDING, setGlobalBuilding);
  yield takeLatest(SET_BUILDING_IMAGE, setbuildingImg);
  yield takeLatest(GET_ALL_BUILDINGS, getAllBuildings);
  yield takeEvery(SET_CACHED_BUILDINGS, setCachedBuildings);
  yield takeLatest(GET_DELIVERY_CODES, getDeliveryCodes);
  yield takeLatest(ADD_DELIVERY_CODE, addDeliveryCode);
  yield takeLatest(REMOVE_DELIVERY_CODE, removeDeliveryCode);
  yield takeLatest(GET_BUILDING_SUITES, getSuitesByOrgAndBuilding);
}
