import { Fonts, Palette } from '../../components/utils';
import styled from 'styled-components';

const containerStyles = `
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

export const Container = styled.div`
  ${containerStyles}
`;

export const NotFoundContainer = styled.div`
  ${containerStyles}
  align-items: center;
  justify-content: center;
`;

export const ConversationButtonText = styled.p`
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
`;

export const TitleText = styled.p`
  color: ${Palette.DARK_ASH};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;

  margin-bottom: 0;
`;

export const StepText = styled.p`
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  margin-bottom: 0;
`;
