import { CCol, CImg, CRow } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultImgCDN, imageString } from '../../../../helper';
import {
  addCommentDynamic,
  addComment,
  getServiceComments
} from '../../../../redux/actions/services';
import { setToast } from '../../../../redux/actions/window';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from '../../constant';
import moment from 'moment';
import styled from 'styled-components';
import { SettingsModalBoldText } from '../../../settings/SettingsModals';
import { StyledDateText } from '../../ServiceComponents';
import { Fonts, Palette } from '../../../../components/utils';
import { IoIosSend } from 'react-icons/io';
import { Button } from '../../../../components/ui/Button';
import { TextArea } from '../../../../components/ui/TextArea';

const ChatBox = () => {
  const { t, i18n } = useTranslation();
  const { comments, commentId, service } = useSelector((state) => state.services);
  const [errorImage, setErrorImage] = useState('');
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const messagesDiv = useRef();

  useEffect(() => {
    const interval = getCommets();

    return () => {
      clearInterval(interval);
    };
  });

  const getCommets = () => {
    const interval = setInterval(() => {
      let lastCmntId = comments[comments.length - 1]?.id;
      dispatch(getServiceComments({ id: service.id, commentId: lastCmntId || 0 }));
    }, 15000);
    return interval;
  };

  const sendMEssage = () => {
    setError('');

    if (service.status !== 'Complete') {
      if (message === '') {
        setError('Kindly type a message');
      } else if (
        message.match(
          /^[a-zA-Z0-9\s\~\`\[\]\{\}\:\"\'\<\>\,\.\?\/\|\\!@#\$%\^\&*\)\(+=._-]{0,500}$/i
        )
      ) {
        setIsSubmitting(true);
        dispatch(
          addComment({
            id: commentId.commentId,
            body: {
              comment: message
            },
            success: () => {
              setIsSubmitting(false);
              dispatch(addCommentDynamic(message));
              setMessage('');
            },
            fail: () => {
              setIsSubmitting(false);
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Message not sent!'),
                  modal: 'errorToast'
                })
              );
            }
          })
        );
      } else {
        setError(t("Can't Enter Special Characters and ; symbol"));
      }
    }
  };
  const submitOnEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && message.length) {
      sendMEssage();
      setMessage('');
    }
  };

  // this is to scroll the chat to the bottomof height
  useEffect(() => {
    if (comments && comments.length) {
      messagesDiv.current.scrollTop = messagesDiv.current.scrollHeight;
    }
  }, [comments]);

  //TODO: ChatAreaMainDiv - Original div style, has styles to be responsive
  return (
    <StyledChatBoxContainer>
      <MessageContainer ref={messagesDiv}>
        {comments && comments.length ? (
          comments.map((comment, index) => {
            return (
              <div key={index} id={index}>
                <CRow>
                  <CCol sm="2">
                    <div
                      style={{
                        width: '40px',
                        margin: '0 auto'
                      }}>
                      <CImg
                        onError={() => setErrorImage(defaultImgCDN)}
                        src={errorImage === '' ? imageString(comment.avatar) : errorImage}
                        className="rounded-circle"
                        style={{ width: '40px', height: '40px' }}
                      />
                    </div>
                  </CCol>
                  <CCol>
                    <SettingsModalBoldText>
                      {comment.first_name} {comment.last_name}
                    </SettingsModalBoldText>
                    <UserRoleText className="text-muted userType">{comment.user_type}</UserRoleText>
                  </CCol>
                  <CCol>
                    <StyledDateText cssOverride={'text-align: right;'}>
                      {comment &&
                        comment.create_date &&
                        moment(comment.create_date).format('MMMM DD, YYYY,')}
                    </StyledDateText>
                    <StyledDateText cssOverride={'text-align: right;'}>
                      {comment &&
                        comment.create_date &&
                        moment(comment.create_date).format('hh:mm a')}
                    </StyledDateText>
                  </CCol>
                </CRow>
                <CRow
                  className={comment.user_type !== 'Technician' ? 'nontechnician' : 'Technician'}>
                  <CCol>
                    <MessageText>{comment.comment}</MessageText>
                  </CCol>
                </CRow>
              </div>
            );
          })
        ) : (
          <>
            <div className="noConvImgDiv">
              <CImg
                className="noChatImg"
                src={
                  i18n.language === 'en-US' || i18n.language === 'en'
                    ? '../noConversationImg.png'
                    : '../noConversationImgFr.png'
                }
                width="100%"
                alt="img"
              />
            </div>
          </>
        )}
      </MessageContainer>

      <MessageBoxContainer>
        <TextArea
          placeholder={'Write your message here...'}
          cssOverride={'max-height: 100px; min-height: 60px; width: 80%;'}
          rows={1}
          onKeyPress={submitOnEnter}
          disabled={
            service.status === 'Complete' ||
            service.status === RequestStatus.ARCHIVED ||
            isSubmitting
          }
          style={
            service.status === 'Complete' || service.status === RequestStatus.ARCHIVED
              ? { cursor: 'not-allowed' }
              : { cursor: 'pointer' }
          }
          value={message}
          onChange={(e) => {
            e.preventDefault();
            if (e.target.value !== '\n') {
              setMessage(e.target.value);
            }
          }}
        />

        <SendButtonColumn className="sendButtonCol">
          <Button
            disabled={
              service.status === 'Complete' ||
              service.status === RequestStatus.ARCHIVED ||
              isSubmitting
            }
            onClick={
              service.status === 'Complete' || service.status === RequestStatus.ARCHIVED
                ? () => {}
                : sendMEssage
            }
            width={'48px'}
            height={'48px'}
            icon={<IoIosSend />}
          />
        </SendButtonColumn>
      </MessageBoxContainer>
      <p className="text-danger">{error}</p>
    </StyledChatBoxContainer>
  );
};

const SendButtonColumn = styled(CCol)`
  align-self: end;
  display: flex;
  justify-content: end;
  padding: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const MessageBoxContainer = styled(CRow)`
  height: 20%;
  align-items: end;
  margin-left: 0;
  margin-right: 0;
  justify-content: space-between;
`;

const MessageContainer = styled.div`
  scroll-behavior: smooth;
  height: 360px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (min-width: 756px) {
    height: 360px;
  }

  @media (min-width: 1310px) {
    height: 390px;
  }

  @media (min-width: 1610px) {
    height: 630px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: none;
  }

  :hover {
    ::-webkit-scrollbar-track {
      display: none;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: none;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${Palette.HAZY_BLUE} !important;
    }
  }

  .row {
    margin-bottom: 15px;

    &:nth-child(2) {
      padding-bottom: 25px;
      border-bottom: #133155 solid 1px;
    }
  }
`;

const UserRoleText = styled.p`
  color: ${Palette.CUSTOM_GRAY};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
`;

const MessageText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
`;

const StyledChatBoxContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default ChatBox;
