import { createReducer } from '@reduxjs/toolkit';
import {
  FILTER_AMENITIES,
  GET_AMENITY,
  GET_AMENITIES,
  ADD_AMENITIES,
  EDIT_AMENITIES,
  MODAL_SHOW_AMENITY_DETAIL,
  CLEAR_AMENITY,
  CLEAR_ERROR,
  SET_AMENITY_TYPE,
  REMOVE_AMENITY,
  GET_AMENITY_TYPES,
  SET_FILTERED_AMENITIES,
  CLEAR_FILTERED_AMENITIES,
  CLEAR_AMENITIES
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';

const initialState = {
  amenities: [],
  filteredAmenities: [],
  amenity: {},
  status: '',
  errors: null,
  isParking: '3',
  showDetailModal: false,
  amenityTypes: []
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    errors: null,
    error: null
  }),

  [SET_AMENITY_TYPE]: (state, { payload }) => {
    return {
      ...state,
      isParking: payload
    };
  },

  [MODAL_SHOW_AMENITY_DETAIL]: (state, { payload }) => ({
    ...state,
    showDetailModal: payload.showDetailModal
  }),

  [CLEAR_AMENITY]: (state, { payload }) => ({
    ...state,
    amenity: {}
  }),

  [FILTER_AMENITIES]: (state, { payload }) => ({
    ...state,
    amenities: payload
  }),
  [CLEAR_AMENITIES]: (state, {}) => ({
    ...state,
    amenities: []
  }),
  [SET_FILTERED_AMENITIES]: (state, { payload }) => ({
    ...state,
    filteredAmenities: payload
  }),
  [CLEAR_FILTERED_AMENITIES]: (state, { payload }) => ({
    ...state,
    filteredAmenities: []
  }),
  [requestSuccess(GET_AMENITY_TYPES)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_AMENITY_TYPES),
    amenityTypes: payload?.map(data => ({...data, ID: data?.id, Description: data?.description})) || [],
    error: null
  }),

  [requestPending(GET_AMENITY_TYPES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_AMENITY_TYPES)
  }),

  [requestFail(GET_AMENITY_TYPES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_AMENITY_TYPES),
    error: payload.data.message
  }),
  [requestSuccess(REMOVE_AMENITY)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(REMOVE_AMENITY),
    error: null
  }),

  [requestPending(REMOVE_AMENITY)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_AMENITY)
  }),

  [requestFail(REMOVE_AMENITY)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REMOVE_AMENITY),
    error: payload.data.message
  }),

  [requestSuccess(GET_AMENITY)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_AMENITY),
    amenity: payload
  }),

  [requestPending(GET_AMENITY)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_AMENITY)
  }),

  [requestFail(GET_AMENITY)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_AMENITY),
    error: payload.error,
    amenity: {}
  }),

  [requestSuccess(GET_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_AMENITIES),
    error: null,
    amenities: payload
  }),

  [requestPending(GET_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_AMENITIES)
  }),

  [requestFail(GET_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_AMENITIES),
    amenities: []
  }),

  [requestSuccess(ADD_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_AMENITIES),
    error: null
  }),
  [requestPending(ADD_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_AMENITIES)
  }),

  [requestFail(ADD_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_AMENITIES),
    error: payload.data
  }),

  [requestSuccess(EDIT_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_AMENITIES),
    error: null
  }),
  [requestPending(EDIT_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_AMENITIES)
  }),

  [requestFail(EDIT_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_AMENITIES),
    error: payload.data
  })
});
