import React from 'react';
import { CFormGroup, CCol, CInvalidFeedback } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { getAllBuildings } from '../redux/actions/building';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../views/users/constant';
import { Select, SelectItem } from './ui/Select';
import { InputSeparator, SettingsModalBoldText } from '../views/settings/SettingsModals';
import organization from '../redux/actions/organization';
import { ErrorText } from './utils/AvatarCropper';

const OrganizationDropDown = ({
  handleBlur,
  handleChange,
  handleOnFocus,
  isDisable = false,
  errors,
  touched,
  values,
  Organizations,
  roleId,
  isUser,
  showAdd = false,
  style,
  className,
  setValues
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div>
      {roleId === UserRole.SUPER_ADMIN ? (
        <InputSeparator>
          <SettingsModalBoldText>{t('Organization')}</SettingsModalBoldText>
          <Select
            className={className}
            error={errors.org_id}
            disabled={isDisable}
            style={isDisable ? { cursor: 'not-allowed' } : {}}
            onBlur={handleBlur}
            onFocus={handleOnFocus ? (e) => handleOnFocus(e) : (e) => {}}
            onChange={(e) => {
              setValues({ ...values, org_id: e, building_id: '', unit_id: '' });
              // handleChange(e);
              if (parseInt(e) !== -1 && isUser) {
                dispatch(
                  getAllBuildings({
                    id: e
                  })
                );
              }
            }}
            custom
            name="org_id"
            id="org_id"
            value={values.org_id ? values.org_id : -1}
            valid={!errors.org_id && touched.org_id}
            invalid={touched.org_id && !!errors.org_id}>
            <SelectItem value={-1}>{t('Select organization')}</SelectItem>

            {Organizations &&
              Organizations.map((item, index) => (
                <SelectItem value={item.id} key={index}>
                  {item.name}
                </SelectItem>
              ))}
          </Select>

          {errors.org_id && <ErrorText>{errors.org_id}</ErrorText>}
        </InputSeparator>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrganizationDropDown;
