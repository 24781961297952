import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_TEMPLATES_DATA,
  GET_CATEGORY_MAPPING,
  GET_TEMPLATE,
  SEND_TEMPLATE,
  SEND_TEMPLATE_IMAGE,
  SEND_DRAFT_TEMPLATE,
  UPDATE_DRAFT_DETAILS,
  GET_DRAFT_DETAILS,
  DELETE_DRAFT,
} from "../constants";
import apiCall, { HTTP_METHOD } from "../../utils/apiCall";

const getAllTemplates = apiCall({
  type: GET_ALL_TEMPLATES_DATA,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/templates?org=${id}`
});

const getCategoryMapping = apiCall({
  type: GET_CATEGORY_MAPPING,
  method: HTTP_METHOD.get,
  path: "/metadata?name=template-categories"
});

const getTemplateData = apiCall({
  type: GET_TEMPLATE,
  method: HTTP_METHOD.get,
  path: ({ name }) => `/docs/${name}`
});

const sendTemplate = apiCall({
  type: SEND_TEMPLATE,
  method: HTTP_METHOD.post,
  path: `/comms`
});

const sendTemplateImage = apiCall({
  type:  SEND_TEMPLATE_IMAGE,
  method: HTTP_METHOD.post,
  path: "/images",
  isBlob: true,
})

const sendDraftTemplate = apiCall({
  type: SEND_DRAFT_TEMPLATE,
  method: HTTP_METHOD.post,
  path: ({commId}) => `/comms/${commId}/exec`,
})

const getDraftDetails = apiCall({
  type: GET_DRAFT_DETAILS,
  method: HTTP_METHOD.get,
  path: ({commId}) => `/comms/${commId}`,
})

const updateDraftDetails = apiCall({
  type: UPDATE_DRAFT_DETAILS,
  method: HTTP_METHOD.put,
  path: ({commId}) => `/comms/${commId}`,
})

const deleteDraft = apiCall({
  type: DELETE_DRAFT,
  method: HTTP_METHOD.delete,
  path: ({commId}) => `/comms/${commId}`,
})

export default function* buildingSaga() {
  yield takeLatest(GET_ALL_TEMPLATES_DATA, getAllTemplates);
  yield takeLatest(GET_CATEGORY_MAPPING, getCategoryMapping);
  yield takeLatest(GET_TEMPLATE, getTemplateData);
  yield takeLatest(SEND_TEMPLATE, sendTemplate);
  yield takeEvery(SEND_TEMPLATE_IMAGE, sendTemplateImage);
  yield takeLatest(SEND_DRAFT_TEMPLATE, sendDraftTemplate);
  yield takeLatest(GET_DRAFT_DETAILS, getDraftDetails);
  yield takeLatest(UPDATE_DRAFT_DETAILS, updateDraftDetails);
  yield takeLatest(DELETE_DRAFT, deleteDraft);
}
