import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterAmenities,
  getAmenities,
  getAmenity,
  modalVisibility,
  removeAmenity,
  setFilteredAmenities
} from '../../../../redux/actions/amenities';
import { CPagination } from '@coreui/react';
import EditAmenities from './EditAmenities';
import ModalAmenityDetails from './ModalAmenityDetails';
import DeleteModal from '../../../../components/DeleteModal';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { useHistory } from 'react-router-dom';

import { setToast } from '../../../../redux/actions/window';
import { bookingFromAmenity } from '../../../../redux/actions/bookingParAm';
import Table from '../../../../components/Table';
import GlobalTableEditDropdown from '../../../../components/GlobalTableEditDropdown';
import { setCurrentAmenityType } from '../../../../redux/actions/services';
import { useTranslation } from 'react-i18next';
import { setCsvFields } from '../../../../redux/actions/reports';

const itemsPerPage = 20;

const Amenities = () => {
  const { t } = useTranslation();

  const amenitiesfields = [
    { key: 'description', label: t('Amenity name') },
    { key: 'type', label: t('Type') },
    { key: 'actions', label: '' }
  ];

  const dispatch = useDispatch();
  const { building } = useSelector((state) => state.building);
  const { amenities, filteredAmenities, showDetailModal, status } = useSelector(
    (state) => state.amenities
  );
  const [handleAmenitiesModal, setHandleAmenitiesModal] = useState(false);
  const [showModal, setShowModal] = useState({
    id: null,
    show: false
  });
  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const [numOfPages, setNumOfPages] = useState(1);

  useEffect(() => {
    if (amenities.length > 0) setNumOfPages(Math.ceil(amenities.length / itemsPerPage));
  }, [amenities]);

  useEffect(() => {
    if (amenities.length > 0) {
      const lastItemIndx = activePage * itemsPerPage;
      const firstItemIndx = lastItemIndx - itemsPerPage;

      dispatch(setFilteredAmenities(amenities.slice(firstItemIndx, lastItemIndx)));
    }
  }, [numOfPages, activePage, amenities]);

  useEffect(() => {
    dispatch(
      setCsvFields([
        {
          key: 'description',
          label: t('Amenity name')
        },
        {
          key: 'type',
          label: t('Type')
        }
      ])
    );
  }, [dispatch, t]);

  useEffect(() => {
    const buildingIdFromUrl = history.location.pathname.split('/')[2];
    dispatch(getAmenities({ id: buildingIdFromUrl }));
  }, [dispatch]);

  const handleEdit = (id) => {
    dispatch(getAmenity({ id }));
    setHandleAmenitiesModal(true);
  };

  const handleDetails = (id) => {
    dispatch(
      getAmenity({
        id: id
      })
    );
    dispatch(
      modalVisibility({
        showDetailModal: true
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      removeAmenity({
        id: showModal.id,
        success: () => {
          dispatch(getAmenities({ id: building.id }));
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: 'Amenity deleted successfully!'
            })
          );
          setShowModal((prev) => ({
            ...prev,
            show: false
          }));
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: 'Amenity deletion failed!',
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleBooking = (id, type) => {
    dispatch(
      setCurrentAmenityType({
        amenityId: type
      })
    );
    dispatch(
      getAmenity({
        id: id,

        success: () => {
          dispatch(bookingFromAmenity(true));
          parseInt(type) === 3
            ? dispatch(
              changeOpenedModal({
                modal: 'modalBookParking'
              })
            )
            : parseInt(type) === 1
              ? dispatch(changeOpenedModal({ modal: 'modalbookAmenity' }))
              : dispatch(changeOpenedModal({ modal: 'modalBookElevator' }));
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: 'Failed to get amenity!',
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const itemsChange = (val) => {
    dispatch(filterAmenities(val));
  };
  const tableChange = (val) => {
    if (val === undefined || val === '')
      if (building && building.id) {
        dispatch(getAmenities({ id: building.id }));
      }
  };

  return (
    <>
      <Table
        noItemsView={{
          noResults: t('no filtering results available'),
          noItems: t('no items available')
        }}
        items={filteredAmenities}
        fields={amenitiesfields}
        loading={status === 'GET_AMENITIES_PENDING' ? true : false}
        onRowClick={(item, index, col) => {
          if (col !== 'actions') {
            handleDetails(item.id);
          }
        }}
        clickableRows
        scopedSlots={{
          description: (item, index) => {
            return (
              <td
                style={{
                  cursor: 'pointer',
                  fontSize: '16px'
                }}>
                <strong> {item.description}</strong>
              </td>
            );
          },
          actions: (item, index) => {
            return (
              <td className="text-right edit-width edit-container">
                <GlobalTableEditDropdown
                  tableName="amenities"
                  handleDetails={handleDetails}
                  handleEdit={handleEdit}
                  item={item}
                  setShowModal={setShowModal}
                  handleBooking={handleBooking}
                />
              </td>
            );
          }
        }}
      />
      <div className="mt-2">
        <CPagination
          activePage={activePage}
          pages={numOfPages}
          onActivePageChange={(i) => setActivePage(i)}
        />
      </div>

      <EditAmenities
        handleAmenitiesModal={handleAmenitiesModal}
        setHandleAmenitiesModal={setHandleAmenitiesModal}
      />

      {showModal.show && (
        <DeleteModal
          type="Amenity"
          action={handleDelete}
          setShowModal={setShowModal}
          description={'Are you sure you want to delete this amenity?'}
        />
      )}

      {showDetailModal === true && <ModalAmenityDetails handleEdit={handleEdit} />}
    </>
  );
};

export default Amenities;
