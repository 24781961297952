import React, { useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { defaultImgCDN, getFullDate12Hr, imageString } from '../../helper';
import StatusInTable from '../../components/StatusInTable';
import { STATUS_MAP } from '../../utils/mappings';
import { useTranslation } from 'react-i18next';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalLightText
} from '../settings/SettingsModals';
import { ImageContainer, StyledImage } from '../settings/SettingsImages';
import { useSelector } from 'react-redux';

const BookingStatus = ({ booking }) => {
  const { t } = useTranslation();
  const [errorImage, setErrorImage] = useState('');

  const filterObject = (array, id) => {
    if (array && id) {
      const resultArray = array.filter((obj) => obj.id === id);
      return resultArray[0];
    }
    return undefined;
  };
  return (
    <div>
      <CRow className="imgAndDetailDiv">
        <CCol sm="3">
          <ImageContainer width={'48px'} height={'48px'}>
            <StyledImage
              onError={() => setErrorImage(defaultImgCDN)}
              src={errorImage === '' ? imageString(booking?.tenant?.building_photo) : errorImage}
              shape="rounded-circle"
            />
          </ImageContainer>
        </CCol>
        <CCol>
          <SettingsModalBoldText>{booking && booking.title}</SettingsModalBoldText>
          <SettingsModalLightText>
            {booking && booking.create_date && getFullDate12Hr(booking.create_date)}
          </SettingsModalLightText>
        </CCol>
        <CCol>
          <SettingsModalBoldText>{t('Create date:')}</SettingsModalBoldText>
          <SettingsModalLightText>
            {booking && booking.create_date && getFullDate12Hr(booking && booking.create_date)}
          </SettingsModalLightText>
        </CCol>
      </CRow>

      <CRow className="statusDiv">
        <CCol sm="5 " className="leftCol">
          <InputSeparator>
            <SettingsModalBoldText>{t('Status')}</SettingsModalBoldText>
            <SettingsModalLightText>
              <StatusInTable
                status={
                  filterObject(STATUS_MAP, booking && booking.status) &&
                  filterObject(STATUS_MAP, booking && booking.status).value
                }
              />
            </SettingsModalLightText>
          </InputSeparator>
        </CCol>
      </CRow>
    </div>
  );
};

export default BookingStatus;
