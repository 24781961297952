import React, { useRef, useState } from 'react';
import { setToast } from '../../../redux/actions/window';
import {
  addOrganization,
  clearOrgError,
  getOrganizations,
  uploadOrgLogo,
  getAllOrganizations
} from '../../../redux/actions/organization';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  CForm,
  CFormGroup,
  CCol,
  CLabel,
  CModal,
  CModalBody,
  CTextarea,
  CRow
} from '@coreui/react';
import * as Yup from 'yup';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import {
  org_property_mode_mapping,
  org_property_provider_mapping,
  org_service_mode_mapping,
  org_service_provider_mapping,
  org_tenant_mode_mapping,
  org_tenant_provider_mapping
} from '../../../utils/mappings';
import InputField from '../../../components/InputField';
import { orgNameRegx, orgUrlRegx } from '../../../helper/Regx';
import ModalFooter from '../../../components/ModalFooter';
import Select from '../../../components/Select';
import { defaultImgCDN } from '../../../helper';
import { useTranslation } from 'react-i18next';
import { data_center_locations } from '../../../helper/types';
import Configurations from './Configurations';
import { Alfred } from '../../../utils/lockInfo';
import AvatarCropper from '../../../components/utils/AvatarCropper';
import { setAllOrgStatus } from '../../../redux/actions/organization';
import { requestPending } from '../../../utils/status';
import { GET_ALL_ORGANIZATIONS } from '../../../redux/constants';
import { ModalHeader } from '../../../components/Modals';

const AddOrganization = () => {
  const { t } = useTranslation();
  const organzationLabels = {
    org_name: t('Organization name'),
    email_address: t('Email address'),
    contact_person: t('Contact person'),
    location: t('Location'),
    configuration: t('Configuration'),
    tenant_provider: t('Resident provider'),
    tenant_mode: t('Resident mode'),
    property_provider: t('Property provider'),
    property_mode: t('Property mode'),
    service_provider: t('Service provider'),
    service_mode: t('Service mode'),
    create_date: t('Create date')
  };

  const inputOrder = [
    'name',
    'email',
    'contact',
    'location',
    'prov_tenant',
    'prov_tenant_mode',
    'prov_property',
    'prov_property_mode',
    'prov_service',
    'prov_service_mode',
    'config'
  ];

  const dispatch = useDispatch();
  const { error, status, logo } = useSelector((state) => state.Organization);
  const { country } = useSelector((state) => state.country);
  const { modal } = useSelector((state) => state.headerModal);
  const [errorInConfig, setErrorInConfig] = useState('');
  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const editorRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = function (values) {
    let validation = {
      name: Yup.string()
        .matches(orgNameRegx, t('Incorrect Format,only Alphabets allowed'))
        .max(50, t('Not more than 50 characters'))
        .required(t('Name is required')),
      contact: Yup.string()
        .matches(orgNameRegx, t('Incorrect Format,only Alphabets allowed'))
        .max(50, t('Not more than 50 characters'))
        .required(t('Contact is required')),
      location: Yup.string().test('is-required', t('Location is required'), (value, context) => {
        if (value === '-1') return false;
        return true;
      }),
      email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
      prov_tenant: Yup.number().required().min(0, t('Select provider')),
      prov_property: Yup.number().required().min(0, t('Select provider')),
      prov_service: Yup.number().required().min(0, t('Select provider')),
      config: Yup.object().shape({
        service_request_url: Yup.string()
          .max(100, t('Not more than 100 characters'))
          .test('valid-service-url', 'Incorrect URL Format', (value, context) => {
            return value === '' || orgUrlRegx.test(value);
          }),
        enable_posts: Yup.bool().required('Missing Enable Posts'),
        enable_offers: Yup.bool().required('Missing Enable Offers'),
        enable_booking: Yup.bool().required('Missing Enable Bookings'),
        enable_lock_screen: Yup.bool().required('Missing Enable Lock Screen'),
        enable_service_requests: Yup.bool().required('Missing Enable Service Requests'),
        enable_security: Yup.bool().required('Missing Enable Security'),
        readonly_users: Yup.bool().required('Missing Enable Readonly Users'),
        show_user_pin_resident: Yup.bool().required('Missing Show User Pin Resident'),
        show_user_pin_admin: Yup.bool().required('Missing Show User Pin Admin'),
        org_allow_edits: Yup.bool().required('Missing Organization Allow Edit'),
        user_pin_len: Yup.number()
          .required()
          .min(Alfred.pinMinLength, t('Minimum Pin Length is 4'))
          .max(Alfred.pinMaxLength, t('Maximum Pin Length is 10'))
      })
    };

    if (values.prov_tenant > 0)
      validation = {
        ...validation,
        prov_tenant_mode: Yup.number().required(t('Required')).moreThan(0, t('Select an option'))
      };

    if (values.prov_property > 0)
      validation = {
        ...validation,
        prov_property_mode: Yup.number().required(t('Required')).moreThan(0, t('Select an option'))
      };

    if (values.prov_service > 0)
      validation = {
        ...validation,
        prov_service_mode: Yup.number().required(t('Required')).moreThan(0, t('Select an option'))
      };

    return Yup.object().shape({
      ...validation
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const initialConfig = {
    enable_posts: true,
    enable_offers: true,
    enable_booking: true,
    enable_lock_screen: true,
    enable_service_requests: true,
    enable_security: false,
    readonly_users: false,
    show_user_pin_resident: true,
    show_user_pin_admin: true,
    show_user_pin_to_admin: false,
    org_allow_edits: true,
    user_pin_len: Alfred.defaultLength,
    service_request_url: ''
  };

  const initialValues = {
    name: '',
    contact: '',
    location: 'CA',
    email: '',
    prov_tenant: -1,
    prov_tenant_mode: 0,
    prov_property: -1,
    prov_property_mode: 0,
    prov_service: -1,
    prov_service_mode: 0,
    config: initialConfig
  };

  const onClose = () => {
    setNewAvatar({
      image: null,
      name: ''
    });
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearOrgError());
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (editorRef.current && newAvatar.image) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        const imgData = new FormData();
        imgData.append('image', blob, newAvatar.name);
        dispatch(
          uploadOrgLogo({
            body: imgData,
            success: (res) => {
              submitAddOrganization(values, res.data.filename);
            },
            fails: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Avatar update failed!'),
                  modal: 'errorToast'
                })
              );
              setIsSubmitting(false);
            }
          })
        );
      });
    } else {
      submitAddOrganization(values);
    }
  };

  const submitAddOrganization = (values, filename) => {
    setErrorInConfig('');

    values.prov_tenant_mode = values.prov_tenant === 0 ? 0 : values.prov_tenant_mode;
    values.prov_property_mode = values.prov_property === 0 ? 0 : values.prov_property_mode;
    values.prov_service_mode = values.prov_service === 0 ? 0 : values.prov_service_mode;

    const newValues = {
      ...values,
      email: values.email.trim(),
      name: values.name.trim(),
      contact: values.contact.trim(),
      logo: filename ? filename : ''
    };

    dispatch(
      addOrganization({
        body: newValues,
        success: () => {
          dispatch(getOrganizations({ location: country }));
          dispatch(setAllOrgStatus({ status: requestPending(GET_ALL_ORGANIZATIONS) }));
          dispatch(getAllOrganizations({ location: country }));
          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Organization has been successfully created!')
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Organization Creating Failed!'),
              modal: 'errorToast'
            })
          );
        },
        finally: () => {
          setIsSubmitting(false);
        }
      })
    );
  };

  const handleCancel = () => {
    onClose();
  };

  //generalized onChange function to use in all inputs
  const handleInputChange = (event, touched, setTouched, handleChange) => {
    setTouched({ ...touched, [event.target.name]: true });
    handleChange(event);
  };

  //prevent triggering errors if no input inserted
  const handleInputOnBlur = (event, values, touched, handleBlur) => {
    const inputName = event.target.name;
    if (values[inputName] && !touched[inputName]) handleBlur(event);
  };

  //trigger errors of required fields present before the input selected if any
  const handleInputOnFocus = (event, errors, touched, setTouched) => {
    const inputName = event.target.name;
    const inputIndex = inputOrder.indexOf(inputName);
    if (inputIndex > 0) {
      const newTouched = {};
      inputOrder.slice(0, inputIndex).forEach((name) => {
        newTouched[name] = true;
      });
      setTouched({ ...touched, ...newTouched });
    }
  };

  return (
    <Formik initialValues={initialValues} validate={validate(validationSchema)} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        setTouched,
        handleChange,
        handleBlur,
        isValid,
        dirty,
        resetForm,
        handleSubmit,
        setFieldValue
      }) =>
        modal && (
          <div className="addOrgModal">
            <CForm onSubmit={handleSubmit} noValidate name="AddOrganizationForm">
              <CModal
                show={modal === 'AddOrganization' ? true : false}
                onClose={() => {
                  onClose();
                  resetForm();
                }}
                closeOnBackdrop={false}>
                <ModalHeader title={t('Add new organization')} />

                <CModalBody>
                  <div className="uploadImageDiv">
                    <h5>Logo</h5>
                    <CFormGroup>
                      <AvatarCropper
                        name={'add-organization'}
                        avatar={defaultImgCDN}
                        newAvatarState={{
                          newAvatar,
                          setNewAvatar
                        }}
                        editorRef={editorRef}
                        withPreview
                      />
                    </CFormGroup>
                  </div>
                  <CFormGroup row className="mt-4">
                    <CCol>
                      <InputField
                        label={organzationLabels.org_name}
                        type="text"
                        id="name"
                        name="name"
                        placeholder={t('Name...')}
                        autoComplete="text"
                        valid={!errors.name && touched.name}
                        invalid={touched.name && !!errors.name}
                        required
                        onChange={(e) => handleInputChange(e, touched, setTouched, handleChange)}
                        onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        value={values.name}
                        errors={errors}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <InputField
                        label={organzationLabels.email_address}
                        type="email"
                        id="email"
                        name="email"
                        placeholder={t('Email')}
                        autoComplete="text"
                        valid={!errors.email && touched.email}
                        invalid={touched.email && !!errors.email}
                        required
                        onChange={(e) => handleInputChange(e, touched, setTouched, handleChange)}
                        onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        value={values.email}
                        errors={errors}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <InputField
                        label={organzationLabels.contact_person}
                        type="text"
                        id="contact"
                        name="contact"
                        placeholder={t('Contact')}
                        autoComplete="text"
                        valid={!errors.contact && touched.contact}
                        invalid={touched.contact && !!errors.contact}
                        required
                        onChange={(e) => handleInputChange(e, touched, setTouched, handleChange)}
                        onBlur={(e) => handleInputOnBlur(e, values, touched, handleBlur)}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        value={values.contact}
                        errors={errors}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        value={values.location}
                        defaultText={t('Select data center')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        name="location"
                        id="location"
                        error={errors.location}
                        valid={!errors.location && touched.location}
                        invalid={touched.location && !!errors.location}
                        data={data_center_locations.map((item) => (
                          <option key={item.id} value={item.value}>
                            {item.value}
                          </option>
                        ))}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CLabel>{organzationLabels.tenant_provider}</CLabel>

                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          setFieldValue('prov_tenant', parseInt(e.target.value));
                        }}
                        name="prov_tenant"
                        id="prov_tenant"
                        valid={!errors.prov_tenant && touched.prov_tenant}
                        invalid={!!errors.prov_tenant && touched.prov_tenant}
                        // value={values.prov_tenant}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        error={errors.prov_tenant}
                        data={org_tenant_provider_mapping.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {t(item.value)}
                            </option>
                          );
                        })}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CLabel>{organzationLabels.tenant_mode}</CLabel>

                      <Select
                        loadingMessage={() => t('Loading...')}
                        defaultText={values.prov_tenant == 0 ? t('Internal') : t('Please select')}
                        onChange={(e) => {
                          setFieldValue('prov_tenant_mode', parseInt(e.target.value));
                        }}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        name="prov_tenant_mode"
                        id="prov_tenant_mode"
                        value={values.prov_tenant > 0 ? values.prov_tenant_mode : -1}
                        invalid={touched.prov_tenant_mode && !!errors.prov_tenant_mode}
                        required={values.prov_tenant > 0 ? true : false}
                        valid={
                          !errors.prov_tenant_mode &&
                          touched.prov_tenant_mode &&
                          !errors.prov_tenant
                        }
                        disabled={values.prov_tenant > 0 ? false : true}
                        style={values.prov_tenant > 0 ? {} : { cursor: 'not-allowed' }}
                        data={org_tenant_mode_mapping.map((item) => (
                          <option key={item.id} value={item.id} disabled={item.id == 0}>
                            {t(item.value)}
                          </option>
                        ))}
                      />

                      <p className="text-danger mb-0">{errors.prov_tenant_mode}</p>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CLabel>{organzationLabels.property_provider}</CLabel>

                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          setFieldValue('prov_property', parseInt(e.target.value));

                          e.target.value === 0 && setFieldValue('prov_property_mode', parseInt(0));
                        }}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        name="prov_property"
                        id="prov_property"
                        valid={!errors.prov_property && touched.prov_property}
                        invalid={!!errors.prov_property && touched.prov_property}
                        error={errors.prov_property}
                        data={org_property_provider_mapping.map((item) => (
                          <option key={item.id} value={item.id}>
                            {t(item.value)}
                          </option>
                        ))}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CLabel>{organzationLabels.property_mode}</CLabel>

                      <Select
                        loadingMessage={() => t('Loading...')}
                        defaultText={values.prov_property == 0 ? t('Internal') : t('Please select')}
                        onChange={(e) => {
                          setFieldValue('prov_property_mode', parseInt(e.target.value));
                        }}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        name="prov_property_mode"
                        id="prov_property_mode"
                        invalid={touched.prov_property_mode && !!errors.prov_property_mode}
                        required={values.prov_property > 0 ? true : false}
                        valid={
                          !errors.prov_property_mode &&
                          touched.prov_property_mode &&
                          !errors.prov_property
                        }
                        value={values.prov_property > 0 ? values.prov_property_mode : -1}
                        disabled={values.prov_property > 0 ? false : true}
                        style={values.prov_property > 0 ? {} : { cursor: 'not-allowed' }}
                        data={org_property_mode_mapping.map((item) => (
                          <option key={item.id} value={item.id} disabled={item.id == 0}>
                            {t(item.value)}
                          </option>
                        ))}
                      />
                      <p className="text-danger mb-0">{errors.prov_property_mode}</p>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CLabel>{organzationLabels.service_provider}</CLabel>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          setFieldValue('prov_service', parseInt(e.target.value));

                          e.target.value === 0 && setFieldValue('prov_service_mode', parseInt(0));
                        }}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        name="prov_service"
                        id="prov_service"
                        valid={!errors.prov_service && touched.prov_service}
                        invalid={!!errors.prov_service && touched.prov_service}
                        error={errors.prov_service}
                        // value={values.prov_service}
                        data={org_service_provider_mapping.map((item) => (
                          <option key={item.id} value={item.id}>
                            {t(item.value)}
                          </option>
                        ))}
                      />
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CLabel>{organzationLabels.service_mode}</CLabel>

                      <Select
                        loadingMessage={() => t('Loading...')}
                        defaultText={values.prov_service == 0 ? t('Internal') : t('Please select')}
                        onChange={(e) => {
                          setFieldValue('prov_service_mode', parseInt(e.target.value));
                        }}
                        onBlur={handleBlur}
                        onFocus={(e) => handleInputOnFocus(e, errors, touched, setTouched)}
                        name="prov_service_mode"
                        id="prov_service_mode"
                        custom
                        required={values.prov_service > 0 ? true : false}
                        valid={
                          !errors.prov_service_mode &&
                          touched.prov_service_mode &&
                          !errors.prov_service
                        }
                        invalid={touched.prov_service_mode && !!errors.prov_service_mode}
                        value={values.prov_service > 0 ? values.prov_service_mode : -1}
                        disabled={values.prov_service > 0 ? false : true}
                        style={values.prov_service > 0 ? {} : { cursor: 'not-allowed' }}
                        data={org_service_mode_mapping.map((item) => (
                          <option key={item.id} value={item.id} disabled={item.id == 0}>
                            {t(item.value)}
                          </option>
                        ))}
                      />
                      <p className="text-danger mb-0">{errors.prov_service_mode}</p>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CRow>
                        <CCol>
                          <CLabel>{organzationLabels.configuration}</CLabel>
                        </CCol>
                      </CRow>

                      <Configurations
                        fieldName="config"
                        values={values}
                        errors={errors}
                        touched={touched}
                        setTouched={setTouched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleInputChange={handleInputChange}
                        handleInputOnBlur={handleInputOnBlur}
                        setFieldValue={setFieldValue}
                        handleInputOnFocus={handleInputOnFocus}
                      />
                      <p className="text-danger">{errorInConfig}</p>
                    </CCol>
                  </CFormGroup>
                  <p className="text-danger d-block w-100 m-0">
                    {error && Object.keys(error).length ? error?.message : ''}
                  </p>
                </CModalBody>
                <ModalFooter
                  text={t('Add')}
                  error={error}
                  status={status === 'ADD_ORGANIZATION_PENDING' ? true : false}
                  onClick={() => {}}
                  handleCancel={handleCancel}
                  className={!dirty || !isValid ? '' : 'btn-primary'}
                  style={isSubmitting || !dirty || !isValid ? { cursor: 'not-allowed' } : {}}
                  disabled={isSubmitting || !dirty || !isValid}
                />
              </CModal>
            </CForm>
          </div>
        )
      }
    </Formik>
  );
};

export default AddOrganization;
