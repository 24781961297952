import { createReducer } from "@reduxjs/toolkit";
import { requestFail, requestSuccess } from "../../utils/status";
import { SEND_ALL_USERS_NOTIFICATION } from "../constants";

const initialState = {
  topic: '',
  data: {
    title: '',
    body: '',
  },
  adminToken: ''
};

export default createReducer(initialState, {
  [requestSuccess(SEND_ALL_USERS_NOTIFICATION)]: (state) => {
    return {
      ...state,
      status: requestSuccess(SEND_ALL_USERS_NOTIFICATION)
    };
  },
  [requestFail("SEND_ALL_USERS_NOTIFICATION")]: (state) => {
    return {
      ...state,
      status: requestFail(SEND_ALL_USERS_NOTIFICATION)
    };
  }
});
