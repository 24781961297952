import { ASC } from "./constants";
import { range } from "lodash";

const env = process.env.NODE_ENV;

const isProduction = () => {
  return env === "production";
}

const sanitizeArray = ({ types, array }) => {
  if (!isProduction()){
    if (types && !Array.isArray(types)){
      throw "types in 'sanitizeArray' must be an array"
    }
    if (!Array.isArray(array)){
      return {
        error: "must be an array"
      }
    }
    if ( types && types.length > 0 && array.length > 0) {
      const errorElement = array.filter((ele)=>{
        return !types.includes(typeof ele);
      })
      if (errorElement.length > 0){
        return {
          error: `element should only have types in ${types}`
        }
      }
    }
  }
  return {}
}

/**
 * Precondition: arr must be sorted already
 * 
 * Description: Insert the min and max range into arr in ASC order.
 * 
 * Return: An new array with unique elements in ASC order
 * 
 * @param {number[]} arr : an array to be inserted
 * @param {number} min : lower boundary of the range
 * @param {number} max : upper boundary of the range (exclusive)
 */
export const insertSortedRange = ( arr, min, max ) => {

  const { error } = sanitizeArray({
    types: ['number'],
    array: arr
  })
  
  if ( error ) throw `insertSortedRange: ${error}`;
  // base cases
  if (arr.length === 0) {
    return range(min, max);
  }
  if (min >= max) {
    return arr;
  }
  if (max <= arr[0]){
    return range(min, max).concat(arr);
  }
  if (min >= arr[arr.length - 1]){
    return min === arr[arr.length - 1]? arr.concat(range(min+1, max)): arr.concat(range(min, max));
  }

  // insertion
  const newArr = [];
  let pointerIdx = 0;
  let curVal = min;
  const { length } = arr;
  while (pointerIdx < length){
    const arrVal = arr[pointerIdx]
    if (curVal < arrVal){
      newArr.push(curVal);
      curVal ++;
    }else if (curVal === arrVal){
      newArr.push(curVal);
      curVal ++;
      pointerIdx ++;
    }else{
      newArr.push(arrVal);
      pointerIdx ++;
    }
    // early termination if all value in the range is added
    if (curVal === max){
      return newArr.concat(arr.slice(pointerIdx))
    }
  }
  // reach here iff the array is all consumed, but range of values are not
  return newArr.concat(range(curVal, max));
}

/**
 * 
 * Increment every element in arr by amount and return the consecutive part of the array
 * Example: incrementFullyConsecutiveArray( [1,2,3,5], 2, 1)
 * Result: [ 3,4 ] ; Started with element whose value is 2, and discarded 5 since it breaks the consecutive array
 * @param {number[]} arr 
 * @param {number} minVal : the min value to start with in the arr
 * @param {number} amount : increment amount
 * @returns 
 */
export const incrementFullyConsecutiveArray = (arr, minVal, amount = 1) => {
  const { error } = sanitizeArray({
    types: ['number'],
    array: arr
  })
  
  if ( error ) throw `isConsecutive: ${error}`;

  if ( arr.length <= 1){
    return arr.map((ele) => ele + amount);
  }

  let isConsecutive = true;
  return arr.reduce((prev, cur, idx, arr) => {
    if (!isConsecutive){
      return prev;
    }
    if (cur >= minVal){
      if (prev.length === 0){
        prev.push(cur + amount);
      }else if ( cur === arr[idx - 1] + amount){
        prev.push(cur + amount)
      }else{
        isConsecutive = false
      }
    }
    return prev
    }, []
  )
}
