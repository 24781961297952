export const UserRole = {
  // Roles -  Customer Facing
  USER: 1,
  MANAGER: 2, //Building Manager
  ADMIN: 3, //Org Manager
  ADVERTISER: 4, // Can view,edit,create Deals
  VENDOR: 5, //same access as manager
  // Roles -  Grata Facing
  ACCOUNT_MGR: 80, // can view,edit.create Businesses and their information
  SUPER_ADMIN: 99
};

export const USER_ROLE = {
  GUEST: {
    id: -1,
    value: 'Guest'
  },
  USER: {
    id: 1,
    value: 'Resident'
  },
  MANAGER: {
    id: 2,
    value: 'Building Manager'
  },
  ADMIN: {
    id: 3,
    value: 'Org Manager'
  },
  ADVERTISER: {
    id: 4,
    value: 'Advertiser'
  },
  VENDOR: {
    id: 5,
    value: 'Vendor'
  },
  ACCOUNT_MANAGER: {
    id: 80,
    value: 'Account Manager'
  },
  SUPER_ADMIN: {
    id: 99,
    value: 'Super Admin'
  }
};

export const USER_ROLE_OPTIONS = [
  USER_ROLE.USER,
  USER_ROLE.VENDOR,
  USER_ROLE.MANAGER,
  USER_ROLE.ADMIN,
  USER_ROLE.SUPER_ADMIN
];

export const shouldShowAddBtn = (userRole, modalName) => {
  if (
    (userRole === UserRole.MANAGER || userRole === UserRole.VENDOR) &&
    modalName !== 'addBuilding'
  )
    return true;

  if (userRole > UserRole.MANAGER && userRole !== UserRole.VENDOR) return true;

  return false;
};

export const getRoleById = (id) => {
  return Object.values(USER_ROLE).find((v) => v.id === id);
};

export const hasPermission = (userRoleId, rolePermissions) => {
  try {
    const parsedUserId = parseInt(userRoleId);
    const parsedRolePermissionId = parseInt(rolePermissions.id);
    return parsedUserId >= parsedRolePermissionId;
  } catch (e) {
    return false;
  }
};

export const BUILDING_TYPE = {
  PERMANENT_RESIDENCY: {
    id: 1,
    label: 'Permanent Residency'
  },
  TEMPORARY_RESIDENCY: {
    id: 2,
    label: 'Temporary Residency'
  }
};

export const USER_TYPE = {
  RESIDENT: {
    id: 1,
    value: 'Resident'
  },
  CO_RESIDENT: {
    id: 2,
    value: 'Co-Resident'
  }
};

export const getTypeById = (id) => {
  return Object.values(USER_TYPE).find((v) => v.id === id);
};

export const UserType = {
  RESIDENT: 1,
  CORESIDENT: 2,
  MANAGER: 0,
  VENDOR: 0,
  TECHNICIAN: 0,
  EXTERNAL: 0
};

export const AccountStatus = {
  NEW: 0,
  OPEN: 1,
  PENDING: 2,
  CANCELLED: 3,
  CLOSED: 4,
  COMPLETE: 5,
  RESERVED: 6,
  BOOKED: 7,
  ENABLED: 8,
  DISABLED: 9,
  SENT: 10,
  PROCESSING: 11,
  ERROR: 12,
  INPROGRESS: 13,
  DELETED: 14,
  FAILED: 15,
  INCOMPLETE: 16
};

const AccountState = [
  {
    id: 0,
    label: 'New'
  },
  {
    id: 2,
    label: 'Pending'
  },
  {
    id: 8,
    label: 'Active'
  },
  {
    id: 14,
    label: 'Archived'
  }
];

export const getAccountStatusById = (id) => {
  return AccountState.find((v) => v.id == id);
};

export const UserTabs = {
  ALL: {
    order: 0,
    label: 'All'
  },
  [AccountStatus.ENABLED]: {
    order: 1,
    label: 'Activated'
  },
  [AccountStatus.NEW]: {
    order: 2,
    label: 'New'
  },
  [AccountStatus.PENDING]: {
    order: 3,
    label: 'Pending'
  },
  [AccountStatus.DELETED]: {
    order: 4,
    label: 'Archived'
  }
};

export const DATE_FORMATS = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_hh_mm_A: 'YYYY-MM-DD hh:mm A',
  MM_DD_YYYY: 'MM/DD/YYYY',
  MMM_D_YYYY: 'MMM D, YYYY',
  hh_mm_a: 'hh:mm a'
};

const t = [
  {
    id: 12714,
    name: 'Georgianna Ullrich',
    email: 'a8574db2-5dad-4419-a250-2c5614238f86@mail7.io',
    role_id: -1,
    type_id: 0,
    mobile_phone: '871-376-9975',
    home_phone: '567-253-6956',
    building_name: 'Park residence two',
    unit_num: '334',
    status_id: '-1',
    create_date: '2024-03-20T15:21:19.695565Z'
  },
  {
    id: 9725,
    name: 'test sendone',
    email: 'hiyoxe2251@bymercy.com',
    role_id: 1,
    type_id: 1,
    mobile_phone: '4645543435',
    home_phone: '3453453454',
    building_name: null,
    unit_num: null,
    status_id: '0',
    create_date: '2023-02-03T16:56:45.758711Z'
  },
  {
    id: 13235,
    name: 'ok ok',
    email: 'ok@øk.dakoda.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: 'QAreg',
    status_id: '0',
    create_date: '2024-06-06T17:16:45.172835Z'
  },
  {
    id: 12630,
    name: 'Coresident ',
    email: 'sasoce454542438@darkse.com',
    role_id: -1,
    type_id: 0,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Park residence two',
    unit_num: '904',
    status_id: '0',
    create_date: '2024-03-12T15:20:16.820471Z'
  },
  {
    id: 11628,
    name: 'Judy Burgess',
    email: 'judithanddaryl@outlook.com',
    role_id: 1,
    type_id: 1,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: null,
    status_id: '0',
    create_date: '2023-08-30T13:12:24.187712Z'
  },
  {
    id: 8309,
    name: 'First Last',
    email: 'email@ema25.ba',
    role_id: 0,
    type_id: 4,
    mobile_phone: '54547787',
    home_phone: '7848787',
    building_name: 'bui',
    unit_num: null,
    status_id: '0',
    create_date: '2022-10-20T18:54:29.657426Z'
  },
  {
    id: 8723,
    name: 'Dishman Jerry',
    email: 'v1puc_1214@yahoo.com',
    role_id: 1,
    type_id: 1,
    mobile_phone: '(817) 939-0146',
    home_phone: '',
    building_name: 'Maitland Streeet',
    unit_num: null,
    status_id: '0',
    create_date: '2022-12-08T17:36:54.691492Z'
  },
  {
    id: 12760,
    name: 'Gost qa',
    email: 'tirzito@operationpatchwork.com',
    role_id: -1,
    type_id: 0,
    mobile_phone: '3333333333',
    home_phone: '4444444444',
    building_name: 'Building 33 Vendor Edit',
    unit_num: null,
    status_id: '0',
    create_date: '2024-03-25T17:45:44.272411Z'
  },
  {
    id: 13388,
    name: 'Amina-Ku QA_A',
    email: 'paxol89227@bsidesmn.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: '00',
    status_id: '0',
    create_date: '2024-07-08T13:03:52.749875Z'
  },
  {
    id: 12629,
    name: 'Coresident ',
    email: 'sasoce2438@darkse.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Park residence two',
    unit_num: '904',
    status_id: '0',
    create_date: '2024-03-12T15:19:50.514624Z'
  },
  {
    id: 13389,
    name: 'éàèùâêîôû ëïüç',
    email: 'tojateb904@atebin.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: '00',
    status_id: '0',
    create_date: '2024-07-08T13:05:27.359093Z'
  },
  {
    id: 12642,
    name: 'Duffy Chuff ',
    email: 'fdsa@gmail.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '2525353565',
    home_phone: '',
    building_name: 'Park residence two',
    unit_num: '904',
    status_id: '0',
    create_date: '2024-03-13T17:39:28.011853Z'
  },
  {
    id: 54,
    name: 'Jhon Smith',
    email: 'jhon@mailinator.com',
    role_id: 1,
    type_id: 1,
    mobile_phone: '2342423423',
    home_phone: '',
    building_name: 'TEST',
    unit_num: null,
    status_id: '0',
    create_date: '2021-05-21T08:17:27.464629Z'
  },
  {
    id: 13095,
    name: 'amina cor',
    email: 'gelef52514@javnoi.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '(674) 756-6775',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: '000',
    status_id: '0',
    create_date: '2024-05-28T08:58:25.963209Z'
  },
  {
    id: 13240,
    name: 'a a',
    email: 'yobavax626@jadsys.com',
    role_id: 99,
    type_id: 0,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: null,
    status_id: '0',
    create_date: '2024-06-06T20:54:31.451544Z'
  },
  {
    id: 11635,
    name: 'Colby Ortiz',
    email: '056d29db-da92-4606-b8d7-6f0b3773348d@mail7.io',
    role_id: 1,
    type_id: 2,
    mobile_phone: '847-508-9903',
    home_phone: '507-895-8877',
    building_name: 'Grata Building',
    unit_num: '972',
    status_id: '0',
    create_date: '2023-08-31T18:17:29.732074Z'
  },
  {
    id: 12621,
    name: 'Dz Dz',
    email: 'dzeno91@hotmail.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '2842888558',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: '34',
    status_id: '0',
    create_date: '2024-03-11T18:27:25.175399Z'
  },
  {
    id: 13236,
    name: 'a a',
    email: 'ffs@gmail.com',
    role_id: 1,
    type_id: 2,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: '000',
    status_id: '0',
    create_date: '2024-06-06T20:48:44.598777Z'
  },
  {
    id: 2369,
    name: 'Keith Wilkins',
    email: 'pessroyloopingkeyz@gmail.com',
    role_id: 1,
    type_id: 1,
    mobile_phone: '1234561425',
    home_phone: '1234561424',
    building_name: null,
    unit_num: null,
    status_id: '0',
    create_date: '2022-05-27T13:57:54.066586Z'
  },
  {
    id: 13037,
    name: 'asd asd',
    email: 'asdasdasd@asdasd.asdasd',
    role_id: 1,
    type_id: 1,
    mobile_phone: '',
    home_phone: '',
    building_name: 'Building 1',
    unit_num: null,
    status_id: '0',
    create_date: '2024-05-08T20:54:59.668981Z'
  }
];
