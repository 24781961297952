export const AMENITY_PARKING = 3;

export const data_center_locations = [
  {
    id: 0,
    value: "CA"
  },
  {
    id: 1,
    value: "US"
  }
];

export const bookingStatus = {
  0: "New",
  1: "Open",
  2: "Pending",
  3: "Cancelled",
  4: "Closed",
  5: "Completed",
  6: "Reserved",
  7: "Booked",
  8: "Enabled",
  9: "Disabled",
  10: "Sent",
  11: "Processing",
  12: "Error",
  13: "In Progress",
  14: "Deleted"
};
