import React, { forwardRef } from 'react';
import { StyledInput } from './ui/TextInput';
import styled from 'styled-components';
import { Palette } from './utils';

const SearchInput = forwardRef(({ children, ...rest }, ref) => {
  return (
    <WrapperStyles>
      <TextInputOverride type="text" ref={ref} {...rest} />
      {children}
    </WrapperStyles>
  );
});

/*
  TODO:
    - This assumes something will be on the left side, what happens if we want to place an icon to the right?
      - Add some styling/customization to allow this and adjust the padding based on which side the component is placed
    - There should be some way to adjust WrapperStyles component
*/
export const WrapperStyles = styled.div`
  display: flex;
  align-items: stretch;
  border-radius: 10px;
  background-color: ${Palette.SOLITUDE};
`;

export const TextInputOverride = styled(StyledInput)`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: inherit;
  outline: none;
  padding: 0 15px;
`;

export const TextSlot = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  order: -1;
  padding-left: 15px;
`;

export default SearchInput;
