import { CButton, CFormGroup, CInputGroup, CLabel, CModalFooter } from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../components/LoadingButton';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../../components/InputField';
import { sendGlobalPushNotification } from '../../redux/actions/globalPushNotification';
import { setToast } from '../../redux/actions/window';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../settings/SettingsModals';
import { Button } from '../../components/ui/Button';
import { ErrorText } from '../../components/utils/AvatarCropper';

const GlobalPushNotificationModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().typeError(t('Required!')).min(1, t('Too Short!')),
    body: Yup.string().typeError(t('Required!')).min(1, t('Too Short!')),
    adminToken: Yup.string().typeError(t('Required!')).min(1, t('Too Short!'))
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      body: '',
      adminToken: ''
    }
  });

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const payload = {
      topic: 'general',
      data: {
        title: values.title,
        body: values.body
      },
      adminToken: values.adminToken
    };
    dispatch(
      sendGlobalPushNotification({
        body: payload,
        success: () => {
          setIsSubmitting(false);

          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );

          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Notification successfully sent!')
            })
          );
        },
        fail: (err) => {
          setError((prev) => ({ ...prev, ...err?.data }));
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to send notification!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  return (
    modal === 'GlobalPushNotification' && (
      <div className="GlobalPushNotification">
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledModal
            show={modal === 'GlobalPushNotification'}
            onClose={onClose}
            closeOnBackdrop={false}>
            <ModalHeader title={t('Send Push Notification to All Users')} />
            <StyledModalBody>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="adminToken">{t('Secret')}</SettingsModalBoldText>

                <Controller
                  control={control}
                  name="adminToken"
                  render={({ field: { onChange, onBlur, value }, fieldState: {} }) => (
                    <SettingsModalInput
                      label="adminToken"
                      type="text"
                      placeholder={t('Secret...')}
                      autoComplete="text"
                      rows="5"
                      invalid={!!errors.code}
                      valid={!errors.code}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                        setError({});
                      }}
                      value={value}
                      error={!!errors.code}
                    />
                  )}
                />

                {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
              </InputSeparator>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="title">{t('Title')}</SettingsModalBoldText>

                <Controller
                  control={control}
                  name="title"
                  render={({ field: { onChange, onBlur, value }, fieldState: {} }) => (
                    <SettingsModalInput
                      label="title"
                      type="text"
                      placeholder={t('Title...')}
                      autoComplete="text"
                      rows="5"
                      invalid={!!errors.code}
                      valid={!errors.code}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                        setError({});
                      }}
                      value={value}
                      error={!!errors.code}
                    />
                  )}
                />
                {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
              </InputSeparator>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="body">{t('Body')}</SettingsModalBoldText>

                <Controller
                  control={control}
                  name="body"
                  render={({ field: { onChange, onBlur, value }, fieldState: {} }) => (
                    <SettingsModalInput
                      label="body"
                      type="text"
                      placeholder={t('Body...')}
                      autoComplete="text"
                      rows="5"
                      invalid={!!errors.code}
                      valid={!errors.code}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                        setError({});
                      }}
                      value={value}
                      error={!!errors.code}
                    />
                  )}
                />
                {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
              </InputSeparator>
              {error && Object.keys(error).length > 0 && <ErrorText>{error.message}</ErrorText>}
            </StyledModalBody>

            <CModalFooter>
              <Button text={t('Cancel')} onClick={onClose} width={'100px'} />
              <Button
                text={t('Send')}
                width={'100px'}
                isPrimary
                disabled={isSubmitting || !isValid}
                type="submit"
              />
            </CModalFooter>
          </StyledModal>
        </form>
      </div>
    )
  );
};

export default GlobalPushNotificationModal;
