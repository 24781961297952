import { createAction } from "@reduxjs/toolkit";
import {
  GET_LOGS,
  GET_ACTIVATION_LOGS,
  GET_REQUEST_TURN_AROUND_TIME,
  SET_CSV_URL,
  SET_CSV_FIELDS,
  GET_ACTIVITIES,
  GET_TOTAL_METRICS,
  GET_DEALS_STATS,
  GET_DEAL_STATS,
} from "../constants";

export const getLogs = createAction(GET_LOGS);
export const getActivities = createAction(GET_ACTIVITIES);
export const getActivationLogs = createAction(GET_ACTIVATION_LOGS);
export const getTurnAroundLog = createAction(GET_REQUEST_TURN_AROUND_TIME);
export const setCsvUrl = createAction(SET_CSV_URL);
export const setCsvFields = createAction(SET_CSV_FIELDS);
export const getTotalMetrics = createAction(GET_TOTAL_METRICS);
export const getDealsStats = createAction(GET_DEALS_STATS);
export const getDealStats = createAction(GET_DEAL_STATS);

export default {
  getLogs,
  getTotalMetrics
};
