import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../../redux/actions/window';
import {
  clearAmenity,
  clearAmenityError,
  editAmenities,
  getAmenities
} from '../../../../redux/actions/amenities';
import { CFormGroup, CRow, CCol, CModalFooter } from '@coreui/react';
import * as Yup from 'yup';
import { convertTime, dayTypes, getDayInString } from '../../../../helper';
import { hoursEnd, hoursStart } from '../../../../utils/mappings';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isArray, isEqual } from 'lodash';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../../settings/SettingsModals';
import { ErrorText } from '../../../../components/styled/ErrorComponents';
import { SelectItem, Select } from '../../../../components/ui/Select';
import { TextArea } from '../../../../components/ui/TextArea';
import { Button } from '../../../../components/ui/Button';
import {
  IconOption,
  inputSelectCustomStyles,
  StyledSelect
} from '../../../../components/CustomSearchBar';
import ReactJson from 'react-json-view';

const EditAmenities = (props) => {
  const { t } = useTranslation();

  const days = [
    { value: 'M', label: t('Monday') },
    { value: 'T', label: t('Tuesday') },
    { value: 'W', label: t('Wednesday') },
    { value: 'TH', label: t('Thursday') },
    { value: 'F', label: t('Friday') },
    { value: 'S', label: t('Saturday') },
    { value: 'SU', label: t('Sunday') }
  ];

  const [singleOptions, setSingleOptions] = useState([]);

  useEffect(() => {
    setSingleOptions([
      { value: 1, label: t('All days') },
      { value: 2, label: t('Weekdays') },
      { value: 3, label: t('Weekends') },
      { value: 4, label: t('Select days') }
    ]);
  }, [t]);

  const amenitiesLabels = {
    building: t('Building'),
    type: t('Type'),
    name: t('Amenity name'),
    available_days: t('Available days'),
    start_time: t('Available start time'),
    end_time: t('Available end time'),
    max_reserve_time: t('Max reservation time'),
    cost_per_hor: t('Cost per hour'),
    description: t('Description'),
    data: t('Data')
  };
  const dispatch = useDispatch();
  const { amenityTypes } = useSelector((state) => state.services);

  const { amenity, error: amenityError, status } = useSelector((state) => state.amenities);
  const { building } = useSelector((state) => state.building);
  const { handleAmenitiesModal, setHandleAmenitiesModal } = props;

  const [selectedDays, setSelectedDays] = useState([]);
  const [initialSelectedDays, setInitialSelectedDays] = useState([]);
  const [preSelectedDays, setPreSelectedDays] = useState([]);
  const [options, setOptions] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let myEndTime;
    if (moment(amenity.available_end).utc().format('HH:mm') === '23:59') {
      myEndTime = '24:00';
    } else {
      myEndTime = convertTime(amenity.available_end);
    }

    reset({
      selectedDays: selectedDays,
      type_id: amenity?.type_id,
      code: amenity?.code,
      available_start: convertTime(amenity.available_start),
      available_end: myEndTime,
      max_time: amenity?.max_time,
      data: amenity.data
    });
  }, [amenity, initialSelectedDays]);

  const validationSchema = Yup.object().shape({
    selectedDays: Yup.mixed().test('is-required', t('Required!'), (value, context) => {
      if (value?.value === dayTypes.SELECT_DAYS) return false;
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          if (value.length === 1 && value[0].value === dayTypes.SELECT_DAYS) return false;

          if (value.length > 0) return true;
          else return false;
        }
        if (value.value) return true;
      } else {
        return false;
      }
    }),
    type_id: Yup.string()
      .required(t('Required!'))
      .test('is-required', t('Required!'), (value, context) => {
        if (parseInt(value) < 0) return false;
        if (parseInt(value) === -1) return false;
        return true;
      }),
    code: Yup.string()
      .matches(/^[a-zA-Z0-9.,\s]*$/i, t('Only Alphanumeric allowed!'))
      .max(32, t('Not more than 32 characters!'))
      .required(t('Required!')),

    max_time: Yup.number()
      .typeError(t('Required!'))
      .max(99999, t('Not more than 5 characters!'))
      .min(1, t("Max time can't be 0!"))
      .required(t('Required!'))
      .test('max-time', t('Max time exceeded!'), (value, context) => {
        if (value > 0) {
          const startTime = moment(context.parent.available_start, 'HH:mm');

          const endTime = moment(context.parent.available_end, 'HH:mm');

          const availableTime = endTime.diff(startTime, 'hours');

          if (value > availableTime) return false;

          return true;
        } else {
          if (value <= 0) return false;
          return true;
        }
      }),
    available_start: Yup.string()
      .required(t('Required!'))
      .test('is-required', t('Required'), (value, context) => {
        if (parseInt(value) === -1) return false;
        return true;
      }),
    available_end: Yup.string()
      .required(t('Required!'))
      .test('is-required', t('Required'), (value, context) => {
        if (parseInt(value) === -1) return false;
        return true;
      })
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema)
  });

  const weekdays = 'M,T,W,TH,F';
  const weekends = 'S,SU';
  const allDays = 'M,T,W,TH,F,S,SU';

  const convertNumberInChar = (number) => {
    const parsedNumber = parseInt(number);
    if (parsedNumber === 1) return 'M';
    else if (parsedNumber === 2) return 'T';
    else if (parsedNumber === 3) return 'W';
    else if (parsedNumber === 4) return 'TH';
    else if (parsedNumber === 5) return 'F';
    else if (parsedNumber === 6) return 'S';
    else if (parsedNumber === 7) return 'SU';
    else return number;
  };

  const decideSelectedDays = () => {
    const daysIndex = amenity.available_days.split(',');
    if (amenity.available_days === allDays) {
      setSelectedDays({ value: dayTypes.ALL_DAYS, label: t('All days') });
      setInitialSelectedDays({
        value: dayTypes.ALL_DAYS,
        label: t('All days')
      });
      setPreSelectedDays({ value: dayTypes.ALL_DAYS, label: t('All days') });
      setOptions(singleOptions);
    } else if (amenity.available_days === weekdays) {
      setSelectedDays({ value: dayTypes.WEEKDAYS, label: t('Weekdays') });
      setInitialSelectedDays({
        value: dayTypes.WEEKDAYS,
        label: t('Weekdays')
      });
      setPreSelectedDays({ value: dayTypes.WEEKDAYS, label: t('Weekdays') });
      setOptions(singleOptions);
    } else if (amenity.available_days === weekends) {
      setSelectedDays({ value: dayTypes.WEEKENDS, label: t('Weekends') });
      setInitialSelectedDays({
        value: dayTypes.WEEKENDS,
        label: t('Weekends')
      });
      setPreSelectedDays({ value: dayTypes.WEEKENDS, label: t('Weekends') });
      setOptions(singleOptions);
    } else {
      const daysArray = daysIndex?.map((value) => {
        const changedValue = convertNumberInChar(value);
        let day = days.find((day) => day.value === changedValue);
        if (day) {
          return {
            value: changedValue,
            label: day.label
          };
        }
      });

      setSelectedDays(daysArray);
      setInitialSelectedDays(daysArray);
      setOptions(days);
    }
  };

  useEffect(() => {
    if (amenity.available_days && handleAmenitiesModal) {
      decideSelectedDays();
    }
  }, [amenity]);

  const isDaysChanged = () => {
    if (isArray(selectedDays) !== isArray(preSelectedDays) && selectedDays.length) {
      return true;
    }
    if (!isArray(selectedDays) && selectedDays.label !== preSelectedDays.label) return true;
    else if (selectedDays.length === 0) return false;
    else if (
      isArray(selectedDays) &&
      isArray(preSelectedDays) &&
      !isEqual(selectedDays, preSelectedDays)
    ) {
      return true;
    }

    return false;
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    let mySelectedDays;
    if (Array.isArray(values.selectedDays)) {
      mySelectedDays = values.selectedDays.filter((v) => v.value !== 4);
    } else {
      mySelectedDays = values.selectedDays;
    }
    const daysInstring = getDayInString(mySelectedDays);
    const hr24EndTime =
      parseInt(values.available_end) === 24
        ? `0000-01-01T23:59:59Z`
        : `0000-01-01T${values.available_end}:00Z`;

    const subValues = {
      ...values,
      max_time: values.max_time,
      available_start: `0000-01-01T${values.available_start}:00Z`,
      available_end: hr24EndTime,
      available_days: daysInstring,
      location: '',
      type_id: parseInt(values.type_id, 10),
      building_id: amenity.building_id,
      data: values.data
    };

    dispatch(
      editAmenities({
        id: amenity.id,
        body: subValues,
        success: () => {
          setHandleAmenitiesModal(false);
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Amenity info has been successfully updated!')
            })
          );
          dispatch(clearAmenity());
          dispatch(getAmenities({ id: building.id }));
          setIsSubmitting(false);
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed Updating Amenity!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  const handleDays = (selectedDays) => {
    let mySelectedDays;
    if (!isArray(selectedDays) && selectedDays.value === dayTypes.SELECT_DAYS) {
      setOptions(days);
      mySelectedDays = [];
    } else if (selectedDays.length === 0) {
      setOptions(singleOptions);
      mySelectedDays = [];
    } else {
      mySelectedDays = selectedDays;
      setSelectedDays(selectedDays);
    }
    setSelectedDays(mySelectedDays);
    setValue('selectedDays', mySelectedDays, { shouldDirty: true });
  };

  const show12HrTime = (hour) => {
    let time = String(hour) + ' am';
    const tim24to12 =
      String(parseInt(String(hour).split(':')[0]) - 12) > 9
        ? String(parseInt(String(hour).split(':')[0]) - 12)
        : '0' + String(parseInt(String(hour).split(':')[0]) - 12);
    if (parseInt(String(hour).split(':')[0]) > 12) time = tim24to12 + ':00 pm';
    if (parseInt(String(hour).split(':')[0]) == 12) time = '12:00 pm';
    if (parseInt(String(hour).split(':')[0]) == 0) time = '12:00 am';
    if (parseInt(String(hour).split(':')[0]) == 24) time = '12:00 am';

    return time;
  };

  const handleCancel = () => {
    setHandleAmenitiesModal(!handleAmenitiesModal);
    dispatch(clearAmenity());
    dispatch(clearAmenityError());
  };

  const controlStylesHasError = (hasError) => (hasError ? { borderColor: '#e55353' } : {});

  return (
    amenity &&
    handleAmenitiesModal && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledModal
          show={handleAmenitiesModal}
          onClose={() => {
            setHandleAmenitiesModal(!handleAmenitiesModal);
            dispatch(clearAmenity());
            dispatch(clearAmenityError());
          }}
          closeOnBackdrop={false}>
          <ModalHeader
            title={t('Edit amenities')}
            onCloseCallback={() => {
              setHandleAmenitiesModal(!handleAmenitiesModal);
              dispatch(clearAmenity());
              dispatch(clearAmenityError());
            }}
          />
          <StyledModalBody>
            <CRow className="mt-4">
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{amenitiesLabels.type}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="type_id"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                        custom
                        id="type_id"
                        valid={!errors.type_id}
                        invalid={!!errors.type_id}>
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        {amenityTypes.map((amenity) => {
                          return (
                            <SelectItem
                              key={amenity.ID}
                              value={amenity.ID}
                              selected={amenity.type_id == amenity.ID}>
                              {amenity.Description}
                            </SelectItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  {errors.type_id && <ErrorText>{errors.type_id.message}</ErrorText>}
                </InputSeparator>
              </CCol>
            </CRow>
            <CFormGroup row>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{amenitiesLabels.description}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="code"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <TextArea
                        type="text"
                        id="description"
                        placeholder="Description..."
                        autoComplete="text"
                        valid={!errors.code}
                        rows="5"
                        invalid={!!errors.code}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
                </InputSeparator>
              </CCol>
            </CFormGroup>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.available_days}</SettingsModalBoldText>
              <Controller
                control={control}
                name="selectedDays"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <StyledSelect
                    styles={inputSelectCustomStyles({ minHeight: '56px', height: 'auto' })}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    loadingMessage={() => t('Loading...')}
                    inputRef={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      handleDays(e);
                      onChange(e);
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: IconOption
                    }}
                    isClearable={options && options.length === 7}
                    isSearchable={true}
                    closeMenuOnSelect={options && options.length !== 7}
                    options={options}
                    placeholder={t('Please select')}
                    isMulti={options && options.length === 7}
                  />
                )}
              />
              {errors.selectedDays && <ErrorText>{errors.selectedDays.message}</ErrorText>}
            </InputSeparator>

            <CRow className="mb-3">
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{amenitiesLabels.start_time}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="available_start"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        custom
                        valid={!errors.available_start}
                        invalid={!!errors.available_start}
                        id="available_start">
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        {hoursStart.map((hour) => (
                          <SelectItem key={hour} value={hour}>
                            {show12HrTime(hour)}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.available_start && (
                    <ErrorText>{errors.available_start.message}</ErrorText>
                  )}
                </InputSeparator>
              </CCol>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{amenitiesLabels.end_time}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="available_end"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        disabled={!watch('available_start')}
                        custom
                        valid={!errors.available_end}
                        invalid={!!errors.available_end}
                        id="available_end">
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>{' '}
                        {hoursEnd
                          .filter((map) => map > watch('available_start'))
                          .map((hour) => {
                            return (
                              <SelectItem key={hour} value={hour}>
                                {show12HrTime(hour)}
                              </SelectItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  {errors.available_end && <ErrorText>{errors.available_end.message}</ErrorText>}
                </InputSeparator>
              </CCol>
            </CRow>
            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.max_reserve_time}</SettingsModalBoldText>
              <Controller
                control={control}
                name="max_time"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <SettingsModalInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    label={amenitiesLabels.max_reserve_time}
                    type="number"
                    id="max_time"
                    placeholder="max time"
                    autoComplete="text"
                    valid={!errors.max_time}
                    invalid={!!errors.max_time}
                    required
                    error={errors.max_time}
                  />
                )}
              />
              {errors.max_time && <ErrorText>{errors.max_time.message}</ErrorText>}
            </InputSeparator>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.data}</SettingsModalBoldText>

              <Controller
                control={control}
                name="data"
                render={({
                  field: { onChange, value},
                }) => (
                  <ReactJson 
                    src={value}
                    name={false}
                    onEdit={(data)=> onChange(data.updated_src)}
                    onAdd={(data)=> onChange(data.updated_src)}
                    onDelete={(data)=> onChange(data.updated_src)}
                  />
                )}
              />
            </InputSeparator>
          </StyledModalBody>

          <CModalFooter>
            <ErrorText>
              {amenityError && Object.keys(amenityError).length ? amenityError.message : ''}
            </ErrorText>
            <Button onClick={handleCancel} text={t('Cancel')} width={'100px'} />
            <Button
              isPrimary
              type="submit"
              text={t('Save')}
              width={'100px'}
              disabled={isSubmitting || !isDirty}
            />
          </CModalFooter>
        </StyledModal>
      </form>
    )
  );
};

export default EditAmenities;
