import styled from 'styled-components';
import { Palette } from '../utils';

export const ErrorText = styled.p`
  color: ${Palette.ERROR};
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`
