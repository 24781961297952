import { createAction } from "@reduxjs/toolkit";
import {
  LOG_IN,
  LOG_OUT,
  SET_IS_LOGGED_IN,
  SET_ROLE_ID,
  CHANGE_LOGIN_PAGE,
  FORGOT_PASSWORD,
  CONFIRM_OTP,
  SET_LOGIN_TRUE,
  SET_CONFIG,
  CLEAR_LOGIN_ERROR,
  SET_LOGIN_FALSE
} from '../constants';

export const login = createAction(LOG_IN);
export const setLoginTrue = createAction(SET_LOGIN_TRUE);
export const logout = createAction(LOG_OUT);
export const setIsLoggedIn = createAction(SET_IS_LOGGED_IN);
export const setRoleId = createAction(SET_ROLE_ID);
export const forgotPass = createAction(FORGOT_PASSWORD);
export const changeLoginPage = createAction(CHANGE_LOGIN_PAGE);
export const confirmOTP = createAction(CONFIRM_OTP);
export const setConfig = createAction(SET_CONFIG);
export const clearLoginError = createAction(CLEAR_LOGIN_ERROR);
export const setLoginFalse = createAction(SET_LOGIN_FALSE);
;

export default {
  login,
  setLoginTrue,
  logout,
  setIsLoggedIn,
  setRoleId,
  forgotPass,
  setLoginFalse
};
