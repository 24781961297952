const ImageSize = 3145728;

export const offerImageValidations = [
  {
    validationFn : (file) => {
      return file.size < ImageSize;
    },
    error: "Maximum file size is 3 MB"
  }
]

export const MediaKeys = {
    LOGO: "logo",
    MEDIA: "media",
    EXTRA_MEDIA: "additional_media",
    BANNER_LOGO: "banner_logo",
};
