import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getFullDate12Hr } from '../helper';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StyledExportCSVButton, TablePageButton } from './tables/TableComponents';

const ExportCSVButton = ({ Data, headers = null }) => {
  const [csvData, setCsvData] = useState(false);
  const [myData, setMyData] = useState(null);
  const csvInstance = useRef();
  const { csvUrl, activationState, csvFields } = useSelector((state) => state.reports);

  useEffect(() => {
    if (Data) {
      let myNewData = [];
      for (const item of Data) {
        let newItem = { ...item };
        if ('create_date' in item) {
          newItem['create_date'] = getFullDate12Hr(newItem['create_date']);
        }
        if ('process_date' in item) {
          newItem['process_date'] = getFullDate12Hr(newItem['process_date']);
        }

        myNewData.push(newItem);
      }
      setMyData(myNewData);
    }
  }, [Data]);

  useEffect(() => {
    if (csvData && csvInstance?.current && csvInstance?.current?.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(false);
      });
    }
  }, [csvData]);

  const getData = async () => {
    const response = await axios.get(csvUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        'org-location': window.localStorage.data_center
      }
    });
    let ret;

    if (response?.data?.requests) {
      ret = response?.data?.requests;
    } else {
      ret = response?.data?.length > 0 ? response?.data : response?.data?.data;
    }

    if (!ret) {
      if (activationState === 'activated') {
        ret = response?.data?.activated_users?.data || response?.data?.activated_users;
      } else {
        ret = response?.data?.unactivated_users?.data || response?.data?.unactivated_users;
      }
    }
    return ret;
  };

  return (
    <>
      {csvUrl && !Data ? (
        <>
          <TablePageButton
            onClick={async () => {
              if (csvUrl) {
                const newCsvData = await getData();
                for (const item of newCsvData) {
                  if ('start_time' in item) {
                    item['start_time'] = moment(item['start_time'], 'HH').format('h:mm a');
                  }
                  if ('end_time' in item) {
                    item['end_time'] = moment(item['end_time'], 'HH').format('h:mm a');
                  }
                  if ('process_date' in item) {
                    item['process_date'] = getFullDate12Hr(item['process_date']);
                  }
                  if ('last_date' in item) {
                    item['last_date'] = getFullDate12Hr(item['last_date']);
                  }
                  if ('create_date' in item) {
                    item['create_date'] = getFullDate12Hr(item['create_date']);
                  }
                  if ('request_created_at' in item) {
                    item['request_created_at'] = getFullDate12Hr(item['request_created_at']);
                  }
                  if ('process_date' in item) {
                    item['process_date'] = getFullDate12Hr(item['process_date']);
                  }
                }
                setCsvData(newCsvData);
              }
            }}></TablePageButton>
          {csvData ? (
            <StyledExportCSVButton
              ref={csvInstance}
              headers={headers ?? csvFields}
              data={csvUrl ? csvData || [] : Data}
              filename={'my-file.csv'}
              target="_blank">
              <TablePageButton />
            </StyledExportCSVButton>
          ) : undefined}
        </>
      ) : (
        myData && (
          <StyledExportCSVButton
            headers={headers ?? csvFields}
            data={myData || []}
            filename={'my-file.csv'}
            target="_blank">
            <TablePageButton />
          </StyledExportCSVButton>
        )
      )}
    </>
  );
};

export default ExportCSVButton;
