import { takeLatest } from "redux-saga/effects";
import {
  GET_AMENITIES,
  EDIT_AMENITIES,
  ADD_AMENITIES,
  GET_AMENITY,
  REMOVE_AMENITY,
  GET_AMENITY_TYPES
} from "../constants";
import apiCall from "../../utils/apiCall";

const getAmenity = apiCall({
  type: GET_AMENITY,
  method: "GET",
  path: ({ id }) => `/amenities/${id}`
});

const getAmenities = apiCall({
  type: GET_AMENITIES,
  method: "get",
  path: ({ id }) => `/buildings/${id}/amenities`
});

const addAmenities = apiCall({
  type: ADD_AMENITIES,
  method: "post",
  path: "/amenities"
});

const editAmenities = apiCall({
  type: EDIT_AMENITIES,
  method: "put",
  path: ({ id }) => `/amenities/${id}`
});

const removeAmenity = apiCall({
  type: REMOVE_AMENITY,
  method: "delete",
  path: ({ id }) => `/amenities/${id}`
});
const getAmenityTypes = apiCall({
  type: GET_AMENITY_TYPES,
  method: "get",
  path: "/metadata?name=amenity-types"
});
export default function* amenitiesSaga() {
  yield takeLatest(REMOVE_AMENITY, removeAmenity);
  yield takeLatest(GET_AMENITIES, getAmenities);
  yield takeLatest(ADD_AMENITIES, addAmenities);
  yield takeLatest(EDIT_AMENITIES, editAmenities);
  yield takeLatest(GET_AMENITY, getAmenity);
  yield takeLatest(GET_AMENITY_TYPES, getAmenityTypes);
}
