import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import addOrganization from "./addOrganization";
import addUserType from "./addUserType";
import auth from "./auth";
import window from "./window";
import user from "./user";
import services from "./services";
import Organization from "./organization";
import building from "./building";
import unit from "./unit";
import country from "./country";
import amenities from "./amenities";
import offers from "./offers";
import notification from "./notifications";
import conversationTemplate from "./conversationTemplate";
import conversationSent from "./conversationSent";
import profile from "./profile";
import headerModal from "./headerModal";
import parAmBooking from "./bookingParAm";
import usersAvatar from "./usersAvatar";
import business from "./business";
import reports from "./reports";
import search from "./search";
import emailRecipient from "./emailRecipient";
import security from "./security";
import globalPushNotification from "./globalPushNotification";
import updateUserProfile from "./updateUserProfile";
import suites from "./suites";

export default (history) =>
  combineReducers({
    addOrganization,
    addUserType,
    amenities,
    auth,
    business,
    building,
    conversationTemplate,
    country,
    conversationSent,
    headerModal,
    notification,
    offers,
    Organization,
    parAmBooking,
    profile,
    reports,
    router: connectRouter(history),
    services,
    usersAvatar,
    user,
    unit,
    window,
    search,
    emailRecipient,
    security,
    globalPushNotification,
    updateUserProfile,
    suites
  });
