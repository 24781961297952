import * as Yup from 'yup';
import { nameRegx } from './Regx';
import { isValidPhoneNumber } from './phoneNumber';

export const USER_SCHEMA = Yup.object().shape(
  {
    firstName: Yup.string()
      .required('First Name is required')
      .matches(nameRegx, 'Incorrect Format, only Alphabets allowed')
      .max(50, 'Not more than 50 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(nameRegx, 'Incorrect Format, only Alphabets allowed')
      .max(50, 'Not more than 50 characters'),
    homePhoneNumber: Yup.string().when('homePhoneNumber', {
      is: (exists) => !!exists,
      then: Yup.string().test('Valid Phone Number', 'Not a valid phone number!', (value) =>
        isValidPhoneNumber(value)
      ),
      otherwise: Yup.string().notRequired()
    }),
    mobilePhoneNumber: Yup.string().when('mobilePhoneNumber', {
      is: (exists) => !!exists,
      then: Yup.string().test('Valid Mobile Phone Number', 'Not a valid phone number!', (value) =>
        isValidPhoneNumber(value)
      ),
      otherwise: Yup.string().notRequired()
    })
  },
  [
    ['homePhoneNumber', 'homePhoneNumber'],
    ['mobilePhoneNumber', 'mobilePhoneNumber']
  ]
);
