import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { useTranslation } from 'react-i18next';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import DetailsModalTitleButtons from '../../../../components/DetailsModalTitleButtons';
import { Icon } from '../../../../helper/icons';
import { ReactComponent as UnitRoom } from '../../../../assets/icons/unitRoom.svg';
import { ReactComponent as Stair } from '../../../../assets/icons/stair.svg';
import { ReactComponent as Area } from '../../../../assets/icons/bxs_area.svg';
import { ReactComponent as Money } from '../../../../assets/icons/money.svg';
import { ReactComponent as Bedroom } from '../../../../assets/icons/bedroom.svg';
import { ReactComponent as Bathroom } from '../../../../assets/icons/bathroom-blue.svg';
import { ReactComponent as Resident } from '../../../../assets/icons/resident.svg';
import { ReactComponent as CoResident } from '../../../../assets/icons/co-resident.svg';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemRow,
  SettingsItemSubTitle,
  SettingsItemTitle,
  SettingsRowContainer,
  SettingsTitle
} from '../../../settings/SettingsItem';
import { Button } from '../../../../components/ui/Button';
import { Container } from '../../../conversation/ConversationComponents';
import { setToast } from '../../../../redux/actions/window';
import { getUser } from '../../../../redux/actions/user';

export const SuiteLockDetails = () => {
  const { modal, data: suiteInfo } = useSelector((state) => state.headerModal);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  const openActivities = () => {
    dispatch(
      changeOpenedModal({
        modal: 'unitStatistics',
        data: suiteInfo
      })
    );
  };

  const showUserDetail = (id) => {
    dispatch(
      getUser({
        id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'UserDetail'
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to get user!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  return (
    <StyledModal show={modal === 'suiteLockDetails'} onClose={onClose} closeOnBackdrop={true}>
      <ModalHeader title={t('Unit Detail')} />
      <StyledModalBody cssOverride={'max-height: 70vh; overflow-y: auto'}>
        <SettingsRowContainer>
          <SettingsTitle>Unit Details</SettingsTitle>
          <SettingsItemRow>
            <SettingsItem cssOverride={'width: 45%; margin-right: 40px'}>
              <Icon icon={UnitRoom} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Unit Number')}</SettingsItemTitle>
                <SettingsItemSubTitle>{suiteInfo?.name}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <Icon icon={Stair} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Floor')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {suiteInfo?.units?.[0]?.floor || 'None'}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </SettingsItemRow>
        </SettingsRowContainer>

        {suiteInfo?.units?.length > 0 &&
          suiteInfo?.units?.map((unit, index) => (
            <>
              <hr style={{ borderTop: '0.5px solid #C3C3C3', margin: '5px 0' }} />
              <SettingsRowContainer>
                <SettingsTitle>Room {index + 1}</SettingsTitle>
                <SettingsItemRow>
                  <SettingsItem cssOverride={'width: 45%; margin-right: 40px'}>
                    <Icon icon={UnitRoom} />
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Room Number')}</SettingsItemTitle>
                      <SettingsItemSubTitle>{unit?.unit_num}</SettingsItemSubTitle>
                    </SettingsItemContent>
                  </SettingsItem>

                  <SettingsItem>
                    <Icon icon={Area} />
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Area')}</SettingsItemTitle>
                      <SettingsItemSubTitle>{unit?.area || 'None'}</SettingsItemSubTitle>
                    </SettingsItemContent>
                  </SettingsItem>
                </SettingsItemRow>
              </SettingsRowContainer>
              <SettingsRowContainer>
                <SettingsItemRow>
                  <SettingsItem cssOverride={'width: 45%; margin-right: 40px'}>
                    <Icon icon={Money} />
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Monthly Rent')}</SettingsItemTitle>
                      <SettingsItemSubTitle>{unit?.rent || 'None'}</SettingsItemSubTitle>
                    </SettingsItemContent>
                  </SettingsItem>

                  <SettingsItem>
                    <Icon icon={Bedroom} />
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Bedroom')}</SettingsItemTitle>
                      <SettingsItemSubTitle>{unit?.bedrooms || 'None'}</SettingsItemSubTitle>
                    </SettingsItemContent>
                  </SettingsItem>
                </SettingsItemRow>
              </SettingsRowContainer>
              <SettingsRowContainer>
                <SettingsItemRow>
                  <SettingsItem cssOverride={'width: 45%; margin-right: 40px'}>
                    <Icon icon={Bathroom} />
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Bathroom')}</SettingsItemTitle>
                      <SettingsItemSubTitle>{unit?.bathrooms || 'None'}</SettingsItemSubTitle>
                    </SettingsItemContent>
                  </SettingsItem>

                  <SettingsItem>
                    <Icon style={{ maxWidth: '20px' }} icon={Resident} />
                    <SettingsItemContent>
                      <SettingsItemTitle>{t('Resident')}</SettingsItemTitle>
                      <SettingsItemSubTitle
                        link={unit?.resident}
                        onClick={() => unit?.resident && showUserDetail(unit?.user_id)}>
                        {unit?.resident || 'None'}
                      </SettingsItemSubTitle>
                    </SettingsItemContent>
                  </SettingsItem>
                </SettingsItemRow>
              </SettingsRowContainer>
              <SettingsItemRow>
                <SettingsItem cssOverride={'width: 100%; '}>
                  <Icon icon={CoResident} />
                  <SettingsItemContent cssOverride={'width: 100%; '}>
                    <SettingsItemTitle>{t('Co-Resident')}</SettingsItemTitle>
                    <SettingsItemContent>
                      {unit?.residents
                        ?.filter((o) => parseInt(o.type) !== 1)
                        .map((coUsers, index, array) => (
                          <SettingsItemSubTitle
                            key={coUsers.id}
                            link
                            onClick={() => showUserDetail(coUsers.id)}>
                            {coUsers.name}
                            {index < array.length - 1 ? ', ' : ''}
                          </SettingsItemSubTitle>
                        ))}
                    </SettingsItemContent>
                  </SettingsItemContent>
                </SettingsItem>
              </SettingsItemRow>
            </>
          ))}
      </StyledModalBody>
      {suiteInfo.showActivitiesButton ? (
        <Container>
          <Button
            isPrimary
            text={t('Activities')}
            cssOverride={'gap: 5px; align-self: center;'}
            width={'150px'}
            onClick={openActivities}
          />
        </Container>
      ) : (
        <DetailsModalTitleButtons modalName="suites" handleClick={onClose} />
      )}
    </StyledModal>
  );
};

export default SuiteLockDetails;
