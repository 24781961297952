import { takeLatest } from "redux-saga/effects";
import {
  SEND_ALL_USERS_NOTIFICATION,
} from "../constants";
import apiCall, { HTTP_METHOD } from "../../utils/apiCall";

const sendGlobalPushNotification = apiCall({
  type: SEND_ALL_USERS_NOTIFICATION,
  method: HTTP_METHOD.post,
  path: () => ``,
  gratafyURL: `${process.env.REACT_APP_PUSH_NOTIFICATION_ENDPOINT}`
});


export default function* pushNotificationSaga() {
  yield takeLatest(SEND_ALL_USERS_NOTIFICATION, sendGlobalPushNotification);
}
