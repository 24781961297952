import { all } from 'redux-saga/effects';
import user from './user';
import auth from './auth';
import services from './services';
import Organization from './organization';
import building from './building';
import unit from './unit';
import amenities from './amenities';
import offers from './offers';
import { receiveNotifications, getNotification } from './notifications';
import parAmBooking from './bookingParAm';
import conversationTemplate from './conversationTemplate';
import conversationSent from './conversationSent';
import profile from './profile';
import usersAvatar from './usersAvatar';
import business from './business';
import reports from './reports';
import welcomeEmailSaga from './emailRecipient';
import securitySaga from './security';
import pushNotificationSaga from './globalPushNotification';
import suitesSaga from './suites';
import searchSaga from './search';

export default function* rootSaga() {
  yield all([
    getNotification(),
    receiveNotifications(),
    user(),
    profile(),
    usersAvatar(),
    conversationSent(),
    conversationTemplate(),
    parAmBooking(),
    auth(),
    services(),
    Organization(),
    building(),
    unit(),
    amenities(),
    offers(),
    searchSaga(),
    business(),
    reports(),
    welcomeEmailSaga(),
    securitySaga(),
    pushNotificationSaga(),
    suitesSaga()
  ]);
}
