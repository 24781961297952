import React, { useMemo } from 'react';
import { CHeader, CHeaderNav } from '@coreui/react';

import { TheHeaderDropdown, TheHeaderDropdownMssg } from './index';
import TheHeaderDropDownModal from './TheHeaderDropDownModal';
import { useSelector } from 'react-redux';
import GlobalOrgDropDown from '../components/GlobalOrgDropDown';
import { UserRole } from '../views/users/constant';
import GlobalBuildingDropDown from '../components/GlobalBuildingDropDown';
import { useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { MediaQuery, Palette } from '../components/utils';
import routes from '../routes';

const TheHeader = () => {
  const { roleId } = useSelector((state) => state.auth);
  const location = useLocation();

  // Since we cannot pass the routes name to the Header due to how the project is currently structured, we must perform a lookup of the
  // current path and compare it to our existing routes. Thankfully, we can reuse the same logic react-router-dom uses to determine
  // which route to render. On top of this, matchPath uses and an internal cache to lookup routes. So we should always be hitting that
  // cache since this function is ran AFTER the route lookup.
  const memoHeaderTitle = useMemo(() => {
    for (const route of routes) {
      const match = matchPath(location.pathname, { path: route.path });
      if (match) {
        return route.name;
      }
    }
    return 'Not Found';
  }, [location.pathname]);

  return (
    <StyledHeader>
      <StyledHeaderNav>
        <HeaderLeft>
          <HeaderTitle>{memoHeaderTitle}</HeaderTitle>
          <HeaderDropdownContainers>
            <HeaderDropdown>
              <GlobalBuildingDropDown />
            </HeaderDropdown>
          </HeaderDropdownContainers>
        </HeaderLeft>
        <HeaderRight>
          <TheHeaderDropDownModal />
          <TheHeaderDropdownMssg />
        </HeaderRight>
      </StyledHeaderNav>
    </StyledHeader>
  );
};

const StyledHeader = styled(CHeader)`
  background-color: ${Palette.GHOST_WHITE};
  padding: 1rem 0;
`;

const StyledHeaderNav = styled(CHeaderNav)`
  display: flex;
  margin-right: auto;
  justify-content: space-between !important;
  width: 100%;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2.5rem;
`;

const HeaderTitle = styled.p`
  display: none;
  color: ${Palette.BLACK};
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 0;

  @media ${MediaQuery.laptopM} {
    display: flex;
  }
`;

const HeaderDropdownContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;

  @media ${MediaQuery.laptopS} {
    flex-direction: row;
  }
`;

const HeaderDropdown = styled.div`
  width: 270px;
`;

const HeaderRight = styled.div`
  display: none;
  column-gap: 0.75rem;

  @media ${MediaQuery.tablet} {
    display: flex;
  }
`;
export default React.memo(TheHeader);
