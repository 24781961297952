import React from 'react';
import { useSelector } from 'react-redux';
import { CModalFooter } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { setToast } from '../../redux/actions/window';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import {
  cancelBooking,
  editingParAmBooking,
  getSelectedBookings
} from '../../redux/actions/bookingParAm';
import { useTranslation } from 'react-i18next';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { SettingsModalLightText } from '../settings/SettingsModals';
import { Button } from '../../components/ui/Button';

const ModalCancelConfirmation = () => {
  const { cancelModal, modal } = useSelector((state) => state.headerModal);
  const { booking } = useSelector((state) => state.parAmBooking);
  const { globalBuildingId } = useSelector((state) => state.building);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClose = () => {
    dispatch(changeOpenedModal({ modal: modal, cancelModal: false }));
  };

  const { globalOrgId } = useSelector((state) => state.Organization);

  return (
    <>
      <StyledModal show={cancelModal} onClose={onClose} closeOnBackdrop={false} width="600px">
        <ModalHeader title={t('Cancel this booking?')} onCloseCallback={onClose} />
        <StyledModalBody>
          <SettingsModalLightText>
            {t(
              'Are you sure you want to cancel this booking? The tenant will be notified about the cancelation. This action cannot be undone.'
            )}
          </SettingsModalLightText>
        </StyledModalBody>
        <CModalFooter className="p-0">
          <Button onClick={onClose} text={t('Close')} width={'100px'} />
          <Button
            isPrimary
            type="button"
            text={t('Cancel')}
            width={'100px'}
            onClick={() => {
              dispatch(
                cancelBooking({
                  id: booking.id,
                  success: () => {
                    dispatch(
                      changeOpenedModal({
                        modal: '',
                        cancelModal: false
                      })
                    );
                    dispatch(
                      getSelectedBookings({
                        id: globalBuildingId,
                        page: 1,
                        amenityId: 0,
                        orgId: globalOrgId
                      })
                    );

                    dispatch(editingParAmBooking({ editBooking: false }));
                    dispatch(
                      setToast({
                        toastShow: true,
                        toastMessage: t('Booking has been cancelled successfully!')
                      })
                    );
                  }
                })
              );
            }}
          />
        </CModalFooter>
      </StyledModal>
    </>
  );
};

export default ModalCancelConfirmation;
