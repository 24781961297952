import React from 'react';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import { closeActiveModal } from '../../helper/modals';
import { resetUserTypeData } from '../../redux/actions/addUserType';
import { store } from '../..';
import addUserType from '../../redux/reducers/addUserType';
import { getRoleById } from '../users/constant';

export const ToggleItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  ${({ cssOverride }) => cssOverride};
`;

export const ToggleItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ cssOverride }) => cssOverride};
`;

export const ToggleItemComponentContainer = styled.div`
  display: flex;
  padding-left: 1rem;

  ${({ cssOverride }) => cssOverride};
`;

export const ToggleItemTitle = styled.p`
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  text-transform: uppercase;

  ${({ cssOverride }) => cssOverride};
`;

export const ToggleItemDescription = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;
export const ToggleHelper = ({ enabled }) => {
  return enabled ? (
    <IoIosCheckmarkCircle size={24} color={'#85CC8E'} className="ml-auto flex-shrink-0" />
  ) : (
    <IoIosCloseCircle size={24} color={'#EA4444'} className="ml-auto flex-shrink-0" />
  );
};

export const closeAddUserTypeModal = (editing) => {
  if (editing) {
    store.dispatch(resetUserTypeData());
  }
  closeActiveModal();
};

export const addUserTypeTitle = (addUserType, showRole = true) => {
  if (addUserType.editingID) {
    return 'Edit User';
  }
  return addUserType.role && showRole
    ? `Add New ${getRoleById(addUserType.role).value}`
    : 'Add User Type';
};

const styles = `
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: end;
  gap: 15px;
  height: 100%;
  width: 100%;
`;

export const OptionsContainer = styled.div`
  ${styles}
`;

export const OptionsContainerForm = styled.form`
  ${styles}
`;
