import { CCard, CCardBody } from '@coreui/react';
import styled from 'styled-components';
import { Palette } from './utils';

export const StyledCard = styled(CCard)`
  padding: ${({ padding }) => padding || '48px 40px'} !important;
  border-radius: 30px;
  background-color: ${Palette.WHITE};
  box-shadow: ${({ boxShadow }) => boxShadow || '1px 1px 25px 0px rgba(0, 0, 0, 0.05)'} !important;

  ${({ cssOverride }) => cssOverride};
`;

export const StyledCardBody = styled(CCardBody)`
  padding: 0;
`;
