import { createAction } from '@reduxjs/toolkit';
import {
  SET_SIDEBAR,
  SET_IS_ON_DASHBOARD,
  SET_TOAST,
  SET_ASIDE,
  SET_MODE,
  SET_IS_ON_REPORTS,
  SET_SIDEBAR_TYPE
} from '../constants';

export const setToast = createAction(SET_TOAST);
export const setSideBar = createAction(SET_SIDEBAR);
export const setSidebarType = createAction(SET_SIDEBAR_TYPE);
export const setASide = createAction(SET_ASIDE);
export const setMode = createAction(SET_MODE);
export const setIsOnDashboard = createAction(SET_IS_ON_DASHBOARD);
export const setIsOnReports = createAction(SET_IS_ON_REPORTS);

export default {
  setIsOnDashboard,
  setIsOnReports,
  setSidebarType,
  setToast,
  setSideBar,
  setASide,
  setMode
};
