import { CSwitch } from '@coreui/react';
import styled from 'styled-components';

export const FeatureSwitch = styled(CSwitch)`
  flex-shrink: 0;
  &.c-switch .c-switch-input:checked + .c-switch-slider {
    background-color: #133155; // primaryBlue
    border-color: #2b4f7c;
    outline: none;
    box-shadow: none;
  }
  &.c-switch-pill .c-switch-slider {
    background-color: #757575;
    outline: none;
    box-shadow: none;
  }
`;
