import { createAction } from "@reduxjs/toolkit";
import {
  SHOW_MESSAGE,
  SET_REGISTRATION_TOKEN,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_READ,
  GET_PAGED_NOTIF,
  SET_NOTIFICATIONS,
  REGISTER_FCM_TOKEN,
  SET_FCM_TOKEN,
  REQUEST_PERMISSION,
  HIDE_MESSAGE,
  SET_NOTIF_PAGE
} from "../constants";

export const showMessage = createAction(SHOW_MESSAGE);
export const hideMessage = createAction(HIDE_MESSAGE);
export const getPagedNotif = createAction(GET_PAGED_NOTIF);
export const getNotifications = createAction(GET_NOTIFICATIONS);
export const setRegistrationToken = createAction(SET_REGISTRATION_TOKEN);
export const MarkNotificationsAsRead = createAction(MARK_NOTIFICATIONS_AS_READ);
export const setNotifications = createAction(SET_NOTIFICATIONS);
export const setCurrentNotificationsPage = createAction(SET_NOTIF_PAGE);

export const registerFCMToken = createAction(REGISTER_FCM_TOKEN);
export const setFCMToken = createAction(SET_FCM_TOKEN);

export const requestPermission = createAction(REQUEST_PERMISSION);
