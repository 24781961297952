import { CCol, CModalFooter, CPagination, CRow } from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import moment from 'moment';
import { useEffect } from 'react';
import LoadingAvatar from '../../../components/LoadingAvatar';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../utils/colorCode';
import { getPMActivities } from '../../../redux/actions/organization';
import { GET_PM_ACTIVITIES_PENDING } from '../../../redux/constants';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../components/Modals';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../settings/SettingsItem';
import { Fonts, Palette } from '../../../components/utils';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { openModal } from '../../../helper/modals';
import { setToast } from '../../../redux/actions/window';
import { getUser } from '../../../redux/actions/user';
import { DATE_FORMATS } from '../../users/constant';
import styled from 'styled-components';

const LOGS_LENGTH = 150;

const UnitStatistics = () => {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(1);
  const [activitiesData, setActivitiesData] = useState([]);
  const [pagination, setPagination] = useState({});

  const [startDateFound, setStartDateFound] = useState(true);

  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));

  const { modal } = useSelector((state) => state.headerModal);
  const { unit } = useSelector((state) => state.unit);
  const { orgId } = useSelector((state) => state.auth);

  const { globalBuildingId, building } = useSelector((state) => state.building);
  const { PMdetails, PMActivities, globalOrgId, status, error } = useSelector(
    (state) => state.Organization
  );
  const { t } = useTranslation();
  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const disabledStartDate = (current) => {
    if (current.isAfter(endDate)) return true;

    return current && current > moment().endOf('day');
  };

  const disabledEndDate = (current) => {
    if (current.isBefore(startDate)) return true;

    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (PMActivities) {
      const groupedData = PMActivities.reduce((events, curr) => {
        const dateString = moment(curr.CreateDate).format('YYYY-MM-DD');
        if (!events.get(dateString)) {
          events.set(dateString, []);
        }

        events.set(dateString, [...events.get(dateString), curr]);
        return events;
      }, new Map());
      setActivitiesData(groupedData);

      // setPagination(PMActivities?.pagination);
      //TODO change the explicit details
      setPagination({
        'pagination-total': 150,
        'pagination-per-page': 30,
        'pagination-current-page': 1
      });
    }
  }, [PMActivities]);

  useEffect(() => {
    const totalPages = Math.ceil(
      parseInt(pagination['pagination-total']) / parseInt(pagination['pagination-per-page'])
    );
    const currPage = parseInt(pagination['pagination-current-page']);
    let myStartDateFound = true;
    if (totalPages === currPage) {
      myStartDateFound = false;
      const startDay = moment(startDate).utc().format('YYYY-MM-DD');
      [...activitiesData.keys()].map((day, index) => {
        if (startDay === day) {
          myStartDateFound = true;
        }
      });
    }

    setStartDateFound(myStartDateFound);
  }, [activitiesData, pagination, startDate, endDate]);

  useEffect(() => {
    if (PMdetails?.user_id) {
      dispatch(
        getPMActivities({
          orgId: globalOrgId != 0 ? globalOrgId : orgId,
          userId: PMdetails.user_id,
          page: activePage
        })
      );
    }
  }, [PMdetails, activePage, dispatch]);

  useEffect(() => {}, [activePage]);

  const actionForPagination = (page) => {
    if (page > 0) setActivePage(page);
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  return (
    <StyledModal show={modal == 'PMActivitiesDetails'} onClose={onClose} closeOnBackdrop={false}>
      <ModalHeader title={t('Activities')} />
      {status === GET_PM_ACTIVITIES_PENDING ? (
        <LoadingAvatar color={LOADING_BLUE} />
      ) : (
        <>
          <StyledModalBody>
            <SettingsItem>
              <IoIosInformationCircleOutline
                size={24}
                color={Palette.HAZY_BLUE}
                style={{ flexShrink: 0 }}
              />
              <SettingsItemContent>
                <SettingsItemTitle>
                  Only the last {LOGS_LENGTH} activities are shown. Please contact Grata staff if
                  you need more than {LOGS_LENGTH} records.
                </SettingsItemTitle>
              </SettingsItemContent>
            </SettingsItem>
            <div className="flex flex-column" style={{ gap: '10px' }}>
              <UserText>User</UserText>
              <SettingsItemSubTitle
                link
                onClick={() => {
                  dispatch(
                    getUser({
                      id: PMdetails.user_id,
                      success: () => {
                        openModal('UserDetail');
                      },
                      fail: () => {
                        dispatch(
                          setToast({
                            toastShow: true,
                            toastMessage: t('Failed to get user!'),
                            modal: 'errorToast'
                          })
                        );
                      }
                    })
                  );
                }}>
                {PMdetails.name}
              </SettingsItemSubTitle>
            </div>

            {[...activitiesData.keys()].map((day, index) => (
              <ActivitiesLogContainer key={index}>
                <CRow>
                  <CCol sm="12">
                    <div>
                      <DateText>{moment(day).format(DATE_FORMATS.MMM_D_YYYY)}</DateText>
                    </div>
                  </CCol>
                </CRow>
                {[...activitiesData.get(day)].map((event, index) => (
                  <CRow className="DetailRow" key={index}>
                    <CCol>
                      <ActivitiesLogTextContainer>
                        <TimeText>{moment(event.CreateDate).format('hh:mm a')}</TimeText>
                        <EventText className="m-0 text-muted key">{event.Message}</EventText>
                      </ActivitiesLogTextContainer>
                    </CCol>
                  </CRow>
                ))}
              </ActivitiesLogContainer>
            ))}
          </StyledModalBody>
          <CModalFooter style={{ justifyContent: 'center' }}>
            {activitiesData.size === 0 && (
              <p className="text-danger d-block w-100 m-0">{t('No available data')}</p>
            )}
            {error ? (
              <p className="text-danger d-block w-100 m-0">{error}</p>
            ) : (
              ((PMActivities.length > 29 && activePage == 1) || activePage != 1) && (
                <div className={'mb-2'}>
                  {pagination['pagination-total'] > 0 && (
                    <CPagination
                      activePage={parseInt(activePage) ?? 1}
                      pages={
                        // PMActivities?.length > 0
                        LOGS_LENGTH > 0
                          ? Math.ceil(
                              pagination['pagination-total'] / pagination['pagination-per-page']
                            )
                          : 0
                      }
                      onActivePageChange={(i) => actionForPagination(i)}></CPagination>
                  )}
                </div>
              )
            )}
          </CModalFooter>
        </>
      )}
    </StyledModal>
  );
};

export const ActivitiesLogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ActivitiesLogTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserText = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${Palette.BLACK};
  margin: 0;
`;

export const DateText = styled.p`
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
`;

export const TimeText = styled.p`
  flex-shrink: 0;
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
`;

export const EventText = styled.p`
  color: ${Palette.OCEANIC_HOVER};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
`;

export default UnitStatistics;
