import { takeLatest } from 'redux-saga/effects';
import {
  EDIT_PAR_AM_BOOKING,
  ADD_PAR_AM_BOOKING,
  GET_AVAILABLE_TIME,
  CANCEL_BOOKING,
  GET_PARKING_PAR_AM_BOOKINGS,
  GET_SELECTED_BOOKINGS,
  GET_AMENITY_PAR_AM_BOOKINGS,
  GET_PAR_AM_BOOKING,
  REMOVE_BOOKING,
  SEARCH_BOOK_PARKINGS,
  SEARCH_BOOK_AMENITIES,
  GET_ELEVATOR_PAR_AM_BOOKINGS,
  SEARCH_BOOK_ELEVATOR,
  SEARCH_BOOK_BY_TYPE,
  SEARCH_BOOK_BY_DATE_RANGE
} from '../constants';
import apiCall from '../../utils/apiCall';

const getSelectedBookings = apiCall({
  type: GET_SELECTED_BOOKINGS,
  method: 'GET',
  path: ({ id, page, amenityId, orgId, perPage = 20, startDate, endDate }) => {
    let url = `/bookings?building=${id}&page=${page}&type=${amenityId}&per_page=${perPage}`;
    if (orgId) {
      url += `&org=${orgId}`;
    }
    if (startDate) {
      url += `&start_date=${startDate}`;
    }
    if (endDate) {
      url += `&end_date=${endDate}`;
    }
    return url;
  }
});

const editParAmBookings = apiCall({
  type: EDIT_PAR_AM_BOOKING,
  method: 'PUT',
  path: ({ id }) => `/bookings/${id}`
});

const cancelBooking = apiCall({
  type: CANCEL_BOOKING,
  method: 'post',
  path: ({ id }) => `/bookings/${id}/cancel`
});

const addParAmBookings = apiCall({
  type: ADD_PAR_AM_BOOKING,
  method: 'POST',
  path: '/bookings'
});

const getAvailableTime = apiCall({
  type: GET_AVAILABLE_TIME,
  method: 'get',
  path: ({ amenity_id, month, year, day }) =>
    `/amenities/${amenity_id}/available?month=${month}&year=${year}&day=${day}`
});

const getParAMBooking = apiCall({
  type: GET_PAR_AM_BOOKING,
  method: 'get',
  path: ({ id }) => `/bookings/${id}`
});

const removeParAmBooking = apiCall({
  type: REMOVE_BOOKING,
  method: 'delete',
  path: ({ id }) => `/bookings/${id}`
});

const searchBookedByType = apiCall({
  type: SEARCH_BOOK_BY_TYPE,
  method: 'get',
  newUrl: true,
  path: ({
    query,
    amenityId,
    page = 1,
    perpage = 20,
    search_col = null,
    building = 0,
    org = 0
  }) => {
    let url;
    amenityId > 0
      ? (url = `/search?entity=booking&type=${amenityId}&q=${query}&page=${page}&per_page=${perpage}`)
      : (url = `/search?entity=booking&q=${query}&page=${page}&per_page=${perpage}`);

    if (building > 0) {
      url += `&building=${building}`;
    }
    if (!building && org > 0) {
      url += `&org=${org}`;
    }
    if (search_col) {
      url += `&search_col=${search_col}`;
    }

    return url;
  }
});

const searchBookedByDateRange = apiCall({
  type: SEARCH_BOOK_BY_DATE_RANGE,
  method: 'get',
  newUrl: true,
  path: ({ building = 0, org = 0, startDate = null, endDate = null }) => {
    let url = `/search?entity=booking&start_date=${startDate}&end_date=${endDate}`;

    if (building > 0) {
      url += `&building=${building}`;
    }
    if (!building && org > 0) {
      url += `&org=${org}`;
    }

    return url;
  }
});

// not used
const searchBookedAmenities = apiCall({
  type: SEARCH_BOOK_AMENITIES,
  method: 'get',
  newUrl: true,
  path: ({ query, page = 1, perpage = 100, buildingId = 0 }) =>
    `/search?entity=booking&type=1&q=${query}&page=${page}&per_page=${perpage}&building=${buildingId}`
});

// the component of which this is called is not been used
const searchBookedParkings = apiCall({
  type: SEARCH_BOOK_PARKINGS,
  method: 'get',
  newUrl: true,
  path: ({ query, page = 1, perpage = 100, building = 0 }) =>
    `/search?entity=booking&type=3&q=${query}&page=${page}&per_page=${perpage}&building=${building}`
});

// not used
const searchBookedElevators = apiCall({
  type: SEARCH_BOOK_ELEVATOR,
  method: 'get',
  newUrl: true,
  path: ({ query, page = 1, perpage = 100, building = 0 }) =>
    `/search?entity=booking&type=2&q=${query}&page=${page}&per_page=${perpage}&building=${building}`
});

export default function* amenitiesSaga() {
  yield takeLatest(SEARCH_BOOK_AMENITIES, searchBookedAmenities);
  yield takeLatest(SEARCH_BOOK_PARKINGS, searchBookedParkings);
  yield takeLatest(SEARCH_BOOK_ELEVATOR, searchBookedElevators);
  yield takeLatest(REMOVE_BOOKING, removeParAmBooking);
  yield takeLatest(GET_SELECTED_BOOKINGS, getSelectedBookings);
  yield takeLatest(EDIT_PAR_AM_BOOKING, editParAmBookings);
  yield takeLatest(ADD_PAR_AM_BOOKING, addParAmBookings);
  yield takeLatest(GET_AVAILABLE_TIME, getAvailableTime);
  yield takeLatest(CANCEL_BOOKING, cancelBooking);
  yield takeLatest(GET_PAR_AM_BOOKING, getParAMBooking);
  yield takeLatest(SEARCH_BOOK_BY_TYPE, searchBookedByType);
  yield takeLatest(SEARCH_BOOK_BY_DATE_RANGE, searchBookedByDateRange);
}
