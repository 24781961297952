import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAvatar,
  clearUser,
  clearUserError,
  editUser,
  editAvatar,
  searchUsers
} from '../../redux/actions/user';
import { setToast } from '../../redux/actions/window';
import { Formik, Field } from 'formik';
import { LOADING_BLUE } from '../../utils/colorCode';

import {
  CForm,
  CFormGroup,
  CCol,
  CInputGroup,
  CSelect,
  CRow,
  CInvalidFeedback,
  CLabel,
  CInput,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from '@coreui/react';

import * as Yup from 'yup';
import { getAllBuildings } from '../../redux/actions/building';
import { ROLE_MAP, USER_TYPE_MAP } from '../../utils/mappings';
import OrganizationDropDown from '../../components/OrganizationDropDown';
import BuildingDropDown from '../../components/BuildingDropDown';
import LoadingAvatar from '../../components/LoadingAvatar';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { getDDUnits } from '../../redux/actions/unit';

import MaskedInput, { conformToMask } from 'react-text-mask';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { emailRegx } from '../../helper/Regx';
import AvatarCropper from '../../components/utils/AvatarCropper';
import { UserRole, UserType } from './constant';
import LoadingButton from '../../components/LoadingButton';
import { ModalHeader, StyledModal } from '../../components/Modals';

const orgId = localStorage.getItem('orgId');

const EditUser = () => {
  const { t } = useTranslation();
  const phoneNumberMask = [
    '(',
    /[0-9]/,
    /\d/,
    /\d/,
    ')',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  const { config } = useSelector((state) => state.auth);
  const { avatar, user, error, status, searchFields } = useSelector((state) => state.user);
  const { roleId } = useSelector((state) => state.auth);
  const { allBuildings } = useSelector((state) => state.building);
  const { unitsForDD } = useSelector((state) => state.unit);
  const { Organizations } = useSelector((state) => state.Organization);
  const [errorImage, setErrorImage] = useState('');
  const { modal } = useSelector((state) => state.headerModal);
  const [isDeleted, setIsDeleted] = useState(false);
  const [formDataObj, setFormDataObj] = useState({});
  const { globalOrgId } = useSelector((state) => state.Organization);
  const { globalBuildingId } = useSelector((state) => state.building);
  const { selectedField } = useSelector((state) => state.search);
  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const editorRef = useRef(null);

  const dispatch = useDispatch();

  const validationSchema = function(values) {
    return Yup.object().shape({
      first_name: Yup.string()
        .matches(/^[A-zÀ-ÿ _-]+$/i, t('Only alphabets allowed'))
        .max(50, t('Not more than 50 characters'))
        .required(t('First Name is required')),
      last_name: Yup.string()
        .matches(/^[A-zÀ-ÿ _-]+$/i, t('Only alphabets allowed'))
        .max(50, t('Not more than 50 characters'))
        .required(t('Last Name is required')),
      email: Yup.string()
        .max(50, t('Not more than 50 characters'))
        .email(t('Invalid email address'))
        .matches(emailRegx, t('Invalid Email'))
        .required(t('Email is required')),
      building_id: Yup.string()
        .test('is-required', t('Building is required!'), (value) => {
          return parseInt(value, 10) >= 0;
        })
        .required(t('Building is required!')),
      type_id: Yup.number()
        .required(t('Type is required!'))
        .test('is-required', t('Type is required!'), (value, context) => {
          if (parseInt(context.parent.role_id) === UserRole.USER && parseInt(value) <= 0)
            return false;
          return true;
        }),
      cell_phone: Yup.string()
        .test('phoneLength', t('Phone number should be 10 digits long'), (phone) => {
          var phoneOnlyNumber = phone.replace(/\D/g, '');
          if (phoneOnlyNumber.length === 0) return true;

          if (phoneOnlyNumber.length !== 10) return false;

          return true;
        })
        .test('phoneValid', t('1st and 4th digits must be b/w (2-9)'), (phone) => {
          var phoneOnlyNumber = phone.replace(/\D/g, '');
          if (phoneOnlyNumber.length === 0) return true;

          if (phoneOnlyNumber.length === 10 && !phoneOnlyNumber.match(/^[2-9][0-9]{2}[2-9][0-9]*/g))
            return false;

          return true;
        }),

      move_in: Yup.string(),
      move_out: Yup.string(),

      home_phone: Yup.string()
        .test('phoneLength', t('Home number should be 10 digits long'), (phone) => {
          var phoneOnlyNumber = phone.replace(/\D/g, '');
          if (phoneOnlyNumber.length === 0) return true;

          if (phoneOnlyNumber.length !== 10) return false;

          return true;
        })
        .test('phoneValid', t('1st and 4th digits must be b/w (2-9)'), (phone) => {
          var phoneOnlyNumber = phone.replace(/\D/g, '');
          if (phoneOnlyNumber.length === 0) return true;

          if (phoneOnlyNumber.length === 10 && !phoneOnlyNumber.match(/^[2-9][0-9]{2}[2-9][0-9]*/g))
            return false;

          return true;
        }),
      org_id: Yup.string().required(t('Select at least one organization')),
      unit_id: Yup.string(),
      amount: Yup.number()
        .max(999999, t('Not more than 999999'))
        .moreThan(-1, t('Rent can not be negative!'))
        .typeError(t('Monthly rent is required!'))
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const updateprofileImg = (id) => {
    dispatch(
      editAvatar({
        body: formDataObj,
        id: id,
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('You successfully updated profile photo')
            })
          );
          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );
        },

        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('User Profile updation failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const filterUserRoleHelper = (userRole) => {
    if (userRole === UserRole.VENDOR)
      return (map) => map.id <= UserRole.USER || map.id === UserRole.VENDOR;

    return (map) => map.id <= userRole || map.id === UserRole.VENDOR;
  };

  const onSubmit = (values, formikHelpers) => {
    if (editorRef.current && newAvatar.image) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        const imgData = new FormData();
        imgData.append('image', blob, newAvatar.name);
        dispatch(
          addAvatar({
            body: imgData,
            success: (res) => {
              onEditUserSubmit({ values, filename: res.data.filename, formikHelpers });
            },
            fails: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Avatar update failed!'),
                  modal: 'errorToast'
                })
              );
              formikHelpers.setSubmitting(false);
            }
          })
        );
      });
    } else {
      onEditUserSubmit({ values, formikHelpers });
    }
  };
  const onEditUserSubmit = ({ values, filename, formikHelpers }) => {
    if (config.readonly_users) {
      if (avatar == true) {
        // updateprofileImg(user.id);
        formikHelpers.setSubmitting(false);
      }
    } else {
      const unitID = parseInt(values.unit_id, 10);
      let userData = {
        role_id: parseInt(values.role_id, 10),
        type_id: parseInt(values.type_id, 10),
        building_id: parseInt(values.building_id, 10),
        org_id: parseInt(values.org_id, 10),
        unit_id: unitID === -1 ? 0 : unitID,

        home_phone:
          values.home_phone === '' ||
            values.home_phone === null ||
            values.home_phone === undefined ||
            values.home_phone === '(___)___-____'
            ? ''
            : values.home_phone.match(/\d/g).join(''),
        cell_phone:
          values.cell_phone === '' ||
            values.cell_phone === null ||
            values.cell_phone === undefined ||
            values.cell_phone === '(___)___-____'
            ? ''
            : values.cell_phone.match(/\d/g).join(''),

        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        move_in: moment(values.move_in, 'YYYY-MM-DD').format('MM/DD/YYYY'),
        move_out: moment(values.move_out, 'YYYY-MM-DD').format('MM/DD/YYYY'),
        rent: values.amount,
        avatar: filename ? filename : user.avatar
      };

      dispatch(
        editUser({
          id: user.id,
          body: userData,
          success: () => {
            if (avatar == true) {
              updateprofileImg(user.id);
            }

            if (searchFields.query) {
              dispatch(
                searchUsers({
                  ...searchFields,
                  search_col: selectedField['user']?.value,
                  building: globalBuildingId,
                  org: globalOrgId
                })
              );
            } else {
              dispatch(
                searchUsers({
                  page: searchFields.page,
                  org: globalOrgId != -1 ? globalOrgId : orgId,
                  building: globalBuildingId,
                  status: searchFields.status
                })
              );
            }
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('User info has been successfully updated!')
              })
            );

            dispatch(
              changeOpenedModal({
                modal: ''
              })
            );
          },
          fail: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('User Update Failed'),
                modal: 'errorToast'
              })
            );
          },
          finally: () => {
            formikHelpers.setSubmitting(false);
          }
        })
      );
    }
  };

  useEffect(() => {
    //setting it false so when open edit form one after other set to false first
    setIsDeleted(false);

    //if logged in user role is lower than the next user then he cant edit it.
    parseInt(user?.role_id) > parseInt(roleId)
      ? parseInt(user?.role_id) === UserRole.VENDOR && parseInt(roleId) >= UserRole.MANAGER
        ? setIsDeleted(false)
        : setIsDeleted(true)
      : setIsDeleted(false);

    //if users status is 14 means hes soft deleted disable the fields for editing
    parseInt(user?.status_id) === 14 && setIsDeleted(true);

    if (user.org_id) {
      dispatch(
        getAllBuildings({
          id: user.org_id
        })
      );
    }
    if (user.building_id) {
      dispatch(getDDUnits({ id: user.building_id }));
    }
  }, [user, avatar]);

  const phoneStyle = {
    appearence: 'none',
    width: '100%',
    border: '1px solid',
    backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
    marginBottom: '5px',
    borderColor: '#2eb85c',
    backgroundColor: '#fff !important',
    opacity: 1,
    boxSizing: 'border-box',
    padding: '6px 33px 6px 12px',
    borderRadius: '3px'
  };
  const disablePhoneStyle = {
    appearence: 'none',
    width: '100%',
    border: '1px solid',
    backgroundColor: '#d5d8d9',
    cursor: 'not-allowed',
    backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
    marginBottom: '5px',
    borderColor: '#2eb85c',
    backgroundColor: '#fff !important',
    opacity: 1,
    boxSizing: 'border-box',
    padding: '6px 33px 6px 12px',
    borderRadius: '3px'
  };

  const initialValues = useMemo(() => {
    return {
      ...user,
      cell_phone: (conformToMask(user.cell_phone, phoneNumberMask) || {}).conformedValue,
      home_phone: (conformToMask(user.home_phone, phoneNumberMask) || {}).conformedValue,

      role_id: parseInt(user.role_id),
      type_id: user.role_id === UserRole.USER ? parseInt(user.type_id) : 0,
      move_in: moment(user.move_in, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      move_out: moment(user.move_out, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      amount: user.rent,
      avatar: ''
    };
  }, [user]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange>
        {({
          resetForm,
          dirty,
          isValid,
          values,
          setValues,
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit
        }) => (
          <CForm onSubmit={handleSubmit} noValidate name="EditUserForm" id="EditUserForm">
            {modal === 'editUser' && (
              <StyledModal
                show={modal === 'editUser' ? true : false}
                onClose={() => {
                  setErrorImage('');
                  dispatch(clearUser());
                  resetForm();
                  dispatch(
                    changeOpenedModal({
                      modal: ''
                    })
                  );
                  dispatch(clearUserError());
                }}
                closeOnBackdrop={false}>
                <ModalHeader title={t('Edit user')} />

                {status === 'GET_USER_PENDING' ? (
                  <LoadingAvatar color={LOADING_BLUE} />
                ) : (
                  <CModalBody>
                    <CFormGroup row>
                      <CCol className="mb-3">
                        <CLabel htmlFor="role_id">{t('Roles')}</CLabel>
                        <CSelect
                          className={config.readonly_users ? 'readOnly' : ''}
                          disabled={isDeleted || config.readonly_users ? true : false}
                          style={
                            isDeleted || config.readonly_users
                              ? {
                                cursor: 'not-allowed'
                              }
                              : {}
                          }
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            if (parseInt(e.target.value) !== UserRole.USER) {
                              setFieldValue('type_id', '0');
                            }
                          }}
                          custom
                          name="role_id"
                          id="role_id"
                          valid={!errors.role_id}
                          invalid={touched.role_id && !!errors.role_id}
                          value={values.role_id ? values.role_id : 0}>
                          <option value={0}>{t('Please select')}</option>
                          {ROLE_MAP.filter(filterUserRoleHelper(roleId)).map((role, index) => (
                            <option key={index} value={role.id}>
                              {role.value}
                            </option>
                          ))}
                        </CSelect>
                        <CInvalidFeedback>{errors.role_id}</CInvalidFeedback>
                      </CCol>
                      {parseInt(values.role_id) === UserRole.USER && (
                        <CCol className="mb-3">
                          <CLabel htmlFor="type_id">{t('Type')}</CLabel>
                          <CSelect
                            className={config.readonly_users ? 'readOnly' : ''}
                            disabled={isDeleted || config.readonly_users ? true : false}
                            style={
                              isDeleted || config.readonly_users
                                ? {
                                  cursor: 'not-allowed'
                                }
                                : {}
                            }
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue('type_id', parseInt(e.target.value));
                            }}
                            custom
                            name="type_id"
                            id="type_id"
                            valid={!errors.type_id}
                            invalid={touched.type_id && !!errors.type_id}
                            value={values.type_id ? parseInt(values.type_id) : 0}>
                            <option value={0}>{t('Please select')}</option>
                            {USER_TYPE_MAP.map((type, index) => {
                              if (
                                parseInt(values.role_id) === 1 &&
                                (type.id === 4 || type.id === 3)
                              ) {
                                return null;
                              } else if (
                                parseInt(values.role_id) !== 1 &&
                                (type.id === 1 || type.id === 2)
                              ) {
                                return null;
                              }

                              return (
                                <option key={index} value={type.id}>
                                  {type.value}
                                </option>
                              );
                            })}
                          </CSelect>
                          <CInvalidFeedback>{errors.type_id}</CInvalidFeedback>
                        </CCol>
                      )}
                    </CFormGroup>
                    {(parseInt(values.role_id) !== UserRole.USER || parseInt(values.type_id)) &&
                      parseInt(values.role_id) ? (
                      <>
                        <CFormGroup>
                          <AvatarCropper
                            name={'edit-user'}
                            avatar={user.avatar}
                            newAvatarState={{
                              newAvatar,
                              setNewAvatar
                            }}
                            editorRef={editorRef}
                            withPreview
                            rounded
                            isEdit
                            setFormValue={(cancel, filename) => {
                              setFieldValue('avatar', cancel ? user.avatar : filename);
                            }}
                          />
                        </CFormGroup>
                        <CFormGroup row>
                          <CCol>
                            <CLabel htmlFor="nf-first-name">{t('First name')}</CLabel>
                            <CInputGroup className="mb-3">
                              <CInput
                                className={config.readonly_users ? 'readOnly' : ''}
                                disabled={isDeleted || config.readonly_users ? true : false}
                                style={
                                  isDeleted || config.readonly_users
                                    ? {
                                      cursor: 'not-allowed'
                                    }
                                    : {}
                                }
                                type="text"
                                id="first_name"
                                name="first_name"
                                placeholder={t('First Name')}
                                autoComplete="text"
                                valid={!errors.first_name}
                                invalid={touched.first_name && !!errors.first_name}
                                required
                                onChange={(e) => {
                                  e.target.value = e.target.value
                                    .replace(/\s{2,}/, ' ')
                                    .trimStart();
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.first_name ? values.first_name : ''}
                              />
                              <CInvalidFeedback>{errors.first_name}</CInvalidFeedback>
                            </CInputGroup>
                          </CCol>
                          <CCol>
                            <CLabel htmlFor="nf-last-name">{t('Last name')}</CLabel>
                            <CInputGroup className="mb-3">
                              <CInput
                                className={config.readonly_users ? 'readOnly' : ''}
                                disabled={isDeleted || config.readonly_users ? true : false}
                                style={
                                  isDeleted || config.readonly_users
                                    ? {
                                      cursor: 'not-allowed'
                                    }
                                    : {}
                                }
                                type="text"
                                id="last_name"
                                name="last_name"
                                placeholder="Last Name..."
                                autoComplete="text"
                                valid={!errors.last_name}
                                invalid={touched.last_name && !!errors.last_name}
                                required
                                onChange={(e) => {
                                  e.target.value = e.target.value
                                    .replace(/\s{2,}/, ' ')
                                    .trimStart();
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.last_name ? values.last_name : ''}
                              />
                              <CInvalidFeedback>{errors.last_name}</CInvalidFeedback>
                            </CInputGroup>
                          </CCol>
                        </CFormGroup>
                        <CFormGroup>
                          <CLabel htmlFor="nf-email">{t('Email address')}</CLabel>
                          <CInputGroup className="mb-3">
                            <CInput
                              className={config.readonly_users ? 'readOnly' : ''}
                              disabled={isDeleted || config.readonly_users ? true : false}
                              style={
                                isDeleted || config.readonly_users
                                  ? {
                                    cursor: 'not-allowed'
                                  }
                                  : {}
                              }
                              type="email"
                              id="email"
                              name="email"
                              placeholder={t('Email')}
                              autoComplete="text"
                              valid={!errors.email}
                              invalid={!!errors.email}
                              required
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email ? values.email : ''}
                            />
                            <CInvalidFeedback>{errors.email}</CInvalidFeedback>
                          </CInputGroup>
                        </CFormGroup>
                        <CFormGroup row style={{ alignItems: 'baseline' }}>
                          <CCol>
                            <CLabel>{t('Phone number')}</CLabel>
                            <CInputGroup className="mb-3">
                              <Field
                                name="cell_phone"
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    id="cell_phone"
                                    placeholder="Cell Phone"
                                    type="text"
                                    className={config.readonly_users ? 'readOnly' : ''}
                                    disabled={isDeleted || config.readonly_users ? true : false}
                                    style={config.readonly_users ? disablePhoneStyle : phoneStyle}
                                    touched={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                )}
                              />
                              <small className="text-danger">
                                {touched.cell_phone && errors.cell_phone}
                              </small>
                            </CInputGroup>
                          </CCol>
                          <CCol>
                            <CLabel htmlFor="nf-home-number">{t('Home phone number')}</CLabel>
                            <CInputGroup className="mb-3">
                              <Field
                                name="home_phone"
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    id="home_phone"
                                    placeholder="home Phone"
                                    type="text"
                                    className={config.readonly_users ? 'readOnly' : ''}
                                    disabled={isDeleted || config.readonly_users ? true : false}
                                    style={config.readonly_users ? disablePhoneStyle : phoneStyle}
                                    touched={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                )}
                              />
                              <small className="text-danger">
                                {touched.home_phone && errors.home_phone}
                              </small>
                            </CInputGroup>
                          </CCol>
                        </CFormGroup>

                        <OrganizationDropDown
                          className={config.readonly_users ? 'readOnly' : ''}
                          isDisable={true}
                          values={values}
                          errors={errors}
                          isUser={true}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          Organizations={Organizations}
                          roleId={roleId}
                          touched={touched}
                          setValues={setValues}
                        />

                        <BuildingDropDown
                          values={values}
                          disable={isDeleted || config.readonly_users ? true : false}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          buildings={allBuildings}
                          setFieldValue={setFieldValue}
                        />
                        {parseInt(values.type_id) === UserType.RESIDENT ||
                          parseInt(values.type_id) === UserType.CORESIDENT ? (
                          <CFormGroup>
                            <CLabel htmlFor="nf-unit">{t('Unit')}</CLabel>
                            <CInputGroup className="mb-3">
                              <CSelect
                                style={
                                  isDeleted || config.readonly_users
                                    ? { cursor: 'not-allowed' }
                                    : {}
                                }
                                disabled={
                                  values.building_id === -1 ||
                                  parseInt(values.type_id) > UserType.CORESIDENT ||
                                  !unitsForDD.length ||
                                  isDeleted ||
                                  config.readonly_users
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                custom
                                value={values.unit_id === 0 ? -1 : values.unit_id}
                                name="unit_id"
                                id="unit_id">
                                <option value={-1}>
                                  {unitsForDD.length
                                    ? t('Please select unit')
                                    : t('No unit available')}
                                </option>
                                {unitsForDD &&
                                  unitsForDD.map((unit) => (
                                    <option key={unit.id} value={unit.id}>
                                      {t('Unit')} {unit.unit_num}
                                    </option>
                                  ))}
                              </CSelect>
                              <CInvalidFeedback>{errors.unit_id}</CInvalidFeedback>
                            </CInputGroup>
                          </CFormGroup>
                        ) : (
                          <></>
                        )}
                        {parseInt(values.type_id) === UserType.RESIDENT &&
                          parseInt(values.unit_id, 10) > 0 ? (
                          <div>
                            <CRow>
                              <CCol>
                                <CFormGroup>
                                  <CLabel htmlFor="lease-start-date">{t('Move in')}</CLabel>
                                  <CInput
                                    disabled={isDeleted}
                                    style={isDeleted ? { cursor: 'not-allowed' } : {}}
                                    type="date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    min={new Date().toISOString().split('T')[0]}
                                    id="move_in"
                                    name="move_in"
                                    value={values.move_in}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    invalid={touched.move_in && !!errors.move_in}
                                    valid={!errors.move_in}
                                  />
                                  <CInvalidFeedback>{errors.move_in}</CInvalidFeedback>
                                </CFormGroup>
                              </CCol>
                              <CCol>
                                <CFormGroup>
                                  <CLabel htmlFor="lease-end-date">{t('Move out')}</CLabel>
                                  <CInput
                                    disabled={isDeleted}
                                    style={isDeleted ? { cursor: 'not-allowed' } : {}}
                                    type="date"
                                    onKeyDown={(e) => e.preventDefault()}
                                    format="yyyy/mm/dd"
                                    id="move_out"
                                    name="move_out"
                                    value={values.move_out}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    invalid={touched.move_out && !!errors.move_out}
                                    valid={!errors.move_out}
                                  />
                                  <CInvalidFeedback>{errors.move_out}</CInvalidFeedback>
                                </CFormGroup>
                              </CCol>
                            </CRow>

                            <CFormGroup>
                              <CLabel>{t('Monthly rent')}</CLabel>
                              <CInput
                                disabled={isDeleted}
                                style={isDeleted ? { cursor: 'not-allowed' } : {}}
                                type="number"
                                id="amount"
                                placeholder="$0"
                                name="amount"
                                value={values.amount}
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  e.target.value === 0
                                    ? setFieldValue('amount', 0)
                                    : setFieldValue('amount', parseInt(e.target.value))
                                }
                                invalid={touched.amount && !!errors.amount}
                                valid={!errors.amount}
                              />
                              <CInvalidFeedback>{errors.amount}</CInvalidFeedback>
                            </CFormGroup>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </CModalBody>
                )}
                <CModalFooter>
                  <p className="text-danger d-block w-100 m-0">{error}</p>
                  <CFormGroup>
                    <CButton
                      className="mr-2"
                      onClick={() => {
                        dispatch(
                          changeOpenedModal({
                            modal: ''
                          })
                        );
                        dispatch(clearUserError());
                      }}>
                      {t('Cancel')}
                    </CButton>
                    <LoadingButton
                      cstmClass={isValid ? 'btn-primary' : ''}
                      type="submit"
                      styling={
                        isSubmitting || !dirty || !isValid || parseInt(values.role_id) === 0
                          ? {
                            cursor: 'not-allowed'
                          }
                          : {}
                      }
                      disabled={
                        isSubmitting || !dirty || !isValid || parseInt(values.role_id) === 0
                      }
                      showLoader={isSubmitting}
                      text={t('Update')}
                    />
                  </CFormGroup>
                </CModalFooter>
              </StyledModal>
            )}
          </CForm>
        )}
      </Formik>
    </>
  );
};

export default EditUser;
