import { createAction } from '@reduxjs/toolkit';
import {
  ADD_UNIT,
  EDIT_UNIT,
  GET_UNITS,
  GET_UNIT,
  FILTER_UNITS,
  GET_DOOR_PIN,
  CLEAR_ERROR,
  SET_UNIT_DATA,
  CLEAR_UNIT_CREATION_RESPONSE,
  GET_DD_UNITS,
  REMOVE_UNIT,
  NOTIFY_UNIT_PIN,
  SET_UNITS_CSV_FILE,
  CLEAR_UNITS_CSV_FILE,
  ADD_UNITS_FROM_CSV,
  SET_UNIT_PAGINATION,
  GET_SUITE,
  GET_CONFLICTING_DATES,
  CLEAR_CONFLICTING_DATES_DATA,
  GET_RESERVATIONS,
  CLEAR_RESERVATIONS
} from '../constants';

export const getDDUnits = createAction(GET_DD_UNITS);
export const clearConflictingDatesData = createAction(CLEAR_CONFLICTING_DATES_DATA);
export const getConflictingDates = createAction(GET_CONFLICTING_DATES);
export const getReservations = createAction(GET_RESERVATIONS);
export const clearReservations = createAction(CLEAR_RESERVATIONS);
export const removeUnit = createAction(REMOVE_UNIT);
export const clearUnitResponse = createAction(CLEAR_UNIT_CREATION_RESPONSE);
export const setUserUnit = createAction(SET_UNIT_DATA);
export const addUnit = createAction(ADD_UNIT);
export const editUnit = createAction(EDIT_UNIT);
export const getUnits = createAction(GET_UNITS);
export const getUnit = createAction(GET_UNIT);
export const getSuite = createAction(GET_SUITE);
export const filterUnits = createAction(FILTER_UNITS);
export const getDoorPin = createAction(GET_DOOR_PIN);
export const clearUnitError = createAction(CLEAR_ERROR);
export const notifyUnitPin = createAction(NOTIFY_UNIT_PIN);
export const setUnitsCSVFile = createAction(SET_UNITS_CSV_FILE);
export const clearUnitsCSVFile = createAction(CLEAR_UNITS_CSV_FILE);
export const addUnitsCSVFile = createAction(ADD_UNITS_FROM_CSV);
export const setUnitPagination = createAction(SET_UNIT_PAGINATION);

export default {
  getConflictingDates,
  clearUnitError,
  removeUnit,
  clearUnitResponse,
  setUserUnit,
  addUnit,
  editUnit,
  getSuite,
  getUnits,
  getUnit,
  filterUnits,
  getDoorPin,
  notifyUnitPin,
  setUnitsCSVFile,
  clearUnitsCSVFile,
  addUnitsCSVFile,
  setUnitPagination
};
