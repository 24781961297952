import React from 'react';
import { ReactComponent as NoConnectionIcon } from '../assets/icons/NoConnectionIcon.svg';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';

const LostInternetPage = () => {
  return (
    <Container>
      <NoConnectionIcon />
      <div>
        <TitleText>No Internet Connection</TitleText>
        <SubtitleText>
          Your computer is offline. Please try checking your network, resetting your modem or
          reconnecting to wifi.
        </SubtitleText>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const TitleText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  text-transform: capitalize;
  text-align: center;
  margin: 7px;
`;

const SubtitleText = styled.p`
  color: ${Palette.BLACK};
  text-align: center;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
`;

export default LostInternetPage;
