import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CCol,
  CRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CButton,
  CDropdownItem
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StatusBGVariants, RequestStatus, PinRelatedCategoryId } from '../../constant';
import { InputSeparator, SettingsModalBoldText } from '../../../settings/SettingsModals';
import { DropdownToggle } from '../../ServiceComponents';

const StatusSection = ({
  serviceStatus,
  setServiceStatus,
  setShowWarningModal,
  updateServiceRequestStatus,
  shouldShowWarning
}) => {
  const { t, i18n } = useTranslation();
  const { service } = useSelector((state) => state.services);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  moment.locale(i18n.resolvedLanguage);

  const onUpdate = (status) => {
    // In progress require an technician is assigned first
    if (status === RequestStatus.IN_PROGRESS || status === service.status) {
      setServiceStatus(status);
      return;
    }
    if (shouldShowWarning && status === RequestStatus.COMPLETE) {
      setShowWarningModal(true);
      return;
    }
    updateServiceRequestStatus(status);
  };

  return (
    <CRow className="statusDiv">
      <CCol sm="6" className="leftCol">
        <InputSeparator>
          <SettingsModalBoldText>{t('Status: ')}</SettingsModalBoldText>

          <CDropdown variant="btn-group">
            <DropdownToggle
              caret
              color={StatusBGVariants[serviceStatus]}
              onClick={() => setIsDropdownOpen((prev) => !prev)}
              disabled={
                service.status === RequestStatus.COMPLETE ||
                service.status === RequestStatus.ARCHIVED
              }>
              {t(serviceStatus)}
            </DropdownToggle>
            <CDropdownMenu className="dropdown-menu" show={isDropdownOpen}>
              {Object.values(RequestStatus).map(
                (val) =>
                  val !== RequestStatus.ARCHIVED &&
                  !(
                    parseInt(service.category_id, 10) === PinRelatedCategoryId &&
                    val === RequestStatus.COMPLETE
                  ) && (
                    <CDropdownItem
                      active={serviceStatus === val}
                      onClick={() => {
                        setIsDropdownOpen(false);
                        onUpdate(val);
                      }}
                      key={`select-${val}`}
                      disabled={
                        service.status === RequestStatus.COMPLETE ||
                        service.status === RequestStatus.ARCHIVED
                      }>
                      {val}
                    </CDropdownItem>
                  )
              )}
            </CDropdownMenu>
          </CDropdown>
        </InputSeparator>
      </CCol>
    </CRow>
  );
};

export default StatusSection;
