import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { StyledModal, StyledModalBody } from './Modals';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';
import { Button } from './ui/Button';
import { Icon } from '../helper/icons';
import { ReactComponent as ExclamationMark } from '../assets/icons/exclamation-mark.svg';
import { Flex } from './ui/Flex';
import { UserRole } from '../views/users/constant';

const DeleteModal = ({
  content,
  type,
  action,
  setShowModal,
  modalState,
  description,
  permDelete
}) => {
  const { modal } = useSelector((state) => state.headerModal);
  const roleId = parseInt(localStorage.getItem('roleId')) || '';
  const dispatch = useDispatch();
  const [permanentDelete, setPermanentDelete] = useState(modalState?.permanentDelete);

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    setShowModal((prev) => ({
      ...prev,
      show: false
    }));
  };

  const onClick = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    if (type === 'User' && roleId === UserRole.SUPER_ADMIN) {
      action(permanentDelete);
    } else {
      action();
    }
  };

  return (
    <StyledModal
      show={modal === 'deleteModal'}
      onClose={onClose}
      closeOnBackdrop={false}
      width={'382px'}>
      <IconContainer>
        <Icon icon={ExclamationMark} />
      </IconContainer>
      <Title>Delete {type}?</Title>
      <StyledModalBody>
        <Text>{description}</Text>
        {type === 'User' && roleId === UserRole.SUPER_ADMIN && (
          <Flex gap="10px">
            <input
              disabled={modalState?.permanentDelete}
              type="checkbox"
              checked={permanentDelete}
              onChange={(e) => setPermanentDelete(e.target.checked)}
            />
            <Text>Permanent Delete?</Text>
          </Flex>
        )}
      </StyledModalBody>
      <ButtonContainer>
        <Button isPrimary text={`Delete ${type}`} onClick={onClick} />
        <Button text={'Go Back'} onClick={onClose} />
      </ButtonContainer>
    </StyledModal>
  );
};

const Title = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-align: center;

  margin-bottom: 0;
`;

const Text = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  margin-bottom: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default DeleteModal;
