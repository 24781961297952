import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  ADD_USER,
  ADD_USERS_FROM_CSV,
  GET_USER,
  EDIT_USER,
  REMOVE_USER,
  ADD_AVATAR,
  GET_AVATAR,
  EDIT_AVATAR,
  BUILDING_USERS,
  SEARCH_USERS,
  INVITE_USER,
  GET_USER_DEVICE_SLOTS,
  GET_ALL_USERS
} from '../constants';
import apiCall from '../../utils/apiCall';
import { HTTP_METHOD } from '../../utils/apiCall';

const getUserSlots = apiCall({
  type: GET_USER_DEVICE_SLOTS,
  method: HTTP_METHOD.get,
  path: ({ id, no_codes, only_empty, first_slot, last_slot }) => {
    let url = `/users/${id}/device-slots`;
    if (no_codes) url += `?no_codes=${no_codes}`;
    if (only_empty) url += `&only_empty=${only_empty}`;
    if (first_slot) url += `&first_slot=${first_slot}`;
    if (last_slot) url += `&last_slot=${last_slot}`;
    return url;
  }
});

const getBuildingUsers = apiCall({
  type: BUILDING_USERS,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/users?format=short&building=${id}`
});

const addAvatar = apiCall({
  type: ADD_AVATAR,
  method: HTTP_METHOD.post,
  path: '/images',
  isFormData: true
});

const getAvatar = apiCall({
  type: GET_AVATAR,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/images/${id}`,
  isFormData: true,
  isBlob: true
});

const editAvatar = apiCall({
  type: EDIT_AVATAR,
  method: HTTP_METHOD.post,
  path: ({ id }) => `/users/${id}/avatar`,
  isFormData: true,
  isBlob: true
});

const getUser = apiCall({
  type: GET_USER,
  method: HTTP_METHOD.get,
  path: ({ id, day, month, year }) => {
    const searchParams = new URLSearchParams();
    if (day) {
      searchParams.set('day', day);
    }
    if (month) {
      searchParams.set('month', month);
    }
    if (year) {
      searchParams.set('year', year);
    }
    return `/users/${id}?${searchParams.toString()}`;
  }
});

const editUser = apiCall({
  type: EDIT_USER,
  method: HTTP_METHOD.put,
  path: ({ id }) => `/users/${id}`
});

const removeUser = apiCall({
  type: REMOVE_USER,
  method: HTTP_METHOD.delete,
  path: ({ id, permanent }) => `/users/${id}?perm=${permanent ? 'y' : 'n'}`
});

const inviteUser = apiCall({
  type: INVITE_USER,
  method: HTTP_METHOD.post,
  path: ({ id }) => `/users/${id}/reinvite`
});

const addUser = apiCall({
  type: ADD_USER,
  method: HTTP_METHOD.post,
  path: '/users'
});

const addUsersFromCSV = apiCall({
  type: ADD_USERS_FROM_CSV,
  method: HTTP_METHOD.post,
  path: '/docs',
  isFormData: true,
  isFile: true
});

const searchUsers = apiCall({
  type: SEARCH_USERS,
  method: HTTP_METHOD.get,
  newUrl: true,
  path: ({
    query = '',
    page = 1,
    perpage = 20,
    search_col = null,
    building = 0,
    org = 0,
    status = 'ALL'
  }) => {
    let url = `/search?entity=user&q=${query}&page=${page}&per_page=${perpage}`;
    if (building > 0) {
      url += `&building=${building}`;
    }
    if (building === 0 && org > 0) {
      url += `&org=${org}`;
    }
    if (search_col) {
      url += `&search_col=${search_col}`;
    }
    if (!isNaN(status)) {
      url += `&status=${status}`;
    }
    return url;
  }
});

const getAllUsers = apiCall({
  type: GET_ALL_USERS,
  method: HTTP_METHOD.get,
  newUrl: true,
  path: ({ query = '', search_col = null, building = 0, org = 0, status = 'ALL' }) => {
    let url = `/search?entity=user&q=${query}&page=1&per_page=99999`;
    if (building > 0) {
      url += `&building=${building}`;
    }
    if (building === 0 && org > 0) {
      url += `&org=${org}`;
    }
    if (search_col) {
      url += `&search_col=${search_col}`;
    }
    if (!isNaN(status)) {
      url += `&status=${status}`;
    }
    return url;
  }
});

export default function* userSaga() {
  yield takeLatest(INVITE_USER, inviteUser);
  yield takeLatest(SEARCH_USERS, searchUsers);
  yield takeLatest(REMOVE_USER, removeUser);
  yield takeLatest(EDIT_USER, editUser);
  yield takeLatest(ADD_AVATAR, addAvatar);
  yield takeLatest(GET_AVATAR, getAvatar);
  yield takeLatest(EDIT_AVATAR, editAvatar);
  yield takeLatest(BUILDING_USERS, getBuildingUsers);
  yield takeLatest(GET_USER, getUser);
  yield takeLatest(ADD_USER, addUser);
  yield takeLatest(ADD_USERS_FROM_CSV, addUsersFromCSV);
  yield takeLatest(GET_USER_DEVICE_SLOTS, getUserSlots);
  yield takeLatest(GET_ALL_USERS, getAllUsers);
}
