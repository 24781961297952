import styled from 'styled-components';
import { Palette } from '../../components/utils';

const SettingsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const SettingsTitle = styled.p`
  font-size: 16px;
  color: #757575;
  font-weight: 600;
`;

const SettingsItemRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SettingsItem = styled.div`
  display: flex;
  gap: 18px;
  justify-content: ${({ option }) => (option ? 'space-between; ' : 'none;')};
  ${({ cssOverride }) => cssOverride};
`;

const SettingsItemContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Palette.BLACK};

  ${({ cssOverride }) => cssOverride};
`;

const SettingsItemTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${Palette.BLACK};
  margin: 0;

  ${({ cssOverride }) => cssOverride};
`;

const SettingsItemSubTitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${Palette.BLACK};
  margin: 0;

  ${({ link }) =>
    link &&
    `
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
  `};

  ${({ cssOverride }) => cssOverride};
`;

export {
  SettingsItem,
  SettingsItemContent,
  SettingsItemTitle,
  SettingsItemSubTitle,
  SettingsRowContainer,
  SettingsTitle,
  SettingsItemRow
};
