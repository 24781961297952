import { createAction } from "@reduxjs/toolkit";
import {
  GET_AMENITY,
  GET_AMENITIES,
  ADD_AMENITIES,
  EDIT_AMENITIES,
  FILTER_AMENITIES,
  MODAL_SHOW_AMENITY_DETAIL,
  CLEAR_AMENITY,
  CLEAR_ERROR,
  SET_AMENITY_TYPE,
  REMOVE_AMENITY,
  GET_AMENITY_TYPES,
  SET_FILTERED_AMENITIES,
  CLEAR_FILTERED_AMENITIES,
  CLEAR_AMENITIES
} from "../constants";

export const setAmenityType = createAction(SET_AMENITY_TYPE);
export const getAmenity = createAction(GET_AMENITY);
export const getAmenities = createAction(GET_AMENITIES);
export const addAmenities = createAction(ADD_AMENITIES);
export const editAmenities = createAction(EDIT_AMENITIES);
export const clearAmenity = createAction(CLEAR_AMENITY);
export const filterAmenities = createAction(FILTER_AMENITIES);
export const modalVisibility = createAction(MODAL_SHOW_AMENITY_DETAIL);
export const clearAmenityError = createAction(CLEAR_ERROR);
export const removeAmenity = createAction(REMOVE_AMENITY);
export const getAmenityTypes = createAction(GET_AMENITY_TYPES);
export const setFilteredAmenities = createAction(SET_FILTERED_AMENITIES);
export const clearFilteredAmenities = createAction(CLEAR_FILTERED_AMENITIES);
export const clearAmenities = createAction(CLEAR_AMENITIES);

export default {
  getAmenity,
  removeAmenity,
  setAmenityType,
  clearAmenityError,
  clearAmenity,
  addAmenities,
  editAmenities,
  getAmenities,
  filterAmenities,
  modalVisibility,
  clearAmenities
};
