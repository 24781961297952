import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import { CButton, CCol, CRow } from '@coreui/react';
import { DownOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { getFullDate12Hr } from '../../../helper/';
import { clearUser } from '../../../redux/actions/user';
import LoadingAvatar from '../../../components/LoadingAvatar';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../utils/colorCode';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../components/Modals';

const ModalSentEmailDetail = () => {
  const { t } = useTranslation();
  const [showGeneral, setShowGeneral] = useState(true);
  const [showMessage, setShowMessage] = useState(true);
  const [showBody, setShowBody] = useState(true);
  const [body, setBody] = useState('');
  const { modal } = useSelector((state) => state.headerModal);
  const { user } = useSelector((state) => state.user);
  const { message, error, status } = useSelector((state) => state.conversationSent);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message.body && message.body.length) {
      setBody(atob(message.body));
    }
  }, [message]);

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  return (
    <div className="modalSentMsgInfo">
      <StyledModal
        show={modal === 'showSentMessageModal' ? true : false}
        onClose={onClose}
        size="lg"
        closeOnBackdrop={true}>
        <ModalHeader title={'Details'} />

        {status === 'SENT_MESSAGE_DETAILS_PENDING' ? (
          <LoadingAvatar color={LOADING_BLUE} />
        ) : (
          <StyledModalBody>
            <CButton onClick={() => setShowGeneral(!showGeneral)}>
              <DownOutlined /> {t('General')}
            </CButton>
            <div className="dataHolder" style={{ display: showGeneral ? 'block' : 'none' }}>
              <CRow>
                <CCol sm="2">
                  <p className="text-muted">{t('Author')}</p>
                </CCol>
                <CCol>
                  <h6>{message.author}</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="2">
                  <p className="text-muted">
                    {message?.process_date ? t('Date Sent') : t('Date Created')}
                  </p>
                </CCol>
                <CCol>
                  <h6>
                    {message?.process_date
                      ? getFullDate12Hr(message.process_date)
                      : getFullDate12Hr(message.create_date)}
                  </h6>
                </CCol>
              </CRow>
            </div>

            <CButton onClick={() => setShowMessage(!showMessage)}>
              <DownOutlined /> {t('Message')}
            </CButton>
            <div className="dataHolder" style={{ display: showMessage ? 'block' : 'none' }}>
              <CRow>
                <CCol sm="2">
                  <p className="text-muted">{t('Subject')}</p>
                </CCol>
                <CCol>
                  <h6>{message.subject}</h6>
                </CCol>
              </CRow>
            </div>

            <CButton onClick={() => setShowBody(!showBody)}>
              <DownOutlined /> {t('Body')}
            </CButton>
            <div className="bodyDiv" style={{ display: showBody ? 'block' : 'none' }}>
              {error === '' ? ReactHtmlParser(body) : <p className="text-danger">{error}</p>}
            </div>
          </StyledModalBody>
        )}
      </StyledModal>
    </div>
  );
};

export default ModalSentEmailDetail;
