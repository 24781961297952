import { CCol, CRow } from '@coreui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { setAssignedOperatorProcess, updateService } from '../../../../../redux/actions/services';
import { getUser, setUser } from '../../../../../redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';

import { AssignOperatorProcess, RequestStatus } from '../../../constant';
import UserAsyncDropDown from '../../../../../components/UserAsyncDropDown';
import { getStatusId } from '../../../../../helper/index';
import { setToast } from '../../../../../redux/actions/window';
import { useTranslation } from 'react-i18next';
import { InputSeparator, SettingsModalBoldText } from '../../../../settings/SettingsModals';
import { ErrorText } from '../../../../../components/utils/AvatarCropper';
import _ from 'lodash';

const AssignRequest = ({ serviceStatus, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { service } = useSelector((state) => state.services);
  const [assignOperatorError, setAssignOperatorError] = useState();

  let serviceReq = {};

  const assignedTechnician = useMemo(()=>{
    return Boolean(service?.assigned_to) && Boolean(user?.id) && service?.assigned_to === user.id ? 
    {label: `${user.first_name} ${user.last_name}`, value: user.id} : {}
  },[
    service?.assigned_to,
    user?.id
  ])

  const { t } = useTranslation();

  const onFail = (errMsg = 'Assigning request failed!') => {
    onUpdateSuccess(dispatch(setAssignedOperatorProcess('')));
    dispatch(
      setToast({
        toastShow: true,
        toastMessage: t(errMsg),
        modal: 'errorToast'
      })
    );
  }

  const onAssign = (e) => {
    let myContactInfo = service.contact_info;

    serviceReq = {
      user_id: parseInt(service.user_id),
      building_id: parseInt(service.building_id),
      category_id: parseInt(service.category_id),
      schedule_date: service.scheduled_date,
      status_id: serviceStatus && getStatusId(serviceStatus),
      subject: service.subject,
      description: service.description,
      media: service.media,
      assigned_to: service.assigned_to ? service.assigned_to : null,
      time_slot: service.time_slot ? service.time_slot : null,
      contact_type: service.contact_type,
      priority: service.priority,
      allow_access: service.allow_access,
      data: service.data,
      contact_info: myContactInfo
    };
    serviceReq.assigned_to = parseInt(e.value);
    dispatch(setAssignedOperatorProcess(AssignOperatorProcess.Pending));

    if(!Boolean(serviceReq?.assigned_to)) {
      onFail();
      return;
    }

    dispatch(
      getUser({
        id: serviceReq.assigned_to,
        success: () => {
          setAssignOperatorError(null);
          dispatch(
            updateService({
              id: service.id,
              body: {
                ...serviceReq
              },
              success: () => {
                onUpdateSuccess(dispatch(setAssignedOperatorProcess('')));
                dispatch(
                  setToast({
                    toastShow: true,
                    toastMessage: t('Request assigned successfully!')
                  })
                );
              },
              fail: onFail
            })
          );
        },
        fail: (res) => {
          setAssignOperatorError(res?.data?.message);
          onFail();
        }
      })
    );
  };

  if (serviceStatus === RequestStatus.OPEN || serviceStatus === RequestStatus.COMPLETE) {
    return <></>;
  }
  return (
    <InputSeparator>
      <SettingsModalBoldText>{t('Assign operator')}</SettingsModalBoldText>

      <div className="operatorDropDownDiv">
        <UserAsyncDropDown
          skip_deleted={true}
          value={!_.isEmpty(assignedTechnician) ? assignedTechnician : undefined}
          setValue={onAssign}
          type={2}
          placeholder={t('Assign operator')}
          disabled={
            serviceStatus === RequestStatus.COMPLETE || serviceStatus === RequestStatus.ARCHIVED
          }
          buildingId={service.building_id}
        />
        {assignOperatorError && <ErrorText>{assignOperatorError}</ErrorText>}
      </div>
    </InputSeparator>
  );
};

export default AssignRequest;
