import { createReducer } from "@reduxjs/toolkit";
import { resetProfileData, updateProfileData } from "../actions/updateUserProfile";

const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  mobileNumber: "",
  avatar: {
    blobURL: undefined, // Used to display on the final modal
    blobData: undefined, // The data to submit to the server (the actual user profile data)
    blobName: undefined, // The image name
  }
};

export default createReducer(initialState, {
  [updateProfileData]: (state, { payload }) => ({
    ...state,
    ...payload
  }),

  [resetProfileData]: () => ({
    ...initialState
  }),
});