import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { baseURL } from '../utils/axios';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  filterConfig,
  IconOption,
  inputSelectCustomStyles,
  StyledAsyncPaginate
} from './CustomSearchBar';

const BuildingsAsyncDropDown = ({
  value,
  setValue,
  type = 0,
  buildingId = null,
  disabled = false,
  residentOnly = false,
  onChange
}) => {
  const { building_id } = useSelector((state) => state.auth);
  const { globalOrgId } = useSelector((state) => state.Organization);
  const { t } = useTranslation();

  if (parseInt(buildingId) === null) {
    buildingId = building_id;
  }

  const loadOptions = useCallback(
    async (searchQuery, loadedOptions, additional) => {
      const { page } = additional;
      let url = `${baseURL}/orgs/${globalOrgId}/buildings?page=${page}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
          'org-location': window.localStorage.data_center
        }
      });
      const responseJSON = await response.json();

      let myResponse = [];

      for (const building of responseJSON) {
        const build = {
          value: building.id,
          label: building.name
        };

        myResponse.push(build);
      }

      return {
        options: myResponse,
        hasMore: responseJSON !== null && responseJSON.length >= 20,
        additional: {
          page: searchQuery ? 2 : page + 1
        }
      };
    },
    [globalOrgId]
  );

  const handleOnChange = (item) => {
    if (onChange) {
      onChange(item);
    } else {
      setValue(item);
    }
  };

  return (
    <div>
      <StyledAsyncPaginate
        debounceTimeout={500}
        key={`product-select-${buildingId}`}
        name="product"
        className="react-select-container"
        classNamePrefix="react-select"
        value={value}
        loadOptionsOnMenuOpen={true}
        loadOptions={loadOptions}
        onChange={handleOnChange}
        filterOption={filterConfig}
        placeholder={t('Select building')}
        styles={inputSelectCustomStyles({ height: '56px' })}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption
        }}
        additional={{
          page: 1
        }}
      />
    </div>
  );
};

export default memo(BuildingsAsyncDropDown);
