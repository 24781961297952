import { createAction } from '@reduxjs/toolkit';
import {
  CLEAR_SEARCH_FILTER,
  FILTERS,
  RESET_SEARCH_FILTER,
  SEARCH,
  SET_SEARCH_COLUMN_FILTER,
  SET_SEARCH_FIELD,
  SET_SEARCH_FILTER,
  SET_SEARCH_PAGINATION,
  SET_SEARCH_STATE,
  SET_SHOULD_CLEAR_QUERY
} from '../constants';

export const searchTable = createAction(SEARCH);
export const getFilters = createAction(FILTERS);
export const setSearchField = createAction(SET_SEARCH_FIELD);
export const setShouldClearQuery = createAction(SET_SHOULD_CLEAR_QUERY);

export const setSearchState = createAction(SET_SEARCH_STATE);
export const setSearchFilter = createAction(SET_SEARCH_FILTER);
export const setSearchColumn = createAction(SET_SEARCH_COLUMN_FILTER);
export const setSearchPagination = createAction(SET_SEARCH_PAGINATION);
export const clearSearchFilter = createAction(CLEAR_SEARCH_FILTER);
export const resetSearchFilter = createAction(RESET_SEARCH_FILTER);

export default {
  searchTable,
  getFilters,
  setSearchField,
  setShouldClearQuery,

  setSearchState,
  setSearchFilter,
  setSearchColumn,
  setSearchPagination,
  clearSearchFilter,
  resetSearchFilter
};
