import React, { forwardRef, useState } from "react";
import { TextInput } from "./ui/TextInput";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import styled from 'styled-components';
import { Palette } from "./utils";

const PasswordInput = forwardRef(({ className, iconClassName, ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className="position-relative">
      <TextInput
        ref={ref}
        {...rest}
        type={showPassword ? "text" : "password"}
      />
      {showPassword ?
        <EyeIconContainer>
          <IoEyeOutline
            size={20}
            color={Palette.BLACK}
            onClick={() => setShowPassword(!showPassword)}
          />
        </EyeIconContainer>
        :
        <EyeIconContainer>
          <IoEyeOffOutline
            color={Palette.BLACK}
            size={20}
            onClick={() => setShowPassword(!showPassword)}
          />
        </EyeIconContainer>
      }
    </div>
  );
});

const EyeIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export default PasswordInput;