import { createReducer } from '@reduxjs/toolkit';
import { resetUserTypeData, updateUserTypeData } from '../actions/addUserType';

const initialState = {
  editingID: undefined, // This will be an ID if the user is editing an entry, undefined if it's new
  type: undefined,
  role: undefined,
  firstName: '',
  lastName: '',
  email: '',
  homePhoneNumber: '',
  mobilePhoneNumber: '',
  organization: {
    organizationId: undefined,
    name: ''
  },
  buildings: {
    buildingId: undefined,
    name: ''
  },
  unitNumber: undefined,
  moveInDate: undefined,
  moveOutDate: undefined,
  monthlyRent: 0,
  sendWelcomeEmail: true,
  welcomeEmailScheduleDateTime: '',
  selectedBuildingsTimezone: undefined,
  avatar: {
    blobURL: undefined, // Used to display on the final modal
    blobData: undefined, // The data to submit to the server (the actual user profile data)
    blobName: undefined // The image name
  }
};

export default createReducer(initialState, {
  [updateUserTypeData]: (state, { payload }) => ({
    ...state,
    ...payload
  }),

  [resetUserTypeData]: () => ({
    ...initialState
  })
});
