import {
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle
} from '@coreui/react';
import { Link } from 'react-router-dom';
import { Fonts, Palette } from '../../components/utils';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none !important;

  :hover {
    svg {
      color: ${Palette.OCEANIC_60} !important;
    }
    color: ${Palette.OCEANIC_60} !important;
  }
`;

export const StyledSidebarNavItem = styled(CSidebarNavItem)`
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  color: ${({ selected }) => (selected ? Palette.OCEANIC_60 : Palette.WHITE)};
  background-color: ${({ selected }) => (selected ? Palette.OCEANIC_NAV_HOVER : Palette.DARK_ASH)};

  :hover {
    svg {
      color: ${Palette.OCEANIC_60} !important;
    }
    color: ${Palette.OCEANIC_60} !important;
  }
`;

export const StyledBottomSidebarNavItem = styled(StyledSidebarNavItem)`
  font-size: 12px;
  line-height: 16px;
`;

export const SidebarCSidebarBrand = styled(CSidebarBrand)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${Palette.HAZY_BLUE} !important;
  padding: 0 20px;
  gap: 12px;
`;

export const SidebarNavTitle = styled(CSidebarNavTitle)`
  margin: 0;

  display: flex;
  width: 256px;
  padding: 8px 16px;
  align-items: flex-start;

  color: ${Palette.BLACK_20} !important;
  font-family: ${Fonts.OPEN_SANS_FONT} !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 16px !important;
`;

export const SidebarOrganizationTitleText = styled.p`
  all: unset;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: ${Palette.WHITE};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledSidebarNav = styled(CSidebarNav)`
  position: sticky;
  overflow-y: scroll !important;
  max-height: '100vh';
`;

export const StyledSidebarNavDropdown = styled(CSidebarNavDropdown)`
  transition: none;

  .c-sidebar-nav-dropdown-toggle {
    transition: none;
    display: flex;
    gap: 18px;
    color: ${Palette.WHITE} !important;
    background-color: ${Palette.DARK_ASH} !important;
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    height: 40px;

    :hover {
      color: ${Palette.OCEANIC_60} !important;
      background-color: ${Palette.OCEANIC_NAV_HOVER} !important;
      svg {
        color: ${Palette.OCEANIC_60} !important;
      }
    }
  }

  svg {
    color: ${Palette.WHITE} !important;
    :hover {
      color: ${Palette.OCEANIC_60} !important;
    }
  }
`;
