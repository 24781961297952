import { Tree } from 'antd';
import styled from 'styled-components';
import { Fonts, Palette } from '../utils';

const { DirectoryTree } = Tree;

export const StyledTree = styled(DirectoryTree)`
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: 8px;

  ${({ disableTopLevel }) =>
    disableTopLevel &&
    `
    ant-tree-list-holder-inner > div:first-child {
      .ant-tree-checkbox {
        display: none;
      }
    }

    
  .ant-tree-treenode.ant-tree-treenode-switcher-open {
    .ant-tree-switcher {
      display: none;
    }
  }

  .ant-tree-treenode.ant-tree-treenode-switcher-close {
    .ant-tree-switcher {
      display: none; 
    }
  }
  `}

  .ant-tree-list-holder-inner:has(> div:first-child.ant-tree-treenode-switcher-close) {
    background-color: ${Palette.SOLITUDE};
    border-radius: 8px;
    .ant-tree-title {
      color: ${Palette.BLACK_50};
    }
  }

  .ant-tree .ant-tree-treenode {
    padding: 0;
  }

  .ant-tree-switcher_close {
    svg {
      transform: rotate(-180deg);
      transition: transform 300ms;
    }
  }

  .ant-tree-switcher_open {
    svg {
      transition: transform 300ms;
    }
  }

  .ant-tree-treenode-selected {
    ::before {
      background: none;
    }
  }

  .ant-tree-list-holder-inner > div:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .ant-tree-list-holder-inner > div:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-tree-list-holder-inner {
  }

  .ant-tree-title {
    color: ${Palette.BLACK};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  [aria-selected='true'] {
    border-top: 1px solid rgba(117, 117, 117, 0.3);

    .ant-tree-switcher {
      display: none;
    }
  }

  .ant-tree-indent {
    display: none;
  }

  .ant-tree-switcher {
    position: absolute;
    right: 22px;
    top: 34%;
  }

  .ant-tree-checkbox {
    order: 1;
    margin: 0;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9 15H11V11H15V9H11V5H9V9H5V11H9V15ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="%234697E0"/></svg>');
  }

  .ant-tree-checkbox-checked {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 0C11.3132 -1.95685e-08 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 3.95203e-08 10 0ZM13.5333 6.94L8.75 11.7367L6.42333 9.41C6.34585 9.33252 6.25387 9.27106 6.15264 9.22913C6.05141 9.1872 5.94291 9.16561 5.83333 9.16561C5.72376 9.16561 5.61526 9.1872 5.51403 9.22913C5.41279 9.27106 5.32081 9.33252 5.24333 9.41C5.16585 9.48748 5.10439 9.57946 5.06246 9.68069C5.02053 9.78193 4.99895 9.89043 4.99895 10C4.99895 10.1096 5.02053 10.2181 5.06246 10.3193C5.10439 10.4205 5.16585 10.5125 5.24333 10.59L8.16 13.5067C8.23741 13.5843 8.32937 13.6458 8.43061 13.6879C8.53185 13.7299 8.64039 13.7515 8.75 13.7515C8.85961 13.7515 8.96815 13.7299 9.06939 13.6879C9.17063 13.6458 9.26259 13.5843 9.34 13.5067L14.7133 8.11667C14.8649 7.95929 14.9486 7.74866 14.9464 7.53016C14.9442 7.31166 14.8563 7.10276 14.7016 6.94847C14.5468 6.79417 14.3377 6.70683 14.1192 6.70524C13.9007 6.70366 13.6903 6.78796 13.5333 6.94Z" fill="%234697E0"/></svg>');
  }

  .ant-tree-checkbox-indeterminate {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM15 9V11H5V9H15Z" fill="%234697E0"/></svg>');
  }

  .ant-tree-node-content-wrapper {
    z-index: 1;
    display: flex;
    padding: 0px 25px;
    align-items: center;
    height: ${(props) => (props.height ? props.height : '56px')};
  }

  .ant-tree-checkbox {
    z-index: 999 !important;
    padding-right: 25px;
    position: absolute;
    right: 0;
  }

  .ant-tree-title {
    align-self: center;
  }

  .ant-tree-treenode {
    ::before {
      background: none !important;

      :hover {
        color: ${Palette.BLACK} !important;
        background-color: none !important;
      }
    }

    padding: 0px;
    align-items: center;
    /* height: ${(props) => (props.height ? props.height : '56px')}; */

    :hover {
      color: ${Palette.BLACK};
      background-color: ${Palette.CUSTOM_GRAY_2};
    }
  }
`;
