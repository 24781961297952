import { CCol, CModalFooter, CPagination, CRow } from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import moment from 'moment';
import { useEffect } from 'react';
import LoadingAvatar from '../../../../components/LoadingAvatar';
import { getBuilding } from '../../../../redux/actions/building';
import { getActivities } from '../../../../redux/actions/reports';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import i18next from 'i18next';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { InputSeparator, SettingsModalBoldText } from '../../../settings/SettingsModals';
import { CustomDatePicker } from '../../../../components/CustomSearchBar';
import {
  ActivitiesLogContainer,
  ActivitiesLogTextContainer,
  DateText,
  EventText,
  TimeText
} from '../../../pm-analytics/orgs/PMActivities';
import { DATE_FORMATS } from '../../../users/constant';

const UnitStatistics = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [activitiesData, setActivitiesData] = useState([]);
  const [pagination, setPagination] = useState({});

  const [startDateFound, setStartDateFound] = useState(true);

  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));

  const { modal, data: suiteData } = useSelector((state) => state.headerModal);
  const { unit } = useSelector((state) => state.unit);

  const { globalBuildingId, building } = useSelector((state) => state.building);
  const { globalOrgId, orgConfig } = useSelector((state) => state.Organization);
  const { activities, status, error } = useSelector((state) => state.reports);
  const { t } = useTranslation();

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const disabledStartDate = (current) => {
    if (current.isAfter(endDate)) return true;

    return current && current > moment().endOf('day');
  };

  const disabledEndDate = (current) => {
    if (current.isBefore(startDate)) return true;

    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (activities && activities?.data) {
      const groupedData = activities.data.reduce((events, curr) => {
        const dateString = moment(curr.timestamp).format('YYYY-MM-DD');
        if (!events.get(dateString)) {
          events.set(dateString, []);
        }

        events.set(dateString, [...events.get(dateString), curr]);
        return events;
      }, new Map());
      setActivitiesData(groupedData);

      setPagination(activities?.pagination);
    }
  }, [activities, pagination]);

  useEffect(() => {
    const totalPages = Math.ceil(
      parseInt(pagination['pagination-total']) / parseInt(pagination['pagination-per-page'])
    );
    const currPage = parseInt(pagination['pagination-current-page']);
    let myStartDateFound = true;
    if (totalPages === currPage) {
      myStartDateFound = false;
      const startDay = moment(startDate).utc().format('YYYY-MM-DD');
      [...activitiesData.keys()].map((day, index) => {
        if (startDay === day) {
          myStartDateFound = true;
        }
      });
    }

    setStartDateFound(myStartDateFound);
  }, [activitiesData, pagination, startDate, endDate]);

  useEffect(() => {
    let baseActivities = {
      page: activePage,
      reportType: 'a',
      start_date: startDate.startOf('day').format('YYYY-MM-DDHH-mm-ss'),
      end_date: endDate.endOf('day').format('YYYY-MM-DDHH-mm-ss')
    };

    if (suiteData) {
      baseActivities = {
        ...baseActivities,
        buildingId: suiteData.building_id,
        orgId: suiteData.org_id,
        suiteId: suiteData.id
      };
    } else {
      baseActivities = {
        ...baseActivities,
        buildingId: building.id,
        orgId: building.org_id,
        unitId: unit?.id
      };
    }

    dispatch(getActivities(baseActivities));
  }, [
    startDate,
    endDate,
    activePage,
    dispatch,
    unit,
    globalBuildingId,
    globalOrgId,
    building,
    suiteData
  ]);

  useEffect(() => {
    if (unit?.building_id) {
      dispatch(getBuilding({ id: unit.building_id }));
    }
  }, [unit, dispatch]);

  const actionForPagination = (page) => {
    if (page > 0) setActivePage(page);
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'unitStatistics' || modal == 'PMActivitiesDetails'}
      onClose={onClose}
      closeOnBackdrop={false}>
      <ModalHeader
        title={`${t('Activities for Unit')} ${suiteData ? suiteData.name : unit.unit_num}`}
      />
      {status === 'GET_ACTIVITIES_PENDING' ? (
        <LoadingAvatar color={LOADING_BLUE} />
      ) : (
        <>
          <StyledModalBody>
            <CRow>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Start Date')}</SettingsModalBoldText>
                  <CustomDatePicker
                    locale={i18next.resolvedLanguage === 'fr' ? locale : localeEn}
                    placeholder={t('Start Date')}
                    id="startDate"
                    allowClear={false}
                    disabledDate={disabledStartDate}
                    onChange={handleChangeStartDate}
                    value={startDate}
                  />
                </InputSeparator>
              </CCol>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{t('End Date')}</SettingsModalBoldText>
                  <CustomDatePicker
                    locale={i18next.resolvedLanguage === 'fr' ? locale : localeEn}
                    placeholder={t('End Date')}
                    id="endDate"
                    allowClear={false}
                    disabledDate={disabledEndDate}
                    onChange={handleChangeEndDate}
                    value={endDate}
                  />
                </InputSeparator>
              </CCol>
            </CRow>

            {[...activitiesData.keys()].map((day, index) => (
              <ActivitiesLogContainer key={index}>
                <CRow>
                  <CCol sm="12">
                    <div>
                      <DateText>{day}</DateText>
                    </div>
                  </CCol>
                </CRow>
                {[...activitiesData.get(day)].map((event, index) => (
                  <CRow className="DetailRow" key={index}>
                    <CCol>
                      <ActivitiesLogTextContainer>
                        <TimeText>{moment(event.timestamp).format(DATE_FORMATS.hh_mm_a)}</TimeText>
                        <EventText>{event.message}</EventText>
                      </ActivitiesLogTextContainer>
                    </CCol>
                  </CRow>
                ))}
              </ActivitiesLogContainer>
            ))}
          </StyledModalBody>
          <CModalFooter style={{ justifyContent: 'center' }}>
            {activitiesData.size === 0 && (
              <p className="text-danger d-block w-100 m-0">{t('No available data')}</p>
            )}
            {error ? (
              <p className="text-danger d-block w-100 m-0">{error}</p>
            ) : (
              pagination && (
                <div className={'mb-2'}>
                  {pagination['pagination-total'] > 0 && (
                    <CPagination
                      activePage={parseInt(pagination['pagination-current-page']) ?? 1}
                      pages={
                        activities?.data?.length > 0
                          ? Math.ceil(
                              pagination['pagination-total'] / pagination['pagination-per-page']
                            )
                          : 0
                      }
                      onActivePageChange={(i) => actionForPagination(i)}></CPagination>
                  )}
                </div>
              )
            )}
          </CModalFooter>
        </>
      )}
    </StyledModal>
  );
};

export default UnitStatistics;
