import { createReducer } from '@reduxjs/toolkit';
import {
  CLEAR_SEARCH_FILTER,
  REMOVE_SEARCH_FILTER,
  RESET_SEARCH_FILTER,
  SEARCH,
  SET_SEARCH_COLUMN_FILTER,
  SET_SEARCH_FIELD,
  SET_SEARCH_FILTER,
  SET_SEARCH_PAGINATION,
  SET_SEARCH_STATE,
  SET_SHOULD_CLEAR_QUERY
} from '../constants';
import {
  OffersSearchFields,
  OrgSearchFields,
  BuildingsearchFields,
  UsersSearchFields,
  BookingSeachFields,
  ServiceSearchFields
} from '../../utils/searchConstant';
import { requestFail, requestPending, requestSuccess } from '../../utils/status';

const initialState = {
  searchFields: {
    organization: OrgSearchFields,
    building: BuildingsearchFields,
    user: UsersSearchFields,
    booking: BookingSeachFields,
    request: ServiceSearchFields,
    offer: OffersSearchFields
  },
  selectedField: {
    organization: '',
    building: '',
    user: '',
    booking: '',
    request: '',
    offer: ''
  },
  searchStatus: '',
  shouldClearQuery: false,

  currentData: [],
  currentPaginationData: {},

  // TODO: Future - Move these options into the filters object
  search: '',
  minCreationDate: undefined,
  maxCreationDate: undefined,
  category: -1,
  building_id: 0,

  filters: {},
  // filters: new Map(),
  ordering: {},
  pagination: {
    page: 1,
    limit: 20
  }
};

export default createReducer(initialState, {
  [SET_SEARCH_FIELD]: (state, { payload }) => {
    let newSelectedField = { ...state.selectedField };

    for (const key in newSelectedField) {
      if (key == payload.entity) {
        newSelectedField[key] = payload.value;
      }
    }

    return {
      ...state,
      selectedField: newSelectedField
    };
  },

  [SET_SHOULD_CLEAR_QUERY]: (state, { payload }) => {
    return {
      ...state,
      shouldClearQuery: payload
    };
  },

  ////////////////////////////////

  [SET_SEARCH_STATE]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  },

  [SET_SEARCH_FILTER]: (state, { payload }) => {
    state.set(payload.option, payload.value);

    return {
      ...state,
      filters: new Map(state)
    };
  },

  [REMOVE_SEARCH_FILTER]: (state, { payload }) => {
    return {
      ...state
    };
  },

  [SET_SEARCH_COLUMN_FILTER]: (state, { payload }) => {
    const currentColumn = state.ordering[payload.column];

    return {
      ...state,
      ordering: currentColumn ? {} : { [payload.column]: payload.order }
    };
  },

  [SET_SEARCH_PAGINATION]: (state, { payload }) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        payload
      }
    };
  },

  [RESET_SEARCH_FILTER]: () => {
    return {
      ...initialState
    };
  },

  [requestSuccess(SEARCH)]: (state) => ({
    ...state,
    searchStatus: requestSuccess(SEARCH)
  }),

  [requestPending(SEARCH)]: (state) => ({
    ...state,
    searchStatus: requestPending(SEARCH)
  }),

  [requestFail(SEARCH)]: (state) => ({
    ...state,
    searchStatus: requestFail(SEARCH)
  })
});
