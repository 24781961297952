import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  defaultImgCDN,
  compressImgFile,
  ImagesOptions,
  generateImageURLObj
} from '../helper';
import { useSelector } from 'react-redux';
import { MediaKeys } from '../views/offers/utils';
import { Button } from './ui/Button';
import styled from 'styled-components';
import { Palette, Fonts } from './utils';
import { SettingsModalLightText } from '../views/settings/SettingsModals';
import { MdError } from 'react-icons/md';

const ImageUploadWithPreview = ({
  name,
  avatar,
  newAvatarState,
  withPreviewImageOnly,
  setFormValue,
  uploaderLength,
  rounded,
  allowResetDefault,
  resetState = {},
  validationSet,
  mediaKey,
  subtitle = '',
  showMaximumFileSizeMessage = true,
  onCancel
}) => {
  const { t } = useTranslation();
  const maxFileSize = 5 * 1024 * 1024;
  const [errMsg, setErrMsg] = useState(null);
  const fileInputId = (name ? name : '') + 'edit-avatar';
  const { newAvatar, setNewAvatar } = newAvatarState;
  const initialAvatar = useMemo(() => newAvatar, []);
  // shape should only be 'rounded' or 'square'
  const canvasMargin = rounded ? 0.5 : 0;
  const canvasSize = uploaderLength ? uploaderLength : 250;
  const setFormValueAvatar = typeof setFormValue === 'function' ? setFormValue : () => {};
  const { modal } = useSelector((state) => state.headerModal);
  const { isResetted, setIsResetted } = resetState;
  const inputRef = useRef();
  // reset state once modal has changed
  useEffect(() => {
    handleReset();
  }, [modal]);

  const handleReset = () => {
    document.getElementById(fileInputId).value = '';
    setNewAvatar(initialAvatar);
    setErrMsg(null);
  };
  // validation check upon file upload
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { error } = validateFile(file);
      if (error) {
        setErrMsg(error);
      } else {
        setErrMsg(null);

        let imageBlob;
        if (mediaKey === MediaKeys.MEDIA || mediaKey === MediaKeys.BANNER_LOGO) {
          imageBlob = await compressImgFile(
            file,
            ImagesOptions(3)[mediaKey].DIMENSION.WIDTH,
            ImagesOptions(3)[mediaKey].DIMENSION.HEIGHT
          );
        } else {
          imageBlob = await compressImgFile(file);
        }
        setNewAvatar({
          image: imageBlob,
          name: file.name
        });
        setFormValueAvatar(false, file.name);
      }
    }
  };

  const runCustomValidations = (file) => {
    if (!validationSet || !Array.isArray(validationSet)) return '';
    for (const validation of validationSet) {
      if (typeof validation['validationFn'] === 'function') {
        const validationFn = validation['validationFn'];
        const isValid = validationFn(file);
        if (!isValid) {
          return validation['error'] || 'Something went wrong';
        }
      }
    }
    return '';
  };

  const validateFile = (file) => {
    const validExt = mediaKey === MediaKeys.BANNER_LOGO ? ['png', 'svg'] : ['jpg', 'png', 'jpeg'];

    const fileSplit = file.name && typeof (file.name === 'string') ? file.name.split('.') : [];

    const error = runCustomValidations(file);
    if (error !== '') {
      return {
        error: <>{error}</>
      };
    }
    if (!validExt.includes(fileSplit[fileSplit.length - 1]?.toLowerCase())) {
      return {
        error: (
          <>
            Please select <strong>{mediaKey === MediaKeys.BANNER_LOGO ? 'svg' : 'jpg'}</strong> or{' '}
            <strong>png</strong> file
          </>
        )
      };
    }

    if (file.size && file.size > maxFileSize) {
      return {
        error: (
          <>
            Maximum file size is <strong>3 MB</strong>
          </>
        )
      };
    }
    return {};
  };

  const triggerFileInput = (e) => {
    e.preventDefault();
    document.getElementById(fileInputId).click();
  };

  const cancelSelectedAvatar = () => {
    handleReset();
    setFormValueAvatar(true);
  };

  const toggleReset = () => {
    handleReset();
    setIsResetted((prev) => !prev);
    if (onCancel) onCancel();
  };

  return (
    <EditAvatarContainer>
      {showMaximumFileSizeMessage && (
        <RuleText>{t('*Support jpg and png file. Maximum file size is 3MB')}</RuleText>
      )}
      {(!mediaKey || mediaKey === MediaKeys.LOGO) && (
        <PreviewImage
          height={canvasSize}
          width={canvasSize}
          borderRadius={canvasSize * canvasMargin}
          src={
            withPreviewImageOnly && !newAvatar.image
              ? defaultImgCDN
              : newAvatar.image
                ? generateImageURLObj(newAvatar.image)
                : avatar
                  ? avatar
                  : defaultImgCDN
          }
          id="avatar"
          name="avatar"
          alt='image'
        />
      )}
      {errMsg && <ErrorText>{errMsg}</ErrorText>}
      {newAvatar.image ? (
        <ButtonContainer>
          <Button isPrimary onClick={triggerFileInput} text={t('Select')} type="button" />
          <Button
            isPrimary={false}
            onClick={cancelSelectedAvatar}
            text={t('Cancel')}
            type="button"
          />
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Button isPrimary onClick={triggerFileInput} text={t('Select')} type="button" />
          {allowResetDefault && avatar && typeof setIsResetted === 'function' && (
            <Button
              isPrimary
              onClick={toggleReset}
              text={isResetted ? t('Reset to original') : t('Reset to default')}
              type="button"
            />
          )}
        </ButtonContainer>
      )}
      <input
        ref={inputRef}
        type="file"
        id={fileInputId}
        className="d-none"
        onChange={handleUpload}
        accept={mediaKey === MediaKeys.BANNER_LOGO ? '.svg,.png' : '.jpg,.png,.jpeg'}
      />
      <SubtitleText>{t(subtitle)}</SubtitleText>
    </EditAvatarContainer>
  );
};

const SubtitleText = styled(SettingsModalLightText)`
  color: ${Palette.ACTIVE_GREEN};
`;

const RuleText = styled.p`
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const EditAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: 2em;
`;


export const ErrorText = ({ children }) => {
  return (
    <StyledErrorText>
      <MdError size={24} className="mr-2" /> {children}
    </StyledErrorText>
  );
};

export const StyledErrorText = styled.p`
  color: ${Palette.ERROR};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
`;

export const PreviewImage = styled.img`
  object-fit: contain;
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: ${props => props.borderRadius};
`;


export default ImageUploadWithPreview;
