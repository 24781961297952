import React from 'react';
import { CCol, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import { ModalCloseButton } from '../views/settings/SettingsButton';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';
import { Button } from './ui/Button';
import { closeActiveModal } from '../helper/modals';

export const ModalHeader = ({ title, onCloseCallback }) => {
  return (
    <CModalHeader>
      <StyledModalTitle>{title}</StyledModalTitle>
      <div className="ml-auto align-self-start">
        <ModalCloseButton onCloseCallback={onCloseCallback} />
      </div>
    </CModalHeader>
  );
};

export const ModalButtons = ({
  leftButtonText,
  leftButtonClick,
  rightButtonText,
  rightButtonClick,
  rightDisabled,
  disabled,
}) => {
  return (
    <CRow>
      <CCol>
        <Button
          className="w-100"
          text={leftButtonText}
          onClick={leftButtonClick}
          disabled={disabled}
        />
      </CCol>
      <CCol>
        <Button
          className="w-100"
          isPrimary
          text={rightButtonText}
          onClick={rightButtonClick}
          disabled={disabled || rightDisabled}
        />
      </CCol>
    </CRow>
  );
};

const StyledModalTitle = styled(CModalTitle)`
  color: ${Palette.BLACK};
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px;
  text-transform: capitalize;
`;

export const StyledModal = ({ onClose, ...props }) => {
  return (
    <StyledCModal
      closeOnBackdrop={false}
      onClose={onClose ? onClose : closeActiveModal}
      {...props}
    />
  );
};

const StyledCModal = styled(CModal)`
  transition: none;

  .modal .modal-dialog {
    transition: none;
  }
  .modal-content {
    transition: none;
    border-radius: 30px !important;
    background: ${Palette.GHOST_WHITE};
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 46px !important;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ width }) => width || '548px'};
    max-height: 85vh;
  }

  .modal-dialog {
    transition: none !important;
    min-width: ${({ width }) => width || '548px'};

    max-height: 85vh;

    .modal-body {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  ${({ cssOverride }) => cssOverride};
`;

export const StyledModalBody = styled(CModalBody)`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '24px'};

  overflow-x: hidden;
  overflow-y: scroll;

  padding-right: 3px !important;

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: none;
  }

  :hover {
    ::-webkit-scrollbar-track {
      display: none;
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: none;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${Palette.HAZY_BLUE};
    }
  }

  ${({ cssOverride }) => cssOverride};
`;
