import { resetUserPagination } from "../redux/actions/user";
import { resetServicePagination } from "../redux/actions/services";
import { resetBookingPagination } from "../redux/actions/bookingParAm";
import { useDispatch} from "react-redux";

export const useResetAllPaginations = () => {
    const dispatch = useDispatch();

    const resetAllPaginations = () => {
      dispatch(resetUserPagination());
      dispatch(resetServicePagination());
      dispatch(resetBookingPagination());
    };

    return {
      resetAllPaginations
    };
};

