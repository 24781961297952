import { CCol, CModalFooter, CRow } from '@coreui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { dayTypes, getDayInString } from '../../../../helper';
import _, { isArray } from 'lodash';
import { addAmenities, clearAmenityError, getAmenities } from '../../../../redux/actions/amenities';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { setToast } from '../../../../redux/actions/window';
import { useTranslation } from 'react-i18next';
import { hoursEnd, hoursStart } from '../../../../utils/mappings';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../../settings/SettingsModals';
import { Button } from '../../../../components/ui/Button';
import { Select, SelectItem } from '../../../../components/ui/Select';
import BuildingsAsyncDropDown from '../../../../components/BuildingsAsyncDropDown';
import {
  IconOption,
  inputSelectCustomStyles,
  StyledSelect
} from '../../../../components/CustomSearchBar';
import { ErrorText } from '../../../../components/utils/AvatarCropper';
import { closeActiveModal } from '../../../../helper/modals';
import ReactJson from 'react-json-view';

const AddAmenities = () => {
  const { t } = useTranslation();

  const days = [
    { value: 'M', label: t('Monday') },
    { value: 'T', label: t('Tuesday') },
    { value: 'W', label: t('Wednesday') },
    { value: 'TH', label: t('Thursday') },
    { value: 'F', label: t('Friday') },
    { value: 'S', label: t('Saturday') },
    { value: 'SU', label: t('Sunday') }
  ];

  const [singleOptions, setSingleOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setSingleOptions([
      { value: 1, label: t('All days') },
      { value: 2, label: t('Weekdays') },
      { value: 3, label: t('Weekends') },
      { value: 4, label: t('Select days') }
    ]);
  }, [t]);

  const amenitiesLabels = {
    building: t('Building'),
    type: t('Type'),
    name: t('Amenity name'),
    available_days: t('Available days'),
    start_time: t('Available start time'),
    end_time: t('Available end time'),
    max_reserve_time: t('Max reservation time'),
    cost_per_hor: t('Cost per hour'),
    description: t('Description'),
    data: t('Data'),
  };
  const dispatch = useDispatch();
  const { building } = useSelector((state) => state.building);
  const { error, status, amenityTypes } = useSelector((state) => state.amenities);
  const { modal } = useSelector((state) => state.headerModal);
  const [multiDays, setMultiDays] = useState(false);

  const [options, setOptions] = useState(singleOptions);

  useEffect(() => {
    if (singleOptions) {
      setOptions(singleOptions);
    }
  }, [t, singleOptions]);

  const validationSchema = Yup.object().shape({
    selectedDays: Yup.mixed().test('is-required', t('Required!'), (value, context) => {
      if (value?.value === dayTypes.SELECT_DAYS) return false;
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          if (value.length === 1 && value[0].value === dayTypes.SELECT_DAYS) return false;

          if (value.length > 0) return true;
          else return false;
        }
        if (value.value) return true;
      } else {
        return false;
      }
    }),
    type_id: Yup.string()
      .typeError(t('Required!'))
      .test('is-required', t('Required!'), (value, context) => {
        if (value < 0 || !value) return false;
        return true;
      }),
    code: Yup.string()
      .matches(/^[a-zA-Z0-9.,\s]*$/i, t('Only Alphanumeric allowed!'))
      .max(100, t('Not more than 100 characters!'))
      .required(t('Required!')),
    max_time: Yup.number()
      .typeError(t('Required!'))
      .min(1, t("Max time can't be 0!"))
      .max(99999, t('Not more than 5 characters!'))
      .required(t('Required!'))
      .test('max-time', t('Max time exceeded!'), (value, context) => {
        if (value > 0) {
          const startTime = moment(context.parent.available_start, 'HH:mm');

          const endTime = moment(context.parent.available_end, 'HH:mm');

          const availableTime = endTime.diff(startTime, 'hours');

          if (value > availableTime) return false;

          return true;
        } else {
          if (value <= 0) return false;
          return true;
        }
      }),
    available_start: Yup.string()
      .test('is-required', t('Required!'), (value, context) => {
        if (parseInt(value) === -1) return false;
        return true;
      })
      .typeError(t('Required!')),
    available_end: Yup.string()
      .test('is-required', t('Required!'), (value, context) => {
        if (parseInt(value) === -1) return false;
        return true;
      })
      .typeError(t('Required!'))
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      type_id: '',
      code: '',
      selectedDays: {},
      available_start: null,
      available_end: null,
      max_time: null,
      data: {}
    }
  });

  const onSubmit = (values) => {
    // remove select days value 4
    setIsSubmitting(true);
    let mySelectedDays;
    if (Array.isArray(values.selectedDays)) {
      mySelectedDays = values.selectedDays.filter((v) => v.value !== 4);
    } else {
      mySelectedDays = values.selectedDays;
    }
    let daysInstring = getDayInString(mySelectedDays);
    const hr24EndTime =
      parseInt(values.available_end) === 24
        ? `0000-01-01T23:59:00Z`
        : `0000-01-01T${values.available_end}:00Z`;

    const subValues = {
      ...values,
      max_time: values.max_time,
      available_start: `0000-01-01T${values.available_start}:00Z`,
      available_end: hr24EndTime,
      available_days: daysInstring,
      location: '',
      type_id: parseInt(values.type_id, 10),
      building_id: parseInt(building.id),
      data: values.data
    };

    dispatch(
      addAmenities({
        body: subValues,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );

          reset({
            type_id: '',
            code: '',
            available_start: null,
            available_end: null,
            max_time: null,
            selectedDays: {}
          });

          setOptions(singleOptions);

          dispatch(getAmenities({ id: building.id }));
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Amenity has been successfully created!')
            })
          );
          setIsSubmitting(false);
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Amenities creating Failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  const handleDays = (selectedDays) => {
    let mySelectedDays;
    if (!isArray(selectedDays) && selectedDays.value === dayTypes.SELECT_DAYS) {
      setMultiDays(true);
      setOptions(days);
      mySelectedDays = [];
    } else if (selectedDays.length === 0) {
      setMultiDays(false);
      setOptions(singleOptions);
      mySelectedDays = [];
    } else {
      setMultiDays(false);
      mySelectedDays = selectedDays;
    }

    setValue('selectedDays', mySelectedDays);
  };

  const show12HrTime = (hour) => {
    let time = String(hour) + ' am';
    const tim24to12 =
      String(parseInt(String(hour).split(':')[0]) - 12) > 9
        ? String(parseInt(String(hour).split(':')[0]) - 12)
        : '0' + String(parseInt(String(hour).split(':')[0]) - 12);
    if (parseInt(String(hour).split(':')[0]) > 12) time = tim24to12 + ':00 pm';
    if (parseInt(String(hour).split(':')[0]) == 12) time = '12:00 pm';
    if (parseInt(String(hour).split(':')[0]) == 0) time = '12:00 am';
    if (parseInt(String(hour).split(':')[0]) == 24) time = '12:00 am';

    return time;
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    reset({
      type_id: '',
      code: '',
      selectedDays: {},
      available_start: null,
      available_end: null,
      max_time: null,
      data: {}
    });

    setMultiDays(false);
    setOptions(singleOptions);

    dispatch(clearAmenityError());
  };

  const controlStylesHasError = (hasError) => (hasError ? { borderColor: '#e55353' } : {});

  return (
    modal === 'addAmenity' && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledModal show={modal === 'addAmenity'} closeOnBackdrop={false} onClose={onClose}>
          <ModalHeader title={t('Add new amenity')} onCloseCallback={onClose} />
          <StyledModalBody>
            <InputSeparator>
              <SettingsModalBoldText>{t('Building')}</SettingsModalBoldText>
              <Controller
                control={control}
                name="building_id"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => {
                  return (
                    <Select
                      label={amenitiesLabels.building}
                      onBlur={onBlur}
                      value={building.name}
                      onChange={onChange}
                      valid={!errors.building_id}
                      type="text"
                      id="building_id"
                      name="building_id"
                      disabled={true}
                      error={errors.building_id}
                    />
                  );
                }}
              />
              {errors?.building_id && <ErrorText>{errors?.building_id?.message}</ErrorText>}
            </InputSeparator>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.type}</SettingsModalBoldText>
              <Controller
                control={control}
                name="type_id"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Select
                    inputRef={ref}
                    modalName="amenity"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value || undefined}
                    placeholder={'Please select'}
                    custom
                    id="type_id"
                    valid={!errors.type_id}
                    invalid={!!errors.type_id}>
                    {amenityTypes.map((amenity) => (
                      <SelectItem key={amenity.ID} value={amenity.ID}>
                        {amenity.Description}
                      </SelectItem>
                    ))}
                  </Select>
                )}
              />
              {errors?.type_id && <ErrorText>{errors?.type_id?.message}</ErrorText>}
            </InputSeparator>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.description}</SettingsModalBoldText>
              <Controller
                control={control}
                name="code"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <SettingsModalInput
                    label={amenitiesLabels.name}
                    type="text"
                    id="code"
                    placeholder={t('Description...')}
                    autoComplete="text"
                    rows="5"
                    invalid={!!errors.code}
                    valid={!errors.code}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    value={value}
                    error={!!errors.code}
                  />
                )}
              />
              {errors?.code && <ErrorText>{errors?.code?.message}</ErrorText>}
            </InputSeparator>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.available_days}</SettingsModalBoldText>

              <Controller
                control={control}
                name="selectedDays"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <StyledSelect
                    styles={inputSelectCustomStyles({ minHeight: '56px', height: 'auto' })}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    loadingMessage={() => t('Loading...')}
                    inputRef={ref}
                    value={!_.isEmpty(value) ? value : null}
                    placeholder={'Please select'}
                    onBlur={onBlur}
                    onChange={(e) => {
                      handleDays(e);
                      onChange(e);
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: IconOption
                    }}
                    isClearable={options && options.length === 7}
                    isSearchable={true}
                    closeMenuOnSelect={options && options.length !== 7}
                    options={options}
                    isMulti={options && options.length === 7}
                  />
                )}
              />
              {errors?.selectedDays && <ErrorText>{errors?.selectedDays?.message}</ErrorText>}
            </InputSeparator>

            <CRow>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{amenitiesLabels.start_time}</SettingsModalBoldText>

                  <Controller
                    control={control}
                    name="available_start"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        value={value || undefined}
                        placeholder={'Please select'}
                        custom
                        valid={!errors.available_start}
                        invalid={!!errors.available_start}
                        placement={'bottomRight'}
                        id="available_start">
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        {hoursStart.map((hour) => (
                          <SelectItem key={hour} value={hour}>
                            {show12HrTime(hour)}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors?.available_start && (
                    <ErrorText>{errors?.available_start?.message}</ErrorText>
                  )}
                </InputSeparator>
              </CCol>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{amenitiesLabels.end_time}</SettingsModalBoldText>

                  <Controller
                    control={control}
                    name="available_end"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        value={value || undefined}
                        placeholder={'Please select'}
                        custom
                        valid={!errors.available_end}
                        invalid={!!errors.available_end}
                        id="available_end"
                        placement={'bottomLeft'}>
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        {hoursEnd
                          .filter((map) => map > watch('available_start'))
                          .map((hour) => (
                            <SelectItem key={hour} value={hour}>
                              {show12HrTime(hour)}
                            </SelectItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors?.available_end && <ErrorText>{errors?.available_end?.message}</ErrorText>}
                </InputSeparator>
              </CCol>
            </CRow>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.max_reserve_time}</SettingsModalBoldText>

              <Controller
                control={control}
                name="max_time"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <SettingsModalInput
                    onBlur={onBlur}
                    onChange={onChange}
                    className="form-control"
                    value={value}
                    label={amenitiesLabels.max_reserve_time}
                    type="number"
                    id="max_time"
                    placeholder="Enter maximum time"
                    autoComplete="off"
                    min="0"
                    required
                    error={errors.max_time}
                  />
                )}
              />
              {errors?.max_time && <ErrorText>{errors?.max_time?.message}</ErrorText>}
            </InputSeparator>

            <InputSeparator>
              <SettingsModalBoldText>{amenitiesLabels.data}</SettingsModalBoldText>

              <Controller
                control={control}
                name="data"
                render={({
                  field: { onChange, value},
                }) => (
                  <ReactJson 
                    src={value}
                    name={false}
                    onEdit={(data)=> onChange(data.updated_src)}
                    onAdd={(data)=> onChange(data.updated_src)}
                    onDelete={(data)=> onChange(data.updated_src)}
                  />
                )}
              />
            </InputSeparator>
          </StyledModalBody>

          <CModalFooter>
            {error && Object.keys(error).length > 0 && <ErrorText>{error.message}</ErrorText>}
            <Button onClick={onClose} text={t('Cancel')} width={'100px'} />
            <Button
              isPrimary
              type="submit"
              text={t('Save')}
              width={'100px'}
              disabled={isSubmitting || !isValid}
            />
          </CModalFooter>
        </StyledModal>
      </form>
    )
  );
};

export default AddAmenities;
