import 'rc-time-picker/assets/index.css';
import * as Yup from 'yup';
import {
  CCol,
  CFormGroup,
  CInput,
  CInvalidFeedback,
  CLabel,
  CModalFooter,
  CRow
} from '@coreui/react';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { getAmenity } from '../../redux/actions/amenities';
import {
  editParAmBooking,
  editingParAmBooking,
  getAmenityParAmBookings,
  getAvailableTime,
  getSelectedBookings,
  searchBookByType
} from '../../redux/actions/bookingParAm';
import { incrementFullyConsecutiveArray, insertSortedRange } from '../../helper/array';
import { range, sortBy, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AMENITY_PARKING } from '../../helper/types';
import BookingStatus from './BookingStatus';
import { ErrorMessage } from '@hookform/error-message';
import { UserRole } from '../users/constant';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import i18next from 'i18next';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import moment from 'moment';
import { setToast } from '../../redux/actions/window';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../settings/SettingsModals';
import { SelectItem, Select } from '../../components/ui/Select';
import { CustomDatePicker } from '../../components/CustomSearchBar';
import { StyledModalBody } from '../../components/Modals';
import { Button } from '../../components/ui/Button';
import { ErrorText } from '../../components/utils/AvatarCropper';

const NO_SELECTION = -1;

const ModalEditBooking = ({ firstLoading, setFirstLoading, onClose }) => {
  const { t } = useTranslation();
  const [maxAvailableHours, setMaxAvailableHours] = useState(null);

  const dispatch = useDispatch();

  const { booking, status, error, availableTime, search } = useSelector(
    (state) => state.parAmBooking
  );
  const { status: amenityStatus } = useSelector((state) => state.amenities);

  const { modal } = useSelector((state) => state.headerModal);

  const [selectedAmenityId, setSelectedAmenityId] = useState(0);
  const [availableEndHours, setAvailableEndHours] = useState(range(25));
  const [availableStartHours, setAvailableStartHours] = useState(range(25));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { amenities, amenity, ...restOfAmen } = useSelector((state) => state.amenities);

  const { building_id, roleId } = useSelector((state) => state.auth);
  const { globalBuildingId } = useSelector((state) => state.building);

  const { globalOrgId } = useSelector((state) => state.Organization);

  const validationSchema = Yup.object().shape({
    amenity_id: Yup.string()
      .required(t('Required!'))
      .test('is-required', t('Required!'), (value, context) => {
        if (parseInt(value) <= 0) return false;
        return true;
      }),
    startDate: Yup.string()
      .required(t('Booking date is required'))
      .typeError(t('Booking date is required'))
      .test('is-required', t('Booking date is required'), (value, context) => {
        if (!value) return false;
        return true;
      }),
    startTime: Yup.string()
      .required(t('Start time is required'))
      .typeError(t('Start time is required'))
      .test('isValidStartTime', t('Start time is required'), function (value) {
        return parseInt(value, 10) >= 0;
      })
      .test('date-required', t('Start date is required'), (value, context) => {
        if (!value) return false;
        return true;
      })
      .test(
        'startTimeBeforeEndTime',
        t('Start time must be before end time'),
        function (value, context) {
          if (!context.parent.endTime || parseInt(context.parent.endTime, 10) === -1) return true;
          if (!context.parent.startDate) return true;
          const startDate = moment(context.parent.startDate).toISOString();
          const endDate = moment(context.parent.startDate).toISOString();
          const startTime = moment(value, 'HH').utcOffset(0, true).toISOString();
          let endTime;
          if (context.parent.endTime == 24) {
            endTime = moment('23:59', 'HH:mm').utcOffset(0, true).toISOString();
          } else {
            endTime = moment(context.parent.endTime, 'HH').utcOffset(0, true).toISOString();
          }

          let endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;
          let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;
          const val = moment(endDateTime).isAfter(moment(startDateTime));
          return val;
        }
      ),
    endTime: Yup.string()
      .required(t('End time is required'))
      .typeError(t('End time is required'))
      .test('start-required', t('Start time is required!'), (value, context) => {
        if (!context.parent.startDate || parseInt(value, 10) === -1) return true;
        if (parseInt(context.parent.startTime) === -1) return false;
        return true;
      })
      .test('isValidStartTime', t('End time is required'), function (value) {
        return parseInt(value, 10) > 0;
      })
      .test('date-required', t('Start date is required!'), (value, context) => {
        if (!context.parent.startDate) return false;
        return true;
      })
      .test(
        'maxAvailableTime',
        `Max available time is ${maxAvailableHours} hours`,
        function (value, context) {
          // this case is handled by end time is required
          if (
            parseInt(context.parent.startTime, 10) === -1 ||
            parseInt(context.parent.endTime, 10) === -1
          )
            return true;
          if (!context.parent.startDate || !value) return false;

          const startDate = moment(context.parent.startDate).utc().toISOString();

          const endDate = moment(context.parent.startDate).utc().toISOString();

          const startTime = moment(context.parent.startTime, 'HH').utcOffset(0, true).toISOString();
          let endTime;
          if (value == 24 && value) {
            endTime = moment('23:59', 'HH:mm').utcOffset(0, true).toISOString();
          } else {
            endTime = moment(value, 'HH').utcOffset(0, true).toISOString();
          }

          if (endTime) {
            let endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;
            let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;
            return moment(endDateTime).isSameOrBefore(
              moment(startDateTime).add(maxAvailableHours, 'hours')
            );
          }
          return true;
        }
      )
      .test(
        'endTimeAfterStartTime',
        t('End time must be after start time'),
        function (value, context) {
          if (parseInt(value, 10) === -1) {
            // no endTime input yet
            return true;
          }
          if (!context.parent.startDate || parseInt(context.parent.startTime) === -1 || !value)
            return false;
          const startDate = moment(context.parent.startDate).utc().toISOString();
          const endDate = moment(context.parent.startDate).utc().toISOString();
          const startTime = moment(context.parent.startTime, 'HH').utcOffset(0, true).toISOString();
          let endTime;
          if (value == 24) {
            endTime = moment('23:59', 'HH:mm').utcOffset(0, true).toISOString();
          } else {
            endTime = moment(value, 'HH').utcOffset(0, true).toISOString();
          }

          let endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;
          let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;
          return moment(endDateTime).isAfter(moment(startDateTime));
        }
      ),
    make: Yup.string().test('is-parking', t('Required'), (value, context) => {
      if (amenity.type_id === AMENITY_PARKING && !value) return false;
      if (amenity.type_id === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    }),

    model: Yup.string().test('is-parking', t('Required'), (value, context) => {
      if (amenity.type_id === AMENITY_PARKING && !value) return false;
      if (amenity.type_id === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    }),

    color: Yup.string().test('is-parking', t('Required'), (value, context) => {
      if (amenity.type_id === AMENITY_PARKING && !value) return false;
      if (amenity.type_id === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    }),

    plate_number: Yup.string().test('is-parking', t('Required'), (value, context) => {
      if (amenity.type_id === AMENITY_PARKING && !value) return false;
      if (amenity.type_id === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    })
  });

  const getSelectedTime = (time) => {
    if (time) {
      if (moment(time).utc().format('HH:mm:ss') == '23:59:00') return 24;
      return parseInt(moment(time).utc().format('HH'));
    }
    return NO_SELECTION;
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
    setError,
    clearErrors,
    resetField,
    setValue,
    getValues,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      startTime: getSelectedTime(booking.start_date),
      endTime: getSelectedTime(booking.end_date)
    }
  });

  const watchAllFields = watch();
  const watchStartTime = watch('startTime');
  const watchEndTime = watch('endTime');
  const watchStartDate = watch('startDate');
  const watchAmenityId = watch('amenity_id');

  // check if form submission requirement is satisfied
  const updateDisabled = () => {
    const isEmpty = (val) => {
      return val === null || parseInt(val, 10) === -1;
    };
    if (!isDirty) {
      return true;
    }
    if (Object.keys(errors).length > 0) {
      return true;
    }
    return isEmpty(watchEndTime) || isEmpty(watchStartTime) || !watchStartDate || !watchAmenityId;
  };

  useEffect(() => {
    trigger('startTime');
  }, [watchEndTime, trigger]);

  useEffect(() => {
    if (maxAvailableHours) {
      trigger('endTime');
    }
  }, [watchStartTime, trigger, maxAvailableHours]);

  // fetch amenity details, and reset form fields
  const handleChangeAmenity = useCallback(
    (amenityId) => {
      const amenityIdInt = parseInt(amenityId, 10);
      if (amenityIdInt && amenityIdInt !== NO_SELECTION) {
        const selectedAmenityId = amenities.find(
          (amenity) => parseInt(amenity?.id) === amenityIdInt
        )?.type_id;

        // early return
        if (!selectedAmenityId) return;

        setSelectedAmenityId(selectedAmenityId);
        dispatch(
          getAmenity({
            id: amenityIdInt,
            success: () => {
              if (amenityIdInt !== booking.amenity_id) {
                reset({
                  amenity_id: parseInt(amenityId, 10),
                  startDate: null,
                  endDate: null,
                  startTime: NO_SELECTION,
                  endTime: NO_SELECTION,
                  make: booking.data.make,
                  model: booking.data.model,
                  color: booking.data.color,
                  plate_number: booking.data.plate
                });
              } else {
                resetToBooking();
              }
            },
            fail: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Failed to get amenity!'),
                  modal: 'errorToast'
                })
              );
            }
          })
        );
      }
    },
    [amenities, dispatch]
  );

  const getAvailableTimeToUse = (date) => {
    const day = date.format('DD');
    const year = date.format('YYYY');
    const month = date.format('MM');

    dispatch(
      getAvailableTime({
        amenity_id: watchAmenityId,
        day,
        year,
        month
      })
    );
  };

  useEffect(() => {
    if (
      !firstLoading &&
      watchAmenityId &&
      watchAmenityId !== NO_SELECTION &&
      booking?.amenity_id &&
      booking?.amenity_id !== watchAmenityId
    ) {
      // fetch new amenity details except first rendering
      handleChangeAmenity(watchAmenityId);
      return;
    }
    if (firstLoading && booking?.amenity_id) {
      // fetch amenity using booking at first rendering
      handleChangeAmenity(booking?.amenity_id);
      setFirstLoading(false);
    }
  }, [watchAmenityId, booking, reset]);

  useEffect(() => {
    if (amenity?.max_time) {
      setMaxAvailableHours(amenity?.max_time);
    }
  }, [amenity]);

  useEffect(() => {
    // reset startTime and endTime to -1 if reset is true
    const setTime = (reset = true) => {
      let tempStart = -1;
      let tempEnd = -1;
      if (!reset) {
        tempStart = getSelectedTime(booking.start_date);
        tempEnd = getSelectedTime(booking.end_date);
      }
      if (!reset || parseInt(watchStartTime, 10) !== -1) setValue('startTime', parseInt(tempStart));
      if (!reset || parseInt(watchEndTime, 10) !== -1) setValue('endTime', parseInt(tempEnd));
    };

    if (watchStartDate) {
      getAvailableTimeToUse(watchStartDate);
    } else {
      return;
    }

    if (booking) {
      // if not true, get original booking time
      const isNotReset = () =>
        parseInt(watchAmenityId, 10) !== booking.amenity_id ||
        !moment(booking.start_date).utc().isSame(watchStartDate);
      setTime(isNotReset());
    }
  }, [watchStartDate, watchAmenityId]);

  const isSameAmenity = () => {
    return watchAmenityId !== null && parseInt(watchAmenityId, 10) === booking.amenity_id;
  };

  const isSameDate = () => {
    return watchStartDate !== null && moment(booking.start_date).utc().isSame(watchStartDate);
  };

  const isSameStartTime = () => {
    return (
      watchStartTime !== null &&
      parseInt(watchStartTime, 10) === parseInt(moment(booking.start_time).utc().format('HH'), 10)
    );
  };

  useEffect(() => {
    // skip if it is the same booking time
    if (firstLoading || (isSameAmenity() && isSameDate() && isSameStartTime())) {
      return;
    }
    if (parseInt(watchEndTime, 10) !== -1) {
      setValue('endTime', -1);
      trigger('endTime');
    }
  }, [watchStartTime]);

  // reset booking to original booking
  const resetToBooking = () => {
    if (booking?.start_date) {
      const startTime = moment(booking.start_date).utc().format('HH');
      let endTime = moment(booking.end_date).utc().format('HH:mm:ss');
      let myEndDate = moment(booking.end_date).utc();
      if (endTime == '23:59:00') {
        endTime = 24;
      }
      reset({
        amenity_id: booking.amenity_id,
        startDate: moment(booking.start_date).utc(),
        endDate: myEndDate,
        startTime: parseInt(startTime),
        endTime: parseInt(endTime),
        make: booking.data.make,
        model: booking.data.model,
        color: booking.data.color,
        plate_number: booking.data.plate
      });
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    let myEndTime = values.endTime;
    let dayOffset = 0;

    if (myEndTime == 24) {
      myEndTime = '00';
      dayOffset = 1;
    }
    const startDate = moment(values.startDate).toISOString();
    let endDate = values.endDate
      ? moment(values.endDate).toISOString()
      : moment(values.startDate).toISOString();

    if (dayOffset === 1) {
      endDate = moment(endDate).add(1, 'days').utcOffset(0, true).toISOString();
    }

    const startTime = moment(values.startTime, 'HH').utcOffset(0, true).toISOString();

    let endTime = moment(values.endTime, 'HH').utcOffset(0, true).toISOString();

    if (moment(endTime).utc().format('HH') === '00') {
      endTime = moment(endTime).utc().subtract(1, 'minutes').toISOString();
    }
    let endDateTime = `${startDate.split('T')[0]}T${endTime.split('T')[1]}`;
    let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;

    startDateTime = startDateTime.split('.')[0] + 'Z';
    endDateTime = endDateTime.split('.')[0] + 'Z';

    let data;
    if (amenity.type_id === AMENITY_PARKING) {
      data = {
        make: values?.make,
        model: values?.model,
        color: values?.color,
        plate: values?.plate_number
      };
    } else {
      data = { ...booking.data };
    }

    let body = {
      title: amenity.code,
      start_date: startDateTime,
      end_date: endDateTime,
      amenity_id: parseInt(values.amenity_id),
      status: parseInt(booking.status),
      data: data
    };

    dispatch(
      editParAmBooking({
        id: booking.id,
        body: body,
        success: () => {
          if (search.query) {
            dispatch(
              searchBookByType({
                query: search.query,
                amenityId: search.amenityType?.value || 0,
                page: 1,
                // search_col: search.search_col,
                building: globalBuildingId,
                org: globalOrgId
              })
            );
          } else {
            dispatch(
              getSelectedBookings({
                id: globalBuildingId,
                page: 1,
                amenityId: search.amenityType?.value || 0,
                orgId: globalOrgId
              })
            );
          }
          dispatch(
            getAmenityParAmBookings({
              id:
                parseInt(globalBuildingId) === -1
                  ? parseInt(roleId) >= UserRole.ADMIN
                    ? 0
                    : building_id
                  : globalBuildingId,
              page: 1
            })
          );
          dispatch(
            editingParAmBooking({
              editBooking: false
            })
          );

          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );

          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Booking details have been successfully updated!')
            })
          );
          setIsSubmitting(false);
          onClose();
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Updation Failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  const validEndTime = (intTime) => {
    const startTime = parseInt(watchStartTime, 10);
    if (startTime === -1 || startTime === null) {
      return false;
    }
    if (!maxAvailableHours) {
      return false;
    }
    return intTime - startTime > 0 && intTime - startTime <= maxAvailableHours;
  };

  const isConsecutiveEndTime = (val, arr) => {
    if (arr.length === 0) {
      if (isSameAmenity() && isSameDate() && isSameStartTime()) {
        return true;
      }
      const startTime = parseInt(watchStartTime, 10);
      return startTime + 1 === val;
    }
    return arr[arr.length - 1] + 1 === val;
  };

  // insert booked times if the user is editing the same amenity on the same booking date ( mutate original arrays)
  const insertBookingAvailability = ({ availableStartTimes, availableEndTimes }) => {
    if (isSameAmenity() && isSameDate()) {
      const startTime = parseInt(moment(booking.start_time).utc().format('HH'), 10);
      const endTime = parseInt(moment(booking.end_time).utc().format('HH'), 10);
      availableStartTimes = insertSortedRange(availableStartTimes, startTime, endTime);
      // available end time is strictly dependent on start time
      availableEndTimes = incrementFullyConsecutiveArray(availableStartTimes, watchStartTime);
    }
    return {
      availableStartTimes,
      availableEndTimes
    };
  };

  // The logic of sorting availability need to rework
  useEffect(() => {
    let startTimes = [];
    // sanitize and convert start time to int
    for (const t of availableTime) {
      let fullDateTime = t.booking_date.split('T')[0] + 'T' + t.start_time.split('T')[1];
      fullDateTime = moment(fullDateTime).utc();
      const now = moment().utcOffset(0, true);
      const bookTime = fullDateTime.utcOffset(0, true);
      if (bookTime.isSameOrAfter(now)) {
        const time = parseInt(moment(t.start_time).utc().format('HH'));
        startTimes.push(time);
      }
    }
    // convert end time to int
    let endTimes = availableTime.map((cur) => {
      const time =
        parseInt(moment(cur.end_time).utc().format('HH')) === 0
          ? 24
          : parseInt(moment(cur.end_time).utc().format('HH'));
      return time;
    });
    endTimes = endTimes.map((t) => (t === 0 ? 24 : t));
    // insert the currently booked time into availabilities
    let { availableStartTimes, availableEndTimes } = insertBookingAvailability({
      availableStartTimes: startTimes,
      availableEndTimes: endTimes
    });
    // sanitize end time availabilities
    availableEndTimes = availableEndTimes.reduce((prev, cur) => {
      if (prev.length >= amenity.max_time) {
        return prev;
      }
      if (validEndTime(cur) && isConsecutiveEndTime(cur, prev)) {
        prev.push(cur);
      }
      return prev;
    }, []);
    const usedStart = moment(booking.start_date).utc().format('HH');
    let usedEnd =
      moment(booking.end_date).utc().format('HH:mm') === '23:59'
        ? '24'
        : moment(booking.end_date).utc().format('HH');

    const sameDay = moment(booking.start_date).isSame(moment(watchStartDate), 'day');
    if (sameDay) {
      availableStartTimes.push(parseInt(usedStart));
      if (isSameAmenity() && isSameStartTime())
        validEndTime(parseInt(usedEnd, 10)) && availableEndTimes.push(parseInt(usedEnd));
    }
    if (!availableStartTimes.length && status === 'GET_AVAILABLE_TIME_SUCCESS') {
      setError('dateNotAvailable', {
        type: 'custom',
        message: 'There is no time slots available for this date'
      });
    } else {
      clearErrors('dateNotAvailable');
    }

    availableEndTimes = sortBy(availableEndTimes);
    availableEndTimes = uniq(availableEndTimes);

    availableStartTimes = sortBy(availableStartTimes);
    availableStartTimes = uniq(availableStartTimes);

    setAvailableStartHours(availableStartTimes);
    setAvailableEndHours(availableEndTimes);
  }, [availableTime, selectedAmenityId, booking, watchStartDate, watchAmenityId, watchStartTime]);

  const amenitiesForDD = () => {
    if (booking?.amenity_type) {
      const filteredAmenities = amenities.filter(
        (item) => parseInt(item.type_id) === booking?.amenity_type
      );
      return filteredAmenities;
    }
  };

  const disabledDates = (current) => {
    let availableNumberDays = [];
    const availableDays = amenity.available_days; // "M,T,W,TH,F,S,SU"

    if (amenity && amenity?.available_days?.length) {
      for (const day of availableDays.split(',')) {
        switch (day) {
          case 'SU':
            availableNumberDays.push(0);
            break;
          case 'M':
            availableNumberDays.push(1);
            break;
          case 'T':
            availableNumberDays.push(2);
            break;
          case 'W':
            availableNumberDays.push(3);
            break;
          case 'TH':
            availableNumberDays.push(4);
            break;
          case 'F':
            availableNumberDays.push(5);
            break;
          case 'S':
            availableNumberDays.push(6);
            break;
          default:
        }
      }

      const currWeekDay = current.day();

      if (!availableNumberDays.includes(currWeekDay)) return true;
    }

    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  return (
    <StyledModalBody>
      <form name="EditBookingForm" onSubmit={handleSubmit(onSubmit)}>
        {status === 'GET_PAR_AM_BOOKING_FAIL' ? (
          <div className="error_for_modal">
            <h4 className="text-danger">{error}</h4>
          </div>
        ) : (
          <InputSeparator>
            <BookingStatus booking={booking} />
            <InputSeparator>
              <SettingsModalBoldText>{t('Amenity')}</SettingsModalBoldText>

              {amenitiesForDD() && (
                <Controller
                  control={control}
                  name="amenity_id"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <Select
                      loadingMessage={() => t('Loading...')}
                      valid={!errors.amenity_id}
                      invalid={!!errors.amenity_id}
                      modalName="editBooking"
                      id="amenity_id"
                      onChange={onChange}
                      value={value}
                      disabled={parseInt(booking.status) === 3}>
                      {amenitiesForDD().map((amenity) => (
                        <SelectItem key={amenity.id} value={amenity.id}>
                          {amenity.description}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                />
              )}
              {errors.amenity_id && <ErrorText>{errors.amenity_id.message}</ErrorText>}
            </InputSeparator>
            <InputSeparator>
              <SettingsModalBoldText
                style={{ paddingTop: '10px', paddingBottom: '10px', fontSize: '24px' }}>
                {t('Book date and time')}
              </SettingsModalBoldText>
              <InputSeparator>
                <CRow style={{ alignItems: 'baseline' }}>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>{t('Select date')}</SettingsModalBoldText>
                      <Controller
                        control={control}
                        name="startDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (
                          <CustomDatePicker
                            height={'56px'}
                            locale={i18next.resolvedLanguage === 'fr' ? locale : localeEn}
                            format="YYYY-MM-DD"
                            onChange={onChange}
                            value={value}
                            disabledDate={disabledDates}
                          />
                        )}
                      />
                      {errors.startDate && <ErrorText>{errors.startDate.message}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>{t('Select start time')}</SettingsModalBoldText>
                      <Controller
                        control={control}
                        name="startTime"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (
                          <Select
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            disabled={!watchStartDate}
                            custom>
                            <SelectItem value={-1}>{t('Please select')}</SelectItem>
                            {availableStartHours.map((start_time) => {
                              let amPmTime;
                              if (start_time == 0) {
                                amPmTime = '00:00 am';
                              } else {
                                amPmTime = moment(start_time, 'HH').format('h:mm a');
                              }
                              return (
                                <SelectItem key={start_time} value={start_time}>
                                  {amPmTime}
                                </SelectItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                      {errors.startTime && <ErrorText>{errors.startTime.message}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol></CCol>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>{t('Select end time')}</SettingsModalBoldText>
                      <CFormGroup>
                        <Controller
                          control={control}
                          name="endTime"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (
                            <Select
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              custom
                              disabled={
                                (parseInt(watchStartTime, 10) !== 0 && !watchStartTime) ||
                                parseInt(watchStartTime, 10) === -1
                              }>
                              <SelectItem value={-1}>{t('Please select')}</SelectItem>
                              {availableEndHours.map((end_time) => {
                                let amPmTime;
                                if (end_time == 0) {
                                  amPmTime = '00:00 am';
                                } else {
                                  amPmTime = moment(end_time, 'HH').format('h:mm a');
                                }

                                return (
                                  <SelectItem key={end_time} value={end_time}>
                                    {amPmTime}
                                  </SelectItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                        {errors.endTime && <ErrorText>{errors.endTime.message}</ErrorText>}
                      </CFormGroup>
                    </InputSeparator>
                  </CCol>
                </CRow>
              </InputSeparator>
            </InputSeparator>
            {amenity.type_id === AMENITY_PARKING && (
              <InputSeparator>
                <SettingsModalBoldText
                  style={{ paddingTop: '10px', paddingBottom: '10px', fontSize: '24px' }}>
                  {t('Vehicle details')}
                </SettingsModalBoldText>

                <InputSeparator>
                  <SettingsModalBoldText>{t('Make')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="make"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <SettingsModalInput
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        valid={!errors.make}
                        invalid={errors.make}
                        id="make"
                        type="text"
                        placeholder={t('Enter car make')}
                      />
                    )}
                  />
                  {errors.make && <ErrorText>{errors.make.message}</ErrorText>}
                </InputSeparator>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Model')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="model"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <SettingsModalInput
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        valid={!errors.model}
                        invalid={errors.model}
                        id="model"
                        type="text"
                        placeholder={t('Enter car model')}
                      />
                    )}
                  />
                  {errors.model && <ErrorText>{errors.model.message}</ErrorText>}
                </InputSeparator>

                <InputSeparator>
                  <SettingsModalBoldText>{t('Color')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="color"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <SettingsModalInput
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        valid={!errors.color}
                        invalid={errors.color}
                        id="color"
                        type="text"
                        placeholder={t('Enter car color')}
                      />
                    )}
                  />
                  {errors.color && <ErrorText>{errors.color.message}</ErrorText>}
                </InputSeparator>

                <InputSeparator>
                  <SettingsModalBoldText>{t('License plate number')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="plate_number"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <SettingsModalInput
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        valid={!errors.plate_number}
                        invalid={errors.plate_number}
                        id="plate_number"
                        type="text"
                        placeholder={t('Enter plate number')}
                      />
                    )}
                  />
                  {errors.plate_number && <ErrorText>{errors.plate_number.message}</ErrorText>}
                </InputSeparator>
              </InputSeparator>
            )}
            {parseInt(booking.status) !== 3 && (
              <Button
                isPrimary
                width={'150px'}
                text={t('Cancel booking')}
                type="button"
                onClick={() =>
                  dispatch(
                    changeOpenedModal({
                      modal: modal,
                      cancelModal: true
                    })
                  )
                }
              />
            )}
          </InputSeparator>
        )}

        <CModalFooter className="p-0">
          {error && <ErrorText>{error}</ErrorText>}
          <ErrorMessage
            errors={errors}
            name="dateNotAvailable"
            render={({ message }) => <ErrorText>{message}</ErrorText>}
          />
          <Button onClick={handleCancel} text={t('Cancel')} width={'100px'} />
          <Button
            isPrimary
            type="submit"
            text={t('Update')}
            width={'100px'}
            disabled={isSubmitting || updateDisabled()}
          />
        </CModalFooter>
      </form>
    </StyledModalBody>
  );
};

export default ModalEditBooking;
