import React from "react";

import { ReactComponent as PartyIcon } from "../assets/icons/celebration.svg";
import { ReactComponent as ElevatorIcon } from "../assets/icons/elevator.svg";
import { ReactComponent as VehicleIcon } from "../assets/icons/vehicle.svg";

import { ReactComponent as SwimmingPoolIcon } from "../assets/icons/pool_black_24dp.svg";
import { ReactComponent as FitnessRoomIcon } from "../assets/icons/fitness_center_black_24dp.svg";
import { ReactComponent as BarbecueIcon } from "../assets/icons/outdoor_grill_black_24dp.svg";
import { ReactComponent as ParkingIcon } from "../assets/icons/topBarPlusDDIcons/bookingParkingIcon.svg";

import { ReactComponent as PatioIcon } from "../assets/icons/deck_black_24dp.svg";
import { ReactComponent as WifiIcon } from "../assets/icons/wifi-line.svg";
import { ReactComponent as StudyIcon } from "../assets/icons/school_black_24dp.svg";
import { ReactComponent as SocialRoomIcon } from "../assets/icons/celebration.svg";
import { ReactComponent as MeetingIcon } from "../assets/icons/team-line.svg";
import { ReactComponent as Details } from '../assets/icons/details.svg';

import { ReactComponent as Sauna } from '../assets/icons/sauna.svg';
import { ReactComponent as Firetable } from '../assets/icons/firetable.svg';
import { ReactComponent as Shuffleboard } from '../assets/icons/shuffleboard.svg';

import { ReactComponent as ElevatorRichIcon } from '../assets/icons/amenities/elevator.svg';
import { ReactComponent as FitnessRichIcon } from '../assets/icons/amenities/gym.svg';
import { ReactComponent as SocialRoomRichIcon } from '../assets/icons/amenities/partyRoom.svg';
import { ReactComponent as OutdoorRichIcon } from '../assets/icons/amenities/rooftop.svg';

export const myIcons = {
  default: Details,
  1: SocialRoomIcon,
  2: ElevatorIcon,
  3: ParkingIcon,
  4: SwimmingPoolIcon,
  5: FitnessRoomIcon,
  6: MeetingIcon,
  7: BarbecueIcon,
  8: PatioIcon,
  9: WifiIcon,
  10: StudyIcon,
  11: Sauna,
  12: Firetable,
  13: Shuffleboard
};

export const myRichIcons = {
  ...myIcons,
  1: SocialRoomRichIcon,
  2: ElevatorRichIcon,
  5: FitnessRichIcon,
  11: OutdoorRichIcon
}

export const Icon = (props) => {
  const { icon } = props;
  const TheIcon = icon;

  if (!icon) {
    return null;
  }

  return <TheIcon {...props} />;
};
