import { createReducer } from '@reduxjs/toolkit';
import {
  ADD_USER,
  ADD_USERS_FROM_CSV,
  EDIT_USER,
  GET_USER,
  ADD_AVATAR,
  GET_AVATAR,
  EDIT_AVATAR,
  SEARCH_USERS,
  BUILDING_USERS,
  SET_USER,
  SET_USERS_FROM_CSV,
  SET_USERS_CSV_FILE,
  CLEAR_USERS_FROM_CSV,
  CLEAR_USERS_CSV_FILE,
  CLEAR_USER,
  SET_USER_AVATAR,
  REMOVE_USER,
  CLEAR_ERROR,
  CLEAR_AVATAR,
  GET_USER_DEVICE_SLOTS,
  SET_USER_DEVICE_SLOTS,
  SET_USERS_ACTIVATED,
  SET_USER_SEARCH,
  RESET_USER_PAGINATION,
  GET_ALL_USERS,
  ADD_SERVICE_REQUEST_CACHE_USER
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';
import { UserType, AccountStatus } from '../../views/users/constant';

const initialState = {
  users: [],
  usersFromCSV: {},
  allUsers: [], // all users for exporting csv
  usersCSVFile: undefined,
  deviceSlots: [],
  user: {}, // used to store temporary user, e.g. technician in service request
  avatar: '',
  loading: true,
  residents: [],
  teamManagement: [],
  status: 'INIT',
  params: {
    page: 1
  },
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  error: '',
  buildingUsers: [],
  // search user field for both query search and generic search
  searchFields: {
    query: '', // used only in query search
    page: 1, // used in both of query search and generic search
    status: 'ALL'
  },
  selectedField: '',
  serviceRequestUserCache: {}
};

export default createReducer(initialState, {
  [ADD_SERVICE_REQUEST_CACHE_USER]: (state, action) => {
    const payload = action.payload;

    if (!Array.isArray(payload) || payload.length === 0) {
      return state;
    }

    const updatedCache = payload.reduce(
      (acc, user) => {
        acc[user.ID] = {
          id: user.ID,
          name: `${user.FirstName} ${user.LastName}`,
          imageURL: user.Avatar
        };
        return acc;
      },
      { ...state.serviceRequestUserCache }
    );

    return {
      ...state,
      serviceRequestUserCache: updatedCache
    };
  },

  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: ''
  }),

  [CLEAR_AVATAR]: (state, { payload }) => ({
    ...state,
    avatar: ''
  }),

  [CLEAR_USER]: (state, { payload }) => ({
    ...state,
    user: {}
  }),

  [SET_USER]: (state, { payload }) => ({
    ...state,
    user: payload
  }),

  [CLEAR_USERS_FROM_CSV]: (state, { payload }) => ({
    ...state,
    usersFromCSV: []
  }),

  [SET_USERS_FROM_CSV]: (state, { payload }) => ({
    ...state,
    usersFromCSV: payload
  }),

  [CLEAR_USERS_CSV_FILE]: (state, { payload }) => ({
    ...state,
    usersCSVFile: undefined
  }),

  [SET_USERS_CSV_FILE]: (state, { payload }) => ({
    ...state,
    usersCSVFile: payload
  }),

  [SET_USER_AVATAR]: (state, { payload }) => ({
    ...state,
    avatar: payload.avatar
  }),
  [SET_USERS_ACTIVATED]: (state, { payload }) => {
    const recipUsers = payload.recipUsers;
    return {
      ...state,
      users: payload.users.map((user) => {
        if (
          user.status_id === AccountStatus.PENDING &&
          recipUsers.some((recipient) => recipient.id === user.id)
        )
          return { ...user, status_id: AccountStatus.OPEN };

        return user;
      })
    };
  },
  [SET_USER_SEARCH]: (state, { payload }) => {
    const { query, page = 1, status } = payload;
    const searchFields = {
      query: typeof query === 'string' ? query : state.searchFields.query,
      page: page,
      status: status || state.searchFields.status
    };
    return {
      ...state,
      searchFields
    };
  },
  [RESET_USER_PAGINATION]: (state, {}) => ({
    ...state,
    pagination: {
      currentPage: 1,
      perPage: null,
      total: null
    }
  }),
  [requestSuccess(SEARCH_USERS)]: (state, { payload }) => ({
    ...state,
    residents: payload.data
      .filter((user) => parseInt(user.type_id) <= UserType.CORESIDENT)
      .sort((a, b) => a.id - b.id),

    teamManagement: payload.data
      .filter((user) => parseInt(user.type_id) > UserType.CORESIDENT)
      .sort((a, b) => a.id - b.id),

    users: payload === null ? [] : payload.data.sort((a, b) => a.id - b.id),

    pagination: {
      currentPage: payload.current_page,
      perPage: payload.per_page,
      total: payload.total
    },

    status: requestSuccess(SEARCH_USERS),
    error: ''
  }),

  [requestPending(SEARCH_USERS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_USERS)
  }),

  [requestFail(SEARCH_USERS)]: (state, { payload }) => ({
    ...state,
    users: [],
    status: requestFail(SEARCH_USERS),
    error: payload?.data?.message
  }),

  [requestSuccess(REMOVE_USER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(REMOVE_USER)
  }),

  [requestPending(REMOVE_USER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_USER)
  }),

  [requestFail(REMOVE_USER)]: (state, { payload }) => {
    return {
      ...state,
      status: requestFail(REMOVE_USER)
    };
  },
  [requestSuccess(GET_USER)]: (state, { payload }) => {
    return {
      ...state,
      user: payload,
      avatar: '',
      status: requestSuccess(GET_USER),
      error: ''
    };
  },
  [requestPending(GET_USER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_USER)
  }),

  [requestFail(GET_USER)]: (state, { payload }) => {
    return {
      ...state,
      status: requestFail(GET_USER),
      error: payload.data.message
    };
  },
  [requestSuccess(ADD_AVATAR)]: (state, { payload }) => ({
    ...state,
    avatar: payload.filename,
    status: requestSuccess(ADD_AVATAR),
    error: ''
  }),

  [requestFail(ADD_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_AVATAR),
    error: payload.data.message
  }),
  [requestSuccess(EDIT_AVATAR)]: (state, { payload }) => ({
    ...state,
    avatar: payload.filename,
    status: requestSuccess(EDIT_AVATAR),
    error: ''
  }),
  [requestPending(EDIT_AVATAR)]: (state, { payload }) => ({
    ...state,

    status: requestPending(EDIT_AVATAR)
  }),

  [requestFail(EDIT_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_AVATAR),
    error: payload.data.message
  }),

  [requestSuccess(GET_AVATAR)]: (state, { payload }) => ({
    ...state,
    avatar: payload,
    status: requestSuccess(GET_AVATAR),
    error: ''
  }),

  [requestPending(GET_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_AVATAR)
  }),

  [requestFail(GET_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_AVATAR)
  }),

  [requestSuccess(EDIT_AVATAR)]: (state, { payload }) => ({
    ...state,
    avatar: payload,
    status: requestSuccess(EDIT_AVATAR),
    error: ''
  }),

  [requestPending(EDIT_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_AVATAR)
  }),

  [requestFail(EDIT_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_AVATAR)
  }),
  [requestSuccess(BUILDING_USERS)]: (state, { payload }) => ({
    ...state,
    buildingUsers: payload.sort((a, b) => a.id - b.id),
    status: requestSuccess(BUILDING_USERS),
    error: ''
  }),

  [requestPending(BUILDING_USERS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(BUILDING_USERS)
  }),

  [requestFail(BUILDING_USERS)]: (state, { payload }) => ({
    ...state,
    buildingUsers: [],
    status: requestFail(BUILDING_USERS),
    error: payload.data.message
  }),

  [requestSuccess(ADD_USER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_USER),
    error: ''
  }),

  [requestPending(ADD_USER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_USER)
  }),

  [requestFail(ADD_USER)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_USER),
    error: payload?.data?.message
  }),

  [requestSuccess(ADD_USERS_FROM_CSV)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_USERS_FROM_CSV),
    error: ''
  }),

  [requestPending(ADD_USERS_FROM_CSV)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_USERS_FROM_CSV)
  }),

  [requestFail(ADD_USERS_FROM_CSV)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_USERS_FROM_CSV),
    error: payload?.data?.message
  }),

  [requestSuccess(EDIT_USER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_USER)
  }),

  [requestPending(EDIT_USER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_USER)
  }),

  [requestFail(EDIT_USER)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_USER),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_USER_DEVICE_SLOTS)]: (state, { payload }) => ({
    ...state,
    deviceSlots: payload,
    status: requestSuccess(GET_USER_DEVICE_SLOTS)
  }),

  [requestPending(GET_USER_DEVICE_SLOTS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_USER_DEVICE_SLOTS)
  }),

  [requestFail(GET_USER_DEVICE_SLOTS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_USER_DEVICE_SLOTS),
    error: payload?.data?.message
  }),

  [SET_USER_DEVICE_SLOTS]: (state, { payload }) => ({
    ...state,
    deviceSlots: payload?.deviceSlots
  }),

  [requestSuccess(GET_ALL_USERS)]: (state, { payload }) => {
    return {
      ...state,
      allUsers: payload.data,
      status: requestSuccess(GET_ALL_USERS)
    };
  },

  [requestPending(GET_ALL_USERS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ALL_USERS)
  }),

  [requestFail(GET_ALL_USERS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_ALL_USERS),
    error: payload?.data?.message
  })
});
