import { STATUS_MAP } from "../../utils/mappings";
const PinRelatedCategoryId = 35;
const RequestCategoryAddGuestPIN    = "Add Guest PIN"
const RequestCategoryRemoveGuestPIN = "Remove Guest PIN"
const RequestCategoryChangePIN = "Change PIN Code"
const AssignOperatorProcess = {
  Pending: 'Pending'
}

const RequestStatus = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
  ARCHIVED: 'Archived',
}

const convertServiceStatusToId = (status) => {
  return STATUS_MAP.filter((statusObj) => statusObj.value === status)[0]?.id;
}

const convertServiceIdToStatus = (id) => {
  return STATUS_MAP.filter((statusObj) => statusObj.id === id)[0]?.value;
}

const StatusBGVariants = {
  [RequestStatus.OPEN]: 'primary',
  [RequestStatus.IN_PROGRESS]: 'warning',
  [RequestStatus.COMPLETE]: 'success',
}

export {
  PinRelatedCategoryId,
  RequestCategoryAddGuestPIN,
  RequestCategoryRemoveGuestPIN,
  RequestCategoryChangePIN,
  AssignOperatorProcess,
  RequestStatus,
  StatusBGVariants,
  convertServiceStatusToId,
  convertServiceIdToStatus,
}
