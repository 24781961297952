import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSidebarNavItem } from './SidebarComponents';
import { getOrganization } from '../../redux/actions/organization';
import { useResetAllPaginations } from '../../hooks/useResetAllPaginations';
import { closeActiveModal } from '../../helper/modals';
import styled from 'styled-components';
import { CImg } from '@coreui/react';
import { setGlobalOrganization } from '../../helper/store';
import { getServices } from '../../redux/actions/services';
import { getAllMessages } from '../../redux/actions/conversationSent';
import { getSelectedBookings } from '../../redux/actions/bookingParAm';
import { getDDUnits } from '../../redux/actions/unit';
import { setGlobalBuildingId } from '../../redux/actions/building';

const NO_ORGANIZATION_SELECTION_OPTION = {
  id: 0,
  name: 'All Organizations'
};

const OrganizationListItem = ({ organization, toggleSidebar }) => {
  const { currentAmenityType, searchStatus } = useSelector((state) => state.services);
  const { globalBuildingId } = useSelector((state) => state.building);
  const { resetAllPaginations } = useResetAllPaginations();

  const dispatch = useDispatch();

  const changeOrganization = (organization) => {
    setGlobalOrganization(organization);
    resetAllPaginations();
    toggleSidebar();
    closeActiveModal();

    dispatch(getServices({ id: 0, page: 1, orgId: organization.id, status: searchStatus }));

    dispatch(
      setGlobalBuildingId({
        globalBuildingId: 0,
        globalBuildingName: ''
      })
    );

    if (organization.id === 0) return;

    dispatch(getOrganization({ id: organization.id }));
    dispatch(
      getAllMessages({
        id: organization.id
      })
    );
    dispatch(
      getSelectedBookings({
        id: 0,
        page: 1,
        amenityId: currentAmenityType,
        orgId: organization.id
      })
    );

    if (globalBuildingId > 0) {
      dispatch(
        getDDUnits({
          id: globalBuildingId
        })
      );
    }
  };

  return (
    <a
      onClick={() => {
        changeOrganization(organization);
      }}>
      <StyledSidebarNavItem>
        <OrganizationLogo src={organization.logo || organization.photo} />
        {organization.name}
      </StyledSidebarNavItem>
    </a>
  );
};

export const OrganizationsListSidebar = ({ toggleSidebar }) => {
  const Organizations = useSelector((state) => state.Organization);

  // TODO: Loading
  if (
    Organizations.getAllStatus === 'GET_ALL_ORGANIZATIONS_PENDING'
    // || Organizations.status === 'GET_ORGANIZATION_PENDING'
  ) {
    return null;
  }

  return [NO_ORGANIZATION_SELECTION_OPTION]
    .concat(Organizations.AllOrganizations)
    .map((organization) => (
      <OrganizationListItem
        key={organization.id}
        organization={organization}
        toggleSidebar={toggleSidebar}
      />
    ));
};

const OrganizationLogo = styled(CImg)`
  width: 50px;
  height: 50px;
`;
