import { createReducer } from '@reduxjs/toolkit';
import {
  GET_UNIT,
  GET_UNITS,
  REMOVE_UNIT,
  EDIT_UNIT,
  ADD_UNIT,
  GET_DOOR_PIN,
  CLEAR_ERROR,
  SET_UNIT_DATA,
  CLEAR_UNIT_CREATION_RESPONSE,
  GET_DD_UNITS,
  NOTIFY_UNIT_PIN,
  CLEAR_UNITS_CSV_FILE,
  SET_UNITS_CSV_FILE,
  ADD_UNITS_FROM_CSV,
  SET_UNIT_PAGINATION,
  GET_SUITE,
  GET_CONFLICTING_DATES,
  CLEAR_CONFLICTING_DATES_DATA,
  GET_RESERVATIONS,
  CLEAR_RESERVATIONS
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';
import { orderBy } from 'lodash';

const initialState = {
  units: [],
  unit: {},
  status: '',
  unitCreateResp: {},
  params: {
    page: 1
  },
  error: null,
  doorPin: [],
  unitForUser: {},
  unitsForDD: [], // this state is for populating the dropdowns of units
  unitInfo: {}, // this is to store unit num and serial to desplay in unit pin modal
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null,
    sort: 'floor',
    sortOrder: 'asc'
  },
  suite_status: '',
  suite_error: null,
  suite: {},
  unitsCSVFile: undefined,

  conflictingMoveDatesStatus: '',
  conflictingMoveDates: [],
  conflictingMoveDatesError: '',

  reservationsStatus: '',
  reservations: [],
  reservationsError: ''
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [CLEAR_UNIT_CREATION_RESPONSE]: (state, { payload }) => ({
    ...state,
    unitCreateResp: {}
  }),

  [SET_UNIT_DATA]: (state, { payload }) => ({
    ...state,
    unitForUser: payload
  }),

  [SET_UNIT_PAGINATION]: (state, { payload }) => ({
    ...state,
    pagination: payload.pagination
  }),

  [GET_DOOR_PIN]: (state, { payload }) => ({
    ...state,
    unitInfo: { ...payload }
  }),
  [SET_UNITS_CSV_FILE]: (state, { payload }) => ({
    ...state,
    unitsCSVFile: payload
  }),

  [CLEAR_UNITS_CSV_FILE]: (state, { payload }) => ({
    ...state,
    unitsCSVFile: undefined
  }),
  [requestSuccess(ADD_UNITS_FROM_CSV)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_UNITS_FROM_CSV),
    error: ''
  }),

  [requestPending(ADD_UNITS_FROM_CSV)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_UNITS_FROM_CSV)
  }),

  [requestFail(ADD_UNITS_FROM_CSV)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_UNITS_FROM_CSV),
    error: payload?.data?.message
  }),

  [CLEAR_CONFLICTING_DATES_DATA]: (state) => ({
    ...state,
    conflictingMoveDatesStatus: initialState.conflictingMoveDatesStatus,
    conflictingMoveDates: initialState.conflictingMoveDates,
    conflictingMoveDatesError: initialState.conflictingMoveDatesError
  }),

  [requestSuccess(GET_CONFLICTING_DATES)]: (state, { payload }) => ({
    ...state,
    conflictingMoveDatesStatus: requestSuccess(GET_CONFLICTING_DATES),
    conflictingMoveDates: payload,
    conflictingMoveDatesError: ''
  }),

  [requestPending(GET_CONFLICTING_DATES)]: (state) => ({
    ...state,
    conflictingMoveDatesStatus: requestPending(GET_CONFLICTING_DATES)
  }),

  [requestFail(GET_CONFLICTING_DATES)]: (state, { payload }) => ({
    ...state,
    conflictingMoveDatesStatus: requestFail(GET_CONFLICTING_DATES),
    conflictingMoveDatesError: payload?.data?.message
  }),

  [requestSuccess(GET_RESERVATIONS)]: (state, { payload }) => ({
    ...state,
    reservationsStatus: requestSuccess(GET_RESERVATIONS),
    reservations: payload,
    reservationsError: ''
  }),

  [requestPending(GET_RESERVATIONS)]: (state) => ({
    ...state,
    reservationsStatus: requestPending(GET_RESERVATIONS)
  }),

  [requestFail(GET_RESERVATIONS)]: (state, { payload }) => ({
    ...state,
    reservationsStatus: requestFail(GET_RESERVATIONS),
    reservations: payload,
    reservationsError: payload?.data?.message
  }),

  [CLEAR_RESERVATIONS]: (state) => ({
    ...state,
    reservationsStatus: '',
    reservations: [],
    reservationsError: ''
  }),

  [requestSuccess(GET_DD_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_DD_UNITS),
    error: null,
    unitsForDD: orderBy(
      payload,
      function (o) {
        return new Number(o.unit_num.replace(/\D/g, '')); //this is done if unitNum is 10a to handlt the alphabets need to do thos
      },
      ['asc']
    )
  }),
  [requestPending(GET_DD_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_DD_UNITS)
  }),
  [requestFail(GET_DD_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_DD_UNITS),
    error: payload.data.message,
    unitsForDD: []
  }),

  [requestSuccess(GET_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_UNIT),
    error: null,
    unit: payload
  }),

  [requestPending(GET_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_UNIT),
    error: null
  }),

  [requestFail(GET_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_UNIT),
    error: payload.data.message,
    unit: {}
  }),

  [requestSuccess(GET_SUITE)]: (state, { payload }) => ({
    ...state,
    suite_status: requestSuccess(GET_SUITE),
    suite_error: null,
    suite: payload
  }),

  [requestPending(GET_SUITE)]: (state) => ({
    ...state,
    suite_status: requestPending(GET_SUITE),
    suite_error: null
  }),

  [requestFail(GET_SUITE)]: (state, { payload }) => ({
    ...state,
    suite_status: requestFail(GET_SUITE),
    suite_error: payload.data.message,
    suite: {}
  }),

  [requestSuccess(GET_DOOR_PIN)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_DOOR_PIN),
    error: null,
    doorPin: payload
  }),

  [requestPending(GET_DOOR_PIN)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_DOOR_PIN)
  }),

  [requestFail(GET_DOOR_PIN)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_DOOR_PIN),
    error: payload.data.message
  }),

  [requestSuccess(ADD_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_UNIT),
    unitCreateResp: payload,
    error: null
  }),
  [requestPending(ADD_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_UNIT)
  }),

  [requestFail(ADD_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_UNIT),
    error: payload?.data?.message
  }),

  [requestSuccess(EDIT_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_UNIT),
    error: null
  }),
  [requestPending(EDIT_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_UNIT)
  }),
  [requestFail(EDIT_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_UNIT),
    error: payload?.data?.message
  }),

  [requestSuccess(REMOVE_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(REMOVE_UNIT),
    error: null
  }),

  [requestPending(REMOVE_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_UNIT)
  }),

  [requestFail(REMOVE_UNIT)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REMOVE_UNIT),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_UNITS)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(GET_UNITS),
      units: payload?.data,
      pagination: {
        ...state.pagination,
        currentPage: payload?.pagination['pagination-current-page'],
        perPage: payload?.pagination['pagination-per-page'],
        total: payload?.pagination['pagination-total']
      },
      error: null
    };
  },
  [requestPending(GET_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_UNITS)
  }),

  [requestFail(GET_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_UNITS),
    units: [],
    error: payload.error
  }),

  [requestSuccess(NOTIFY_UNIT_PIN)]: (state) => {
    return {
      ...state,
      status: requestSuccess(NOTIFY_UNIT_PIN)
    };
  },
  [requestPending(NOTIFY_UNIT_PIN)]: (state) => ({
    ...state,
    status: requestPending(NOTIFY_UNIT_PIN)
  }),

  [requestFail(NOTIFY_UNIT_PIN)]: (state) => ({
    ...state,
    status: requestFail(NOTIFY_UNIT_PIN)
  })
});
