import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { changeLoginPage, logout } from '../redux/actions/auth';
import { CCol, CDropdown, CDropdownToggle, CImg, CRow } from '@coreui/react';
import { ReactComponent as HelpIcon } from '../assets/icons/topBarProfileDDIcons/helpIcon.svg';
import { ReactComponent as LogOutIcon } from '../assets/icons/topBarProfileDDIcons/logOut.svg';
import { ReactComponent as PrivacyPolicyIcon } from '../assets/icons/topBarProfileDDIcons/privacyPolicy.svg';
import { ReactComponent as TermsServicsIcon } from '../assets/icons/topBarProfileDDIcons/termsServices.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/topBarProfileDDIcons/profileIcon.svg';
import { ReactComponent as GoToNextTabIcon } from '../assets/icons/topBarProfileDDIcons/goToNextTabIcon.svg';
import ChangePassword from '../components/ChangePasswordModal';
import EditProfile from '../components/EditProfile';
import { defaultImgCDN, imageString } from '../helper';
import EditDataCenter from '../components/EditDataCenter';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../views/users/constant';
import { NEW_NOTIFICATION_KEY, USER_ID_KEY } from './constants';
import { StyledDropdown, StyledDropdownItem, StyledDropdownItemText } from './HeaderComponents';
import { FaUser, FaWpforms } from 'react-icons/fa';
import { Palette } from '../components/utils';
import { IoIosHelpCircle } from 'react-icons/io';
import { BiWorld } from 'react-icons/bi';

const TheHeaderDropdown = () => {
  const { t } = useTranslation();
  const { roleId } = useSelector((state) => state.auth);

  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const [errorImage, setErrorImage] = useState('');

  const removeLogoutItems = () => {
    const userId = localStorage.getItem(USER_ID_KEY);
    const newNotification = localStorage.getItem(NEW_NOTIFICATION_KEY);

    localStorage.clear();
    localStorage.setItem(USER_ID_KEY, userId);
    localStorage.setItem(NEW_NOTIFICATION_KEY, newNotification);

    dispatch(
      changeLoginPage({
        page: '0'
      })
    );
    history.push('/login');
    window.location.reload();
  };

  const handleLogout = () => {
    dispatch(
      logout({
        success: () => {
          removeLogoutItems();
        },
        fail: () => {
          removeLogoutItems();
        }
      })
    );
  };

  return (
    <div className="topBarProfileDropDown">
      <CDropdown inNav className="c-header-nav-items" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="imgDiv">
            {profile && (
              <CImg
                onError={() => setErrorImage(defaultImgCDN)}
                src={
                  profile?.avatar === ''
                    ? defaultImgCDN
                    : profile?.avatar
                    ? imageString(profile && profile.avatar)
                    : defaultImgCDN
                }
                // className="w-100 h-100"
                alt="img"
                className="profile-img"
              />
            )}
          </div>
        </CDropdownToggle>
        <StyledDropdown className="dropDownMenu pt-0" placement="bottom-end">
          <StyledDropdownItem
            className="first"
            onClick={() => {
              dispatch(
                changeOpenedModal({
                  modal: 'ModalEditProfile'
                })
              );
            }}>
            <CRow className="dropDownMenuRow">
              <CCol>
                <FaUser color={Palette.HAZY_BLUE} size={20} />
              </CCol>
              <CCol>
                <StyledDropdownItemText className="m-0">{t('Profile')}</StyledDropdownItemText>
              </CCol>
            </CRow>
          </StyledDropdownItem>
          {roleId === UserRole.SUPER_ADMIN && (
            <StyledDropdownItem
              className="first"
              onClick={() => {
                dispatch(
                  changeOpenedModal({
                    modal: 'ModalEditDataCenter'
                  })
                );
              }}>
              <CRow className="dropDownMenuRow">
                <CCol>
                  <BiWorld color={Palette.HAZY_BLUE} size={20} />
                </CCol>
                <CCol>
                  <StyledDropdownItemText className="m-0">
                    {t('Change data center')}
                  </StyledDropdownItemText>
                </CCol>
              </CRow>
            </StyledDropdownItem>
          )}

          <StyledDropdownItem
            onClick={() => {
              dispatch(changeOpenedModal({ modal: 'helpModal' }));
            }}>
            <CRow className="dropDownMenuRow">
              <CCol>
                <IoIosHelpCircle color={Palette.HAZY_BLUE} size={20} />
              </CCol>
              <CCol>
                <StyledDropdownItemText className="m-0">{t('Help')}</StyledDropdownItemText>
              </CCol>
            </CRow>
          </StyledDropdownItem>
          <StyledDropdownItem target="_blank" href="https://www.grata.life/terms-and-conditions">
            <CRow className="dropDownMenuRow">
              <CCol>
                <FaWpforms color={Palette.HAZY_BLUE} size={20} />
              </CCol>
              <CCol>
                <StyledDropdownItemText className="m-0">
                  {t('Terms and Conditions')}
                </StyledDropdownItemText>
              </CCol>
              <CCol>
                <GoToNextTabIcon style={{ width: '300px' }} />
              </CCol>
            </CRow>
          </StyledDropdownItem>
          <StyledDropdownItem target="_blank" href="https://www.grata.life/privacy-policy">
            <CRow className="dropDownMenuRow">
              <CCol>
                <FaWpforms color={Palette.HAZY_BLUE} size={20} />
              </CCol>
              <CCol>
                <StyledDropdownItemText className="m-0">
                  {t('Privacy Policy')}
                </StyledDropdownItemText>
              </CCol>
              <CCol>
                <GoToNextTabIcon style={{ width: '300px' }} />
              </CCol>
            </CRow>
          </StyledDropdownItem>
        </StyledDropdown>
      </CDropdown>

      <ChangePassword />
      <EditProfile />
      <EditDataCenter />
    </div>
  );
};

export default TheHeaderDropdown;
