import React from 'react';
import styled from 'styled-components';
import { FiDownload, FiSearch, FiUpload } from 'react-icons/fi';
import SearchInput, { TextSlot } from '../../components/SearchInput';
import { Select } from '../ui/Select.jsx';
import { CSVLink } from 'react-csv';
import { Skeleton } from '../ui/Skeleton';
import { DeviceSize, Fonts, Palette } from '../utils';
import { Button, ButtonBody } from '../ui/Button';
import { GoPlus } from 'react-icons/go';
import { CLabel } from '@coreui/react';

export const AddNewButton = ({ ...props }) => {
  return (
    <StyledAddNewButton isPrimary {...props}>
      <GoPlus size={20} color={Palette.WHITE} /> Add New
    </StyledAddNewButton>
  );
};

const StyledAddNewButton = styled(ButtonBody)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 46px;
  max-width: 160px;
  gap: 5px;
`;

export const TableSelect = styled(Select)`
  width: 150px;
  padding: 11px 20px;
`;

const LoadingTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const LoadingTable = ({ amount = 6 }) => {
  return (
    <LoadingTableContainer>
      {Array(amount)
        .fill(amount)
        .map((_, i) => (
          <Skeleton key={i} width={'100%'} cssOverride={'border-radius: 15px'} />
        ))}
    </LoadingTableContainer>
  );
};

export const TablePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
`;

export const TablePageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 24px;

  @media (max-width: ${DeviceSize.desktopS}px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const TablePageTitle = styled.p`
  flex-shrink: 0;
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 20px;
  font-style: normal;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-weight: 700;
  line-height: 32px;
  align-self: start;
  margin-bottom: 0;
`;

export const TablePageSubTitle = styled.p`
  flex-shrink: 0;
  color: ${Palette.BLACK};
  font-size: 18px;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-weight: 500;
  word-wrap: 'break-word';
  margin-bottom: 0;
`;

export const TablePageButton = React.forwardRef(({ upload = false, children, ...props }, ref) => (
  <Button
    width={'91px'}
    height={'46px'}
    cssOverride={'gap: 6px;'}
    text={'CSV'}
    icon={
      upload ? (
        <FiUpload size={16} color={Palette.HAZY_BLUE} />
      ) : (
        <FiDownload size={16} color={Palette.HAZY_BLUE} />
      )
    }
    color={Palette.HAZY_BLUE}
    ref={ref}
    {...props}
  />
));

export const TablePageSearchBar = ({ type, children, ...props }) => (
  <SearchInput height={'46px'} width={'450px'} {...props}>
    <TextSlot>
      <FiSearch size={25} color={Palette.BLACK_50} />
    </TextSlot>
  </SearchInput>
);

export const TableSearchButton = () => {
  return (
    <Button
      icon={<FiSearch size={20} color={Palette.HAZY_BLUE} />}
      width={'45px'}
      height={'45px'}
    />
  );
};

export const TableDetailsText = styled(CLabel)`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
`;

export const StyledExportCSVButton = styled(CSVLink)`
  height: 46px;
  font: normal normal 600 14px ${Fonts.OPEN_SANS_FONT};

`;

export const Table = styled.table`
  width: 100%;

  ${({ cssOverride }) => cssOverride};
`;

export const TableHeader = styled.thead`
  width: 100%;

  tr:first-child {
    border-bottom: none;
    padding: 0 0 0 0;

    th {
      height: 12px;

      padding-right: 12px;
      align-content: center;
      color: ${Palette.BLACK_50};

      font-family: ${Fonts.OPEN_SANS_FONT};
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      border-bottom: none !important;
      border-top: none !important;
      padding: 24px 0px;
      background-color: ${Palette.WHITE};

      :after {
        line-height: 60px;
      }

      :hover {
        cursor: auto;
      }

      svg {
        color: ${Palette.BLACK};
        width: 18px;
        height: 18px;
        align-self: center;
        right: auto;
        top: 45%;
      }
    }
  }

  ${({ cssOverride }) => cssOverride};
`;

export const TableHead = styled.th`
  height: 12px;

  padding-right: 12px;
  align-content: center;
  color: ${Palette.BLACK_50};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;

  padding-bottom: 24px;

  :after {
    line-height: 60px;
  }

  ${({ cssOverride }) => cssOverride};
`;
export const TableRow = styled.tr`
  border-bottom: ${Palette.STROKE_GREY} solid 0.5px;

  &:hover {
    background-color: rgba(117, 117, 117, 0.2);
    cursor: pointer;
  }

  ${({ cssOverride }) => cssOverride};
`;

export const TableCell = styled.td`
  padding: 1rem 0 1rem 0px;
  align-items: center;

  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  ${({ cssOverride }) => cssOverride};
`;

export const TableCellText = styled.p`
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;

export const TableCellOptions = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
  color: ${Palette.HAZY_BLUE};

  ${({ cssOverride }) => cssOverride};
`;

export const TableColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TableColumnTitle = styled.p`
  display: inline;
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  margin: 0;
`;

// From tanstack table example
export function IndeterminateCheckbox({ indeterminate, className = '', ...rest }) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />;
}
