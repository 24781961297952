import React, { useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateService } from '../../../../../redux/actions/services';
import { setToast } from '../../../../../redux/actions/window';
import moment from 'moment';
import { getStatusId } from '../../../../../helper';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { RequestStatus } from '../../../constant';
import { InputSeparator, SettingsModalBoldText } from '../../../../settings/SettingsModals';
import { CustomDatePicker } from '../../../../../components/CustomSearchBar';

const ScheduleRequest = ({ serviceStatus, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localLang = i18next.resolvedLanguage === 'fr' ? locale : localeEn;
  const [date, setDate] = useState('');
  const { service } = useSelector((state) => state.services);

  let serviceReq = {};
  const handleSchedule = (value, dateString) => {
    setDate(value);

    let myContactInfo = service.contact_info;

    serviceReq = {
      user_id: parseInt(service.user_id),
      building_id: parseInt(service.building_id),
      category_id: parseInt(service.category_id),
      schedule_date: value ? value.utcOffset(0, true).toISOString() : null,
      status_id: serviceStatus && getStatusId(serviceStatus),
      subject: service.subject,
      description: service.description,
      media: service.media,
      assigned_to: service.assigned_to ? service.assigned_to : null,
      time_slot: service.time_slot ? service.time_slot : null,
      contact_type: service.contact_type,
      priority: service.priority,
      allow_access: service.allow_access,
      data: service.data,
      contact_info: myContactInfo
    };
    dispatch(
      updateService({
        id: service.id,
        body: {
          ...serviceReq
        },
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Request scheduled successfully!')
            })
          );
          onUpdateSuccess();
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Request scheduling failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  useEffect(() => {
    if (service && Object.keys(service).length) {
      let dateTime = service.scheduled_date ? service.scheduled_date : '';

      if (dateTime !== '') {
        dateTime = dateTime.split('T');
        const date = dateTime[0];
        const time = dateTime[1].split('.')[0];
        setDate(moment(service.scheduled_date ? `${date} ${time}` : ''));
      }
    }
  }, [service, dispatch]);

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }

  if (service?.assigned_to === 0 || serviceStatus === RequestStatus.OPEN) {
    return <></>;
  }
  return (
    <InputSeparator>
      <SettingsModalBoldText>{t('Schedule service')}</SettingsModalBoldText>
      <CustomDatePicker
        height={'56px'}
        width={'100%'}
        placeholder={t('Select date')}
        disabledDate={disabledDate}
        disabled={
          serviceStatus === RequestStatus.COMPLETE || serviceStatus === RequestStatus.ARCHIVED
        }
        showTime={{
          format: 'hh:mm a'
        }}
        value={date}
        name="scheduled_on"
        use12Hours
        showSecond={false}
        onChange={handleSchedule}
        format="YYYY-MM-DD hh:mm a"
        getPopupContainer={(trigger) => trigger.parentNode}
        locale={{
          ...localLang,
          lang: {
            ...localLang.lang,
            ok: 'OK'
          }
        }}
      />
    </InputSeparator>
  );
};

export default ScheduleRequest;
