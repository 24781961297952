import React from 'react';
import styled from 'styled-components';
import { Fonts, Palette } from '../components/utils';
import { CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';

const HeaderTitle = styled.p`
  color: ${Palette.BLACK};
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;

const HeaderNavButton = styled(CDropdownToggle)`
  display: inline-flex;
  background-color: ${Palette.SOLITUDE};
  border-radius: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;

  :hover {
    background-color: rgba(117, 117, 117, 0.2);
  }

  ${({ cssOverride }) => cssOverride};
`;

const HeaderNavButtonIcon = styled.div`
  display: flex;
  color: ${Palette.HAZY_BLUE};
  font-size: 24px;

  ${({ cssOverride }) => cssOverride};
`;

const HeaderNavButtonIconBadge = styled.span`
  position: absolute;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  background-color: ${Palette.ERROR};
  left: 56%;
  top: 34%;
  transform: translateY(-50%);

  ${({ cssOverride }) => cssOverride};
`;

const StyledDropdown = styled(CDropdownMenu)`
  border-radius: 10px;
  padding: 0;
`;

const StyledDropdownItem = styled(CDropdownItem)`
  margin-top: 0 !important;
  padding: 0.75rem 1.25rem;
`;

const StyledDropdownItemText = styled.p`
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 15px;
  font-style: normal;
  font-weight: 400 !important;
  margin: 0 !important;
`;

export {
  HeaderTitle,
  HeaderNavButton,
  HeaderNavButtonIcon,
  HeaderNavButtonIconBadge,
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownItemText
};
