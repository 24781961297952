import { CCol, CRow } from '@coreui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Fonts, Palette } from './utils';
import { BsTelephoneForwardFill } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { ModalHeader, StyledModal, StyledModalBody } from './Modals';

const Help = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);

  return (
    <StyledModal show={modal === 'helpModal'}>
      <ModalHeader title={t('Help')} />

      <StyledModalBody>
        <div className="p-2">
          <HeaderText>{t('Please reach out to us if you have any questions')}</HeaderText>
          <CRow>
            <CCol sm="1">
              <MdOutlineEmail size={30} color={Palette.HAZY_BLUE} />
            </CCol>
            <CCol>
              <CRow>
                <CCol>
                  <SupportText href="mailto:support@grata.com">support@grata.com</SupportText>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </StyledModalBody>
    </StyledModal>
  );
};

const HeaderText = styled.p`
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const SupportText = styled.a`
  color: ${({ color }) => (color ? color : Palette.OCEANIC)};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;

export default Help;
