import { CCol, CRow } from '@coreui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { ModalCloseButton } from './SettingsButton';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from './SettingsItem';
import {
  SettingsModalBoldText,
  SettingsModalBorder,
  SettingsModalSubHeader
} from './SettingsModals';
import { FaUser } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { MdOutlinePhoneIphone } from 'react-icons/md';
import { editProfile, getProfile, uploadProfileAvatar } from '../../redux/actions/profile';
import { setToast } from '../../redux/actions/window';
import { resetProfileData } from '../../redux/actions/updateUserProfile';
import { FaUserEdit } from 'react-icons/fa';
import { Palette } from '../../components/utils';
import { closeActiveModal, openModal } from '../../helper/modals';
import { formatPhoneNumber } from '../../helper/phoneNumber';
import { ImageContainer, StyledImage } from './SettingsImages';
import { ModalButtons, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';

const CreateProfileModalFinal = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.updateUserProfile);
  const { profile } = useSelector((state) => state.profile);
  const { modal } = useSelector((state) => state.headerModal);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    setIsSubmitting(true);
    if (userProfile.avatar.blobData) {
      const imgData = new FormData();
      imgData.append('image', userProfile.avatar.blobData, userProfile.avatar.blobName);
      dispatch(
        uploadProfileAvatar({
          body: imgData,
          success: (res) => {
            submitEditProfile(userProfile, res.data.filename);
          },
          fails: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Avatar update failed!'),
                modal: 'errorToast'
              })
            );
          }
        })
      );
    } else {
      submitEditProfile(userProfile);
    }
  };

  const submitEditProfile = (data, filename) => {
    const subValues = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: profile.email,
      phone: data.mobileNumber ? data.mobileNumber.match(/\d/g).join('') : '',
      home_phone: data.phoneNumber ? data.phoneNumber.match(/\d/g).join('') : '',
      avatar: filename === undefined ? profile.avatar : filename
    };

    dispatch(
      editProfile({
        body: subValues,
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Profile has been successfully updated.')
            })
          );
          dispatch(changeOpenedModal({ modal: '' }));
          dispatch(getProfile());
          dispatch(resetProfileData());
          setIsSubmitting(false);
        },
        fails: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Profile update failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  return (
    <StyledModal
      show={modal === 'CreateProfileModalFinal'}
      closeOnBackdrop={false}
      onClose={closeActiveModal}>
      <ModalHeader title={t('Edit Profile')} />

      <StyledModalBody>
        <SettingsModalSubHeader>
          <FaUserEdit size={24} color={Palette.HAZY_BLUE} />
          <SettingsModalBoldText>{t('User Image')}</SettingsModalBoldText>
        </SettingsModalSubHeader>

        <SettingsModalBorder height={245}>
          <ImageContainer>
            <StyledImage
              src={userProfile.avatar.blobURL ? userProfile.avatar.blobURL : profile.avatar}
            />
          </ImageContainer>
          <SettingsModalBoldText>
            {userProfile.firstName} {userProfile.lastName}
          </SettingsModalBoldText>
        </SettingsModalBorder>

        <SettingsItem>
          <FaUser size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>Name</SettingsItemTitle>
            <SettingsItemSubTitle>
              {userProfile.firstName} {userProfile.lastName}
            </SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <SettingsItem>
          <MdOutlineEmail size={24} color={Palette.HAZY_BLUE} />
          <SettingsItemContent>
            <SettingsItemTitle>Email</SettingsItemTitle>
            <SettingsItemSubTitle>{profile.email}</SettingsItemSubTitle>
          </SettingsItemContent>
        </SettingsItem>

        <CRow>
          <CCol>
            <SettingsItem>
              <MdOutlinePhoneIphone size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>Work Number</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatPhoneNumber(userProfile.phoneNumber)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
          <CCol>
            <SettingsItem>
              <MdOutlinePhoneIphone size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>Mobile Number</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {formatPhoneNumber(userProfile.mobileNumber)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>
          </CCol>
        </CRow>
      </StyledModalBody>

      <ModalButtons
        leftButtonText={'Back'}
        leftButtonClick={() => openModal('CreateProfileImageModal')}
        rightButtonText={'Confirm'}
        rightButtonClick={onSubmit}
        disabled={isSubmitting}
      />
    </StyledModal>
  );
};

export default CreateProfileModalFinal;
