import { CImg } from '@coreui/react';
import styled from 'styled-components';

export const ImageContainer = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius || '50%'};
  overflow: hidden;
  margin-bottom: 1rem;

  height: ${({ height }) => height || '150px'};
  width: ${({ width }) => width || '150px'};
`;

export const StyledImage = styled(CImg)`
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  width: 100%;
  height: 100%;
`;
