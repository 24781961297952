import { takeLatest } from "redux-saga/effects";
import {
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  REMOVE_ORGANIZATION,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  SEARCH_ORGANIZATIONS,
  UPLOAD_ORG_LOGO,
  GET_ALL_ORGANIZATIONS,
  GET_ALL_UNITS_IN_ORG,
  GET_PM_ANALYTICS,
  GET_PM_ACTIVITIES,
  GET_ORG_CONFIG
} from "../constants";
import apiCall, { HTTP_METHOD } from "../../utils/apiCall";

const uploadOrgLogo = apiCall({
  type: UPLOAD_ORG_LOGO,
  method: HTTP_METHOD.post,
  path: "/images",
  isFormData: true
});

const addOrganization = apiCall({
  type: ADD_ORGANIZATION,
  method: HTTP_METHOD.post,
  path: "/orgs"
});

const editOrganization = apiCall({
  type: EDIT_ORGANIZATION,
  method: HTTP_METHOD.put,
  path: ({ id }) => `/orgs/${id}`
});

const getOrganizations = apiCall({
  type: GET_ORGANIZATIONS,
  method: HTTP_METHOD.get,
  path: ({ page, location }) => {
    let url = `/orgs`;
    let paramSign = '?';
    if (page) {
      url += `?page=${page}`;
      paramSign = '&';
    }
    if (location) {
      url += paramSign + `location=${location}`;
    }
    return url;
  }
});

const getAllOrganizations = apiCall({
  type: GET_ALL_ORGANIZATIONS,
  method: HTTP_METHOD.get,
  path: ({ page, location }) => {
    let url = `/orgs`;
    let paramSign = '?';
    if (page) {
      url += `?page=${page}`;
      paramSign = '&';
    }
    if (location) {
      url += paramSign + `location=${location}`;
    }
    return url;
  }
});

const getOrganization = apiCall({
  type: GET_ORGANIZATION,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/orgs/${id}`
});

const getOrgConfig = apiCall({
  type: GET_ORG_CONFIG,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/orgs/${id}`
});

const removeOrganization = apiCall({
  type: REMOVE_ORGANIZATION,
  method: "delete",
  path: ({ id }) => `/orgs/${id}`
});

const searchOrganizations = apiCall({
  type: SEARCH_ORGANIZATIONS,
  method: HTTP_METHOD.get,
  newUrl: true,
  path: ({ query, page = 1, perpage = 20, search_col = null }) => {
    let url = `/search?entity=organization&q=${query}&page=${page}&per_page=${perpage}`;
    if (search_col) {
      url += `&search_col=${search_col}`;
    }
    return url;
  }
});

const getAllUnitsInOrg = apiCall({
  type: GET_ALL_UNITS_IN_ORG,
  method: HTTP_METHOD.get,
  path: ({ orgId, buildingId }) => {
    if (!buildingId) {
      return `/buildings/0/units?org=${orgId}`;
    }
    // building option for managers
    return `/buildings/${buildingId}/units?org=${orgId}`;
  }
});

const getPMAnalytics = apiCall({
  type: GET_PM_ANALYTICS,
  method: HTTP_METHOD.get,
  path: ({ orgId }) => {
    if (!orgId) {
      return `/orgs/0/pm-analytics`;
    }
    return `/orgs/${orgId}/pm-analytics`;
  }
});

const getPMActivities = apiCall({
  type: GET_PM_ACTIVITIES,
  method: HTTP_METHOD.get,
  path: ({ orgId, userId, page }) => {
    return `/orgs/${orgId}/pm-analytics?user_id=${userId}&page=${page}`;
  }
});

export default function* OrganizationSaga() {
  yield takeLatest(GET_ALL_ORGANIZATIONS, getAllOrganizations);
  yield takeLatest(SEARCH_ORGANIZATIONS, searchOrganizations);
  yield takeLatest(ADD_ORGANIZATION, addOrganization);
  yield takeLatest(EDIT_ORGANIZATION, editOrganization);
  yield takeLatest(GET_ORGANIZATIONS, getOrganizations);
  yield takeLatest(GET_ORGANIZATION, getOrganization);
  yield takeLatest(REMOVE_ORGANIZATION, removeOrganization);
  yield takeLatest(UPLOAD_ORG_LOGO, uploadOrgLogo);
  yield takeLatest(GET_ALL_UNITS_IN_ORG, getAllUnitsInOrg);
  yield takeLatest(GET_PM_ANALYTICS, getPMAnalytics);
  yield takeLatest(GET_PM_ACTIVITIES, getPMActivities);
  yield takeLatest(GET_ORG_CONFIG, getOrgConfig);
}
