import { createReducer } from '@reduxjs/toolkit';
import { SET_MODAL, SET_MODAL_sTACK } from '../constants';

const initialData = {
  modal: '',
  modalChangePass: '',
  cancelModal: false,
  modalsArr: [],
  data: null
};

export default createReducer(initialData, {
  [SET_MODAL]: (state, { payload }) => {
    return {
      ...state,
      modal: payload.modal,
      modalChangePass: payload.modalChangePass,
      cancelModal: payload.cancelModal,
      data: payload.data
    };
  },
  [SET_MODAL_sTACK]: (state, { payload }) => ({
    ...state,
    modalsArr: payload
  })
});
