import React from 'react';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import { TextInput } from '../../components/ui/TextInput';
import PasswordInput from '../../components/PasswordInput';

const SettingsModalBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || '345'}px;
  border-radius: 30px;
  border: 1px dashed rgba(19, 49, 85, 0.3);
  padding: 20px;
  ${({ cssOverride }) => cssOverride};
`;

const SettingsModalBoldText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;

const SettingsModalLightText = styled.p`
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;

const InputSeparator = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ rowGap }) => rowGap || '12px'};

  ${({ cssOverride }) => cssOverride};
`;

const SettingsModalSubHeader = styled.div`
  display: flex;
  gap: 10px;

  ${({ cssOverride }) => cssOverride};
`;

const SettingsModalInput = React.forwardRef(({ className, ...props }, ref) => {
  return <TextInput ref={ref} cssOverride={`height: 56px;`} {...props} />;
});
SettingsModalInput.displayName = 'SettingsModalInput';

const SettingsModalPasswordInput = React.forwardRef(({ className, ...props }, ref) => {
  return <PasswordInput ref={ref} cssOverride={`height: 56px;`} {...props} />;
});
SettingsModalPasswordInput.displayName = 'SettingsModalPasswordInput';

export {
  SettingsModalBorder,
  SettingsModalBoldText,
  SettingsModalLightText,
  SettingsModalSubHeader,
  SettingsModalInput,
  SettingsModalPasswordInput,
  InputSeparator
};
