import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import configureStores from "./redux/configureStore";
import WrapperCustom from "./components/WrapperCustom";

import "./i18n";

const initialState = {};
export const store = configureStores(initialState);

React.icons = icons;

ReactDOM.render(
  <Provider store={store}>
    <WrapperCustom>
      <React.Suspense fallback="loading">
        <App />
      </React.Suspense>
    </WrapperCustom>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
