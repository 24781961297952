import { createAction } from '@reduxjs/toolkit';
import {
  ADD_NEW_SUITE,
  DELETE_A_SUITE,
  EDIT_A_SUITE,
  GET_A_SUITE,
  GET_DEVICES_FOR_SUITES,
  REMOVE_SEARCH_TEXT_SUITE,
  RESET_CSV_SUITES,
  SET_CSV_SUITES,
  SET_SEARCH_TEXT_SUITE
} from '../constants';

export const addSuite = createAction(ADD_NEW_SUITE);
export const getDevicesSuites = createAction(GET_DEVICES_FOR_SUITES);
export const setCSVSuites = createAction(SET_CSV_SUITES);
export const deleteSuite = createAction(DELETE_A_SUITE);
export const getSuite = createAction(GET_A_SUITE);
export const editSuite = createAction(EDIT_A_SUITE);
export const resetCSVSuites = createAction(RESET_CSV_SUITES);
export const setSearchTextSuite = createAction(SET_SEARCH_TEXT_SUITE);
export const resetSearchTextSuite = createAction(REMOVE_SEARCH_TEXT_SUITE);

export default {
  addSuite,
  getDevicesSuites,
  setCSVSuites,
  deleteSuite,
  getSuite,
  editSuite,
  resetCSVSuites,
  setSearchTextSuite,
  resetSearchTextSuite
};
