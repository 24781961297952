import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import LoadingAvatar from '../../../components/LoadingAvatar';
import { defaultImgCDN, imageString } from '../../../helper';
import { getOrganization } from '../../../redux/actions/organization';
import { setToast } from '../../../redux/actions/window';
import DetailsModalTitleButtons from '../../../components/DetailsModalTitleButtons';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../users/constant';
import { LOADING_BLUE } from '../../../utils/colorCode';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../components/Modals';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../settings/SettingsItem';
import { FaHouseUser } from 'react-icons/fa';
import { Palette } from '../../../components/utils';
import { BiWorld } from 'react-icons/bi';
import { MdOutlineMarkunreadMailbox } from 'react-icons/md';
import { GoOrganization } from 'react-icons/go';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { ImageContainer, StyledImage } from '../../settings/SettingsImages';

const BuildingDetails = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const { building, status } = useSelector((state) => state.building);
  const { roleId, config } = useSelector((state) => state.auth);
  const orgState = useSelector((state) => state.Organization);
  const [errorImage, setErrorImage] = useState('');
  const dispatch = useDispatch();

  const onClose = () => {
    setErrorImage('');
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  // to show custom error that is coming from backEnd
  useEffect(() => {
    orgState.status === 'GET_ORGANIZATION_FAIL' &&
      dispatch(
        setToast({
          toastShow: true,
          toastMessage: orgState.error,
          modal: 'errorToast'
        })
      );
  }, [orgState.status]);

  return (
    modal === 'BuildingDetail' && (
      <StyledModal show={modal === 'BuildingDetail'} onClose={onClose} closeOnBackdrop={true}>
        <ModalHeader title={t('Building details')} />
        {status === 'GET_BUILDING_PENDING' ? (
          <LoadingAvatar color={LOADING_BLUE} />
        ) : (
          <>
            <StyledModalBody>
              <div className="flex justify-content-center">
                <ImageContainer borderRadius='0'>
                  <StyledImage
                    objectFit='contain'
                    onError={() => setErrorImage(defaultImgCDN)}
                    src={errorImage === '' ? imageString(building.photo) : errorImage}
                  />
                </ImageContainer>
              </div>

              <SettingsItem>
                <IoIosInformationCircleOutline size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('Building name')}</SettingsItemTitle>
                  <SettingsItemSubTitle>{building.name}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              <SettingsItem>
                <GoOrganization size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('Organization name')}</SettingsItemTitle>
                  <SettingsItemSubTitle
                    link={parseInt(roleId) === UserRole.SUPER_ADMIN}
                    onClick={() => {
                      parseInt(roleId) === UserRole.SUPER_ADMIN &&
                        dispatch(
                          getOrganization({
                            id: building.org_id,

                            success: () => {
                              dispatch(
                                changeOpenedModal({
                                  modal: 'OrganizationDetail'
                                })
                              );
                            }
                          })
                        );
                    }}>
                    {building.org}
                  </SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              <SettingsItem>
                <BiWorld size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('Country')}</SettingsItemTitle>
                  <SettingsItemSubTitle>{building.country}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              <SettingsItem>
                <BiWorld size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('City')}</SettingsItemTitle>
                  <SettingsItemSubTitle>{building.city}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              <SettingsItem>
                <BiWorld size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('State / Province')}</SettingsItemTitle>
                  <SettingsItemSubTitle>{building.state}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              <SettingsItem>
                <BiWorld size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('Zip / Postal code')}</SettingsItemTitle>
                  <SettingsItemSubTitle>{building.zip}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              <SettingsItem>
                <MdOutlineMarkunreadMailbox size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>{t('Address')}</SettingsItemTitle>
                  <SettingsItemSubTitle>{building.address}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
              {
                orgState?.organization?.config?.suite_enabled &&
                <SettingsItem>
                  <FaHouseUser size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>
                      {t('Number of units')}
                    </SettingsItemTitle>
                    <SettingsItemSubTitle>{building.suites}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>
              }

              <SettingsItem>
                <FaHouseUser size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>
                    {orgState?.organization?.config?.suite_enabled
                      ? t('Number of rooms')
                      : t('Number of units')}
                  </SettingsItemTitle>
                  <SettingsItemSubTitle>{building.units}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            </StyledModalBody>
            {config.org_allow_edits && (
              <DetailsModalTitleButtons
                className="editButtinDiv mb-3"
                colClassName="text-center editIconDiv"
                handleClick={() => {
                  dispatch(changeOpenedModal({ modal: 'editBuilding' }));
                }}
                editText={t('Edit')}
              />
            )}
          </>
        )}
      </StyledModal>
    )
  );
};

export default BuildingDetails;
