import React from 'react';

import 'react-dropzone-uploader/dist/styles.css';
import { CContainer } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import Table from './Table';
import { USER_TYPES } from '../helper';
import { SettingsModalBoldText } from '../views/settings/SettingsModals';
import { useSelector } from 'react-redux';
import { getRoomUnitTerminology } from '../utils/utils';

const replaceEmptyValue = (value) => (value ? value : '-');

const UsersFromCSVTable = ({ users = [], title }) => {
  const { t } = useTranslation();
  const { organization } = useSelector((state) => state.Organization);

  const userfields = [
    { key: 'name', label: t('Name') },
    { key: 'email', label: t('Email address') },
    { key: 'user_type', label: t('Type') },
    { key: 'mobile_phone', label: t('Cell phone') },
    { key: 'home_phone', label: t('Home phone') },
    { key: 'unit_num', label: getRoomUnitTerminology(organization) },
    { key: 'rent', label: t('Rent') },
    { key: 'move_in', label: t('Move in date') },
    { key: 'move_out', label: t('Move out date') }
  ];

  return (
    <>
      <SettingsModalBoldText fontSize="24px">{t(`${title}`)}</SettingsModalBoldText>
      <Table
        items={users}
        fields={userfields}
        scopedSlots={{
          unit_num: (item, index) => {
            return <td className="addUsers-table-unit">{item.unit_num}</td>;
          },
          user_type: (item, index) => {
            const userType = Object.entries(USER_TYPES).find(
              ([key, value]) => value === item.user_type
            );
            return <td className="addUsers-table-type">{userType?.length > 0 && userType[0]}</td>;
          },

          name: (item, index) => {
            return (
              <td className="addUsers-table-name">{`${item.first_name} ${item.last_name}`}</td>
            );
          },
          email: (item, index) => {
            return <td className="addUsers-table-email">{item.email}</td>;
          },
          rent: (item) => {
            return <td className="addUsers-table-unit">{replaceEmptyValue(item.rent)}</td>;
          },
          move_in: (item) => {
            return <td className="addUsers-table-date">{item.move_in}</td>;
          },
          move_out: (item) => {
            return <td className="addUsers-table-date">{item.move_out}</td>;
          },
          mobile_phone: (item) => {
            return <td className="addUsers-table-phone">{replaceEmptyValue(item.mobile_phone)}</td>;
          },
          home_phone: (item) => {
            return <td className="addUsers-table-phone">{replaceEmptyValue(item.home_phone)}</td>;
          }
        }}
      />
    </>
  );
};

export default UsersFromCSVTable;
