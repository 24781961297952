import { createReducer } from "@reduxjs/toolkit";

import { SELECT_EMAIL_RECIPIENT, RESET_EMAIL_RECIPIENT, SELECT_ALL_RECIPIENTS, REMOVE_SELECTED_RECIPIENTS, SEND_WELCOME_EMAILS } from "../constants";

const initialState = {
  users: {},
  length: 0
}
export default createReducer(
  initialState,
  {
    [SELECT_EMAIL_RECIPIENT]: (state, { payload }) => {
      const { item } = payload
      if (!item) {
        return state;
      }
      if (state.users[item.id]) {
        delete state.users[item.id];
        state.length--;
      } else {
        state.users[item.id] = item;
        state.length++;
      }
    },
    [RESET_EMAIL_RECIPIENT]: (state) => (initialState),
    [SELECT_ALL_RECIPIENTS]: (state, { payload }) => {
      // users is an array of users
      const { users } = payload;
      users.forEach(user => {
        if (state.users[user.id] === undefined) {
          state.users[user.id] = user;
          state.length++;
        }
      })
    },
    [REMOVE_SELECTED_RECIPIENTS]: (state, { payload }) => {
      // users is an array of users
      const { users } = payload;
      users.forEach((user) => {
        if (!!state.users[user.id]) {
          delete state.users[user.id]
          state.length--;
        }
      })
    }
  }
);
