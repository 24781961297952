import { applyMiddleware, createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";

import createRootReducer from "./reducers";
import sagas from "./sagas";

export const history = createBrowserHistory();

export default () => {
    const initialState = {};
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    const enhancers = [applyMiddleware(...middlewares)];

    const composeEnhancers =
        process.env.NODE_ENV !== "production" &&
        typeof window === "object" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  shouldHotReload: false,
                  trace: true,
                  traceLimit: 25
              })
            : compose;

    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(...enhancers)
    );

    sagaMiddleware.run(sagas);

    return store;
};
