import { takeLatest } from 'redux-saga/effects';
import {
  GET_ACTIVATION_LOGS,
  GET_ACTIVITIES,
  GET_LOGS,
  GET_REQUEST_TURN_AROUND_TIME,
  GET_TOTAL_METRICS,
  GET_DEALS_STATS,
  GET_DEAL_STATS
} from '../constants';
import apiCall, { HTTP_METHOD } from '../../utils/apiCall';
import { EventCommonAccessDeviceUnlock } from '../../views/reports/constants';

const getLogs = apiCall({
  setCsv: true,
  type: GET_LOGS,
  method: HTTP_METHOD.get,
  path: ({
    buildingId,
    orgId,
    page = 1,
    reportType,
    categoryType,
    isDaily,
    start_date,
    end_date,
    noticeStatus,
    per_page = 10,
    unitId
  }) => {
    let type = categoryType === EventCommonAccessDeviceUnlock ? 's' : reportType;

    let url = `/audit/log?t=${type}&o=${orgId}&p=${page}&per_page=${per_page}`;

    if (type !== 's') {
      url += `&c=${categoryType}`;
    } else {
      url += `&e=${categoryType}`;
    }
    if (buildingId) {
      url += `&b=${buildingId}`;
    }
    if (isDaily === 1 && categoryType >= 0) {
      url += `&is_daily=1`;
    }
    if (noticeStatus && categoryType !== EventCommonAccessDeviceUnlock) {
      url += `&n_s=${noticeStatus}`;
    }
    if (start_date && categoryType >= 0) {
      url += `&start_date=${start_date}`;
    }
    if (end_date && categoryType >= 0) {
      url += `&end_date=${end_date}`;
    }
    if (unitId && categoryType >= 0) {
      url += `&unit_id=${unitId}`;
    }

    return url;
  }
});

const getActivities = apiCall({
  setCsv: true,
  type: GET_ACTIVITIES,
  method: HTTP_METHOD.get,
  path: ({
    buildingId,
    orgId,
    page = 1,
    reportType,
    categoryType,
    isDaily,
    start_date,
    end_date,
    noticeStatus,
    per_page = 10,
    unitId,
    suiteId
  }) => {
    let category = categoryType;

    let url = `/audit/log?t=${reportType}&o=${orgId}&p=${page}&per_page=${per_page}&page=${page}`;
    console.log('🚀 ~ file: reports.js ~ line 76 ~ url', url);
    if (category >= 0) {
      url += `&c=${category}`;
    }
    if (buildingId) {
      url += `&b=${buildingId}`;
    }
    if (isDaily === 1) {
      url += `&is_daily=1`;
    }
    if (noticeStatus) {
      url += `&n_s=${noticeStatus}`;
    }
    if (start_date) {
      url += `&start_date=${start_date}`;
    }
    if (end_date) {
      url += `&end_date=${end_date}`;
    }
    if (unitId) {
      url += `&unit_id=${unitId}`;
    }
    if (suiteId) {
      url += `&suite_id=${suiteId}`;
    }

    return url;
  }
});

const getRequestTurnAroundTime = apiCall({
  setCsv: true,
  type: GET_REQUEST_TURN_AROUND_TIME,
  method: HTTP_METHOD.get,
  path: ({ org, building, page = 1, per_page, start_date, end_date, unitId }) => {
    let url = `/reports/requests?org=${org}&building=${building}&page=${
      page || 1
    }&per_page=${per_page}`;
    if (start_date) {
      url += `&start_date=${start_date}`;
    }
    if (end_date) {
      url += `&end_date=${end_date}`;
    }
    if (unitId) {
      url += `&unit_id=${unitId}`;
    }
    return url;
  }
});

const getActivationLogs = apiCall({
  setCsv: false,
  type: GET_ACTIVATION_LOGS,
  method: HTTP_METHOD.get,
  path: ({ org, building, per_page, page }) => {
    let url = `/reports/users?org=${org}&building=${building}&per_page=${per_page}&page=${page}`;
    return url;
  }
});

const getTotalMetrics = apiCall({
  type: GET_TOTAL_METRICS,
  method: HTTP_METHOD.get,
  path: () => `/audit/log?t=status&p=1`
});

const getDealsStats = apiCall({
  type: GET_DEALS_STATS,
  method: HTTP_METHOD.get,
  path: ({ org, building }) => `/audit/log?t=dv&o=${org}&b=${building}&p=1`
});

const getDealStats = apiCall({
  type: GET_DEAL_STATS,
  method: HTTP_METHOD.get,
  path: ({ offerId }) => `/audit/log?t=dv&p=1&id=${offerId}`
});

export default function* reportsSaga() {
  yield takeLatest(GET_LOGS, getLogs);
  yield takeLatest(GET_ACTIVITIES, getActivities);
  yield takeLatest(GET_ACTIVATION_LOGS, getActivationLogs);
  yield takeLatest(GET_REQUEST_TURN_AROUND_TIME, getRequestTurnAroundTime);
  yield takeLatest(GET_TOTAL_METRICS, getTotalMetrics);
  yield takeLatest(GET_DEALS_STATS, getDealsStats);
  yield takeLatest(GET_DEAL_STATS, getDealStats);
}
