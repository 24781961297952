import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSideBar, setSidebarType } from '../redux/actions/window';
import { CImg, CSidebar } from '@coreui/react';
import Logo from '../components/icons/Logo';
import { DefaultSidebar } from './Sidebar/DefaultSidebar';
import {
  SidebarCSidebarBrand,
  SidebarOrganizationTitleText,
  StyledSidebarNav
} from './Sidebar/SidebarComponents';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import { Fonts, Palette } from '../components/utils';
import { UserRole } from '../views/users/constant';
import { OrganizationsListSidebar } from './Sidebar/OrganizationsListSidebar';
import styled from 'styled-components';

const TheSidebar = () => {
  const dispatch = useDispatch();
  const { sidebarShow, sidebarType } = useSelector((state) => state.window);
  const { globalOrg } = useSelector((state) => state.Organization);
  const { roleId } = useSelector((state) => state.auth);
  const scrollRef = useRef();

  const toggleSidebar = () => {
    dispatch(
      setSidebarType({
        sidebarType: sidebarType === 'default' ? 'organization-selection' : 'default'
      })
    );
    scrollToTop();
  };

  // This function scrolls to the top of the navbar. Since we have many organizations that
  // require the user to scroll, we need a way to scroll back to the top when we toggle Sidebars.
  const scrollToTop = useCallback(() => {
    if (scrollRef.current) scrollRef.current.current.scrollTo(0, 0);
  }, [scrollRef]);

  return (
    <StyledSidebar
      className="coreUiSideBar"
      show={sidebarShow}
      unfoldable
      onShowChange={() => dispatch(setSideBar({ sidebarShow: !sidebarShow }))}>
      <SidebarCSidebarBrand>
        {globalOrg?.id ? (
          <BrandContainer>
            {globalOrg.logo && <OrgLogo src={globalOrg.logo} width={36} height={36} />}
            <SidebarOrganizationTitleText>{globalOrg.name}</SidebarOrganizationTitleText>
          </BrandContainer>
        ) : (
          <Logo className="text-white" />
        )}
        {roleId === UserRole.SUPER_ADMIN && (
          <a onClick={toggleSidebar}>
            {sidebarType === 'default' ? (
              <GoChevronUp color={Palette.WHITE} size={24} />
            ) : (
              <GoChevronDown color={Palette.WHITE} size={24} />
            )}
          </a>
        )}
      </SidebarCSidebarBrand>
      {/* CoreUI doesn't allow you to pass in refs normally, so we have to use their innerRef to hook into their scrolling */}
      <StyledSidebarNav innerRef={(e) => (scrollRef.current = e)}>
        {sidebarType === 'default' ? (
          <DefaultSidebar />
        ) : (
          <OrganizationsListSidebar toggleSidebar={toggleSidebar} />
        )}
        <StyledFooter>
          <StyledFooterText>
            Grata Technologies, Copyright ©{new Date().getFullYear()}.
          </StyledFooterText>
          <StyledFooterText>All rights reserved. V 1.4.5</StyledFooterText>
        </StyledFooter>
      </StyledSidebarNav>
    </StyledSidebar>
  );
};

// Fix: There is a visual bug when switching between the Default Sidebar and the Organization Selector since a
// DropdownTab renders above the scrollable thumb on the Sidebar itself.
const StyledSidebar = styled(CSidebar)`
  .ps__rail-y {
    z-index: 10;
  }
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OrgLogo = styled(CImg)`
  object-fit: contain;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

const StyledFooterText = styled.p`
  color: ${Palette.WHITE};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
`;

export default React.memo(TheSidebar);
