import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAmenity, modalVisibility } from '../../../../redux/actions/amenities';
import { CCol, CFormGroup, CRow } from '@coreui/react';
import { roundUp } from '../../../../helper';
import LoadingAvatar from '../../../../components/LoadingAvatar';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import DetailsModalTitleButtons from '../../../../components/DetailsModalTitleButtons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../../settings/SettingsItem';
import { FaBuilding, FaCalendar, FaClock, FaDatabase, FaUser } from 'react-icons/fa';
import { Palette } from '../../../../components/utils';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { DATE_FORMATS } from '../../../users/constant';
import { MdOutlineDescription } from 'react-icons/md';
import ReactJson from 'react-json-view';

const ModalAmenityDetails = ({ handleEdit }) => {
  const { t } = useTranslation();
  const amenitiesLabels = {
    building: t('Building'),
    type: t('Type'),
    name: t('Amenity name'),
    available_days: t('Available days'),
    start_time: t('Available start time'),
    end_time: t('Available end time'),
    max_reserve_time: t('Max reservation time'),
    cost_per_hor: t('Cost per hour'),
    description: t('Description'),
    data: t('Data')
  };
  const dispatch = useDispatch();
  const { showDetailModal, amenity, status } = useSelector((state) => state.amenities);
  const onClose = () => {
    dispatch(modalVisibility({ showDetailModal: false }));
    dispatch(clearAmenity());
  };

  const showTime = (time) => {
    let hr = String(time).split('T')[1].split(':')[0];
    let min = String(time).split('T')[1].split(':')[1];
    if (parseInt(hr) > 11) {
      if (hr == '23' && min == '59') return '12 : 00 am';
      if (hr != '12') hr = parseInt(hr) - 12;
      return String(hr) + ' : 00 pm';
    } else {
      if (hr == '00') return '12 : 00 am';
      else {
        return hr + ' : 00 am';
      }
    }
  };

  return (
    <>
      {showDetailModal === true && (
        <StyledModal
          show={showDetailModal === true ? true : false}
          onClose={onClose}
          closeOnBackdrop={true}>
          <ModalHeader title={t('Amenity details')} onCloseCallback={onClose} />

          {status === 'GET_AMENITY_PENDING' ? (
            <LoadingAvatar color={LOADING_BLUE} />
          ) : (
            <>
              <StyledModalBody>
                <SettingsItem>
                  <FaBuilding size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.building}</SettingsItemTitle>
                    <SettingsItemSubTitle>{amenity.building}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>

                <SettingsItem>
                  <MdOutlineDescription size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.description}</SettingsItemTitle>
                    <SettingsItemSubTitle>{amenity.code}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>

                <SettingsItem>
                  <FaCalendar size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.start_time}</SettingsItemTitle>
                    <SettingsItemSubTitle>
                      {moment(amenity.available_start).utc().format(DATE_FORMATS.hh_mm_a)}
                    </SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>

                <SettingsItem>
                  <FaCalendar size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.end_time}</SettingsItemTitle>
                    <SettingsItemSubTitle>
                      {roundUp(moment(amenity.available_end).utc(), 'second', 2).format(
                        DATE_FORMATS.hh_mm_a
                      )}
                    </SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>

                <SettingsItem>
                  <FaCalendar size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.available_days}</SettingsItemTitle>
                    <SettingsItemSubTitle>{amenity.available_days}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>

                <SettingsItem>
                  <FaClock size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.max_reserve_time}</SettingsItemTitle>
                    <SettingsItemSubTitle>{amenity.max_time}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>

                <SettingsItem>
                  <FaDatabase size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{amenitiesLabels.data}</SettingsItemTitle>
                    <ReactJson 
                      src={amenity.data}
                      name={false}
                      collapsed={true}
                    />
                  </SettingsItemContent>
                </SettingsItem>

                <DetailsModalTitleButtons
                  handleClick={() => {
                    onClose();
                    handleEdit(amenity.id);
                  }}
                  editText="Edit"
                />
              </StyledModalBody>
            </>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default ModalAmenityDetails;