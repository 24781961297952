import { createReducer } from '@reduxjs/toolkit';
import {
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  REMOVE_ORGANIZATION,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  SEARCH_ORGANIZATIONS,
  CLEAR_ERROR,
  SET_ORG_ID,
  SET_GLOBAL_ORG_ID,
  UPLOAD_ORG_LOGO,
  GET_ALL_ORGANIZATIONS,
  CLEAR_ORGANIZATION,
  SET_ALL_ORG_STATUS,
  GET_ALL_UNITS_IN_ORG,
  GET_PM_ANALYTICS,
  SET_PM_DETAILS,
  GET_PM_ACTIVITIES,
  SET_GLOBAL_ORG,
  GET_ORG_CONFIG
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';

const initialState = {
  AllOrganizations: [],
  Organizations: [],
  organization: {},
  globalOrg: {},
  orgConfig: {},
  status: 'INIT',
  error: null,
  orgId: null,
  globalOrgId: 0,
  logo: '',
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  getAllStatus: '',
  allUnitsInOrg: [],
  PMAnalyticsLastLogin: [],
  PMdetails: {},
  PMActivities: []
};

export default createReducer(initialState, {
  [CLEAR_ORGANIZATION]: (state) => ({
    ...state,
    organization: {}
  }),

  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [SET_ORG_ID]: (state, { payload }) => {
    return {
      ...state,
      orgId: payload
    };
  },

  [SET_PM_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      PMdetails: payload
    };
  },

  [SET_GLOBAL_ORG_ID]: (state, { payload }) => {
    return {
      ...state,
      globalOrgId: payload
    };
  },

  [SET_GLOBAL_ORG]: (state, { payload }) => {
    return {
      ...state,
      globalOrg: payload
    };
  },

  [SET_ALL_ORG_STATUS]: (state, { payload }) => {
    return {
      ...state,
      getAllStatus: payload.status
    };
  },
  [requestSuccess(UPLOAD_ORG_LOGO)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(UPLOAD_ORG_LOGO),
    logo: payload.filename,
    error: null
  }),

  [requestFail(UPLOAD_ORG_LOGO)]: (state, { payload }) => ({
    ...state,
    status: requestFail(UPLOAD_ORG_LOGO),
    logo: '',
    error: payload
  }),

  [requestSuccess(SEARCH_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    Organizations: payload.data,
    status: requestSuccess(SEARCH_ORGANIZATIONS),
    pagination: {
      currentPage: payload.current_page,
      perPage: payload.per_page,
      total: payload.total
    },
    error: null
  }),

  [requestPending(SEARCH_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_ORGANIZATIONS)
  }),

  [requestFail(SEARCH_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    Organizations: [],
    status: requestFail(SEARCH_ORGANIZATIONS),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_ORGANIZATION),
    organization: payload,
    error: ''
  }),

  [requestPending(GET_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ORGANIZATION)
  }),

  [requestFail(GET_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_ORGANIZATION),
    error: payload.data.message,
    organization: {}
  }),

  [requestSuccess(GET_ORG_CONFIG)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_ORG_CONFIG),
    orgConfig: payload,
    error: ''
  }),

  [requestPending(GET_ORG_CONFIG)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ORG_CONFIG)
  }),

  [requestFail(GET_ORG_CONFIG)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_ORG_CONFIG),
    error: payload.data.message,
    orgConfig: {}
  }),

  [requestSuccess(ADD_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_ORGANIZATION),
    error: null
  }),

  [requestPending(ADD_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_ORGANIZATION),
    error: null
  }),

  [requestFail(ADD_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_ORGANIZATION),
    error: payload.data
  }),

  [requestSuccess(GET_ORGANIZATIONS)]: (state, { payload }) => {
    return {
      ...state,
      Organizations: payload?.data,
      status: requestSuccess(GET_ORGANIZATIONS),

      pagination: {
        currentPage: payload?.pagination['pagination-current-page'],
        perPage: payload?.pagination['pagination-per-page'],
        total: payload?.pagination['pagination-total']
      },

      error: null
    };
  },
  [requestPending(GET_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ORGANIZATIONS),
    error: null
  }),

  [requestFail(GET_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    Organizations: [],
    status: requestFail(GET_ORGANIZATIONS),
    error: payload.data.message
  }),

  [requestSuccess(REMOVE_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(REMOVE_ORGANIZATION),
    error: null
  }),

  [requestPending(REMOVE_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_ORGANIZATION),
    error: null
  }),

  [requestFail(REMOVE_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REMOVE_ORGANIZATION),
    error: payload.data.message
  }),

  [requestSuccess(EDIT_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_ORGANIZATION),
    error: null
  }),

  [requestPending(EDIT_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_ORGANIZATION),
    error: null
  }),

  [requestFail(EDIT_ORGANIZATION)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_ORGANIZATION),
    error: payload.data.message
  }),

  [requestSuccess(GET_ALL_ORGANIZATIONS)]: (state, { payload }) => {
    // pagination is optional
    if (payload?.pagination) {
      return {
        ...state,
        AllOrganizations: payload.data,
        pagination: {
          currentPage: payload.pagination['pagination-current-page'],
          perPage: payload.pagination['pagination-per-page'],
          total: payload.pagination['pagination-total']
        },
        getAllStatus: requestSuccess(GET_ALL_ORGANIZATIONS),
        status: requestSuccess(GET_ALL_ORGANIZATIONS),
        error: null
      };
    }
    return {
      ...state,
      AllOrganizations: payload.data,
      getAllStatus: requestSuccess(GET_ALL_ORGANIZATIONS),
      status: requestSuccess(GET_ALL_ORGANIZATIONS),
      error: null
    };
  },

  [requestPending(GET_ALL_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ALL_ORGANIZATIONS)
  }),

  [requestFail(GET_ALL_ORGANIZATIONS)]: (state, { payload }) => ({
    ...state,
    Organizations: [],
    status: requestFail(GET_ALL_ORGANIZATIONS),
    getAllStatus: requestFail(GET_ALL_ORGANIZATIONS),
    error: payload?.data?.message
  }),
  [requestSuccess(GET_ALL_UNITS_IN_ORG)]: (state, { payload }) => {
    return {
      ...state,
      allUnitsInOrg: payload,
      status: requestSuccess(GET_ALL_UNITS_IN_ORG),
      error: null
    };
  },

  [requestPending(GET_ALL_UNITS_IN_ORG)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ALL_UNITS_IN_ORG),
    error: null
  }),

  [requestFail(GET_ALL_UNITS_IN_ORG)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_ORGANIZATION),
    error: payload.data.message
  }),

  //Get PM Analytics last login
  [requestSuccess(GET_PM_ANALYTICS)]: (state, { payload }) => {
    return {
      ...state,
      PMAnalyticsLastLogin: payload,
      status: requestSuccess(GET_PM_ANALYTICS),
      error: null
    };
  },

  [requestPending(GET_PM_ANALYTICS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_PM_ANALYTICS),
    error: null
  }),

  [requestFail(GET_PM_ANALYTICS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_PM_ANALYTICS),
    error: payload.data.message
  }),

  //Get PM Activities
  [requestSuccess(GET_PM_ACTIVITIES)]: (state, { payload }) => {
    return {
      ...state,
      PMActivities: payload,
      status: requestSuccess(GET_PM_ACTIVITIES),
      error: null
    };
  },

  [requestPending(GET_PM_ACTIVITIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_PM_ACTIVITIES),
    error: null
  }),

  [requestFail(GET_PM_ACTIVITIES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_PM_ACTIVITIES),
    error: payload.data.message
  })
});
