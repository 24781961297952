import React from 'react';
import styled, { css } from 'styled-components';
import { Palette } from '../utils/palette';
import { variant } from './variant';
import { Fonts } from '../utils';

export function Badge({ children, ...props }) {
  return <StyledBadge {...props}>{children}</StyledBadge>;
}

export const StyledBadge = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 20px;

  padding: 0px 12px;

  text-align: center;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  ${variant(
    {
      default: css`
        background-color: ${Palette.HAZY_BLUE};
        color: ${Palette.WHITE};
      `,
      complete: css`
        background-color: rgba(0, 150, 136, 0.2);
        color: ${Palette.STAR_GREEN};
      `,
      archived: css`
        background-color: rgba(138, 70, 224, 0.2);
        color: ${Palette.PURPLE};
      `,
      reserved: css`
        background-color: ${Palette.BORDER_OUTLINE};
        color: ${Palette.DARK_ASH};
      `,
      inProgress: css`
        background-color: rgba(227, 132, 60, 0.2);
        color: ${Palette.RUSTY_ORANGE};
      `,
      open: css`
        background-color: rgba(70, 151, 224, 0.2);
        color: ${Palette.OCEANIC};
      `,
      danger: css`
        background-color: rgba(234, 68, 68, 0.2);
        color: ${Palette.ERROR};
      `,
      selected: css`
        background-color: ${Palette.SOLITUDE};
        color: ${Palette.HAZY_BLUE};
      `,
      unselected: css`
        background-color: ${Palette.WHITE};
        color: ${Palette.HAZY_BLUE};
      `
    },
    'variant'
  )}

  ${({ cssOverride }) => cssOverride};
`;
