import React from "react";
import { TextInput } from "../../../components/ui/TextInput";
import { Button } from "../../../components/ui/Button";
import { Fonts, MediaQuery, Palette } from "../../../components/utils";
import styled from 'styled-components';
import classNames from "classnames";

const AuthenticationParent = React.forwardRef(({ cssOverride, ...props }, ref) => {
  return <AuthenticationHeaderParentStyle
    ref={ref}
    {...props}
  />
});
AuthenticationParent.displayName = "AuthenticationParent";

const AuthenticationInput = React.forwardRef(({ cssOverride, ...props }, ref) => {
  return <TextInput
    ref={ref}
    cssOverride={classNames(cssOverride, "height: 56px;")}
    {...props}
  />
});
AuthenticationInput.displayName = "AuthenticationInput";

const AuthenticationButton = React.forwardRef(({ ...props }, ref) => {
  return <Button
    ref={ref}
    height={"56px"}
    isPrimary
    {...props}
  />
});
AuthenticationButton.displayName = "AuthenticationButton";

const AuthenticationHeaderLabel = React.forwardRef(({ cssOverride, ...props }, ref) => {
  return <AuthenticationHeaderLabelStyle
    ref={ref}
    cssOverride={cssOverride}
    {...props}
  />
});
AuthenticationHeaderLabel.displayName = "AuthenticationHeaderLabel";

const AuthenticationSubHeaderLabel = React.forwardRef(({ cssOverride, ...props }, ref) => {
  return <AuthenticationSubHeaderLabelStyle
    ref={ref}
    cssOverride={cssOverride}
    {...props}
  />
});
AuthenticationSubHeaderLabel.displayName = "AuthenticationSubHeaderLabel";

const AuthenticationInputLabel = React.forwardRef(({ cssOverride, ...props }, ref) => {
  return <AuthenticationInputLabelStyle
    ref={ref}
    cssOverride={cssOverride}
    {...props}
  />
});
AuthenticationInputLabel.displayName = "AuthenticationInputLabel";

const AuthenticationLink = React.forwardRef(({ cssOverride, ...props }, ref) => {
  return <AuthenticationLinkStyle
    ref={ref}
    cssOverride={cssOverride}
    {...props}
  />
});
AuthenticationLink.displayName = "AuthenticationLink";

const AuthenticationHeaderParentStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  height: 100%;
  max-width: 480px;
  padding-left: 2rem;
  padding-right: 2rem;

  @media ${MediaQuery.desktopL} {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ cssOverride }) => cssOverride};
`;

const AuthenticationHeaderLabelStyle = styled.p`
  color: ${Palette.BLACK};
  font-size: 48px;
  font-family: ${Fonts.RED_HAT_FONT};
  font-weight: 800;
  text-transform: capitalize;
  line-height: 64px;
  word-wrap: break-word;
  margin-bottom: 0;

  ${({ cssOverride }) => cssOverride};
`;

const AuthenticationSubHeaderLabelStyle = styled.p`
  color: ${Palette.BLACK_50};
  font-size: 20px;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  word-wrap: break-word;

  ${({ cssOverride }) => cssOverride};
`;

const AuthenticationInputLabelStyle = styled.label`
  color: ${Palette.BLACK};
  font-size: 14px;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;

  ${({ cssOverride }) => cssOverride};
`;

const AuthenticationLinkStyle = styled.p`
  display: inline;
  color: ${Palette.HAZY_BLUE};
  font-size: 15px;
  font-weight: 600;
  
  :hover {
    cursor: pointer;
    filter: opacity(80%);
  }

  ${({ cssOverride }) => cssOverride};
`;

export {
  AuthenticationParent,

  AuthenticationInput,
  AuthenticationButton,

  AuthenticationHeaderLabel,
  AuthenticationSubHeaderLabel,
  AuthenticationInputLabel,

  AuthenticationLink,
}