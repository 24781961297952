import { createAction } from "@reduxjs/toolkit";
import { SET_MODAL, SET_MODAL_sTACK } from "../constants";

export const changeOpenedModal = createAction(SET_MODAL);
export const setModalStack = createAction(SET_MODAL_sTACK);

export default {
  changeOpenedModal,
  setModalStack
};
