import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_PAGE,
  TEMPLATE_DATA,
  CHANGED_TEMPLATE_DATA,
  IS_MODAL_VISIBLE,
  EDIT_THIS_TEMPLATE,
  SHOW_MODAL_FOR_TEMPLATE,
  SET_TREE_DATA,
  GET_ALL_TEMPLATES_DATA,
  GET_CATEGORY_MAPPING,
  SEND_EMAIL,
  SELECT_USER,
  GET_TEMPLATE,
  SET_PARSED_TEMPLATE,
  CHANGE_SHOW_BUTTON,
  SEND_TEMPLATE,
  CLEAR_TREE,
  SET_TEMPLATE_DATA,
  SET_RECIPIENTS_LENGTH,
  SET_SELECTIION_TEXT,
  SET_IS_FETCHING_TEMPLATES,
  SET_SELECT_USER_HELPERS,
  GET_DRAFT_DETAILS,
  RESET_DRAFT_DETAILS,
  SET_PINNED_NEWS,
  SET_TITLE
} from '../constants';
import { requestPending, requestFail, requestSuccess } from '../../utils/status';

export const MODAL_TYPES = {
  SEND: 'send',
  DRAFT: 'draft',
  DRAFT_EDIT: 'draft_edit',
  DRAFT_SEND: 'draft_send'
};

const initialState = {
  page: '0', // for navigating page
  draftPage: '0',
  draftId: '',
  templateSelected: '',
  parsedTemplate: '',
  templateData: '',
  selectedDraftData: {
    units: [],
    buildings: []
  },
  isModalVisible: false,
  modalType: '',
  modelVisibility: false,
  templates: [],
  selectedUsers: [],
  selectedUsersHelpers: {},
  allTemplatesData: [],
  treeData: [],
  status: '',
  title: '',
  categoryMapping: [],
  showButton: false,
  recipientsLength: 0,
  selectionText: '0 selection',
  isFetchingTemplates: false,
  pinNews: false
};

export default createReducer(initialState, {
  [SET_TREE_DATA]: (state, { payload }) => ({
    ...state,
    treeData: [payload.treeData]
  }),
  [SET_TEMPLATE_DATA]: (state, { payload }) => ({
    ...state,
    templateData: -1
  }),
  [CHANGE_PAGE]: (state, { payload }) => ({
    ...state,
    page: payload.isDraft ? '0' : payload.page,
    draftPage: payload.isDraft ? payload.page : '0'
  }),

  [SET_RECIPIENTS_LENGTH]: (state, { payload }) => ({
    ...state,
    recipientsLength: payload.recipientsLength
  }),

  [SET_SELECTIION_TEXT]: (state, { payload }) => ({
    ...state,
    selectionText: payload.selectionText
  }),

  [SET_TITLE]: (state, { payload }) => ({
    ...state,
    title: payload
  }),

  [SET_PINNED_NEWS]: (state, { payload }) => ({
    ...state,
    pinNews: payload
  }),

  [requestSuccess(GET_ALL_TEMPLATES_DATA)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_ALL_TEMPLATES_DATA),
    allTemplatesData: payload
  }),

  [requestPending(GET_ALL_TEMPLATES_DATA)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ALL_TEMPLATES_DATA)
  }),

  [requestFail(GET_ALL_TEMPLATES_DATA)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_ALL_TEMPLATES_DATA)
  }),

  [requestSuccess(SEND_TEMPLATE)]: (state, { payload }) => ({
    ...state,
    selectedUsers: [],
    selectedUsersHelpers: {},
    status: requestSuccess(SEND_TEMPLATE)
  }),

  [requestPending(SEND_TEMPLATE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEND_TEMPLATE)
  }),

  [requestFail(SEND_TEMPLATE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SEND_TEMPLATE)
  }),

  [requestSuccess(GET_CATEGORY_MAPPING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_CATEGORY_MAPPING),
    categoryMapping: payload
  }),

  [requestPending(GET_CATEGORY_MAPPING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_CATEGORY_MAPPING)
  }),

  [requestFail(GET_ALL_TEMPLATES_DATA)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_ALL_TEMPLATES_DATA)
  }),

  [TEMPLATE_DATA]: (state, { payload }) => ({
    ...state,
    templateData: payload.templateData,
    parsedTemplate: '',
    templateSelected: ''
  }),

  [SET_PARSED_TEMPLATE]: (state, { payload }) => ({
    ...state,
    parsedTemplate: payload.parsedTemplate
  }),
  [SELECT_USER]: (state, { payload }) => ({
    ...state,
    selectedUsers: payload.selectedUsers
  }),

  [SET_SELECT_USER_HELPERS]: (state, { payload }) => ({
    ...state,
    selectedUsersHelpers: payload.selectedUsersHelpers
  }),

  [IS_MODAL_VISIBLE]: (state, { payload }) => {
    return {
      ...state,
      modelVisibility: payload.modelVisibility,
      modalType: payload.type
    };
  },

  [EDIT_THIS_TEMPLATE]: (state, { payload }) => ({
    ...state,
    page: payload.isDraft ? '0' : payload.page,
    draftPage: payload.isDraft ? payload.page : '0',
    title: payload.title,
    templateSelected: payload.templateSelected,
    modelVisibility: false
  }),
  [SHOW_MODAL_FOR_TEMPLATE]: (state, { payload }) => ({
    ...state,
    modelVisibility: true,
    templateSelected: payload.templateSelected
  }),
  [requestSuccess(GET_TEMPLATE)]: (state, { payload }) => ({
    ...state,
    templateData: payload,
    status: requestSuccess(GET_TEMPLATE)
  }),
  [requestPending(GET_TEMPLATE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SHOW_MODAL_FOR_TEMPLATE)
  }),
  [requestFail(GET_TEMPLATE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_TEMPLATE)
  }),
  [requestSuccess(GET_DRAFT_DETAILS)]: (state, { payload }) => {
    return {
      ...state,
      selectedDraftData: {
        units: payload.units.split(',').map((unitId) => parseInt(unitId, 10)),
        buildings: payload.buildings.split(',').map((buildingId) => parseInt(buildingId, 10))
      },
      draftId: payload.id,
      templateData: Buffer.from(payload.body, 'base64').toString('utf-8'),
      parsedTemplate: '',
      status: requestSuccess(GET_TEMPLATE)
    };
  },
  [requestPending(GET_DRAFT_DETAILS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_DRAFT_DETAILS)
  }),
  [requestFail(GET_DRAFT_DETAILS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_DRAFT_DETAILS)
  }),
  [RESET_DRAFT_DETAILS]: (state) => ({
    ...state,
    selectedDraftData: {
      units: [],
      buildings: []
    },
    draftId: '',
    templateData: ''
  }),
  [CHANGED_TEMPLATE_DATA]: (state, { payload }) => ({
    ...state,
    parsedTemplate: payload.parsedTemplate
  }),
  [CHANGE_SHOW_BUTTON]: (state, { payload }) => ({
    ...state,
    showButton: payload.showButton
  }),

  [SEND_EMAIL]: (state, { payload }) => ({
    ...state,
    page: '0',
    modelVisibility: false,
    templateSelected: payload.templateSelected,
    templateData: payload.templateData,
    parsedTemplate: '',
    selectedUsers: [],
    selectedUsersHelpers: {},
    pinNews: false
  }),

  [CLEAR_TREE]: (state, { payload }) => ({
    ...state,
    treeData: payload.treeData,
    templateData: '',
    parsedTemplate: '',
    templateSelected: '',
    selectedUsers: [],
    selectedUsersHelpers: {}
  }),
  [SET_IS_FETCHING_TEMPLATES]: (state, { payload }) => ({
    ...state,
    isFetchingTemplates: !!payload
  })
});
