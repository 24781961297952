import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  GET_COMMON_ACCESS
} from "../constants";
import apiCall from "../../utils/apiCall";
// import { gratafyURL } from "../../utils/axios";
const gratafyURL = process.env.REACT_APP_GRATAFY_URL //'https://grata-admin-api-9q7yezmw.ue.gateway.dev'

const getCommonAccess = apiCall({
  type: GET_COMMON_ACCESS,
  method: "get",
  path: ({ org_id, building_id }) => `/admin/port/common/access/${org_id}/${building_id}`,
  gratafyURL
});

export default function* securitySaga() {
  yield takeLatest(GET_COMMON_ACCESS, getCommonAccess);
  
}
