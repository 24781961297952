import React, { useEffect, useRef, useState } from "react";
import {
  addUser,
  addAvatar,
  clearUserError,
  searchUsers
} from "../../redux/actions/user";
import { setToast } from "../../redux/actions/window";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import "react-dropzone-uploader/dist/styles.css";
import {
  CForm,
  CFormGroup,
  CCol,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CInputGroup,
  CSelect,
  CInvalidFeedback,
  CLabel,
  CInput,
  CButton,
  CCardFooter,
  CInputCheckbox
} from "@coreui/react";
import { ROLE_MAP, USER_TYPE_MAP } from "../../utils/mappings";
import * as Yup from "yup";
import { getAllBuildings } from "../../redux/actions/building";
import { changeOpenedModal } from "../../redux/actions/headerModal";
import OrganizationDropDown from "../../components/OrganizationDropDown";
import BuildingDropDown from "../../components/BuildingDropDown";
import {
  clearUnitResponse,
  getDDUnits,
  getUnits,
  setUserUnit
} from "../../redux/actions/unit";
import MaskedInput from "react-text-mask";
import { useTranslation } from "react-i18next";
import { emailRegx } from "../../helper/Regx";
import AvatarCropper from "../../components/utils/AvatarCropper";
import { defaultImgCDN } from "../../helper";
import { UserRole, UserType } from "./constant";
import { GET_ALL_BUILDINGS } from "../../redux/constants";
import { requestPending } from "../../utils/status";
import LoadingButton from "../../components/LoadingButton";

const AddUser = () => {
  const { t } = useTranslation();
  const phoneNumberMask = [
    "(",
    /[0-9]/,
    /\d/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  const dispatch = useDispatch();
  const { globalOrgId } = useSelector((state) => state.Organization);

  const { roleId, building_id, orgId } = useSelector((state) => state.auth);
  const [allowUserCreation, setAllowUserCreation] = useState(true);
  const { allBuildings, status } = useSelector((state) => state.building);
  const { AllOrganizations } = useSelector((state) => state.Organization);
  const { error, searchFields } = useSelector((state) => state.user);
  const { modal } = useSelector((state) => state.headerModal);
  const [startDateError, setStartDateError] = useState("");
  const { selectedField } = useSelector((state) => state.search);
  const { unitForUser, unitsForDD } = useSelector(
    (state) => state.unit
  );
  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ""
  });
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const editorRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { globalBuildingId } = useSelector((state) => state.building);

  const validationSchema = function (values) {
    const validation = {
      first_name: Yup.string()
        .matches(/^[\p{Letter}\-_\s]+$/iu, t("Only alphabets allowed"))
        .max(50, t("Not more than 50 characters"))
        .required(t("First name is required!")),
      last_name: Yup.string()
        .matches(/^[\p{Letter}\-_\s]+$/iu, t("Only alphabets allowed"))
        .max(50, t("Not more than 50 characters"))
        .required(t("Last name is required!")),
      email: Yup.string()
        .trim()
        .email(t("Invalid email address!"))
        .matches(emailRegx, t("Invalid Email"))
        .max(50, t("Not more than 50 characters"))
        .required(t("Email is required!")),
      cell_phone: Yup.string()
        .test(
          "phoneLength",
          t("Phone number should be 10 digits long"),
          (phone) => {
            var phoneOnlyNumber = phone.replace(/\D/g, "");
            if (phoneOnlyNumber.length === 0) return true;

            if (phoneOnlyNumber.length !== 10) return false;

            return true;
          }
        )
        .test(
          "phoneValid",
          t("1st and 4th digits must be b/w (2-9)"),
          (phone) => {
            var phoneOnlyNumber = phone.replace(/\D/g, "");
            if (phoneOnlyNumber.length === 0) return true;

            if (
              phoneOnlyNumber.length === 10 &&
              !phoneOnlyNumber.match(/^[2-9][0-9]{2}[2-9][0-9]*/g)
            )
              return false;

            return true;
          }
        ),
      home_phone: Yup.string()
        .test(
          "homeLength",
          t("Home number should be 10 digits long"),
          (phone) => {
            var phoneOnlyNumber = phone.replace(/\D/g, "");
            if (phoneOnlyNumber.length === 0) return true;

            if (phoneOnlyNumber.length !== 10) return false;

            return true;
          }
        )
        .test(
          "phoneValid",
          t("1st and 4th digits must be b/w (2-9)"),
          (phone) => {
            var phoneOnlyNumber = phone.replace(/\D/g, "");
            if (phoneOnlyNumber.length === 0) return true;

            if (
              phoneOnlyNumber.length === 10 &&
              !phoneOnlyNumber.match(/^[2-9][0-9]{2}[2-9][0-9]*/g)
            )
              return false;

            return true;
          }
        ),

      building_id: Yup.string()
        .required(t("Building is required!"))
        .test("is-required", t("Building is required!"), (value) => {
          return parseInt(value, 10) >= 0;
        }),
      role_id: Yup.number()
        .required(t("Role is required!"))
        .test("is-required", t("Role is required!"), (value, context) => {
          if (parseInt(value) <= 0) return false;
          return true;
        }),
      type_id: Yup.number()
        .required(t("Type is required!"))
        .test("is-required", t("Type is required!"), (value, context) => {
          if (context.parent.role_id === UserRole.USER && value <= 0) return false;
          return true;
        }),
      org_id: Yup.string()
        .required(t("Organization is required!"))
        .test("is-required", t("Organization is required!"), (value) => {
          if (parseInt(value) < 1) return false;
          return true;
        })
    };
    // commented so unit is is optinal while creating user

    if (parseInt(values.type_id) === UserType.RESIDENT && parseInt(values.unit_id) > 1) {
      return Yup.object().shape({
        ...validation,
        unit_id: Yup.string().nullable(),
        start_date: Yup.string().required(t("Move in date is required")),
        end_date: Yup.string().required(t("Move out date is required")),
        amount: Yup.number()
          .max(999999, t("Not more than 999999"))
          .moreThan(-1, t("Rent can not be negative!"))
          .typeError(t("Monthly rent is required!"))
      });
    }

    if (parseInt(values.type_id) === UserType.CORESIDENT) {
      return Yup.object().shape({
        ...validation,
        unit_id: Yup.string().nullable().required(t("Unit is required")).test("is-required", t("Unit is required!"), (value) => {
          if (parseInt(value, 10) <= 0) return false;
          return true;
        })
      });
    }

    if (parseInt(values.Type_id) === 0) {
      return Yup.string().nullable();
    }

    return Yup.object().shape(validation);
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const persitBuildings = () => {
    if (globalOrgId > 0) {
      dispatch(
        getAllBuildings({
          id: globalOrgId != -1 ? globalOrgId : orgId,
        })
      );
    }
  };

  let initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    cell_phone: "",
    home_phone: "",
    role_id: 0,
    type_id: 0,
    avatar: "",
    building_id: "",
    org_id: "",
    start_date: "",
    end_date: "",
    amount: 0,
    insurance_doc: "",
    lease_doc: ""
  };

  useEffect(() => {
    dispatch(getAllBuildings({ id: localStorage.getItem("orgId")}));
  }, []);

  const setInitialValues = () => {
    // Clearing error on opening modal
    dispatch(clearUserError());
    // calling so when user comes from unit from if he was in another org get buildings of that org so populate the buildingDD
    Object.keys(unitForUser).length &&
      dispatch(getAllBuildings({ id: unitForUser.org_id }));

    //calling below api to get unit of building for which unit was created not get units of that building prepopulated in the DD
    Object.keys(unitForUser).length &&
      dispatch(
        getDDUnits({
          id: unitForUser?.building_id
        })
      );

    initialValues.role_id = Object.keys(unitForUser).length ? 1 : 0;

    if (unitForUser?.building_id){
      initialValues.building_id = unitForUser.building_id;
    } else {
      initialValues.building_id = parseInt(roleId) < 2 ? building_id : "";
    }

    if (unitForUser?.org_id){
      initialValues.org_id = unitForUser.org_id;
    } else {
      initialValues.org_id = parseInt(roleId) < 99 ? orgId : "";
    }
    
    initialValues.unit_id = null;
  };

  useEffect(() => {
    setInitialValues();
  }, [roleId]);

  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (editorRef.current && newAvatar.image) {
      editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
        const imgData = new FormData();
        imgData.append("image", blob, newAvatar.name);
        dispatch(
          addAvatar({
            body: imgData,
            success: (res) => {
              submitAddUserForm(values, res.data.filename);
            },
            fails: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t("Avatar update failed!"),
                  modal: "errorToast"
                })
              );
              setIsSubmitting(false);
            }
          })
        );
      });
    } else {
      submitAddUserForm(values);
    }
  };
  const submitAddUserForm = (values, filename) => {
    const role_id = parseInt(values.role_id);
    const type_id = parseInt(values.type_id);
    const building_id = parseInt(values.building_id);
    const org_id = parseInt(values.org_id);
    const unit_id = parseInt(values.unit_id);
    const start_date =
      unit_id > 0 ? handleDate(values.start_date) : "";
    const end_date =
      unit_id > 0 ? handleDate(values.end_date) : "";
    const amount = unit_id > 0 ? values.amount : 0;

    const cell_phone =
      values.cell_phone === "" ? "" : values.cell_phone.match(/\d/g).join("");
    const home_phone =
      values.home_phone === "" ? "" : values.home_phone.match(/\d/g).join("");
    const userData = {
      last_name: values.last_name.trim(),
      first_name: values.first_name.trim(),
      email: values.email.trim(),
      cell_phone,
      home_phone,
      building_id,
      avatar: filename ? filename : defaultImgCDN,
      role_id,
      org_id,
      type_id,
      unit_id,
      move_in: start_date,
      move_out: end_date,
      rent: amount,
    };

    if (roleId === UserRole.SUPER_ADMIN && !sendWelcomeEmail) {
      userData.pending = true;
    }

    dispatch(
      addUser({
        body: userData,
        success: () => {
          if (searchFields.query){
            dispatch(
              searchUsers({
                ...searchFields,
                search_col: selectedField["user"]?.value,
                building: globalBuildingId,
                org: globalOrgId
              })
            )
          }else{
            dispatch(
              searchUsers({
                page: searchFields.page,
                org: globalOrgId != -1 ? globalOrgId : orgId,
                building: globalBuildingId,
                status: searchFields.status
              })
            );
          }

          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t("User has been successfully added!")
            })
          );
          dispatch(
            changeOpenedModal({
              modal: ""
            })
          );
          setNewAvatar({
            image: null,
            name: ""
          });
          dispatch(
            getAllBuildings({
              id: localStorage.getItem("orgId"),
            })
          );
          dispatch(
            getUnits({
              id: parseInt(values.building_id),
              page: 1
            })
          );
          dispatch(setUserUnit({})); // if creating user for unit flow then empty this object
          dispatch(clearUnitResponse()); // clearing the response of unit created
          persitBuildings();
          setIsSubmitting(false);
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t("User creation failed!"),
              modal: "errorToast"
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };
  const onClose = () => {
    setNewAvatar({
      image: null,
      name: ""
    });
    dispatch(setUserUnit({})); // if creating user for unit flow then empty this object
    dispatch(clearUnitResponse()); // clearing the response of unit created
    persitBuildings();
    dispatch(
      changeOpenedModal({
        modal: ""
      })
    );
    dispatch(clearUserError());
    setIsSubmitting(false);
  };

  const handleDate = (date) => {
    const splitDate = date.split("-"); /// It will return array in this form ["2021","12","26"]
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  };

  const inputOrder = [
    "first_name",
    "last_name",
    "email",
    "cell_phone",
    "home_phone",
    "org_id",
    "building_id",
    "unit_id",
    "start_date",
    "end_date"
  ];

  //generalized onChange function to use in all inputs
  const handleInputChange = (event, touched, setTouched, handleChange) => {
    setTouched({ ...touched, [event.target.name]: true });
    handleChange(event);
  };
  //prevent triggering errors if no input inserted
  const handleInputOnBlur = (event, values, touched, handleBlur) => {
    const inputName = event.target.name;
    if (values[inputName] && !touched[inputName]) handleBlur(event);
  };
  //trigger errors of required fields present before the input selected if any
  const handleInputOnFoucs = (event, errors, touched, setTouched) => {
    const inputName = event.target.name;
    const inputIndex = inputOrder.indexOf(inputName);
    if (inputIndex > 0) {
      const newTouched = {};
      inputOrder.slice(0, inputIndex).forEach((name) => {
        if (errors[name]) newTouched[name] = true;
      });
      setTouched({ ...touched, ...newTouched });
    }
  };

  const filterUserRoleHelper = (userRole) => {
    if(userRole === UserRole.VENDOR)
      return (map) => map.id <= UserRole.USER || map.id === UserRole.VENDOR
    
      return (map) => map.id <= userRole || map.id === UserRole.VENDOR
  };

  const { config } = useSelector((state) => state.auth);

  useEffect(() => {
    if (parseInt(roleId) != UserRole.SUPER_ADMIN && config.org_allow_edits == false) {
      setAllowUserCreation(false);
    }
  }, [orgId]);

  const phoneStyle = {
    appearence: "none",
    width: "180px",
    border: "1px solid",
    backgroundPosition: "right calc(0.375em + 0.1875rem) center",
    marginBottom: "5px",
    borderColor: "#2eb85c",
    backgroundColor: "#fff !important",
    opacity: 1,
    boxSizing: "border-box",
    padding: "6px 33px 6px 12px",
    borderRadius: "3px"
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate(validationSchema)}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        errors,
        dirty,
        isValid,
        touched,
        resetForm,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setTouched,
        setValues,
      }) =>
        modal === "AddUser" && (
          <CForm onSubmit={handleSubmit} noValidate name="AddUserForm">
            <div className="createUserForm">
              <CModal
                show={modal === "AddUser" ? true : false}
                onClose={() => {
                  onClose();
                  resetForm({});
                }}
                size=""
                closeOnBackdrop={false}
              >
                <CModalHeader closeButton>
                  <CModalTitle>{t("Add new user")}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <CFormGroup row className="roleTypeDiv">
                    <CCol className="mb-3">
                      <CLabel htmlFor="role_id">{t("Role")}</CLabel>
                      <CSelect
                        onBlur={handleBlur}
                        onChange={(e)=>{
                          handleChange(e);
                          if(parseInt(e.target.value) !== UserRole.USER) {
                            setFieldValue('type_id', '0');
                          }
                        }}
                        custom
                        disabled={Object.keys(unitForUser).length}
                        valid={!errors.role_id && touched.role_id}
                        invalid={touched.role_id && !!errors.role_id}
                        value={values.role_id ? parseInt(values.role_id) : 0}
                        name="role_id"
                        id="role_id"
                      >
                        <option value={0}>{t("Please select")}</option>
                        {ROLE_MAP.filter(filterUserRoleHelper(roleId)).map(
                          (role, index) => {
                            if (
                              role.value == "User" &&
                              allowUserCreation == false
                            )
                              return;
                            return (
                              <option key={index} value={role.id}>
                                {role.value}
                              </option>
                            );
                          }
                        )}
                      </CSelect>
                      <CInvalidFeedback>{errors.role_id}</CInvalidFeedback>
                    </CCol>
                    { parseInt(values.role_id) === UserRole.USER &&
                      <CCol className="mb-3">
                      <CLabel htmlFor="type_id">{t("Type")}</CLabel>
                      <CSelect
                        onChange={(e) => {
                          setFieldValue("type_id", e.target.value);
                          // setFieldValue("unit_id", null);
                        }}
                        onBlur={handleBlur}
                        custom
                        valid={!errors.type_id && touched.type_id}
                        invalid={touched.type_id && !!errors.type_id}
                        name="type_id"
                        id="type_id"
                      >
                        <option value={0}>{t("Please select")}</option>
                        {USER_TYPE_MAP.map((type, index) => {
                          if (
                            parseInt(values.role_id) === 1 &&
                            (type.id === 4 || type.id === 3)
                          ) {
                            return null;
                          } else if (
                            parseInt(values.role_id) !== 1 &&
                            (type.id === 1 || type.id === 2)
                          ) {
                            return null;
                          }
                          return (
                            <option key={index} value={type.id}>
                              {type.value}
                            </option>
                          );
                        })}
                      </CSelect>
                      <CInvalidFeedback>{errors.type_id}</CInvalidFeedback>
                    </CCol>
                    }
                  </CFormGroup>
                  {parseInt(values.role_id) && (parseInt(values.role_id) !== UserRole.USER || parseInt(values.type_id)) ? (
                    <div className="userSubBody">
                      <CFormGroup>
                        <AvatarCropper
                          name={"add-user"}
                          avatar={defaultImgCDN}
                          newAvatarState={{
                            newAvatar,
                            setNewAvatar
                          }}
                          editorRef={editorRef}
                          withPreview
                          rounded
                          setFormValue={(cancel, filename) => {
                            setFieldValue(
                              "avatar",
                              cancel ? defaultImgCDN : filename
                            );
                          }}
                        />
                      </CFormGroup>
                      <CFormGroup row>
                        <CCol>
                          <CLabel htmlFor="nf-first-name">
                            {t("First name")}
                          </CLabel>
                          <CInputGroup className="mb-3">
                            <CInput
                              type="text"
                              id="first_name"
                              name="first_name"
                              placeholder={t("First name")}
                              autoComplete="text"
                              valid={!errors.first_name}
                              invalid={
                                touched.first_name && !!errors.first_name
                              }
                              required
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\s{2,}/,
                                  " "
                                ).trimStart();
                                handleInputChange(
                                  e,
                                  touched,
                                  setTouched,
                                  handleChange
                                );
                              }}
                              onBlur={(e) =>
                                handleInputOnBlur(
                                  e,
                                  values,
                                  touched,
                                  handleBlur
                                )
                              }
                              onFocus={(e) =>
                                handleInputOnFoucs(
                                  e,
                                  errors,
                                  touched,
                                  setTouched
                                )
                              }
                              value={values.first_name}
                            />
                            <CInvalidFeedback>
                              {errors.first_name}
                            </CInvalidFeedback>
                          </CInputGroup>
                        </CCol>
                        <CCol>
                          <CLabel htmlFor="nf-last-name">
                            {t("Last name")}
                          </CLabel>
                          <CInputGroup className="mb-3">
                            <CInput
                              type="text"
                              id="last_name"
                              name="last_name"
                              placeholder={t("Last name")}
                              autoComplete="text"
                              valid={!errors.last_name}
                              invalid={touched.last_name && !!errors.last_name}
                              required
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(
                                  /\s{2,}/,
                                  " "
                                ).trimStart();
                                handleInputChange(
                                  e,
                                  touched,
                                  setTouched,
                                  handleChange
                                );
                              }}
                              onBlur={(e) =>
                                handleInputOnBlur(
                                  e,
                                  values,
                                  touched,
                                  handleBlur
                                )
                              }
                              onFocus={(e) =>
                                handleInputOnFoucs(
                                  e,
                                  errors,
                                  touched,
                                  setTouched
                                )
                              }
                              value={values.last_name}
                            />
                            <CInvalidFeedback>
                              {errors.last_name}
                            </CInvalidFeedback>
                          </CInputGroup>
                        </CCol>
                      </CFormGroup>
                      <CFormGroup>
                        <CLabel htmlFor="nf-email">{t("Email address")}</CLabel>
                        <CInputGroup className="mb-3">
                          <CInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder={t("Email")}
                            autoComplete="text"
                            valid={!errors.email}
                            invalid={touched.email && !!errors.email}
                            required
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                touched,
                                setTouched,
                                handleChange
                              )
                            }
                            onBlur={(e) =>
                              handleInputOnBlur(e, values, touched, handleBlur)
                            }
                            onFocus={(e) =>
                              handleInputOnFoucs(e, errors, touched, setTouched)
                            }
                            value={values.email}
                          />
                          <CInvalidFeedback>{errors.email}</CInvalidFeedback>
                        </CInputGroup>
                      </CFormGroup>
                      <CFormGroup row style={{ alignItems: "baseline" }}>
                        <CCol>
                          <CLabel>{t("Phone number")}</CLabel>
                          <CInputGroup className="mb-3">
                            <Field
                              name="cell_phone"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={phoneNumberMask}
                                  id="cell_phone"
                                  style={phoneStyle}
                                  placeholder="Cell Phone"
                                  type="text"
                                  showMask={true}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onFocus={(e) =>
                                    handleInputOnFoucs(
                                      e,
                                      errors,
                                      touched,
                                      setTouched
                                    )
                                  }
                                  className={
                                    errors.cell_phone && touched.cell_phone
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                              )}
                            />
                            <small className="text-danger">
                              {touched.cell_phone && errors.cell_phone}
                            </small>
                          </CInputGroup>
                        </CCol>
                        <CCol>
                          <CLabel htmlFor="nf-home-number">
                            {t("Home phone number")}
                          </CLabel>
                          <CInputGroup className="mb-3">
                            <Field
                              name="home_phone"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={phoneNumberMask}
                                  id="home_phone"
                                  style={phoneStyle}
                                  placeholder={t("Home Number")}
                                  type="text"
                                  showMask={true}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onFocus={(e) =>
                                    handleInputOnFoucs(
                                      e,
                                      errors,
                                      touched,
                                      setTouched
                                    )
                                  }
                                  className={
                                    errors.home_phone && touched.home_phone
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                              )}
                            />
                            <small className="text-danger">
                              {touched.home_phone && errors.home_phone}
                            </small>
                          </CInputGroup>
                        </CCol>
                      </CFormGroup>
                      { 
                        roleId === UserRole.SUPER_ADMIN && 
                        <CFormGroup>
                          <CCol>
                            <CInputCheckbox checked={sendWelcomeEmail} onChange={() => setSendWelcomeEmail(prev => !prev)}></CInputCheckbox>
                            <CLabel>{t("Send welcome email")}</CLabel>
                          </CCol>
                        </CFormGroup>
                      }
                      <OrganizationDropDown
                        showAdd={true}
                        values={values}
                        errors={errors}
                        isUser={true}
                        isDisable={
                          Object.keys(unitForUser).length ? true : false
                        }
                        handleBlur={handleBlur}
                        handleChange={(e) => handleChange(e)}
                        handleOnFocus={(e) =>
                          handleInputOnFoucs(e, errors, touched, setTouched)
                        }
                        Organizations={AllOrganizations}
                        roleId={roleId}
                        touched={touched}
                        setValues={setValues}
                      />

                      {touched.org_id && !!errors.org_id && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block", marginTop: "-10px" }}
                        >
                          {errors.org_id}
                        </div>
                      )}

                      {allBuildings && !errors.org_id && (
                        <BuildingDropDown
                          showAdd={true}
                          values={values}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          disable={
                            Object.keys(unitForUser).length ? true : false
                          }
                          touched={touched}
                          buildings={allBuildings}
                          orgId={values.org_id}
                          setTouched={setTouched}
                          isLoading={status === requestPending(GET_ALL_BUILDINGS)}
                          setFieldValue={setFieldValue}
                        />
                      )}

                      {(parseInt(values.type_id) === UserType.RESIDENT ||
                        parseInt(values.type_id) === UserType.CORESIDENT) &&
                      !errors.org_id &&
                      !errors.building_id &&
                      values.building_id ? (
                        <CFormGroup>
                          <CLabel htmlFor="nf-unit">{t("Unit")}</CLabel>
                          <CInputGroup className="mb-3">
                            <CSelect
                              disabled={
                                values.building_id === "" || // if building is not selected
                                !unitsForDD.length //if units length is zero
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              invalid={touched.unit_id && !!errors.unit_id}
                              custom
                              defaultValue={""}
                              name="unit_id"
                              id="unit_id"
                            >
                              <option value={""}>
                                {unitsForDD.length ? t("Please select unit") : t("No unit available")}
                              </option>
                              {unitsForDD &&
                                unitsForDD.map((unit) => (
                                  <option key={unit.id} value={unit.id}>
                                    Unit {unit.unit_num}
                                  </option>
                                ))}
                            </CSelect>
                            {errors.unit_id && (
                              <div
                                className="invalid-feedback"
                              >
                                {errors.unit_id}
                              </div>
                            )}
                          </CInputGroup>
                        </CFormGroup>
                      ) : (
                        <></>
                      )}
                      {parseInt(values.type_id) === UserType.RESIDENT &&
                      parseInt(values.unit_id, 10) > 0 ? (
                        <>
                          <CRow>
                            <CCol>
                              <CFormGroup>
                                <CLabel htmlFor="lease-start-date">
                                  {t("Move in")}
                                </CLabel>

                                <CInput
                                  type="date"
                                  onKeyDown={(e) => e.preventDefault()}
                                  min={new Date().toISOString().split("T")[0]}
                                  id="start_date"
                                  name="start_date"
                                  required
                                  value={values.start_date}
                                  onBlur={handleBlur}
                                  onFocus={(e) =>
                                    handleInputOnFoucs(
                                      e,
                                      errors,
                                      touched,
                                      setTouched
                                    )
                                  }
                                  onChange={(e) => {
                                    setFieldValue("start_date", e.target.value);

                                    if (
                                      Date.parse(String(e.target.value)) >=
                                      Date.parse(String(values.end_date))
                                    ) {
                                      setStartDateError(
                                        t(
                                          "Start date should be smaller then end date"
                                        )
                                      );
                                    } else {
                                      setStartDateError("");
                                    }
                                  }}
                                  invalid={
                                    touched.start_date && !!errors.start_date
                                  }
                                  valid={
                                    touched.start_date && !errors.start_date
                                  }
                                />
                                <CInvalidFeedback>
                                  {errors.start_date}
                                </CInvalidFeedback>
                              </CFormGroup>
                            </CCol>
                            <CCol>
                              <CFormGroup>
                                <CLabel htmlFor="lease-end-date">
                                  {t("Move out")}
                                </CLabel>
                                <CInput
                                  type="date"
                                  onKeyDown={(e) => e.preventDefault()}
                                  min={values.start_date}
                                  id="end_date"
                                  name="end_date"
                                  value={values.end_date}
                                  required
                                  onBlur={handleBlur}
                                  onFocus={(e) =>
                                    handleInputOnFoucs(
                                      e,
                                      errors,
                                      touched,
                                      setTouched
                                    )
                                  }
                                  onChange={(e) => {
                                    setFieldValue("end_date", e.target.value);

                                    if (
                                      Date.parse(String(e.target.value)) <=
                                      Date.parse(String(values.start_date))
                                    ) {
                                      setStartDateError(
                                        t(
                                          "Start date should be smaller then end date"
                                        )
                                      );
                                    } else {
                                      setStartDateError("");
                                    }
                                  }}
                                  invalid={
                                    touched.end_date && !!errors.end_date
                                  }
                                  valid={touched.end_date && !errors.end_date}
                                />
                                <CInvalidFeedback>
                                  {errors.end_date}
                                </CInvalidFeedback>
                              </CFormGroup>
                            </CCol>
                          </CRow>
                          <p className="text-danger">{startDateError}</p>
                          <CFormGroup>
                            <CLabel>{t("Monthly rent")}</CLabel>
                            <CInput
                              type="number"
                              id="amount"
                              placeholder={t("Amount")}
                              name="amount"
                              value={values.amount}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                e.target.value === 0
                                  ? setFieldValue("amount", 0)
                                  : setFieldValue(
                                    "amount",
                                    parseInt(e.target.value)
                                  )
                              }
                              invalid={touched.amount && !!errors.amount}
                              valid={!errors.amount}
                            />
                            <CInvalidFeedback>{errors.amount}</CInvalidFeedback>
                          </CFormGroup>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </CModalBody>
                <CCardFooter>
                  <p className="text-danger d-block w-100 m-0">{error}</p>
                  <CFormGroup className="text-right m-0">
                    <CButton
                      className="mr-2"
                      onClick={() => {
                        onClose();
                        resetForm();
                      }}
                    >
                      {t("Cancel")}
                    </CButton>
                    <LoadingButton
                      cstmClass={
                        !dirty || !isValid || startDateError !== ""
                          ? "btn"
                          : "btn-primary"
                      }
                      type="submit"
                      styling={
                        isSubmitting || !dirty || !isValid || startDateError !== ""
                          ? { cursor: "not-allowed" }
                          : {}
                      }
                      disabled={isSubmitting || !dirty || !isValid || startDateError !== ""}
                      showLoader={isSubmitting}
                      text={t("Add")}
                    />
                  </CFormGroup>
                </CCardFooter>
              </CModal>
            </div>
          </CForm>
        )
      }
    </Formik>
  );
};

export default AddUser;
