import styled from 'styled-components';

export const Flex = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;

  ${({ display }) =>
    display &&
    `
    display: ${display};
  `}

  ${({ direction }) =>
    direction &&
    `
    flex-direction: ${direction};
  `}

  ${({ align }) =>
    align &&
    `
    align-content: ${align};
  `}

  ${({ alignItems }) =>
    alignItems &&
    `
    align-items: ${alignItems};
  `}

  ${({ justify }) =>
    justify &&
    `
    justify-content: ${justify};
  `}

  ${({ wrap }) =>
    wrap &&
    `
    flex-wrap: ${wrap};
  `}

  ${({ gap }) =>
    gap &&
    `
    gap: ${gap};
  `}

  ${({ gapX }) =>
    gapX &&
    `
    row-gap: ${gapX};
  `}

  ${({ gapY }) =>
    gapY &&
    `
    column-gap: ${gapY};
  `}
`;
