import {
  SHOW_MESSAGE,
  SET_REGISTRATION_TOKEN,
  GET_NOTIFICATIONS,
  GET_PAGED_NOTIF,
  SET_NOTIFICATIONS,
  REGISTER_FCM_TOKEN,
  SET_FCM_TOKEN,
  HIDE_MESSAGE,
  SET_NOTIF_PAGE
} from "../constants";
import { createReducer } from "@reduxjs/toolkit";
import {
  requestSuccess,
  requestFail,
  requestPending
} from "../../utils/status";

const initialState = {
  receivedNotification: {},
  registrationToken: null,
  notifications: [],
  pagedNotifications: [],
  status: "",
  error: "",
  fcmTokenRegistered: false,
  currentPage: 1
};

export default createReducer(initialState, {
  [HIDE_MESSAGE]: (state) => {
    localStorage.setItem("newNotification", JSON.stringify({}));
    return {
      ...state,
      newNotification: []
    };
  },

  [SHOW_MESSAGE]: (state, { payload }) => {
    localStorage.setItem(
      "newNotification",
      JSON.stringify(payload.notification)
    );

    return {
      ...state,
      newNotification: payload.notification
    };
  },

  [SET_NOTIFICATIONS]: (state, { payload }) => ({
    ...state,
    notifications: payload.notifications
  }),
  [SET_NOTIF_PAGE]: (state, { payload }) => ({
    ...state,
    currentPage: payload.currentPage
  }),
  [requestSuccess(GET_PAGED_NOTIF)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(GET_PAGED_NOTIF),
      pagedNotifications: payload,

      error: null
    };
  },

  [requestPending(GET_PAGED_NOTIF)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_PAGED_NOTIF)
  }),

  [requestFail(GET_PAGED_NOTIF)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_PAGED_NOTIF),
    pagedNotifications: [],
    error: payload
  }),

  [requestSuccess(GET_NOTIFICATIONS)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(GET_NOTIFICATIONS),
      notifications: payload,
      pagedNotifications: state.currentPage === 1 ? payload : state.pagedNotifications,
      error: null
    };
  },

  [requestPending(GET_NOTIFICATIONS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_NOTIFICATIONS)
  }),

  [requestFail(GET_NOTIFICATIONS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_NOTIFICATIONS),
    notifications: [],
    pagedNotifications: [],
    error: payload
  }),

  [requestSuccess(REGISTER_FCM_TOKEN)]: (state, { payload }) => {
    return {
      ...state,
      fcmTokenRegistered: true,
      status: requestSuccess(REGISTER_FCM_TOKEN)
    };
  },

  [requestPending(REGISTER_FCM_TOKEN)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REGISTER_FCM_TOKEN)
  }),

  [requestFail(REGISTER_FCM_TOKEN)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REGISTER_FCM_TOKEN)
  }),

  [requestSuccess(SET_FCM_TOKEN)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(SET_FCM_TOKEN)
    };
  },

  [requestPending(SET_FCM_TOKEN)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SET_FCM_TOKEN)
  }),

  [requestFail(SET_FCM_TOKEN)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SET_FCM_TOKEN)
  }),

  [SET_REGISTRATION_TOKEN]: (state, { payload }) => {
    return {
      ...state,
      registrationToken: payload
    };
  }
});
