import React, { useRef } from 'react';
import styled from 'styled-components';
import { Palette } from '../utils/palette';
import { Fonts } from '../utils';
import { FiSearch } from 'react-icons/fi';

export function TextInput({
  height,
  width,
  fontSize,
  fontWeight,
  fontFamily,
  background,
  placeholder,
  type,
  value,
  onChange,
  error,
  cssOverride,
  disabled,
  includedSearchIcon = false,
  ...props
}) {
  const inputRef = useRef(null);
  return (
    <InputContainer>
      {includedSearchIcon && <StyledIcon onClick={() => inputRef.current.focus()} />}
      <StyledInput
        ref={inputRef}
        height={height}
        width={width}
        fontSize={fontSize}
        fontWeight={fontWeight}
        background={background}
        placeholder={placeholder}
        fontFamily={fontFamily}
        type={type}
        value={value}
        onChange={onChange}
        error={error}
        cssOverride={cssOverride}
        disabled={disabled}
        {...props}
      />
    </InputContainer>
  );
}

const InputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  background: ${({ background }) => background || Palette.SOLITUDE};
  color: ${Palette.BLACK_50};
  border: none;
  border-radius: 10px;
  height: ${({ height }) => height || '34px'};
  width: ${({ width }) => width || '100%'};
  padding: 0px 10px 0px 24px;
  font: ${({ fontWeight, fontSize, fontFamily }) =>
    `${fontWeight || '600'} ${fontSize || '16px'} ${fontFamily || Fonts.OPEN_SANS_FONT}`};

  :disabled {
    opacity: 50%;
  }

  :focus {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
    outline: none;

    border: ${Palette.BORDER_OUTLINE} solid 0.5px !important;

    ::placeholder {
      color: ${Palette.BLACK};
    }
  }

  ${({ error }) => error && `border: ${Palette.ERROR} solid 2px;`}

  ${({ cssOverride }) => cssOverride};
`;

const StyledIcon = styled(FiSearch)`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: ${Palette.CUSTOM_GRAY};
  font-size: 24px;
  cursor: pointer;
`;
