import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { RiPencilFill } from 'react-icons/ri';
import { Palette } from '../../components/utils';
import { Button } from '../../components/ui/Button';
import { closeActiveModal } from '../../helper/modals';
import { ButtonVariant } from '../../components/ui/ButtonVariant';

const SettingsEditButton = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      isPrimary={false}
      cssOverride={'width: 42px; height: 42px;'}
      icon={<RiPencilFill size={20} color={Palette.HAZY_BLUE} />}
      {...props}
    />
  );
});
SettingsEditButton.displayName = 'SettingsEditButton';

const ModalCloseButton = React.forwardRef(({ className, onCloseCallback, ...props }, ref) => {
  return (
    <ButtonVariant
      ref={ref}
      variant="outline"
      size="icon"
      onClick={() => closeActiveModal(onCloseCallback)}
      {...props}>
      <IoCloseOutline size={20} color={Palette.BLACK_50} />
    </ButtonVariant>
  );
});
ModalCloseButton.displayName = 'ModalCloseButton';

export { SettingsEditButton, ModalCloseButton };
