import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { changePassword } from '../../redux/actions/profile';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalLightText,
  SettingsModalPasswordInput
} from './SettingsModals';
import { setToast } from '../../redux/actions/window';
import { Palette } from '../../components/utils';
import { ErrorMessage } from '../../components/ErrorMessage';
import { closeActiveModal } from '../../helper/modals';
import { ModalButtons, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { ErrorText } from '../../components/utils/AvatarCropper';

const VALID_PASSWORD = Yup.string()
  .required('Password is required')
  .min(8, 'Password should be at least 8 characters long with both letters and numbers or symbols.')
  .max(24, 'Password cannot exceed 24 characters');

const SecurityPreferencesModal = () => {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.headerModal);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // TODO: Get password requirements from BE
  const schema = Yup.object().shape({
    password: VALID_PASSWORD,
    newPassword: VALID_PASSWORD,
    confirmNewPassword: Yup.string()
      .required('Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });

  const onSubmit = (data) => {
    setIsSubmitting(true);
    dispatch(
      changePassword({
        body: {
          current_password: data.password,
          password: data.confirmNewPassword
        },
        success: () => {
          setIsSubmitting(false);
          closeActiveModal();
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Password has been updated!')
            })
          );
        },
        fail: (res) => {
          setIsSubmitting(false);
          if (res?.data?.message) {
            setError('serverError', { type: 'custom', message: res.data.message });
          } else {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Failed to change password!'),
                modal: 'errorToast'
              })
            );
          }
        }
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledModal show={modal === 'SecurityPreferencesModal'} closeOnBackdrop={false}>
        <ModalHeader title={t('Security Preferences')} />
        <StyledModalBody>
          <SettingsModalLightText
            cssOverride={`color: ${Palette.BLACK}; text-align: start;`}
            className="mt-2 mb-2">
            Your password must be at least 8 characters and and should include a combination of
            numbers, letters and special characters ($%@%).
          </SettingsModalLightText>

          <InputSeparator>
            <SettingsModalBoldText>{t('Current Password')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalPasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.password}
                    placeholder={t('Current Password')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('New Password')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="newPassword"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalPasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.newPassword}
                    placeholder={t('New Password')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="newPassword"
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Confirm Password')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="confirmNewPassword"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalPasswordInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.confirmNewPassword}
                    placeholder={t('Confirm Password')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="confirmNewPassword"
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <ErrorMessage
            errors={errors}
            name="serverError"
            render={({ message }) => <ErrorText>{message}</ErrorText>}
          />

          <ModalButtons
            leftButtonText={'Close'}
            leftButtonClick={closeActiveModal}
            rightButtonText={'Confirm'}
            disabled={isSubmitting}
          />
        </StyledModalBody>
      </StyledModal>
    </form>
  );
};

export default SecurityPreferencesModal;
