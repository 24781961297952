import { call, fork, take, takeLatest } from "redux-saga/effects";
import {
  GET_OFFERS,
  GET_OFFER,
  EDIT_OFFER,
  ADD_OFFER,
  ADD_LOGO,
  DELETE_OFFER,
  SEARCH_OFFERS,
  GET_CATEGORIES
} from "../constants";
import { businessId } from "../../helper";

import apiCall from "../../utils/apiCall";

const addLogo = apiCall({
  type: ADD_LOGO,
  method: "post",
  path: "/images",
  isFormData: true
});

const getOffers = apiCall({
  type: GET_OFFERS,
  method: "get",
  path: ({
    page,
    buildingId,
    orgId,
    sort,
    sortOrder,
    perPage,
    active,
    expired
  }) => {
    let url = `/offers?page=${page}`;
    if (orgId) {
      url += `&org=${orgId}`;
    }
    if (buildingId) {
      url += `&building=${buildingId}`;
    }
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (sortOrder) {
      url += `&sort_order=${sortOrder}`;
    }
    if (perPage) {
      url += `&per_page=${perPage}`;
    }
    if (active === true || active === false) {
      url += `&active=${active}`;
    }
    if (expired === true || expired === false) {
      url += `&expired=${expired}`;
    }
    return url;
  }
});

const searchOffers = apiCall({
  type: SEARCH_OFFERS,
  method: "get",
  newUrl: true,
  path: ({
    query,
    page = 1,
    perPage = 20,
    search_col = null,
    org,
    building,
  }) => {
    let url = `/search?entity=offer&q=${query}&page=${page}&per_page=${perPage}`;
    if (search_col) {
      url += `&search_col=${search_col}`;
    }
    if (org) {
      url += `&org=${org}`;
    }
    if (building) {
      url += `&building=${building}`;
    }
    return url;
  }
});

const getCustomOffers = apiCall({
  type: GET_OFFER,
  method: "get",
  path: ({ id }) => `/offers/${id}`
});

const addOffer = apiCall({
  type: ADD_OFFER,
  method: "post",
  path: "/offers"
});

const editOffer = apiCall({
  type: EDIT_OFFER,
  method: "put",
  path: ({ id }) => `/offers/${id}`
});

const deleteOffer = apiCall({
  type: DELETE_OFFER,
  method: "delete",
  path: ({ id }) => `/offers/${id}`
});

const getCategories = apiCall({
  type: GET_CATEGORIES,
  method: "GET",
  path: `/metadata?name=offer-categories`
});

function* watchAddOffer() {
  while (true) {
    const action = yield take(ADD_OFFER);
    yield call(addOffer, action);
  }
}

function* offersSaga() {
  yield takeLatest(GET_OFFERS, getOffers);
  yield takeLatest(GET_OFFER, getCustomOffers);
  yield takeLatest(GET_CATEGORIES, getCategories);
  yield takeLatest(EDIT_OFFER, editOffer);
  yield takeLatest(ADD_LOGO, addLogo);
  yield takeLatest(DELETE_OFFER, deleteOffer);
  yield takeLatest(SEARCH_OFFERS, searchOffers);
  yield call(watchAddOffer);
}

export default offersSaga;
