import { CDropdownMenu, CDropdownItem } from '@coreui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ModalBookingCancel from '../views/bookings/ModalCancelConfirmation';
import ModalSentEmailDetail from '../views/conversation/sent/ModalSentMsgsDetail';
import ModalBooking from '../views/bookings/ModalBooking';
import ModalBookingDetails from '../views/bookings/ModalBookingDetails';
import AddOffer from '../views/offers/AddOffer';
import AddBuilding from '../views/properties/Building/AddBuilding';
import BuildingDetails from '../views/properties/Building/BuildingDetails';
import EditBuilding from '../views/properties/Building/EditBuilding';
import AddOrganization from '../views/properties/Organization/AddOrganization';
import EditOrganization from '../views/properties/Organization/EditOrganization';
import OrganizationDetails from '../views/properties/Organization/OrganizationDetails';
import AddAmenity from '../views/properties/Unit&Amenities/Amenities/AddAmenities';
import AddUnit from '../views/properties/Unit&Amenities/Unit/AddUnit';
import EditRoom from '../views/properties/Unit&Amenities/Unit/EditRoom';
import AddUnitsFromCSVModal from '../views/properties/Unit&Amenities/Unit/AddUnitsFromCSVModal';
import OperatorLogs from '../views/properties/Unit&Amenities/Unit/OperatorLogs';
import UnitDetails from '../views/properties/Unit&Amenities/Unit/UnitDetails';
import UnitLockDetails from '../views/properties/Unit&Amenities/Unit/UnitLockDetails';
import UnitStatistics from '../views/properties/Unit&Amenities/Unit/UnitStatistics';
import ServiceCreate from '../views/services/ServiceCreate';
import ServiceDetail from '../views/services/ServiceDetail';
import ServiceDetailEdit from '../views/services/ServiceDetailEdit';
import AddUser from '../views/users/AddUser';
import AddUsersFromCSV from '../views/users/AddUsersFromCSV';
import EditUser from '../views/users/EditUser';
import UserDetailModal from '../views/users/UserDetailModal';
import EditModalContent from '../views/bookings/BlankModalContent';
import PMActivities from '../views/pm-analytics/orgs/PMActivities';
import GlobalPushNotificationModal from '../views/pushnotification/GlobalPushNotificationModal';
import CreateProfileImageModal from '../views/settings/CreateProfileImageModal';
import CreateProfileModalFinal from '../views/settings/CreateProfileModalFinal';
import SecurityPreferencesModal from '../views/settings/SecurityPreferencesModal';
import AddOrganizationModalStep1 from '../views/manage-users/AddOrganizationModalFlow/AddOrganizationModalStep1';
import AddOrganizationModalStep2 from '../views/manage-users/AddOrganizationModalFlow/AddOrganizationModalStep2';
import AddOrganizationModalStep3 from '../views/manage-users/AddOrganizationModalFlow/AddOrganizationModalStep3';
import AddOrganizationModalStep4 from '../views/manage-users/AddOrganizationModalFlow/AddOrganizationModalStep4';
import AddNewUserTypeModalStep1 from '../views/manage-users/AddUserTypeModalFlow/AddNewUserTypeModalStep1';
import AddNewUserTypeModalStep2 from '../views/manage-users/AddUserTypeModalFlow/AddNewUserTypeModalStep2';
import AddNewUserTypeModalStep3 from '../views/manage-users/AddUserTypeModalFlow/AddNewUserTypeModalStep3';
import AddNewUserTypeModalStep4 from '../views/manage-users/AddUserTypeModalFlow/AddNewUserTypeModalStep4';
import CreateProfileModal from '../views/settings/CreateProfileModal';
import Help from '../components/Help';
import AddDeliveryCode from '../views/delivery-codes/AddDeliveryCode';
import styled from 'styled-components';
import EditDataCenter from '../components/EditDataCenter';
import AddSuite from '../views/properties/Unit&Amenities/Suites/AddSuite';
import EditSuite from '../views/properties/Unit&Amenities/Suites/EditSuite';
import SuiteLockDetails from '../views/properties/Unit&Amenities/Suites/SuiteLockDetails';

const TheHeaderDropDownModal = () => {
  const { modal } = useSelector((state) => state.headerModal);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = Boolean(modal) ? 'hidden' : 'auto';
  }, [modal]);

  return (
    <div>
      {modal === 'serviceDetail' ? (
        <ServiceDetail />
      ) : modal === 'serviceDetailEdit' ? (
        <ServiceDetailEdit />
      ) : modal === 'ModalEditDataCenter' ? (
        <EditDataCenter />
      ) : modal === 'createService' ? (
        <ServiceCreate />
      ) : modal === 'modalbookAmenity' ? (
        <ModalBooking />
      ) : modal === 'modalBookParking' ? (
        <ModalBooking />
      ) : modal === 'modalBookElevator' ? (
        <ModalBooking />
      ) : modal === 'showSentMessageModal' ? (
        <ModalSentEmailDetail />
      ) : modal === 'addBuilding' ? (
        <AddBuilding />
      ) : modal === 'AddOrganization' ? (
        <AddOrganization />
      ) : modal === 'addUnit' ? (
        <AddUnit />
      ) : modal === 'AddUser' ? (
        <AddUser />
      ) : modal === 'AddUnitsFromCSV' ? (
        <AddUnitsFromCSVModal />
      ) : modal === 'AddUsersFromCSV' ? (
        <AddUsersFromCSV />
      ) : modal === 'AddOffer' ? (
        <AddOffer />
      ) : modal === 'addAmenity' ? (
        <AddAmenity />
      ) : modal === 'editBookingLoading' || modal === 'editBooking' ? (
        <EditModalContent />
      ) : modal === 'editUser' ? (
        <EditUser />
      ) : modal === 'editRoom' ? (
        <EditRoom />
      ) : modal === 'unitLockDetails' ? (
        <UnitLockDetails />
      ) : modal === 'unitStatistics' ? (
        <UnitStatistics />
      ) : modal === 'UnitDetail' ? (
        <UnitDetails />
      ) : modal === 'editOrg' ? (
        <EditOrganization />
      ) : modal === 'helpModal' ? (
        <Help />
      ) : modal === 'AddDeliveryCode' ? (
        <AddDeliveryCode />
      ) : modal === 'OperatorLogs' ? (
        <OperatorLogs />
      ) : modal === 'GlobalPushNotification' ? (
        <GlobalPushNotificationModal />
      ) : modal === 'PMActivitiesDetails' ? (
        <PMActivities />
      ) : modal === 'CreateProfileModal' ? (
        <CreateProfileModal />
      ) : modal === 'CreateProfileImageModal' ? (
        <CreateProfileImageModal />
      ) : modal === 'CreateProfileModalFinal' ? (
        <CreateProfileModalFinal />
      ) : modal === 'SecurityPreferencesModal' ? (
        <SecurityPreferencesModal />
      ) : modal === 'AddOrganizationModalStep1' ? (
        <AddOrganizationModalStep1 />
      ) : modal === 'AddOrganizationModalStep2' ? (
        <AddOrganizationModalStep2 />
      ) : modal === 'AddOrganizationModalStep3' ? (
        <AddOrganizationModalStep3 />
      ) : modal === 'AddOrganizationModalStep4' ? (
        <AddOrganizationModalStep4 />
      ) : modal === 'AddNewUserTypeModalStep1' ? (
        <AddNewUserTypeModalStep1 />
      ) : modal === 'AddNewUserTypeModalStep2' ? (
        <AddNewUserTypeModalStep2 />
      ) : modal === 'AddNewUserTypeModalStep3' ? (
        <AddNewUserTypeModalStep3 />
      ) : modal === 'AddNewUserTypeModalStep4' ? (
        <AddNewUserTypeModalStep4 />
      ) : modal === 'addSuite' ? (
        <AddSuite />
      ) : modal === 'editSuite' ? (
        <EditSuite />
      ) : modal === 'suiteLockDetails' ? (
        <SuiteLockDetails />
      ) : (
        <></>
      )}

      <UserDetailModal />
      <BuildingDetails />
      <EditBuilding />
      <OrganizationDetails />
      <ModalBookingDetails />
      <ModalBookingCancel />
    </div>
  );
};

export default TheHeaderDropDownModal;
