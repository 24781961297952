import React from 'react';
import { CFormGroup, CModalFooter } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonBody } from './ui/Button';
import { Palette } from './utils';
import styled from 'styled-components';
import { ErrorText } from './utils/AvatarCropper';

const ModalFooter = ({
  error,
  disabled,
  className,
  style,
  text,
  handleCancel,
  status,
  onClick = () => {}
}) => {
  const { t } = useTranslation();
  const styles = {
    loadingBtn: {
      width: '15px',
      height: '15px',
      marginRight: '5px'
    }
  };

  return (
    <>
      <CModalFooter>
        <div className="text-left w-100 justify-content-start flex mb-3">
          {error && <ErrorText>{error}</ErrorText>}
        </div>
        <StyledCFormGroup className="justify-content-end m-0 flex w-100">
          <Button
            className="mr-2"
            onClick={handleCancel}
            text={t('Cancel')}
            width={'125px'}
            type="button"
          />

          <ButtonBody
            width={'125px'}
            disabled={disabled}
            isPrimary
            color={Palette.ERROR}
            onClick={onClick}
            type="submit">
            <div
              style={styles.loadingBtn}
              className={status ? 'spinner-border' : 'd-none'}
              role="status">
              <span className="visually-hidden"></span>
            </div>
            {text}
          </ButtonBody>
        </StyledCFormGroup>
      </CModalFooter>
    </>
  );
};

const StyledCFormGroup = styled(CFormGroup)`
  text-align: right;
  margin: 0;
  display: flex;
  gap: 10px;
`;

export default ModalFooter;
