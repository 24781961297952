function compareDate(timeString, d) {
  const date = new Date(d);
  // Parse the time string into hours, minutes, and seconds
  const [hoursStr, minutesStr, secondsStr] = timeString.split(':');
  const timeStringSeconds =
    parseInt(hoursStr) * 3600 + parseInt(minutesStr) * 60 + parseInt(secondsStr);

  // Extract hours, minutes, and seconds from the date object
  const dateHours = date.getHours();
  const dateMinutes = date.getMinutes();
  const dateSeconds = date.getSeconds();
  const dateSecondsTotal = dateHours * 3600 + dateMinutes * 60 + dateSeconds;

  return timeStringSeconds < dateSecondsTotal;
}

export function formatTimeString(d) {
  const date = new Date(d);

  const dateHours = date.getHours();
  const dateMinutes = date.getMinutes();
  const dateSeconds = date.getSeconds();

  return `${dateHours.toString().padStart(2, '0')}:${dateMinutes
    .toString()
    .padStart(2, '0')}:${dateSeconds.toString().padStart(2, '0')}`;
}

export function getMinTime(events, defaultMinTime) {
  let minTime = defaultMinTime;

  events.forEach((event) => {
    minTime = compareDate(minTime, event.start) ? minTime : formatTimeString(event.start);
  });

  return minTime;
}

export function getMaxTime(events, defaultMaxTime) {
  let maxTime = defaultMaxTime;

  events.forEach((event) => {
    maxTime = !compareDate(maxTime, event.end) ? maxTime : formatTimeString(event.end);
  });

  return maxTime;
}

export function filterEvent(event, filters) {
  if (!filters.size) return event;

  return event.filter((e) => !filters.has(e.title));
}

export function formatPopoverDate(date) {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  };

  return date.toLocaleDateString('en-US', options);
}
