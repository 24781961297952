import { createReducer } from "@reduxjs/toolkit";
import {
  CLEAR_ERROR,
  GET_ACTIVATION_LOGS,
  GET_ACTIVITIES,
  GET_LOGS,
  GET_REQUEST_TURN_AROUND_TIME,
  SET_CSV_FIELDS,
  SET_CSV_URL,
  GET_TOTAL_METRICS,
  GET_DEALS_STATS,
  GET_DEAL_STATS,
} from "../constants";
import {
  requestSuccess,
  requestFail,
  requestPending
} from "../../utils/status";

export const ModalName = {
  DEAL_STATS: 'DEAL_STATS',
}

const initialState = {
  status: "INIT",
  error: "",
  csvUrl: null,
  csvFields: [],
  logs: [],
  activationLogs: [],
  activationState: "activated",
  totalMetrics: {},
  dealsStats: {},
  dealStats: {},
}

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [SET_CSV_URL]: (state, { payload }) => {
    return {
      ...state,
      csvUrl: payload?.payload,
      activationState: payload?.activationState
    };
  },
  [SET_CSV_FIELDS]: (state, { payload }) => {
    return {
      ...state,
      csvFields: payload
    };
  },
  [requestSuccess(GET_LOGS)]: (state, { payload }) => {
    if ('pagination' in payload) {
      const pagination = {
        current_page: Number(payload.pagination?.['pagination-current-page']),
        from: Number(payload.pagination?.['pagination-from']),
        per_page: Number(payload.pagination?.['pagination-per-page']),
        to: Number(payload.pagination?.['pagination-to']),
        total: Number(payload.pagination?.['pagination-total']),
      };
      return {
        ...state,
        logs: {
          data: payload.data,
          paginate: pagination
        },
        status: requestSuccess(GET_LOGS),
        error: ""
      }
    }
    return {
    ...state,
    logs: payload,
    status: requestSuccess(GET_LOGS),
    error: ""
  }},

  [requestPending(GET_LOGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_LOGS)
  }),

  [requestFail(GET_LOGS)]: (state, { payload }) => ({
    ...state,
    logs: [],
    status: requestFail(GET_LOGS),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_ACTIVITIES)]: (state, { payload }) => ({
    ...state,
    activities: payload,
    status: requestSuccess(GET_ACTIVITIES),
    error: ""
  }),

  [requestPending(GET_ACTIVITIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ACTIVITIES)
  }),

  [requestFail(GET_ACTIVITIES)]: (state, { payload }) => ({
    ...state,
    activities: [],
    status: requestFail(GET_ACTIVITIES),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_ACTIVATION_LOGS)]: (state, { payload }) => ({
    ...state,
    activationLogs: payload,
    status: requestSuccess(GET_ACTIVATION_LOGS),
    error: ""
  }),

  [requestPending(GET_ACTIVATION_LOGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ACTIVATION_LOGS)
  }),

  [requestFail(GET_ACTIVATION_LOGS)]: (state, { payload }) => ({
    ...state,
    activationLogs: [],
    status: requestPending(GET_ACTIVATION_LOGS)
  }),

  [requestSuccess(GET_REQUEST_TURN_AROUND_TIME)]: (state, { payload }) => {
    return {
      ...state,
      logs: payload,
      status: requestSuccess(GET_REQUEST_TURN_AROUND_TIME),
      error: ""
    };
  },

  [requestPending(GET_REQUEST_TURN_AROUND_TIME)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_REQUEST_TURN_AROUND_TIME)
  }),

  [requestFail(GET_REQUEST_TURN_AROUND_TIME)]: (state, { payload }) => ({
    ...state,
    logs: [],
    status: requestPending(GET_REQUEST_TURN_AROUND_TIME)
  }),
  [requestSuccess(GET_TOTAL_METRICS)]: (state, { payload }) => {
    return {
    ...state,
    totalMetrics: payload,
    status: requestSuccess(GET_TOTAL_METRICS),
    error: ""
  }},

  [requestPending(GET_TOTAL_METRICS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_TOTAL_METRICS)
  }),

  [requestFail(GET_TOTAL_METRICS)]: (state, { payload }) => ({
    ...state,
    logs: [],
    status: requestFail(GET_TOTAL_METRICS),
    error: payload?.data?.message
  }),

  [requestPending(GET_DEALS_STATS)]: (state) => ({
    ...state,
    status: requestPending(GET_DEALS_STATS),
  }),

  [requestSuccess(GET_DEALS_STATS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_DEALS_STATS),
    dealsStats: payload,
    error: '',
  }),

  [requestFail(GET_DEALS_STATS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_DEALS_STATS),
    dealsStats: {},
    error: payload?.data?.message,
  }),

  [requestSuccess(GET_DEAL_STATS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_DEAL_STATS),
    dealStats: payload.stats[0],
    error: '',
  }),
  [requestPending(GET_DEAL_STATS)]: (state) => ({
    ...state,
    status: requestPending(GET_DEAL_STATS),
  }),
  [requestFail(GET_DEAL_STATS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_DEAL_STATS),
    dealStats: {},
    error: payload?.data?.message,
  }),
});
