import React from "react";
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CCol
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenedModal } from "../../../../redux/actions/headerModal";
import { ReactComponent as UnitIcon } from "../../../../assets/icons/unit.svg";
import { ReactComponent as HashIcon } from "../../../../assets/icons/hash.svg";
import { ReactComponent as PinIcon } from "../../../../assets/icons/pin.svg";

const OperatorLogs = () => {
  const { modal } = useSelector((state) => state.headerModal);
  const { doorPin, unitInfo } = useSelector((state) => state.unit);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ""
      })
    );
  };
  return (
    <div>
      <div className="unitLockDetailsMainDiv">
        <CModal show={modal === "OperatorLogs"} onClose={onClose}>
          <CModalHeader closeButton>
            <CModalTitle>Lock info</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow className="DetailRow">
              <CCol sm="1" className="IconCol">
                <div>
                  <UnitIcon />
                </div>
              </CCol>
              <CCol>
                <p className="m-0 text-muted key">Unit number</p>
                <p className="m-0 value">Unit </p>
              </CCol>
            </CRow>

            <CRow className="DetailRow">
              <CCol sm="1" className="IconCol">
                <div>
                  <HashIcon />
                </div>
              </CCol>
              <CCol>
                <p className="m-0 text-muted key">Lock id</p>
                <p className="m-0 value">daskjfadsj</p>
              </CCol>
            </CRow>

            {doorPin.map((obj, index) => {
              if (parseInt(obj.TypeID) === 1) {
                return (
                  <CRow className="DetailRow" key={index}>
                    <CCol sm="1" className="IconCol">
                      <div>
                        <PinIcon />
                      </div>
                    </CCol>
                    <CCol>
                      <p className="m-0 text-muted key">
                        Master PIN {obj.Slot + 1}
                      </p>
                      <p className="m-0 value">{obj.Code}</p>
                    </CCol>
                  </CRow>
                );
              } else if (parseInt(obj.TypeID) === 2) {
                return (
                  <CRow className="DetailRow" key={index}>
                    <CCol sm="1" className="IconCol">
                      <div>
                        <PinIcon />
                      </div>
                    </CCol>
                    <CCol>
                      <p className="m-0 text-muted key">
                        User PIN {obj.Slot + 1}
                      </p>
                      <p className="m-0 value">{obj.Code}</p>
                    </CCol>
                  </CRow>
                );
              } else {
                return (
                  <CRow className="DetailRow" key={index}>
                    <CCol sm="1" className="IconCol">
                      <div>
                        <PinIcon />
                      </div>
                    </CCol>
                    <CCol>
                      <p className="m-0 text-muted key">
                        Guest PIN {obj.Slot + 1}
                      </p>
                      <p className="m-0 value">{obj.Code}</p>
                    </CCol>
                  </CRow>
                );
              }
            })}
          </CModalBody>
        </CModal>
      </div>
    </div>
  );
};

export default OperatorLogs;
