import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "../redux/actions/window";
import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";

const Toaster = () => {
  const { toastMessage, toastShow, modal, notificationHeader } = useSelector(
    (state) => state.window
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(setToast({ toastShow: false }));
    }, 3000);
  }, [dispatch, toastShow]);

  return (
    <CToaster position="bottom-left" className="mb-3 ml-3">
      <CToast
        show={true}
        autohide={3000}
        fade={true}
        className={modal === "errorToast" ? "bg-danger" : "bg-success"}
      >
        {modal === "notification" ? (
          <CToastHeader
            closeButton={true}
            className="p-2 bg-success text-white"
          >
            {notificationHeader}
          </CToastHeader>
        ) : (
          ""
        )}

        <CToastBody
          className={
            modal === "errorToast"
              ? "p-2 bg-danger text-white"
              : "p-2 bg-success text-white"
          }
        >
          {toastMessage}
        </CToastBody>
      </CToast>
    </CToaster>
  );
};

export default Toaster;
