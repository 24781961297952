import React, { useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Palette } from '../../../components/utils';
import { MdAttachMoney, MdOutlineEmail, MdOutlinePhoneIphone, MdPhone } from 'react-icons/md';
import {
  SettingsModalBoldText,
  SettingsModalBorder,
  SettingsModalSubHeader
} from '../../settings/SettingsModals';
import { FaCalendar, FaUser, FaUserEdit } from 'react-icons/fa';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../settings/SettingsItem';
import { Separator } from '../../../components/ui/Separator';
import { closeActiveModal, openModal } from '../../../helper/modals';
import { addUserTypeTitle, ToggleHelper } from '../ManageUserComponents';
import { VscOrganization } from 'react-icons/vsc';
import { LiaBuilding } from 'react-icons/lia';
import { defaultImgCDN } from '../../../helper';
import { setToast } from '../../../redux/actions/window';
import { addUser, editUser, searchUsers, setUserSearch } from '../../../redux/actions/user';
import { DATE_FORMATS, getTypeById, hasPermission, USER_ROLE } from '../../users/constant';
import { uploadProfileAvatar } from '../../../redux/actions/profile';
import { closeAddUserTypeModal } from '../ManageUserComponents';
import { useForm } from 'react-hook-form';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import { ImageContainer, StyledImage } from '../../settings/SettingsImages';
import { resetUserTypeData } from '../../../redux/actions/addUserType';
import { getAllBuildings } from '../../../redux/actions/building';
import { clearUnitResponse, getUnits, setUserUnit } from '../../../redux/actions/unit';
import { getRoomUnitTerminology } from '../../../utils/utils';
import { searchTable, setSearchPagination, setSearchState } from '../../../redux/actions/search';
import moment from 'moment-timezone';
import { formatTimeString } from '../../../components/Calendar/eventHelper';

const orgId = localStorage.getItem('orgId');

const AddNewUserTypeModalStep4 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.headerModal);
  const addUserType = useSelector((state) => state.addUserType);
  const { searchFields } = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);
  const { globalBuildingId } = useSelector((state) => state.building);
  const { globalOrgId, organization } = useSelector((state) => state.Organization);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { roleId } = useSelector((state) => state.auth);

  const methods = useForm({
    mode: 'onSubmit'
  });

  const onSubmit = () => {
    setIsSubmitting(true);
    const submitMethodFunction = addUserType.editingID ? submitEditUser : submitAddUser;
    if (addUserType.avatar.blobData) {
      const imgData = new FormData();
      imgData.append('image', addUserType.avatar.blobData, addUserType.avatar.blobName);
      dispatch(
        uploadProfileAvatar({
          body: imgData,
          success: (res) => {
            submitMethodFunction(addUserType, res.data.filename);
          },
          fails: (res) => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Organization Avatar update failed!'),
                modal: 'errorToast'
              })
            );
            methods.setError('root.serverError', {
              message: res?.data?.message
            });
          }
        })
      );
    } else {
      submitMethodFunction(addUserType);
    }
  };

  const formatValues = (values, filename) => {
    return {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      home_phone: values.homePhoneNumber,
      cell_phone: values.mobilePhoneNumber,
      building_id: parseInt(values.buildings.buildingId),
      avatar: filename ? filename : defaultImgCDN,
      role_id: values.role,
      org_id: parseInt(values.organization.organizationId),
      type_id: values.role == USER_ROLE.USER.id ? values.type : 0,
      unit_id:
        values.role == USER_ROLE.USER.id
          ? values.unitNumber?.id
            ? parseInt(values.unitNumber.id)
            : undefined
          : 0,
      move_in: values.moveInDate
        ? values.moveInDate
        : values.reservation?.check_in_date
        ? moment(values.reservation?.check_in_date).format(DATE_FORMATS.MM_DD_YYYY)
        : '',
      move_out: values.moveOutDate
        ? values.moveOutDate
        : values.reservation?.check_out_date
        ? moment(values.reservation?.check_out_date).format(DATE_FORMATS.MM_DD_YYYY)
        : '',
      rent: parseInt(values.monthlyRent),
      pending: !addUserType.sendWelcomeEmail,
      futureSendDate: addUserType.welcomeEmailScheduleDateTime
        ? moment(addUserType.welcomeEmailScheduleDateTime)
            .tz(addUserType.selectedBuildingsTimezone)
            .format()
        : '',
      reservation_id: values.reservation?.id
    };
  };

  const afterSubmit = (values) => {
    dispatch(resetUserTypeData());
    setIsSubmitting(false);
    closeActiveModal();
    dispatch(
      searchTable({
        entity: 'user',
        orgID: globalOrgId,
        body: {
          search: searchState.search,
          minCreationDate: searchState.minCreationDate,
          maxCreationDate: searchState.maxCreationDate,
          category: searchState.category,
          building_id: searchState.building_id,

          filters: searchState.filters,
          ordering: searchState.ordering,
          pagination: searchState.pagination
        },
        success: (res) => {
          dispatch(
            setSearchState({
              currentData: res.data.data ?? [],
              currentPaginationData: res.data.pagination
            })
          );

          if (!res.data.data) {
            dispatch(setUserSearch({ page: 1 }));
            dispatch(
              setSearchPagination({
                page: 1,
                limit: 20
              })
            );
          }
        }
      })
    );
    // if (searchFields.query) {
    //   dispatch(
    //     searchUsers({
    //       ...searchFields,
    //       search_col: selectedField['user']?.value,
    //       building: globalBuildingId,
    //       org: globalOrgId
    //     })
    //   );
    // } else {
    //   dispatch(
    //     searchUsers({
    //       page: searchFields.page,
    //       org: globalOrgId != -1 ? globalOrgId : orgId,
    //       building: globalBuildingId,
    //       status: searchFields.status
    //     })
    //   );
    // }
  };

  const submitAddUser = (values, filename) => {
    setIsSubmitting(true);
    const formattedValues = formatValues(values, filename);
    dispatch(
      addUser({
        body: formattedValues,
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('User has been successfully added!')
            })
          );
          afterSubmit(values);
        },
        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('User creation failed!'),
              modal: 'errorToast'
            })
          );
          methods.setError('root.serverError', {
            message: res?.data?.message
          });
          setIsSubmitting(false);
        }
      })
    );
  };

  const submitEditUser = (values, filename) => {
    setIsSubmitting(true);
    const formattedValues = formatValues(values, filename);

    dispatch(
      editUser({
        id: values.editingID,
        body: formattedValues,
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('User has been successfully updated!')
            })
          );
          afterSubmit(values);
        },
        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('User update failed!'),
              modal: 'errorToast'
            })
          );
          methods.setError('root.serverError', {
            message: res?.data?.message
          });
          setIsSubmitting(false);
        }
      })
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <StyledModal
        show={modal === 'AddNewUserTypeModalStep4'}
        closeOnBackdrop={false}
        onClose={() => closeAddUserTypeModal(addUserType.editingID)}>
        <ModalHeader
          title={addUserTypeTitle(addUserType)}
          onCloseCallback={() => closeAddUserTypeModal(addUserType.editingID)}
        />
        <StyledModalBody>
          <SettingsModalSubHeader>
            <FaUserEdit size={24} color={Palette.HAZY_BLUE} />
            <SettingsModalBoldText>{t('User Image')}</SettingsModalBoldText>
          </SettingsModalSubHeader>

          <SettingsModalBorder height={245}>
            <ImageContainer>
              <StyledImage
                src={addUserType.avatar.blobURL ? addUserType.avatar.blobURL : defaultImgCDN}
              />
            </ImageContainer>
            <SettingsModalBoldText>
              {addUserType.firstName} {addUserType.lastName}
            </SettingsModalBoldText>
          </SettingsModalBorder>

          <SettingsItem>
            <FaUser size={24} color={Palette.HAZY_BLUE} />
            <SettingsItemContent>
              <SettingsItemTitle>Name</SettingsItemTitle>
              <SettingsItemSubTitle>
                {addUserType.firstName} {addUserType.lastName}
              </SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>

          <SettingsItem>
            <MdOutlineEmail size={24} color={Palette.HAZY_BLUE} />
            <SettingsItemContent>
              <SettingsItemTitle>Email</SettingsItemTitle>
              <SettingsItemSubTitle>{addUserType.email}</SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>

          <CRow>
            <CCol>
              <SettingsItem>
                <MdPhone size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>Home Phone</SettingsItemTitle>
                  <SettingsItemSubTitle>
                    {addUserType.homePhoneNumber || 'Not Set'}
                  </SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            </CCol>
            <CCol>
              <SettingsItem>
                <MdOutlinePhoneIphone size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>Mobile Phone</SettingsItemTitle>
                  <SettingsItemSubTitle>
                    {addUserType.mobilePhoneNumber || 'Not Set'}
                  </SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            </CCol>
          </CRow>

          <SettingsItem>
            <VscOrganization size={24} color={Palette.HAZY_BLUE} />
            <SettingsItemContent>
              <SettingsItemTitle>Organization</SettingsItemTitle>
              <SettingsItemSubTitle>{addUserType.organization.name}</SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>

          <SettingsItem>
            <LiaBuilding size={24} color={Palette.HAZY_BLUE} />
            <SettingsItemContent>
              <SettingsItemTitle>Building</SettingsItemTitle>
              <SettingsItemSubTitle>{addUserType.buildings.name}</SettingsItemSubTitle>
            </SettingsItemContent>
          </SettingsItem>

          {addUserType.role === USER_ROLE.USER.id && (
            <>
              <SettingsItem>
                <LiaBuilding size={24} color={Palette.HAZY_BLUE} />
                <SettingsItemContent>
                  <SettingsItemTitle>User Type</SettingsItemTitle>
                  <SettingsItemSubTitle>{getTypeById(addUserType.type).value}</SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>

              {addUserType.unitNumber?.unitId && (
                <SettingsItem>
                  <LiaBuilding size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{getRoomUnitTerminology(organization)}</SettingsItemTitle>
                    <SettingsItemSubTitle>
                      {addUserType.unitNumber.name || 'Empty Unit Name'}
                    </SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>
              )}

              {addUserType.monthlyRent > 0 && (
                <SettingsItem>
                  <MdAttachMoney size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>Monthly Rent</SettingsItemTitle>
                    <SettingsItemSubTitle>${addUserType.monthlyRent}</SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>
              )}

              {addUserType.reservation ? (
                <SettingsItem>
                  <FaCalendar size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>Reservation</SettingsItemTitle>
                    <SettingsItemSubTitle>
                      {addUserType.reservation.user_name} -{' '}
                      {moment
                        .utc(addUserType.reservation.check_in_date)
                        .format(DATE_FORMATS.MMM_D_YYYY)}{' '}
                      -{' '}
                      {moment
                        .utc(addUserType.reservation.check_out_date)
                        .format(DATE_FORMATS.MMM_D_YYYY)}
                    </SettingsItemSubTitle>
                  </SettingsItemContent>
                </SettingsItem>
              ) : (
                <>
                  <CRow>
                    <CCol>
                      <SettingsItem>
                        <FaCalendar size={24} color={Palette.HAZY_BLUE} />
                        <SettingsItemContent>
                          <SettingsItemTitle>Move In Date</SettingsItemTitle>
                          <SettingsItemSubTitle>
                            {addUserType.moveInDate || 'Not Set'}
                          </SettingsItemSubTitle>
                        </SettingsItemContent>
                      </SettingsItem>
                    </CCol>
                    <CCol>
                      <SettingsItem>
                        <FaCalendar size={24} color={Palette.HAZY_BLUE} />
                        <SettingsItemContent>
                          <SettingsItemTitle>Move Out Date</SettingsItemTitle>
                          <SettingsItemSubTitle>
                            {addUserType.moveOutDate || 'Not Set'}
                          </SettingsItemSubTitle>
                        </SettingsItemContent>
                      </SettingsItem>
                    </CCol>
                  </CRow>
                </>
              )}
            </>
          )}

          {!addUserType.editingID && (
            <>
              <Separator orientation={'horizontal'} />
              <CRow>
                <CCol>
                  <SettingsItem>
                    <SettingsItemTitle>{t('Welcome Email')}</SettingsItemTitle>
                    <ToggleHelper enabled={addUserType.sendWelcomeEmail} />
                  </SettingsItem>
                  {addUserType.sendWelcomeEmail && (
                    <SettingsItemSubTitle>
                      Your Welcome Email will be sent{' '}
                      {addUserType.welcomeEmailScheduleDateTime ? (
                        <>
                          on{' '}
                          {moment(addUserType.welcomeEmailScheduleDateTime).format(
                            'MMMM D, YYYY [at] h:mm A z'
                          )}
                        </>
                      ) : (
                        <>Instantly</>
                      )}
                    </SettingsItemSubTitle>
                  )}
                </CCol>
              </CRow>
            </>
          )}

          {methods.formState?.errors?.root?.serverError && (
            <ErrorText className="my-4">
              {methods.formState?.errors?.root?.serverError?.message}
            </ErrorText>
          )}
        </StyledModalBody>
        <ModalButtons
          leftButtonText={t('Back')}
          leftButtonClick={() => openModal('AddNewUserTypeModalStep3')}
          rightButtonText={addUserType.editingID ? t('Update') : t('Add')}
          disabled={isSubmitting}
        />
      </StyledModal>
    </form>
  );
};

export default AddNewUserTypeModalStep4;
