import { takeLatest } from "redux-saga/effects";
import {
  GET_MESSAGES,
  GET_MESSAGE_BODY,
  SENT_MESSAGE_DETAILS
} from "../constants";
import apiCall from "../../utils/apiCall";

const getMessages = apiCall({
  type: GET_MESSAGES,
  method: "get",
  path: ({ id }) => {
    let url = `/comms?org=${id}`;
    return url;
  }
});

const getBodyOfMessage = apiCall({
  type: GET_MESSAGE_BODY,
  method: "get",
  path: ({ name }) => `/docs/${name}`
});

const getMessageDetails = apiCall({
  type: SENT_MESSAGE_DETAILS,
  method: "get",
  path: ({ id }) => `/comms/${id}`
});

export default function* buildingSaga() {
  yield takeLatest(GET_MESSAGES, getMessages);
  yield takeLatest(GET_MESSAGE_BODY, getBodyOfMessage);
  yield takeLatest(SENT_MESSAGE_DETAILS, getMessageDetails);
}
