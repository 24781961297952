import { takeLatest } from "redux-saga/effects";
import { LOGGED_IN_USER_AVATAR, NON_LOGGED_IN_USER_AVATAR } from "../constants";
import apiCall from "../../utils/apiCall";

const loggedInUserAvatar = apiCall({
    type: LOGGED_IN_USER_AVATAR,
    method: "get",
    path: ({ id }) => `/images/${id}`,
    isFormData: true,
    isBlob: true
});

const nonLoggedInUserAvatar = apiCall({
    type: NON_LOGGED_IN_USER_AVATAR,
    method: "put",
    path: ({ id }) => `/images/${id}`,
    isFormData: true,
    isBlob: true
});

export default function* userSaga() {
    yield takeLatest(LOGGED_IN_USER_AVATAR, loggedInUserAvatar);
    yield takeLatest(NON_LOGGED_IN_USER_AVATAR, nonLoggedInUserAvatar);
}
