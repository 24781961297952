import { createAction } from "@reduxjs/toolkit";
import {
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  REMOVE_ORGANIZATION,
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  SEARCH_ORGANIZATIONS,
  CLEAR_ERROR,
  SET_ORG_ID,
  UPLOAD_ORG_LOGO,
  GET_ALL_ORGANIZATIONS,
  CLEAR_ORGANIZATION,
  SET_ALL_ORG_STATUS,
  GET_ALL_UNITS_IN_ORG,
  GET_PM_ANALYTICS,
  GET_PM_ACTIVITIES,
  SET_PM_DETAILS,
  GET_ORG_CONFIG
} from "../constants";

export const setOrgId = createAction(SET_ORG_ID);
export const addOrganization = createAction(ADD_ORGANIZATION);
export const editOrganization = createAction(EDIT_ORGANIZATION);
export const removeOrganization = createAction(REMOVE_ORGANIZATION);
export const getOrganizations = createAction(GET_ORGANIZATIONS);
export const getOrganization = createAction(GET_ORGANIZATION);
export const getOrgConfig = createAction(GET_ORG_CONFIG);
export const searchOrganizations = createAction(SEARCH_ORGANIZATIONS);
export const clearOrgError = createAction(CLEAR_ERROR);
export const uploadOrgLogo = createAction(UPLOAD_ORG_LOGO);
export const getAllOrganizations = createAction(GET_ALL_ORGANIZATIONS);
export const clearOrganization = createAction(CLEAR_ORGANIZATION);
export const setAllOrgStatus = createAction(SET_ALL_ORG_STATUS);
export const getAllUnitsInOrg = createAction(GET_ALL_UNITS_IN_ORG);
export const getPMAnalytics = createAction(GET_PM_ANALYTICS);
export const getPMActivities = createAction(GET_PM_ACTIVITIES);
export const setPMDetails = createAction(SET_PM_DETAILS);

export default {
  clearOrgError,
  setOrgId,
  addOrganization,
  editOrganization,
  removeOrganization,
  getOrganizations,
  getOrganization,
  searchOrganizations,
  uploadOrgLogo,
  setAllOrgStatus,
  getAllUnitsInOrg,
  getPMAnalytics,
  getPMActivities,
  getOrgConfig
};
