import { createReducer } from "@reduxjs/toolkit";
import {
    LOGGED_IN_USER_AVATAR,
    NON_LOGGED_IN_USER_AVATAR,
    SET_LOGGED_IN_USER_AVATAR
} from "../constants";
import {
    requestSuccess,
    requestFail,
    requestPending
} from "../../utils/status";

const initialState = {
    avatar: "",
    nonLoggedUserAvatar: "",
    status: "",
    error: null
};

export default createReducer(initialState, {
    [SET_LOGGED_IN_USER_AVATAR]: (state, { payload }) => ({
        ...state,
        avatar: payload.avatar
    }),

    // not using these right now \/
    [requestSuccess(LOGGED_IN_USER_AVATAR)]: (state, { payload }) => ({
        ...state,
        avatar: payload,
        status: requestSuccess(LOGGED_IN_USER_AVATAR),
        error: null
    }),

    [requestPending(LOGGED_IN_USER_AVATAR)]: (state, { payload }) => ({
        ...state,
        status: requestPending(LOGGED_IN_USER_AVATAR)
    }),

    [requestFail(LOGGED_IN_USER_AVATAR)]: (state, { payload }) => ({
        ...state,
        avatar: "",
        status: requestFail(LOGGED_IN_USER_AVATAR),
        error: payload.data
    }),

    [requestSuccess(NON_LOGGED_IN_USER_AVATAR)]: (state, { payload }) => ({
        ...state,
        nonLoggedUserAvatar: payload.filename,
        status: requestSuccess(NON_LOGGED_IN_USER_AVATAR),
        error: null
    }),

    [requestFail(NON_LOGGED_IN_USER_AVATAR)]: (state, { payload }) => ({
        ...state,
        nonLoggedUserAvatar: "",
        status: requestFail(NON_LOGGED_IN_USER_AVATAR),
        error: payload.error
    })
    // not using these right now /\
});
