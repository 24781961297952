import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ModalCloseButton } from './SettingsButton';
import { InputSeparator, SettingsModalBoldText, SettingsModalInput } from './SettingsModals';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateProfileData } from '../../redux/actions/updateUserProfile';
import { closeActiveModal, openModal } from '../../helper/modals';
import { isValidPhoneNumber } from '../../helper/phoneNumber';
import { nameRegx } from '../../helper/Regx';
import { ErrorMessage } from '../../components/ErrorMessage';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import { StyledInput } from '../../components/ui/TextInput';
import { ModalButtons, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { ErrorText } from '../../components/utils/AvatarCropper';

export const PHONE_NUMBER_MASK = '(999) 999-9999';

const CreateProfileModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updateUserProfile = useSelector((state) => state.updateUserProfile);
  const { profile } = useSelector((state) => state.profile);
  const { modal } = useSelector((state) => state.headerModal);

  const schema = Yup.object().shape(
    {
      firstName: Yup.string()
        .required(t('First Name is required'))
        .matches(nameRegx, t('Only alphabets allowed'))
        .max(50, t('Not more than 50 characters')),
      lastName: Yup.string()
        .required(t('Last Name is required'))
        .matches(nameRegx, t('Only alphabets allowed'))
        .max(50, t('Not more than 50 characters')),
      phoneNumber: Yup.string().when('phoneNumber', {
        is: (exists) => !!exists,
        then: Yup.string().test('Valid Phone Number', 'Not a valid phone number!', (value) =>
          isValidPhoneNumber(value)
        ),
        otherwise: Yup.string().notRequired()
      }),
      mobileNumber: Yup.string().when('mobileNumber', {
        is: (exists) => !!exists,
        then: Yup.string().test('Valid Mobile Phone Number', 'Not a valid phone number!', (value) =>
          isValidPhoneNumber(value)
        ),
        otherwise: Yup.string().notRequired()
      })
    },
    [
      ['phoneNumber', 'phoneNumber'],
      ['mobileNumber', 'mobileNumber']
    ]
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const onSubmit = (data) => {
    dispatch(
      updateProfileData({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        mobileNumber: data.mobileNumber
      })
    );
    openModal('CreateProfileImageModal');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledModal
        show={modal === 'CreateProfileModal'}
        closeOnBackdrop={false}
        onClose={closeActiveModal}>
        <ModalHeader title={t('Edit Profile')} />
        <StyledModalBody>
          <InputSeparator>
            <SettingsModalBoldText>{t('First Name')}</SettingsModalBoldText>

            <Controller
              control={control}
              defaultValue={
                !updateUserProfile.firstName ? profile.first_name : updateUserProfile.firstName
              }
              name="firstName"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.firstName}
                    placeholder={t('First Name')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Last Name')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={
                !updateUserProfile.lastName ? profile.last_name : updateUserProfile.lastName
              }
              name="lastName"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalInput
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.lastName}
                    placeholder={t('Last Name')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Work Number')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={
                !updateUserProfile.phoneNumber ? profile.home_phone : updateUserProfile.phoneNumber
              }
              name="phoneNumber"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <StyledInputMask
                    mask={PHONE_NUMBER_MASK}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.phoneNumber}
                    placeholder={t('Work Number')}
                    autoComplete="off"
                  />

                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Mobile Number')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={
                !updateUserProfile.mobileNumber
                  ? profile.cell_phone
                  : updateUserProfile.mobileNumber
              }
              name="mobileNumber"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <StyledInputMask
                    mask={PHONE_NUMBER_MASK}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.mobileNumber}
                    placeholder={t('Mobile Number')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>
          <ModalButtons
            leftButtonText={'Cancel'}
            leftButtonClick={closeActiveModal}
            rightButtonText={'Next'}
          />
        </StyledModalBody>
      </StyledModal>
    </form>
  );
};

export const StyledInputMask = styled(StyledInput.withComponent(InputMask))`
  height: 56px;
`;

export default CreateProfileModal;
