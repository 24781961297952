import React from "react";
import { CFooter, CLink } from "@coreui/react";
import styled from 'styled-components';
import { Fonts, Palette } from "../components/utils";

const TheFooter = () => {
  return (
    <StyledFooter fixed={false}>
      <div>
        <StyledLink
          href="https://www.gratacda.com"
          target="_blank"
        >
          Grata Technologies Inc.
        </StyledLink>
      </div>
      <div className="ml-auto">
        <span className="mr-1">
          Copyright ©{new Date().getFullYear()}. All rights reserved.
        </span>
      </div>
      <span>V 1.4.5</span>
    </StyledFooter>
  );
};

const StyledFooter = styled(CFooter)`
  background: inherit;
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledLink = styled(CLink)`
  color: ${Palette.BLACK};

  :hover {
    color: ${Palette.BLACK};
    text-decoration: underline;
    cursor: pointer;
  }
`;


export default React.memo(TheFooter);
