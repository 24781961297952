import { createReducer } from "@reduxjs/toolkit";
import {
    requestFail,
    requestPending,
    requestSuccess
} from "../../utils/status";
import {
    CHANGE_SENT_PAGE,
    SHOW_MODAL_FOR_SEND_MESSAGE,
    GET_MESSAGES,
    SENT_MESSAGES,
    SENT_MESSAGE_DETAILS,
    GET_MESSAGE_BODY
} from "../constants";
import { TemplateStatus } from "../../views/conversation/constant";

const initialData = {
    page: "0",
    error: "",
    modalVisible: false,
    author: "",
    dateSent: "",
    subject: "",
    recepients: "",
    body: "",
    sentMessages: [],
    draftTemplates: [],
    message: {},
    status: ""
};

export default createReducer(initialData, {
    [requestSuccess(SENT_MESSAGE_DETAILS)]: (state, { payload }) => ({
        ...state,
        message: payload,
        status: requestSuccess(SENT_MESSAGE_DETAILS),
        error: ""
    }),

    [requestPending(SENT_MESSAGE_DETAILS)]: (state, { payload }) => ({
        ...state,
        status: requestPending(SENT_MESSAGE_DETAILS)
    }),

    [requestFail(SENT_MESSAGE_DETAILS)]: (state, { payload }) => ({
        ...state,
        status: requestFail(SENT_MESSAGE_DETAILS),
        error: payload.data.message,
        message: {}
    }),

    [requestSuccess(GET_MESSAGES)]: (state, { payload }) => {
        const {drafts, sentMessages} = payload.reduce(
            (acc, message) => {
                if (message.status === TemplateStatus.Draft) {
                    acc.drafts.push(message);
                } else {
                    acc.sentMessages.push(message);
                }
                return acc;
            },
            {drafts: [], sentMessages: []}
        );
        return {
            ...state,
            draftTemplates: drafts,
            sentMessages: sentMessages,
            status: requestSuccess(GET_MESSAGES)
        }
    },

    [requestPending(GET_MESSAGES)]: (state, { payload }) => ({
        ...state,
        status: requestPending(GET_MESSAGES)
    }),

    [requestFail(GET_MESSAGES)]: (state, { payload }) => ({
        ...state
    }),

    [requestSuccess(GET_MESSAGE_BODY)]: (state, { payload }) => ({
        ...state,
        body: payload
    }),

    [requestPending(GET_MESSAGE_BODY)]: (state, { payload }) => ({
        ...state
    }),

    [requestFail(GET_MESSAGE_BODY)]: (state, { payload }) => ({
        ...state,
        body: ""
    }),

    [SENT_MESSAGES]: (state, { payload }) => ({
        ...state
    }),

    [CHANGE_SENT_PAGE]: (state, { payload }) => ({
        ...state,
        page: payload.page
    }),

    [SHOW_MODAL_FOR_SEND_MESSAGE]: (state, { payload }) => ({
        ...state,
        modalVisible: payload.modalVisible
    })
});
