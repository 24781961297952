export const dashboard = [
  `<svg  viewBox="0 0 20 20">
 <path d="M10 18V8H18V18H10ZM0 10V0H8V10H0ZM6 8V2H2V8H6ZM0 18V12H8V18H0ZM2 16H6V14H2V16ZM12 16H16V10H12V16ZM10 0H18V6H10V0ZM12 2V4H16V2H12Z" fill="#646464"/>
 </svg>
 `
];

export const organization = [
  `<svg  viewBox="0 0 20 20">
    <path d="M12 0C12.552 0 13 0.448 13 1V5C13 5.552 12.552 6 12 6H10V8H14C14.552 8 15 8.448 15 9V12H17C17.552 12 18 12.448 18 13V17C18 17.552 17.552 18 17 18H11C10.448 18 10 17.552 10 17V13C10 12.448 10.448 12 11 12H13V10H5V12H7C7.552 12 8 12.448 8 13V17C8 17.552 7.552 18 7 18H1C0.448 18 0 17.552 0 17V13C0 12.448 0.448 12 1 12H3V9C3 8.448 3.448 8 4 8H8V6H6C5.448 6 5 5.552 5 5V1C5 0.448 5.448 0 6 0H12ZM6 14H2V16H6V14ZM16 14H12V16H16V14ZM11 2H7V4H11V2Z" fill="#646464"/>
    </svg>
    `
];

export const buildings = [
  `<svg  viewBox="0 0 20 20">
<path d="M20 16H22V18H0V16H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V16H18V8H16V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V16ZM4 2V16H12V2H4ZM6 8H10V10H6V8ZM6 4H10V6H6V4Z" fill="#646464"/>
</svg>
`
];

export const users = [
  `<svg  viewBox="0 0 20 20">
<path d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10ZM16.284 13.703C17.6893 14.3359 18.882 15.3612 19.7186 16.6557C20.5552 17.9502 21.0002 19.4587 21 21H19C19.0002 19.844 18.6666 18.7125 18.0391 17.7416C17.4116 16.7707 16.5171 16.0017 15.463 15.527L16.283 13.703H16.284ZM15.596 2.413C16.6035 2.8283 17.465 3.53354 18.071 4.43923C18.6771 5.34492 19.0004 6.41024 19 7.5C19.0004 8.87233 18.4877 10.1952 17.5625 11.2088C16.6374 12.2224 15.3667 12.8535 14 12.978V10.965C14.7409 10.8589 15.4283 10.518 15.9613 9.99246C16.4943 9.4669 16.8447 8.78432 16.9612 8.04493C17.0776 7.30555 16.954 6.5483 16.6084 5.88435C16.2628 5.22041 15.7134 4.68475 15.041 4.356L15.596 2.413Z" fill="#646464"/>
</svg>
`
];

export const bookings = [
  `<svg  viewBox="0 0 20 20">
<path d="M10 12V16H13V18H5V16H8V12H1C0.734784 12 0.48043 11.8946 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H10ZM2 10H16V2H2V10ZM5 5H13V7H5V5Z" fill="#646464"/>
</svg>
`
];

export const services = [
  `<svg  viewBox="0 0 20 20">
<path d="M2.33003 0.271017C2.99706 0.0330009 3.72061 0.00294581 4.40509 0.184822C5.08957 0.366698 5.70277 0.751947 6.16368 1.28967C6.62459 1.8274 6.91152 2.49231 6.98657 3.19655C7.06162 3.90079 6.92126 4.61123 6.58403 5.23402L17.293 15.944L15.879 17.358L5.16903 6.64802C4.5461 6.98392 3.83602 7.12321 3.13236 7.04752C2.4287 6.97183 1.76448 6.68472 1.22724 6.22403C0.689997 5.76333 0.304944 5.15067 0.122823 4.46679C-0.0592985 3.7829 -0.0299406 3.05989 0.207027 2.39302L2.44403 4.63002C2.5824 4.77328 2.74791 4.88756 2.93092 4.96617C3.11393 5.04478 3.31076 5.08616 3.50993 5.08789C3.70909 5.08962 3.90661 5.05167 4.09096 4.97625C4.2753 4.90083 4.44278 4.78945 4.58362 4.64861C4.72446 4.50777 4.83584 4.34029 4.91126 4.15595C4.98668 3.9716 5.02463 3.77408 5.0229 3.57492C5.02117 3.37575 4.97979 3.17892 4.90118 2.99591C4.82257 2.8129 4.70829 2.64739 4.56503 2.50902L2.32903 0.270017L2.33003 0.271017ZM12.697 2.15502L15.879 0.387017L17.293 1.80102L15.525 4.98302L13.757 5.33702L11.637 7.45802L10.222 6.04402L12.343 3.92302L12.697 2.15502ZM5.97903 10.287L7.39303 11.701L2.09003 17.004C1.90973 17.1848 1.66709 17.2898 1.41186 17.2975C1.15663 17.3051 0.908141 17.2148 0.717343 17.0451C0.526545 16.8754 0.407891 16.6392 0.385706 16.3848C0.363521 16.1304 0.439486 15.8772 0.598027 15.677L0.676028 15.59L5.97903 10.287Z" fill="#646464"/>
</svg>
`
];

export const news = [
  `<svg  viewBox="0 0 20 20">
<path d="M0.741 0.407984L19.203 10.562C19.2814 10.6051 19.3468 10.6686 19.3923 10.7456C19.4378 10.8226 19.4618 10.9105 19.4618 11C19.4618 11.0895 19.4378 11.1773 19.3923 11.2544C19.3468 11.3314 19.2814 11.3948 19.203 11.438L0.741 21.592C0.664873 21.6339 0.579148 21.6552 0.492273 21.6538C0.405398 21.6525 0.320372 21.6285 0.245577 21.5843C0.170781 21.5401 0.108797 21.4772 0.0657334 21.4017C0.0226703 21.3263 1.48755e-05 21.2409 0 21.154V0.845984C1.48755e-05 0.759098 0.0226703 0.673717 0.0657334 0.598254C0.108797 0.522791 0.170781 0.459852 0.245577 0.415641C0.320372 0.37143 0.405398 0.347472 0.492273 0.346129C0.579148 0.344786 0.664873 0.366105 0.741 0.407984ZM2 12V18.617L15.85 11L2 3.38298V9.99998H7V12H2Z" fill="#646464"/>
</svg>
`
];

export const dotSidebar = [
  `<svg  viewBox="0 0 20 20">
<circle cx="2" cy="2" r="2" fill="#646464"/>
</svg>
`
];

export const deals = [
  `<svg  viewBox="0 0 20 20">
<path d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V6.5C19.337 6.5 18.7011 6.76339 18.2322 7.23223C17.7634 7.70107 17.5 8.33696 17.5 9C17.5 9.66304 17.7634 10.2989 18.2322 10.7678C18.7011 11.2366 19.337 11.5 20 11.5V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1ZM6.085 16C6.18807 15.707 6.37955 15.4532 6.63301 15.2737C6.88647 15.0942 7.1894 14.9978 7.5 14.9978C7.8106 14.9978 8.11353 15.0942 8.36699 15.2737C8.62045 15.4532 8.81193 15.707 8.915 16H18V13.032C17.2488 12.6597 16.6165 12.085 16.1745 11.3725C15.7325 10.6601 15.4982 9.8384 15.4982 9C15.4982 8.1616 15.7325 7.33987 16.1745 6.62746C16.6165 5.91505 17.2488 5.3403 18 4.968V2H8.915C8.81193 2.29299 8.62045 2.54677 8.36699 2.72629C8.11353 2.90581 7.8106 3.00223 7.5 3.00223C7.1894 3.00223 6.88647 2.90581 6.63301 2.72629C6.37955 2.54677 6.18807 2.29299 6.085 2H2V16H6.085ZM7.5 8C7.10218 8 6.72064 7.84196 6.43934 7.56066C6.15804 7.27936 6 6.89782 6 6.5C6 6.10218 6.15804 5.72064 6.43934 5.43934C6.72064 5.15804 7.10218 5 7.5 5C7.89782 5 8.27936 5.15804 8.56066 5.43934C8.84196 5.72064 9 6.10218 9 6.5C9 6.89782 8.84196 7.27936 8.56066 7.56066C8.27936 7.84196 7.89782 8 7.5 8ZM7.5 13C7.10218 13 6.72064 12.842 6.43934 12.5607C6.15804 12.2794 6 11.8978 6 11.5C6 11.1022 6.15804 10.7206 6.43934 10.4393C6.72064 10.158 7.10218 10 7.5 10C7.89782 10 8.27936 10.158 8.56066 10.4393C8.84196 10.7206 9 11.1022 9 11.5C9 11.8978 8.84196 12.2794 8.56066 12.5607C8.27936 12.842 7.89782 13 7.5 13Z" fill="#646464"/>
</svg>
`
];

export const reports = [
  `<svg  viewBox="0 0 20 20">
<path d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2V18H16ZM5 5H13V7H5V5ZM5 9H13V11H5V9ZM5 13H10V15H5V13Z" fill="#646464"/>
</svg>
`
];

export const deliveryCodes = [
  `<svg viewBox="0 0 42 42">
  <path d="M5.25 7C4.32174 7 3.4315 7.36875 2.77513 8.02513C2.11875 8.6815 1.75 9.57174 1.75 10.5V29.75H5.25C5.25 31.1424 5.80312 32.4777 6.78769 33.4623C7.77226 34.4469 9.10761 35 10.5 35C11.8924 35 13.2277 34.4469 14.2123 33.4623C15.1969 32.4777 15.75 31.1424 15.75 29.75H26.25C26.25 31.1424 26.8031 32.4777 27.7877 33.4623C28.7723 34.4469 30.1076 35 31.5 35C32.8924 35 34.2277 34.4469 35.2123 33.4623C36.1969 32.4777 36.75 31.1424 36.75 29.75H40.25V21L35 14H29.75V7M17.5 10.5L24.5 17.5L17.5 24.5V19.25H7V15.75H17.5M29.75 16.625H34.125L37.5725 21H29.75M10.5 27.125C11.1962 27.125 11.8639 27.4016 12.3562 27.8938C12.8484 28.3861 13.125 29.0538 13.125 29.75C13.125 30.4462 12.8484 31.1139 12.3562 31.6062C11.8639 32.0984 11.1962 32.375 10.5 32.375C9.80381 32.375 9.13613 32.0984 8.64384 31.6062C8.15156 31.1139 7.875 30.4462 7.875 29.75C7.875 29.0538 8.15156 28.3861 8.64384 27.8938C9.13613 27.4016 9.80381 27.125 10.5 27.125ZM31.5 27.125C32.1962 27.125 32.8639 27.4016 33.3562 27.8938C33.8484 28.3861 34.125 29.0538 34.125 29.75C34.125 30.4462 33.8484 31.1139 33.3562 31.6062C32.8639 32.0984 32.1962 32.375 31.5 32.375C30.8038 32.375 30.1361 32.0984 29.6438 31.6062C29.1516 31.1139 28.875 30.4462 28.875 29.75C28.875 29.0538 29.1516 28.3861 29.6438 27.8938C30.1361 27.4016 30.8038 27.125 31.5 27.125Z" fill="#646464"/>
  </svg>
  `
];
