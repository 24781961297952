import axios from "axios";
// import { useHistory } from "react-router-dom";
// import { put } from "redux-saga/effects";
// import { LOG_OUT } from "../redux/constants";
// import { requestSuccess } from "./status";
export const baseURL = process.env.REACT_APP_BASE_URL_NEW;

// function* handleLogOut() {
//   console.log("==callhoa");
//   yield put({
//     type: LOG_OUT
//   });
// }

const API = axios.create({
  baseURL
});

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.data.code === 401 &&
      error.response.data.message === "Jwt is expired" &&
      !originalRequest._retry
    ) {
      try {
        let res = await refreshAccessToken();
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem("idToken", res.data.idToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${res.data.idToken}`;
          return axios(originalRequest);
        } else {
          localStorage.clear();
          return Promise.reject(error);
        }
      } catch (error) {
        return Promise.reject(error);
      }
      //   resolve(res);
    } else if (
      (parseInt(error.response.data.code) === 403 &&
        error.response.data.message === "not logged in") ||
      (parseInt(error.response.data.code) === 401 &&
        error.response.data.message === "Jwt is missing") ||
      (parseInt(error.response.data.code) === 401 &&
        error.response.data.message === "Jwt is expired") ||
      (parseInt(error.response.data.code) === 400 &&
        error.response.data.message.includes(
          "failed to refresh token. firebase returned http status 400"
        ))
    ) {
      localStorage.clear();
      window.location.href = window.location.href;
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const headers = {
    "Content-Type": "application/form-x-encoded",
    "Access-Control-Allow-Origin": "*"
  };

  if (refreshToken == "" || !refreshToken) return;

  const data = {
    refreshToken
  };

  const response = await API.post(
    `/auth/refresh?key=${process.env.REACT_APP_TOKEN_API_KEY}`,
    data,
    headers
  );
  return response;
};

export default API;
