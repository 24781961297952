import { takeEvery, takeLatest } from "redux-saga/effects";
import { LOG_IN, LOG_OUT, FORGOT_PASSWORD, CONFIRM_OTP } from "../constants";
import apiCall from "../../utils/apiCall";

const login = apiCall({
  type: LOG_IN,
  method: "post",
  path: "/login"
});

const logout = apiCall({
  type: LOG_OUT,
  method: "post",
  path: "/logout"
});

const forgotPass = apiCall({
  type: FORGOT_PASSWORD,
  method: "post",
  path: "/my/password/forgot"
});

const confirmOTP = apiCall({
  type: CONFIRM_OTP,
  method: "post",
  path: "my/activate"
});

export default function* authSaga() {
  yield takeLatest(LOG_IN, login);
  yield takeEvery(LOG_OUT, logout);
  yield takeEvery(FORGOT_PASSWORD, forgotPass);
  yield takeLatest(CONFIRM_OTP, confirmOTP);
}
