import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../redux/actions/headerModal';

import { CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../views/users/constant';
import { Button } from './ui/Button';
import { AddNewButton } from './tables/TableComponents';

const GlobalAddButton = ({ text, modalName, width }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { roleId } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.auth);

  return (
    <AddNewButton
      isPrimary
      disabled={
        (modalName === 'AddUser' || modalName === 'addBuilding') &&
        roleId < UserRole.SUPER_ADMIN &&
        config.org_allow_edits === false
      }
      onClick={() => {
        dispatch(
          changeOpenedModal({
            modal: modalName
          })
        );
      }}
    />
  );
};

export default GlobalAddButton;
