// TODO: Update colors if needed
export const Palette = {
  BLACK: '#000000',
  BLACK_20: '#BBBBBB',
  BLACK_50: '#757575',
  BLACK_0: '#343B43',
  WHITE: '#FFFFFF',
  WHITE_SMOKE: '#F1F4F9',
  DARK_ASH: '#131A2C',
  SOLITUDE: '#E9EDF3',
  SOLITUDE2: '#e9edf2',
  GHOST_WHITE: '#F8F8FA',
  ERROR: '#EA4444',
  STROKE_GREY: '#C3C3C3',
  HAZY_BLUE: '#133155',
  LIGHT_GREY: '#7C8DA1',
  OCEANIC: '#4697E0',
  OCEANIC_60: '#8ECAFF',
  OCEANIC_NAV_HOVER: '#2D4664',
  TRANS_GREY: 'rgba(0, 0, 0, 0.25)',
  SHUTTLE_GREY: '#606770',
  TRANS_GREY_BG: 'rgba(0, 0, 0, 0.1)',
  RUSTY_ORANGE: '#E3843C',
  CUSTOM_GRAY: '#6B6A6A',
  CUSTOM_GRAY_2: '#D6D6D6',
  OCEANIC_HOVER: '#6BACE6',
  BACKGROUND_TAG_GREEN: 'rgba(83, 163, 91, 0.2)',
  TEXT_TAG_GREEN: 'rgba(83, 163, 91, 1)',
  BACKGROUND_TAG_RED: '#FCE0E0',
  FIELD_TEXT: '#ECECEC',
  STAR_GREEN: '#009688',
  ACTIVE_GREEN: '#53A35B',
  BRAND_BLUE: "#143154",
  PURPLE: '#8A46E0',
  BORDER_OUTLINE: 'rgba(19, 26, 44, 0.50)'
};
