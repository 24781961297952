import { Palette } from '../utils';
import styled, { css } from 'styled-components';

const Separator = styled.div`
  flex-shrink: 0;
  background-color: ${({ color }) => color || Palette.STROKE_GREY};

  ${({ orientation }) =>
    orientation === 'horizontal'
      ? css`
          width: ${({ width }) => width || '100%'};
          height: ${({ height }) => height || '1px'};
        `
      : css`
          height: ${({ height }) => height || '100%'};
          width: ${({ width }) => width || '1px'};
        `};

  ${({ cssOverride }) => cssOverride};
`;

export { Separator };
