import { CCol, CFormGroup, CModalFooter, CRow } from '@coreui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import 'react-day-picker/lib/style.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BuildingsAsyncDropDown from '../../components/BuildingsAsyncDropDown';
import UserAsyncDropDown from '../../components/UserAsyncDropDown';
import { AMENITY_PARKING } from '../../helper/types';
import { clearAmenity, getAmenities, getAmenity } from '../../redux/actions/amenities';
import {
  addParAmBooking,
  bookingFromAmenity,
  clearBookingError,
  getAvailableTime,
  getSelectedBookings
} from '../../redux/actions/bookingParAm';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { getAmenityTypes } from '../../redux/actions/services';
import { setToast } from '../../redux/actions/window';
import { delay, range } from 'lodash';
import { useTranslation } from 'react-i18next';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import i18next from 'i18next';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../settings/SettingsModals';
import { Select, SelectItem } from '../../components/ui/Select';
import { CustomDatePicker } from '../../components/CustomSearchBar';
import { Button } from '../../components/ui/Button';
import { ErrorText } from '../../components/utils/AvatarCropper';

const ModalBooking = () => {
  const { t } = useTranslation();

  const [selectedAmenityId, setSelectedAmenityId] = useState(0);
  const [availableEndHours, setAvailableEndHours] = useState(range(25));
  const [availableStartHours, setAvailableStartHours] = useState(null);
  const [maxAvailableHours, setMaxAvailableHours] = useState(null);
  const [verifyTimeslot, setVerifyTimeslot] = useState(false);

  const validationSchema = Yup.object().shape({
    building: Yup.object().required(t('Building is required')),
    amenity_id: Yup.string()
      .typeError(t('Required!'))
      .required(t('Required!'))
      .test('required', t('Required!'), (value, context) => {
        if (!value || parseInt(value) === -1) return false;
        return true;
      }),
    user: Yup.object().test('required', t('Required!'), (value, context) => {
      if (!value || value.value === 0) return false;
      return true;
    }),
    startDate: Yup.string()
      .required(t('Start date is required'))
      .typeError(t('Start date is required'))
      .test('no-time-slots', t('There is no time slots available for this date'), function (value) {
        if (!verifyTimeslot) return true;
        if (availableStartHours != null && availableStartHours.length == 0) {
          return false;
        }
        return true;
      }),
    startTime: Yup.string()
      .required(t('Start time is required'))
      .typeError(t('Start time is required'))
      .test('isValidStartTime', t('Start time is required'), function (value) {
        return parseInt(value, 10) >= 0;
      })
      .test(
        'startTimeBeforeEndTime',
        t('Start time must be before end time'),
        function (value, context) {
          if (!context.parent.endTime || parseInt(context.parent.endTime, 10) === -1) return true;
          const startDate = moment(context.parent.startDate).utcOffset(0, true).toISOString();
          const endDate = moment(context.parent.startDate).utcOffset(0, true).toISOString();
          const startTime = moment(value, 'HH').utcOffset(0, true).toISOString();
          let endTime;
          if (context.parent.endTime == 24) {
            endTime = moment('23:59', 'HH:mm').utcOffset(0, true).toISOString();
          } else {
            endTime = moment(context.parent.endTime, 'HH').utcOffset(0, true).toISOString();
          }

          let endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;

          let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;

          return moment(endDateTime).isAfter(moment(startDateTime));
        }
      ),
    endTime: Yup.string()
      .required(t('End time is required'))
      .typeError(t('End time is required'))
      .test('isValidStartTime', t('End time is required'), function (value) {
        return parseInt(value, 10) > 0;
      })
      .test(
        'maxAvailableTime',
        `${t('Max available time is')} ${maxAvailableHours} ${t('hours')}`,
        function (value, context) {
          // this case is handled by end time is required
          if (
            parseInt(context.parent.startTime, 10) === -1 ||
            parseInt(context.parent.endTime, 10) === -1
          )
            return true;
          if (!context.parent.endDate) {
            return moment(value, 'HH').isSameOrBefore(
              moment(this.parent.startTime, 'HH').add(maxAvailableHours, 'hours')
            );
          }
          const startDate = moment(context.parent.startDate).utcOffset(0, true).toISOString();
          const endDate = moment(context.parent.startDate).utcOffset(0, true).toISOString();
          const startTime = moment(context.parent.startTime, 'HH').utcOffset(0, true).toISOString();
          let endTime;
          if (value == 24) {
            endTime = moment('23:59', 'HH:mm').utcOffset(0, true).toISOString();
          } else {
            endTime = moment(value, 'HH').utcOffset(0, true).toISOString();
          }

          let endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;
          let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;

          return moment(endDateTime).isSameOrBefore(
            moment(startDateTime).add(maxAvailableHours, 'hours')
          );
        }
      )
      .test(
        'endTimeAfterStartTime',
        t('End time must be after start time'),
        function (value, context) {
          // this case if handled by required field
          if (parseInt(value, 10) === -1) return true;
          if (!context.parent.endDate) {
            return moment(value, 'HH').isAfter(moment(this.parent.startTime, 'HH'));
          }

          const startDate = moment(context.parent.startDate).utcOffset(0, true).toISOString();
          const endDate = moment(context.parent.startDate).utcOffset(0, true).toISOString();
          const startTime = moment(context.parent.startTime, 'HH').utcOffset(0, true).toISOString();
          let endTime;
          if (value == 24) {
            endTime = moment('23:59', 'HH:mm').utcOffset(0, true).toISOString();
          } else {
            endTime = moment(value, 'HH').utcOffset(0, true).toISOString();
          }

          let endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;
          let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;
          return moment(endDateTime).isAfter(moment(startDateTime));
        }
      ),
    make: Yup.string().test('is-parking', 'Required', (value, context) => {
      if (selectedAmenityId === AMENITY_PARKING && !value) return false;
      if (selectedAmenityId === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    }),

    model: Yup.string().test('is-parking', 'Required', (value, context) => {
      if (selectedAmenityId === AMENITY_PARKING && !value) return false;
      if (selectedAmenityId === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    }),

    color: Yup.string().test('is-parking', 'Required', (value, context) => {
      if (selectedAmenityId === AMENITY_PARKING && !value) return false;
      if (selectedAmenityId === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    }),

    plate_number: Yup.string().test('is-parking', t('Required'), (value, context) => {
      if (selectedAmenityId === AMENITY_PARKING && !value) return false;
      if (selectedAmenityId === AMENITY_PARKING && value && value.length === 0) return false;
      return true;
    })
  });

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isDirty },
    resetField
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  const dispatch = useDispatch();

  const watchAllFields = watch();
  const watchAmenityId = watch('amenity_id');
  const watchStartDate = watch('startDate');
  const watchBuilding = watch('building');
  const watchStartTime = watch('startTime');
  const watchEndTime = watch('endTime');

  const { availableTime, error } = useSelector((state) => state.parAmBooking);

  const getAvailableTimeToUse = useCallback(
    (date) => {
      const day = date.format('DD');
      const year = date.format('YYYY');
      const month = date.format('MM');
      if (
        watchAmenityId &&
        watchAmenityId >= 0 &&
        watchAllFields !== 'Please select' &&
        parseInt(watchAmenityId) > 0
      ) {
        dispatch(
          getAvailableTime({
            amenity_id: watchAmenityId,
            day,
            year,
            month,
            success: () => setVerifyTimeslot(true)
          })
        );
      }
    },

    [dispatch, watchAmenityId]
  );

  useEffect(() => {
    if (maxAvailableHours) {
      let availableEndTimes = availableTime.map((t) =>
        parseInt(moment(t.end_time).utc().format('HH'))
      );

      availableEndTimes = availableEndTimes.map((t) => (t === 0 ? 24 : t));
      let nextAvailableTime = parseInt(watchStartTime) + 1;
      let myFinalAvailableEndHours = [];
      let myAvailableEndHours = availableEndTimes.filter(
        (h) => h <= parseInt(watchStartTime) + maxAvailableHours && h > parseInt(watchStartTime)
      );

      for (const time of myAvailableEndHours) {
        if (time == nextAvailableTime) {
          myFinalAvailableEndHours.push(time);
          nextAvailableTime++;
        }
      }
      setAvailableEndHours(myFinalAvailableEndHours);
    }
  }, [watchStartTime, maxAvailableHours, availableTime]);

  useEffect(() => {
    if (watchStartDate) {
      getAvailableTimeToUse(watchStartDate);
    }
    resetField('startTime', {
      keepError: false,
      keepDirty: true,
      keepTouched: true,
      defaultValue: -1
    });
    resetField('endTime', {
      keepError: false,
      keepDirty: true,
      keepTouched: true,
      defaultValue: -1
    });
  }, [getAvailableTimeToUse, watchStartDate]);

  useEffect(() => {
    const intEndtime = parseInt(watchEndTime, 10);
    if (parseInt(watchStartTime, 10) > -1 && intEndtime !== -1 && watchEndTime) {
      resetField('endTime', {
        keepError: false,
        keepDirty: true,
        keepTouched: true,
        defaultValue: -1
      });
      trigger('startTime', false);
    }
  }, [watchStartTime]);

  const { currentAmenityType } = useSelector((state) => state.services);
  const { modal } = useSelector((state) => state.headerModal);

  const { globalBuildingId } = useSelector((state) => state.building);
  const { amenities, amenity, amenityTypes } = useSelector((state) => state.amenities);

  const { globalOrgId } = useSelector((state) => state.Organization);

  const [amenityLabel, setAmenityLabel] = useState('');

  useEffect(() => {
    const myAmenityLabel = amenityTypes.find((a) => a.ID === amenity.type_id)?.Description;

    setAmenityLabel(myAmenityLabel ?? t('amenity'));
  }, [amenityTypes, amenity]);

  const onClose = () => {
    dispatch(bookingFromAmenity(false));
    dispatch(clearAmenity());
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearBookingError());
  };

  useEffect(() => {
    if (amenity) {
      setMaxAvailableHours(amenity?.max_time);
    }
  }, [amenity]);

  const customSubmit = (values) => {
    let myEndTime = values.endTime;

    const amenity = amenities.find((amenity) => amenity.id === parseInt(values.amenity_id));
    const description = amenity ? amenity.description : '';
    let subValues;

    const startDate = moment(values.startDate).utcOffset(0, true).toISOString();

    let endDate = values.endDate
      ? moment(values.endDate).utcOffset(0, true).toISOString()
      : moment(values.startDate).utcOffset(0, true).toISOString();

    const startTime = moment(values.startTime, 'HH').utcOffset(0, true).toISOString();
    const endTime = moment(myEndTime, 'HH').utcOffset(0, true).toISOString();

    let endDateTime;

    if (myEndTime == 24) {
      endDateTime = `${endDate.split('T')[0]}T23:59:00`;
    } else {
      endDateTime = `${endDate.split('T')[0]}T${endTime.split('T')[1]}`;
    }

    let startDateTime = `${startDate.split('T')[0]}T${startTime.split('T')[1]}`;

    startDateTime = startDateTime.split('.')[0] + 'Z';
    endDateTime = endDateTime.split('.')[0] + 'Z';

    subValues = {
      user_id: values.user.value,
      building_id: values.building.value,
      amenity_id: parseInt(values.amenity_id),
      title: description,
      start_date: startDateTime,
      end_date: endDateTime,
      data: {}
    };

    let body = {
      ...subValues
    };

    if (amenity.type_id === AMENITY_PARKING) {
      body = {
        ...subValues,
        data: {
          make: values?.make,
          model: values?.model,
          color: values?.color,
          plate: values?.plate_number
        }
      };
    }

    dispatch(
      addParAmBooking({
        body: body,
        success: () => {
          onClose();
          // dispatch(bookingFromAmenity(false));
          dispatch(
            getSelectedBookings({
              id: globalBuildingId,
              page: 1,
              amenityId: currentAmenityType,
              orgId: globalOrgId
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Booking has been successfully created!')
            })
          );
        },

        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to create booking!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  useEffect(() => {}, [errors]);

  const handleChangeAmenity = useCallback(
    (amenityId) => {
      if (amenityId !== 'Please select') {
        const selectedAmenityId = amenities.find(
          (amenity) => parseInt(amenity.id) === parseInt(amenityId)
        )?.type_id;

        setSelectedAmenityId(selectedAmenityId);

        dispatch(
          getAmenity({
            id: parseInt(amenityId),

            fail: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Failed to get amenity!'),
                  modal: 'errorToast'
                })
              );
            }
          })
        );
      }
    },
    [amenities, dispatch]
  );

  useEffect(() => {
    if (watchAmenityId >= 0) {
      handleChangeAmenity(watchAmenityId);
    }
  }, [watchAmenityId, handleChangeAmenity]);

  const getFilteredAmenities = () => {
    if (currentAmenityType === 0) {
      return amenities;
    }

    let filteredAmenities = amenities.filter(
      (map) => parseInt(map?.type_id) === currentAmenityType
    );

    return filteredAmenities;
  };

  useEffect(() => {
    dispatch(getAmenityTypes());
  }, [dispatch]);

  const disabledDates = (current) => {
    let availableNumberDays = [];

    if (amenity && amenity?.available_days?.length) {
      const availableDays = amenity.available_days; // "M,T,W,TH,F,S,SU"

      const aval = availableDays && availableDays !== '' && availableDays.split(',');
      for (const day of aval) {
        switch (day) {
          case 'SU':
            availableNumberDays.push(0);
            break;
          case 'M':
            availableNumberDays.push(1);
            break;
          case 'T':
            availableNumberDays.push(2);
            break;
          case 'W':
            availableNumberDays.push(3);
            break;
          case 'TH':
            availableNumberDays.push(4);
            break;
          case 'F':
            availableNumberDays.push(5);
            break;
          case 'S':
            availableNumberDays.push(6);
            break;
          default:
        }
      }
      const currWeekDay = current.day();
      if (!availableNumberDays.includes(currWeekDay)) return true;
    }

    if (current && current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      return false;
    }

    const today = moment().endOf('day');
    // Can not select days before today and today
    return current && current.isSameOrBefore(today);
  };

  useEffect(() => {
    const building = watchBuilding;
    if (building?.value) {
      dispatch(getAmenities({ id: building?.value }));
    }
  }, [watchBuilding, dispatch]);

  useEffect(() => {
    let availableStartTimes = [];
    for (const t of availableTime) {
      let fullDateTime = t.booking_date.split('T')[0] + 'T' + t.start_time.split('T')[1];
      fullDateTime = moment(fullDateTime).utc();
      const now = moment().utcOffset(0, true);
      const bookTime = fullDateTime.utcOffset(0, true);
      if (bookTime.isSameOrAfter(now)) {
        const time = parseInt(moment(t.start_time).utc().format('HH'));
        availableStartTimes.push(time);
      }
    }
    let availableEndTimes = availableTime.map((t) =>
      parseInt(moment(t.end_time).utc().format('HH'))
    );

    availableEndTimes = availableEndTimes.map((t) => (t === 0 ? 24 : t));
    setAvailableStartHours(availableStartTimes);
    setAvailableEndHours(availableEndTimes);
  }, [availableTime, selectedAmenityId]);

  useEffect(() => {
    const availableTimeTest = async () => {
      await trigger(['startDate']);
    };
    if (watchStartDate && availableStartHours) {
      delay(function () {
        availableTimeTest();
      }, 500);
    }
  }, [availableStartHours, trigger, watchStartDate]);

  return (
    <form name="AddBookingForm" onSubmit={handleSubmit(customSubmit)}>
      <StyledModal
        show={
          modal === 'modalbookAmenity' ||
          modal === 'modalBookParking' ||
          modal === 'modalBookElevator'
        }
        onClose={onClose}
        closeOnBackdrop={false}>
        <ModalHeader title={t('book_amenity', { amenityLabel: amenityLabel })} />

        <StyledModalBody>
          <SettingsModalBoldText fontSize={'28px'}>{t('Amenity')}</SettingsModalBoldText>
          <InputSeparator>
            <SettingsModalBoldText>{t('Building')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="building"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <BuildingsAsyncDropDown
                  value={value}
                  onChange={(e) => {
                    resetField('amenity_id');
                    resetField('user');
                    setAmenityLabel(t('amenity'));
                    onChange(e);
                  }}
                  valid={!errors.building}
                  invalid={errors.building}
                />
              )}
            />
            {errors.building && <ErrorText>{errors.building.message}</ErrorText>}
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>
              {t('select_amenity', { amenityLabel: amenityLabel })}
            </SettingsModalBoldText>
            <Controller
              control={control}
              name="amenity_id"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <Select
                  id="amenity_id"
                  onChange={onChange}
                  disabled={!watch('building')}
                  placeholder={'Please Select'}
                  custom
                  onBlur={onBlur}
                  invalid={!!errors.amenity_id}
                  value={value}
                  options={
                    getFilteredAmenities()?.length > 0
                      ? [
                          {
                            value: -1,
                            label: t('Please select')
                          },
                          ...getFilteredAmenities().map((amenity) => ({
                            value: amenity.id,
                            label: amenity.description
                          }))
                        ]
                      : [
                          {
                            value: null,
                            label: t('No Amenities Available')
                          }
                        ]
                  }></Select>
              )}
            />
            {errors.amenity_id && <ErrorText>{errors.amenity_id.message}</ErrorText>}
          </InputSeparator>

          <SettingsModalBoldText fontSize={'28px'}>{t('Resident details')}</SettingsModalBoldText>

          <InputSeparator>
            <SettingsModalBoldText>{t('Resident name')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="user"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <UserAsyncDropDown
                  disabled={!watch('amenity_id')}
                  value={value}
                  type={1}
                  buildingId={watchAllFields['building']?.value}
                  onChange={onChange}
                  valid={!errors.user}
                  invalid={!!errors.user}
                  only_residents
                />
              )}
            />
            {errors.user && <ErrorText>{errors.user.message}</ErrorText>}
          </InputSeparator>

          <SettingsModalBoldText fontSize={'28px'}>{t('Book date and time')}</SettingsModalBoldText>

          <CFormGroup>
            <CRow style={{ alignItems: 'baseline' }}>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Select date')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <CustomDatePicker
                        height={'56px'}
                        locale={i18next.resolvedLanguage === 'fr' ? locale : localeEn}
                        disabled={!watch('user')}
                        format="YYYY-MM-DD"
                        onChange={onChange}
                        value={value}
                        disabledDate={amenity && disabledDates}
                      />
                    )}
                  />
                  {errors.startDate && <ErrorText>{errors.startDate.message}</ErrorText>}
                </InputSeparator>
              </CCol>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Select start time')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled={!watch('startDate')}
                        value={value}
                        custom>
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        {availableStartHours &&
                          availableStartHours.map((start_time) => {
                            let amPmTime;
                            if (start_time == 0) {
                              amPmTime = '00:00 am';
                            } else {
                              amPmTime = moment(start_time, 'HH').format('h:mm a');
                            }
                            return (
                              <SelectItem key={start_time} value={start_time}>
                                {amPmTime}
                              </SelectItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  {errors.startTime && <ErrorText>{errors.startTime.message}</ErrorText>}
                </InputSeparator>
              </CCol>
            </CRow>
          </CFormGroup>

          <CFormGroup>
            <CRow>
              <CCol></CCol>
              <CCol>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Select end time')}</SettingsModalBoldText>

                  <Controller
                    control={control}
                    name="endTime"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <Select
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled={
                          watch('startTime') === undefined || parseInt(watchStartTime, 10) === -1
                        }
                        value={value}
                        custom>
                        <SelectItem value={-1}>{t('Please select')}</SelectItem>
                        {availableEndHours.map((end_time) => {
                          const amPmTime = moment(end_time, 'HH').format('h:mm a');
                          return (
                            <SelectItem key={end_time} value={end_time}>
                              {amPmTime}
                            </SelectItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  {errors.endTime && <ErrorText>{errors.endTime.message}</ErrorText>}
                </InputSeparator>
              </CCol>
            </CRow>
          </CFormGroup>

          <CRow></CRow>

          {(modal === 'modalBookParking' || selectedAmenityId === AMENITY_PARKING) && (
            <>
              <SettingsModalBoldText fontSize={'28px'}>
                {t('Vehicle details')}
              </SettingsModalBoldText>
              <InputSeparator>
                <SettingsModalBoldText>Make</SettingsModalBoldText>
                <Controller
                  control={control}
                  name="make"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      valid={!errors.make}
                      error={errors.make}
                      id="make"
                      type="text"
                      placeholder={t('Enter car make')}
                    />
                  )}
                />
                {errors.make && <ErrorText>{errors.make.message}</ErrorText>}
              </InputSeparator>

              <InputSeparator>
                <SettingsModalBoldText>{t('Model')}</SettingsModalBoldText>
                <Controller
                  control={control}
                  name="model"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      valid={!errors.model}
                      error={errors.model}
                      id="model"
                      type="text"
                      placeholder={t('Enter car model')}
                    />
                  )}
                />
                {errors.model && <ErrorText>{errors.model.message}</ErrorText>}
              </InputSeparator>

              <InputSeparator>
                <SettingsModalBoldText>{t('Color')}</SettingsModalBoldText>
                <Controller
                  control={control}
                  name="color"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      valid={!errors.color}
                      error={errors.color}
                      id="color"
                      type="text"
                      placeholder={t('Enter car color')}
                    />
                  )}
                />
                {errors.color && <ErrorText>{errors.color.message}</ErrorText>}
              </InputSeparator>

              <InputSeparator>
                <SettingsModalBoldText>{t('License plate number')}</SettingsModalBoldText>
                <Controller
                  control={control}
                  name="plate_number"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      valid={!errors.plate_number}
                      error={errors.plate_number}
                      id="plate_number"
                      type="text"
                      placeholder={t('Enter plate number')}
                    />
                  )}
                />
                {errors.plate_number && <ErrorText>{errors.plate_number.message}</ErrorText>}
              </InputSeparator>
            </>
          )}
        </StyledModalBody>

        <CModalFooter>
          {error && <ErrorText>{error}</ErrorText>}
          <Button onClick={onClose} text={t('Cancel')} width={'100px'} />
          <Button isPrimary type="submit" text={t('Book')} width={'100px'} disabled={!isDirty} />
        </CModalFooter>
      </StyledModal>
    </form>
  );
};

export default ModalBooking;
