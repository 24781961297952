import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../redux/actions/window';
import { Formik } from 'formik';
import {
  clearOrgError,
  editOrganization,
  getOrganizations,
  uploadOrgLogo
} from '../../../redux/actions/organization';
import { CForm, CFormGroup } from '@coreui/react';
import * as Yup from 'yup';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import {
  LOCK_PROVIDERS,
  org_property_mode_mapping,
  org_property_provider_mapping,
  org_service_mode_mapping,
  org_service_provider_mapping,
  org_tenant_mode_mapping,
  org_tenant_provider_mapping
} from '../../../utils/mappings';
import InputField from '../../../components/InputField';
import ModalFooter from '../../../components/ModalFooter';
import { emailRegx, orgNameRegx } from '../../../helper/Regx';
import LoadingAvatar from '../../../components/LoadingAvatar';
import { useTranslation } from 'react-i18next';
import { Alfred } from '../../../utils/lockInfo';
import { data_center_locations } from '../../../helper/types';
import Configurations from './Configurations';
import { orgUrlRegx } from '../../../helper/Regx';
import { LOADING_BLUE } from '../../../utils/colorCode';
import AvatarCropper, { ErrorText } from '../../../components/utils/AvatarCropper';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../components/Modals';
import { TableDetailsText } from '../../../components/tables/TableComponents';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { Select, SelectItem } from '../../../components/ui/Select';
import ImageUploadWithPreview from '../../../components/ImageUploadWithPreview';

const EditOrganization = () => {
  const { t } = useTranslation();
  const organzationLabels = {
    org_name: t('Organization name'),
    email_address: t('Email address'),
    contact_person: t('Contact person'),
    configuration: t('Configuration'),
    tenant_provider: t('Resident provider'),
    tenant_mode: t('Resident mode'),
    property_provider: t('Property provider'),
    property_mode: t('Property mode'),
    service_provider: t('Service provider'),
    service_mode: t('Service mode'),
    create_date: t('Create date')
  };

  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.headerModal);
  const { organization, error, status, logo, pagination } = useSelector(
    (state) => state.Organization
  );
  const formikref = useRef(null);
  const [errorInConfig, setErrorInConfig] = useState('');
  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const [resetToDefault, setResetToDefault] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = function (values) {
    let validation = {
      name: Yup.string()
        .matches(orgNameRegx, t('Incorrect Format,only alphabets allowed'))
        .max(50, t('Not more than 50 characters'))
        .required(t('Name is required')),
      contact: Yup.string()
        .matches(orgNameRegx, t('Incorrect Format,only alphabets allowed'))
        .max(50, t('Not more than 50 characters'))
        .required(t('Contact is required')),
      email: Yup.string()
        .email(t('Invalid email address'))
        .matches(emailRegx, t('Invalid Email'))
        .required(t('Email is required')),
      logo: Yup.string(),
      prov_tenant: Yup.number().required().min(0, t('Select provider')),
      prov_property: Yup.number().required().min(0, t('Select provider')),
      prov_service: Yup.number().required().min(0, t('Select provider')),
      config: Yup.object().shape({
        service_request_url: Yup.string()
          .max(100, t('Not more than 100 characters'))
          .test('valid-service-url', 'Incorrect URL Format', (value, context) => {
            return value === '' || orgUrlRegx.test(value);
          }),
        lock_provider: Yup.number()
          .required(t('Lock Provider is required'))
          .oneOf(LOCK_PROVIDERS.map((v) => v.id)),
        enable_posts: Yup.bool().required('Missing Enable Posts'),
        enable_offers: Yup.bool().required('Missing Enable Offers'),
        enable_booking: Yup.bool().required('Missing Enable Bookings'),
        enable_lock_screen: Yup.bool().required('Missing Enable Lock Screen'),
        enable_service_requests: Yup.bool().required('Missing Enable Service Requests'),
        suite_enabled: Yup.bool().required('Missing Enable Multi Lock'),
        readonly_users: Yup.bool().required('Missing Enable Readonly Users'),
        show_user_pin_resident: Yup.bool().required('Missing Show User Pin Resident'),
        show_user_pin_admin: Yup.bool().required('Missing Show User Pin Admin'),
        org_allow_edits: Yup.bool().required('Missing Organization Allow Edit'),
        user_pin_len: Yup.number()
          .required()
          .min(Alfred.pinMinLength, t('Minimum Pin Length is 4'))
          .max(Alfred.pinMaxLength, t('Maximum Pin Length is 10')),
        location: Yup.string()
          .required('Missing Location')
          .test('valid-location', 'Invalid Location', (value, context) => {
            const validLocations = data_center_locations.map((obj) => {
              return obj.value;
            });
            return validLocations.includes(value);
          })
      })
    };

    if (values.prov_tenant > 0)
      validation = {
        ...validation,
        prov_tenant_mode: Yup.number().required('Required').moreThan(0, t('Select an option'))
      };

    if (values.prov_property > 0)
      validation = {
        ...validation,
        prov_property_mode: Yup.number().required('Required').moreThan(0, t('Select an option'))
      };

    if (values.prov_service > 0)
      validation = {
        ...validation,
        prov_service_mode: Yup.number().required('Required').moreThan(0, t('Select an option'))
      };

    return Yup.object().shape({
      ...validation
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  // submit avatar before submitting the form
  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (newAvatar.image) {
      const imgData = new FormData();
      imgData.append('image', newAvatar.image, newAvatar.name);
      dispatch(
        uploadOrgLogo({
          body: imgData,
          success: (res) => {
            submitEditOrganization(values, res.data.filename);
          },
          fails: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Avatar update failed!'),
                modal: 'errorToast'
              })
            );
            setIsSubmitting(false);
          }
        })
      );
    } else {
      submitEditOrganization(values, '', resetToDefault);
    }
  };

  // form submission with optional avatar file path
  const submitEditOrganization = (formValues, filename, isResetted) => {
    // prevent frozen values
    const values = { ...formValues };
    values.prov_tenant_mode = formValues.prov_tenant === 0 ? 0 : formValues.prov_tenant_mode;
    values.prov_property_mode = formValues.prov_property === 0 ? 0 : formValues.prov_property_mode;
    values.prov_service_mode = formValues.prov_service === 0 ? 0 : formValues.prov_service_mode;

    const newValues = { ...values, logo: filename || isResetted ? filename : organization.logo };

    dispatch(
      editOrganization({
        id: organization.id,
        body: newValues,
        success: () => {
          dispatch(
            getOrganizations({
              page: parseInt(pagination.currentPage, 10) || 1
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Organization info has been successfully updated!')
            })
          );
          onClose();
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Organization Updating Failed!'),
              modal: 'errorToast'
            })
          );
        },
        finally: () => {
          setIsSubmitting(false);
        }
      })
    );
  };

  const onClose = () => {
    setNewAvatar({
      image: null,
      name: ''
    });
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearOrgError());
  };

  const handleCancel = () => {
    onClose();
  };

  const isValidToSubmit = (dirty, isValid) => {
    return (
      !((dirty || resetToDefault) && isValid) &&
      ((logo === '' || logo === undefined) && !resetToDefault ? true : false)
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={organization}
      validate={validate(validationSchema)}
      onSubmit={onSubmit}
      innerRef={formikref}>
      {({
        isValid,
        dirty,
        values,
        resetForm,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched
      }) => (
        <CForm onSubmit={handleSubmit} noValidate name="EditOrganizationForm">
          {modal === 'editOrg' && (
            <StyledModal
              show={modal === 'editOrg'}
              onClose={() => {
                onClose();
                resetForm();
              }}
              closeOnBackdrop={false}>
              <ModalHeader title={t('Edit organization')} />
              {status === 'GET_ORGANIZATION_PENDING' ? (
                <>
                  <LoadingAvatar color={LOADING_BLUE} />
                </>
              ) : (
                <>
                  <StyledModalBody>
                    <div className="uploadImageDiv">
                      <CFormGroup>
                        <ImageUploadWithPreview
                          name={'edit-organization'}
                          avatar={organization.logo || null}
                          newAvatarState={{
                            newAvatar,
                            setNewAvatar
                          }}
                          isEdit
                          setFormValue={(cancel, filename) => {
                            setFieldValue('logo', cancel ? organization.logo : filename);
                          }}
                          allowResetDefault
                          resetState={{
                            isResetted: resetToDefault,
                            setIsResetted: setResetToDefault
                          }}
                        />
                      </CFormGroup>
                    </div>
                    <InputSeparator>
                      <SettingsModalBoldText>{t('Name')}</SettingsModalBoldText>
                      <SettingsModalInput
                        type="text"
                        id="name"
                        name="name"
                        placeholder={t('Name')}
                        autoComplete="text"
                        error={!!errors.name}
                        value={values.name ? values.name : ''}
                        required
                        onChange={handleChange}
                      />
                      {errors?.name && <ErrorText>{errors.name}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Email')}</SettingsModalBoldText>
                      <SettingsModalInput
                        label={organzationLabels.email_address}
                        type="text"
                        id="email"
                        name="email"
                        placeholder={t('email...')}
                        autoComplete="text"
                        error={!!errors.email}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email ? values.email : ''}
                      />
                      {errors?.email && <ErrorText>{errors.email}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Contact')}</SettingsModalBoldText>
                      <SettingsModalInput
                        label={organzationLabels.contact_person}
                        type="text"
                        id="contact"
                        name="contact"
                        placeholder={t('contact...')}
                        autoComplete="text"
                        error={!!errors.contact}
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contact ? values.contact : ''}
                      />
                      {errors?.contact && <ErrorText>{errors.contact}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Lock Provider')}</SettingsModalBoldText>
                      <Select
                        placeholder={'Lock Provider'}
                        onChange={(e) => {
                          setFieldValue('config.lock_provider', parseInt(e));
                        }}
                        name="config.lock_provider"
                        id="config.lock_provider"
                        value={values.config.lock_provider}
                        required
                        error={errors?.lock_provider}>
                        {LOCK_PROVIDERS.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.lock_provider && <ErrorText>{errors.lock_provider}</ErrorText>}
                    </InputSeparator>
                    <InputSeparator>
                      <SettingsModalBoldText>
                        {organzationLabels.tenant_provider}
                      </SettingsModalBoldText>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          const value = parseInt(e);
                          if (value === -1) setFieldValue('prov_tenant_mode', value);
                          setFieldValue('prov_tenant', value);
                        }}
                        name="prov_tenant"
                        id="prov_tenant"
                        custom
                        valid={!errors.prov_tenant}
                        invalid={!!errors.prov_tenant}
                        value={values.prov_tenant}
                        error={errors.prov_tenant}>
                        {org_tenant_provider_mapping.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.prov_tenant && <ErrorText>{errors.prov_tenant}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{organzationLabels.tenant_mode}</SettingsModalBoldText>
                      <Select
                        placeholder={organzationLabels.tenant_mode}
                        onChange={(e) => {
                          setFieldValue('prov_tenant_mode', parseInt(e));
                        }}
                        name="prov_tenant_mode"
                        id="prov_tenant_mode"
                        custom
                        value={values.prov_tenant === 0 ? 0 : values.prov_tenant_mode}
                        invalid={!!errors.prov_tenant_mode}
                        required={values.prov_tenant > 0 ? true : false}
                        valid={!errors.prov_tenant_mode}
                        disabled={values.prov_tenant > 0 ? false : true}
                        style={values.prov_tenant > 0 ? {} : { cursor: 'not-allowed' }}
                        error={errors.prov_tenant_mode}>
                        {org_tenant_mode_mapping.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.prov_tenant_mode && <ErrorText>{errors.prov_tenant_mode}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>
                        {organzationLabels.property_provider}
                      </SettingsModalBoldText>
                      <Select
                        onChange={(e) => {
                          const value = parseInt(e);
                          if (value === -1) setFieldValue('prov_property_mode', value);
                          setFieldValue('prov_property', value);
                        }}
                        name="prov_property"
                        id="prov_property"
                        custom
                        valid={!errors.prov_property}
                        invalid={!!errors.prov_property}
                        value={values.prov_property}
                        error={errors.prov_property}>
                        {org_tenant_provider_mapping.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.prov_property && <ErrorText>{errors.prov_property}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>
                        {organzationLabels.property_mode}
                      </SettingsModalBoldText>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          setFieldValue('prov_property_mode', parseInt(e));
                        }}
                        name="prov_property_mode"
                        id="prov_property_mode"
                        custom
                        invalid={!!errors.prov_property_mode}
                        required={values.prov_property > 0 ? true : false}
                        valid={!errors.prov_property_mode}
                        value={values.prov_property === 0 ? 0 : values.prov_property_mode}
                        disabled={values.prov_property > 0 ? false : true}
                        style={values.prov_property > 0 ? {} : { cursor: 'not-allowed' }}
                        error={errors.prov_property_mode}>
                        {org_property_mode_mapping.map((item) => (
                          <SelectItem
                            key={item.id}
                            value={item.id}
                            disabled={parseInt(item.id) === 0}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.prov_property_mode && (
                        <ErrorText>{errors.prov_property_mode}</ErrorText>
                      )}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>
                        {organzationLabels.service_provider}
                      </SettingsModalBoldText>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          const value = parseInt(e);
                          if (value === -1) setFieldValue('prov_service_mode', value);
                          setFieldValue('prov_service', value);
                        }}
                        name="prov_service"
                        id="prov_service"
                        custom
                        valid={!errors.prov_service}
                        invalid={!!errors.prov_service}
                        value={values.prov_service}
                        error={errors.prov_service}>
                        {org_service_provider_mapping.map((item) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.prov_service && <ErrorText>{errors.prov_service}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>
                        {organzationLabels.service_mode}
                      </SettingsModalBoldText>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        onChange={(e) => {
                          setFieldValue('prov_service_mode', parseInt(e));
                        }}
                        name="prov_service_mode"
                        id="prov_service_mode"
                        custom
                        required={values.prov_service > 0 ? true : false}
                        valid={!errors.prov_service_mode}
                        invalid={!!errors.prov_service_mode}
                        value={values.prov_service === 0 ? 0 : values.prov_service_mode}
                        disabled={values.prov_service > 0 ? false : true}
                        style={values.prov_service > 0 ? {} : { cursor: 'not-allowed' }}
                        error={errors.prov_service_mode}>
                        {org_service_mode_mapping.map((item) => (
                          <SelectItem
                            key={item.id}
                            value={item.id}
                            disabled={parseInt(item.id) === 0}>
                            {item.value}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.prov_service_mode && (
                        <ErrorText>{errors.prov_service_mode}</ErrorText>
                      )}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>
                        {organzationLabels.configuration}
                      </SettingsModalBoldText>
                      {values.config && (
                        <>
                          <Configurations
                            fieldName="config"
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            isEdit
                          />

                          {errorInConfig && <ErrorText>{errorInConfig}</ErrorText>}
                        </>
                      )}
                    </InputSeparator>
                    {errors?.config && <ErrorText>{errors.config}</ErrorText>}
                  </StyledModalBody>
                </>
              )}

              <ModalFooter
                text={t('Update')}
                error={error}
                handleCancel={handleCancel}
                onClick={() => {}}
                status={status === 'EDIT_ORGANIZATION_PENDING' ? true : false}
                style={
                  isValidToSubmit(dirty, isValid) || isSubmitting
                    ? { cursor: 'not-allowed' }
                    : { cursor: 'pointer' }
                }
                disabled={isValidToSubmit(dirty, isValid) || isSubmitting}
                className={(dirty || resetToDefault) && isValid ? 'btn btn-primary' : 'btn'}
              />
            </StyledModal>
          )}
        </CForm>
      )}
    </Formik>
  );
};

export default EditOrganization;
