import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PlusIcon } from "../assets/icons/plus.svg";
import { changeOpenedModal } from "../redux/actions/headerModal";
import {
  CCol,
  CFormGroup,
  CLabel,
  CRow,
  CSelect
} from "@coreui/react";
import { getAmenities } from "../redux/actions/amenities";
import {
  clearUnitResponse,
  getDDUnits,
  setUserUnit
} from "../redux/actions/unit";
import { useTranslation } from "react-i18next";
import { UserRole } from "../views/users/constant";
import { DefaultLoadingIcon } from "./AsyncPagination/loadingIcons";

const BuildingDropDown = ({
  values,
  handleBlur,
  handleChange,
  buildings,
  disable = false,
  errors,
  touched,
  showAdd = false,
  className,
  setFieldValue,
  isLoading,
}) => {
  const { building_id, roleId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (roleId === UserRole.MANAGER) showAdd = false;
  return (
    <div>
      <CFormGroup>
        <CRow className="addFormPlusButtonDiv">
          <CCol>
            <CLabel htmlFor="Building_ID">{t("Building")}</CLabel>
          </CCol>
          {showAdd ? (
            <CCol className="text-right">
              <div
                className="addFormPlusButton"
                onClick={() => {
                  dispatch(
                    changeOpenedModal({
                      modal: "addBuilding"
                    })
                  );
                  //START : doing this if user comes from unit form to user form but then went to add new building or somewhere else remove all data
                  dispatch(setUserUnit({}));
                  dispatch(clearUnitResponse());
                  //END
                }}
              >
                <PlusIcon />

                <span className="ml-2">{t("Add new building")}</span>
              </div>
            </CCol>
          ) : (
            <></>
          )}
        </CRow>
        <div className="building-dropdown-position">
          <CSelect
            className={className}
            name="building_id"
            id="building_id"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              setTimeout(() => {
                handleBlur(e);
              })
              if (setFieldValue){
                setFieldValue("unit_id", 0)
              }
              if (e.target.value !== "") {
                dispatch(
                  getDDUnits({
                    id: e.target.value
                  })
                );
                dispatch(getAmenities({ id: e.target.value }));
              }
            }}
            disabled={buildings.length === 0 || disable || isLoading}
            style={disable ? { cursor: "not-allowed" } : {}}
            value={values.building_id ? values.building_id : 0}
            custom
            valid={!errors.building_id && touched.building_id}
            invalid={
              (touched.building_id && !!errors.building_id) ||
              String(values.building_id) === "-1"
            }
          >
            <option value={-1}>{t("Please select")}</option>
            {buildings && parseInt(roleId) >= UserRole.ADMIN
              ? buildings.map((building) => (
                  <option key={building.id} value={building.id}>
                    {building.name}
                  </option>
                ))
              : buildings
                  .filter((map) => parseInt(map.id) === parseInt(building_id))
                  .map((building) => (
                    <option key={building.id} value={building.id}>
                      {building.name}
                    </option>
                  ))}
          </CSelect>
          {
            isLoading && 
            <DefaultLoadingIcon className="spin-icon"/>
          }
        </div>
      </CFormGroup>
      {((touched.building_id && !!errors.building_id) || String(values.building_id) === "-1")  && (
        <div
          className="invalid-feedback"
          style={{ display: "block", marginTop: "-10px" }}
        >
          {errors.building_id}
        </div>
      )}
    </div>
  );
};

export default BuildingDropDown;
