import { CCol, CImg, CRow, CCard, CCardBody, CButtonClose, CCardHeader } from '@coreui/react';
import locale from 'antd/es/date-picker/locale/fr_FR';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { DatePicker } from 'antd';
import moment from 'moment';
import LoadingAvatar from '../../components/LoadingAvatar';
import ModalFooter from '../../components/ModalFooter';
import UserAsyncDropDown from '../../components/UserAsyncDropDown';
import { getFullDate12Hr, getStatusId } from '../../helper';
import {
  clearServiceError,
  getCategories,
  getService,
  getServices,
  searchRequests,
  updateService
} from '../../redux/actions/services';
import { getBuildingUsers, getUser, setUser } from '../../redux/actions/user';
import { setToast } from '../../redux/actions/window';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../utils/colorCode';
import localeEn from 'antd/es/date-picker/locale/en_US';
import i18next from 'i18next';
import { PinRelatedCategoryId } from './constant';
import { CDropdown, CButton, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import { RequestStatus, StatusBGVariants } from './constant';
import { setSearchField, setShouldClearQuery } from '../../redux/actions/search';
import { ENTITY } from '../../utils/searchConstant';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { Select, SelectItem } from '../../components/ui/Select';
import { InputSeparator, SettingsModalBoldText } from '../settings/SettingsModals';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import { DropdownToggle, StyledDateText } from './ServiceComponents';
import { CustomDatePicker } from '../../components/CustomSearchBar';

const ServiceDetailEdit = () => {
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(false);
  const { modal } = useSelector((state) => state.headerModal);
  const { user } = useSelector((state) => state.user);
  const { service, categories, status, error, pagination, searchStatus, search } = useSelector(
    (state) => state.services
  );
  const { globalOrgId } = useSelector((state) => state.Organization);
  const { globalBuildingId } = useSelector((state) => state.building);
  const { t } = useTranslation();
  const localLang = i18next.resolvedLanguage === 'fr' ? locale : localeEn;
  const servicesLabels = {
    category: t('Category'),
    subject: t('Subject'),
    description: t('Description'),
    contact_method: t('Preferred method of contact'),
    urgency: t('Urgency'),
    organization: t('Organization'),
    building: t('Building'),
    tenant: t('Resident'),
    assigned_to: t('Assigned to'),
    scheduled_on: t('Scheduled on')
  };

  const [date, setDate] = useState('');
  const [isDateChanged, setIsDateChanged] = useState('');
  const [serviceReq, setServiceReq] = useState({
    category_id: null,
    schedule_date: '',
    assigned_to: null,
    status_id: null
  });
  const [assignedTechnician, setAssignedTechnician] = useState({});
  const [serviceStatus, setServiceStatus] = useState(service?.status ?? '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setServiceStatus(service?.status ?? '');
    if (service.assigned_to === 0) {
      dispatch(setUser({ first_name: 'Please', last_name: 'select', id: 0 }));
    } else {
      if (service.assigned_to) {
        dispatch(getUser({ id: service.assigned_to }));
      }
    }
  }, [service]);

  useEffect(() => {
    if (serviceStatus === RequestStatus.OPEN) {
      setAssignedTechnician(null);
    }
  }, [serviceStatus]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      let firstName = user.first_name;
      let lastName = user.last_name;
      if (user.id === 0) {
        firstName = t(user.first_name);
        lastName = t(user.last_name);
      }

      setAssignedTechnician({
        value: user.id,
        label: firstName + ' ' + lastName
      });
    }
  }, [user, t]);

  const submit = () => {
    let myContactInfo = service.contact_info;
    const body = {
      user_id: parseInt(service.user_id),
      building_id: parseInt(service.building_id),
      subject: service.subject,
      description: service.description,
      media: service.media,
      time_slot: service.time_slot ? service.time_slot : null,
      contact_type: service.contact_type,
      priority: service.priority,
      allow_access: service.allow_access,
      assigned_to: assignedTechnician?.value || 0,
      category_id: serviceReq.category_id,
      schedule_date: serviceReq.schedule_date,
      status_id: getStatusId(serviceStatus),
      data: service.data,

      contact_info: myContactInfo
    };
    dispatch(
      updateService({
        id: service.id,
        body: body,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'serviceDetail'
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Service request details have been successfully updated!')
            })
          );
          dispatch(getService({ id: service.id }));
          if (search) {
            dispatch(
              searchRequests({
                query: search,
                page: 1,
                search_col: null,
                building: globalBuildingId,
                org: globalOrgId,
                status: searchStatus
              })
            );
          } else {
            dispatch(
              getServices({
                id: globalBuildingId,
                page: 1,
                orgId: globalOrgId,
                status: searchStatus
              })
            );
          }
          dispatch(setSearchField({ entity: ENTITY.request, value: null }));
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Service request updation failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const onClose = () => {
    setServiceStatus('');
    dispatch(
      changeOpenedModal({
        modal: 'serviceDetail'
      })
    );
    dispatch(clearServiceError());
  };

  const onChange = (e, isInt) => {
    e.persist();

    setServiceReq((prev) => ({
      ...prev,
      [e.target.name]: parseInt(e.target.value)
    }));
  };

  const handleSchedule = (value, dateString) => {
    setDate(value);
    const isoDate = Boolean(value) ? value.utcOffset(0, true).toISOString() : '';
    setServiceReq((prev) => ({
      ...prev,
      schedule_date: value ? isoDate : null
    }));
  };

  useEffect(() => {
    const incommingStatus = service.status && getStatusId(service.status);

    let dateTime = service.scheduled_date ? service.scheduled_date : '';
    if (dateTime !== '') {
      dateTime = dateTime.split('T');
      const date = dateTime[0];
      const time = dateTime[1].split('.')[0];
      setDate(service.scheduled_date ? moment(`${date} ${time}`) : ''); // doing this to populate proper dateTIme in calender
      setIsDateChanged(service.scheduled_date ? service.scheduled_date : '');
    }
    status === 'GET_SERVICE_SUCCESS' && dispatch(getCategories());

    service.building_id && dispatch(getBuildingUsers({ id: service.building_id }));

    setServiceReq((prev) => ({
      ...prev,
      category_id: parseInt(service.category_id),
      schedule_date: service.scheduled_date,
      status_id: incommingStatus,
      assigned_to: service.assigned_to ? service.assigned_to : 0
    }));
  }, [service, dispatch]);

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }
  const handleCancel = () => {
    onClose();
  };

  const isUpdateDisabled = useMemo(() => {
    const isNoFieldUpdated =
      serviceReq.category_id === service.category_id &&
      serviceReq.assigned_to === assignedTechnician?.value &&
      serviceReq.schedule_date === isDateChanged &&
      serviceStatus === service.status;
    if (isNoFieldUpdated) return isNoFieldUpdated;

    const isInvalidFields =
      !serviceStatus ||
      serviceReq.category_id <= 0 ||
      (serviceStatus === RequestStatus.IN_PROGRESS && !assignedTechnician?.value);
    return isInvalidFields || service.status === 'Complete';
  }, [serviceReq, service, assignedTechnician, isDateChanged, serviceStatus]);

  return (
    <StyledModal
      show={modal === 'serviceDetailEdit'}
      onClose={handleCancel}
      closeOnBackdrop={false}>
      <ModalHeader title={t('Edit service request')} />
      <StyledModalBody>
        <div>
          {status === 'GET_SERVICE_PENDING' ? (
            <LoadingAvatar color={LOADING_BLUE} />
          ) : status === 'GET_SERVICE_FAIL' ? (
            <div className="error_for_modal">
              <h4 className="text-danger">{error}</h4>
            </div>
          ) : (
            <>
              <CRow className="imgAndDetailDiv">
                <CCol>
                  <InputSeparator>
                    <SettingsModalBoldText>{service.subject}</SettingsModalBoldText>
                    <StyledDateText>
                      {service.create_date && getFullDate12Hr(service.create_date)}
                    </StyledDateText>
                  </InputSeparator>
                </CCol>
                <CCol>
                  <InputSeparator>
                    <SettingsModalBoldText>{t('Updated on:')}</SettingsModalBoldText>
                    <StyledDateText>
                      {service.last_update && getFullDate12Hr(service.last_update)}
                    </StyledDateText>
                  </InputSeparator>
                </CCol>
              </CRow>
              <CRow className="statusDiv">
                <CCol sm="5" className="leftCol">
                  <InputSeparator>
                    <SettingsModalBoldText>{t('Status: ')}</SettingsModalBoldText>
                    <CDropdown variant="btn-group">
                      <DropdownToggle
                        caret
                        color={StatusBGVariants[serviceStatus]}
                        disabled={
                          service.status === RequestStatus.COMPLETE ||
                          service.status === RequestStatus.ARCHIVED
                        }>
                        {t(serviceStatus)}
                      </DropdownToggle>
                      <StyledDropdown
                        show={isDropdownOpen}
                        style={{ transform: 'translate(0px, 60px)' }}>
                        {Object.values(RequestStatus).map(
                          (val) =>
                            val !== RequestStatus.ARCHIVED &&
                            !(
                              parseInt(service.category_id, 10) === PinRelatedCategoryId &&
                              val === RequestStatus.COMPLETE
                            ) && (
                              <CDropdownItem
                                active={serviceStatus === val}
                                onClick={() => {
                                  setIsDropdownOpen(false);
                                  setServiceStatus(val);
                                }}
                                disabled={
                                  service.status === RequestStatus.COMPLETE ||
                                  service.status === RequestStatus.ARCHIVED
                                }
                                key={`select-${val}`}>
                                {val}
                              </CDropdownItem>
                            )
                        )}
                      </StyledDropdown>
                    </CDropdown>
                  </InputSeparator>
                </CCol>
              </CRow>

              <InputSeparator>
                <SettingsModalBoldText>{servicesLabels.category}</SettingsModalBoldText>
                <Select
                  loadingMessage={() => t('Loading...')}
                  disabled={
                    completed === true ||
                    parseInt(service.category_id, 10) === PinRelatedCategoryId ||
                    service.status === RequestStatus.COMPLETE ||
                    service.status === RequestStatus.ARCHIVED
                  }
                  onChange={(value) => {
                    setServiceReq((prev) => ({
                      ...prev,
                      category_id: parseInt(value)
                    }));
                  }}
                  name="category_id"
                  id="category_id"
                  value={serviceReq.category_id ? serviceReq.category_id : 0}>
                  {categories &&
                    categories.map((item) => {
                      return (
                        <SelectItem
                          key={`${item.ID}-${item.SubCategory}`}
                          value={item.ID}
                          disabled={item.ID === PinRelatedCategoryId}>
                          {item.Name}/{item.SubCategory}
                        </SelectItem>
                      );
                    })}
                </Select>
              </InputSeparator>

              <InputSeparator>
                <SettingsModalBoldText>{servicesLabels.assigned_to}</SettingsModalBoldText>
                <UserAsyncDropDown
                  skip_deleted={true}
                  disabled={
                    service.status === RequestStatus.COMPLETE ||
                    completed === true ||
                    serviceStatus !== RequestStatus.IN_PROGRESS ||
                    service.status === RequestStatus.ARCHIVED
                  }
                  value={assignedTechnician}
                  setValue={setAssignedTechnician}
                  type={2}
                  buildingId={service.building_id}
                  height={'90px'}
                  asyncPaginateStyles={{ height: '150px' }}
                />
              </InputSeparator>
              {!isUpdateDisabled && serviceStatus === RequestStatus.COMPLETE && (
                <StyledWarningText>
                  Once service request is updated to 'Complete', it will not be reversible
                </StyledWarningText>
              )}

              {assignedTechnician?.value > 0 && (
                <InputSeparator>
                  <SettingsModalBoldText>{servicesLabels.scheduled_on}</SettingsModalBoldText>
                  <CustomDatePicker
                    width={'100%'}
                    height={'56px'}
                    cssOverride={'padding: 0 24px;'}
                    disabled={
                      service.status === RequestStatus.COMPLETE ||
                      service.status === RequestStatus.ARCHIVED
                    }
                    disabledDate={disabledDate}
                    showTime
                    value={date}
                    name="schedule_date"
                    use12Hours
                    showSecond={false}
                    onChange={handleSchedule}
                    format="YYYY-MM-DD hh:mm A"
                    placeholder={t('Select date')}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    locale={{
                      ...localLang,
                      lang: {
                        ...localLang.lang,
                        ok: 'OK'
                      }
                    }}
                  />
                </InputSeparator>
              )}
            </>
          )}
        </div>
      </StyledModalBody>
      {status === 'GET_SERVICE_PENDING' || status === 'GET_SERVICE_FAIL' ? (
        <></>
      ) : (
        <ModalFooter
          text={t('Update')}
          handleCancel={handleCancel}
          className={isUpdateDisabled ? 'btn disable' : 'btn  btn-primary'}
          disabled={isUpdateDisabled}
          onClick={submit}
          status={status === 'UPDATE_SERVICE_PENDING' ? true : false}
        />
      )}
    </StyledModal>
  );
};

const StyledDropdown = styled(CDropdownMenu)`
  transform: translate(0px, 40px) !important;
`;

const StyledWarningText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0;
`;

export default ServiceDetailEdit;
