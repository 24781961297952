import { takeLatest } from "redux-saga/effects";
import {
  SEARCH_BUSINESSES,
  ADD_BUSINESS,
  EDIT_BUSINESS,
  GET_BUSINESS,
  GET_BUSINESSES,
  SET_BUSINESSES_LOGO,
  REMOVE_BUSINESS
} from "../constants";
import apiCall from "../../utils/apiCall";

const addBusiness = apiCall({
  type: ADD_BUSINESS,
  method: "post",
  path: "/admin/businesses"
});

const searchBusinesses = apiCall({
  type: SEARCH_BUSINESSES,
  method: "get",
  newUrl: true
});

const editBusiness = apiCall({
  type: EDIT_BUSINESS,
  method: "put",
  path: ({ id }) => `/admin/businesses/${id}`
});

const getBusinesses = apiCall({
  type: GET_BUSINESSES,
  method: "get",
  path: () => `/admin/businesses`
});

const getBusiness = apiCall({
  type: GET_BUSINESS,
  method: "get",
  path: ({ id }) => `/admin/businesses/${id}`
});

const removeBusiness = apiCall({
  type: REMOVE_BUSINESS,
  method: "delete",
  path: ({ id }) => `/admin/businesses/${id}`
});

const setBusinessLogo = apiCall({
  type: SET_BUSINESSES_LOGO,
  method: "post",
  path: "/images",
  isFormData: true
});

export default function* businessSaga() {
  yield takeLatest(REMOVE_BUSINESS, removeBusiness);
  yield takeLatest(SEARCH_BUSINESSES, searchBusinesses);
  yield takeLatest(ADD_BUSINESS, addBusiness);
  yield takeLatest(EDIT_BUSINESS, editBusiness);
  yield takeLatest(GET_BUSINESSES, getBusinesses);
  yield takeLatest(GET_BUSINESS, getBusiness);
  yield takeLatest(SET_BUSINESSES_LOGO, setBusinessLogo);
}
