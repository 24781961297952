import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUnits,
  getUnit,
  filterUnits,
  getDoorPin,
  removeUnit,
  setUnitPagination
} from '../../../../redux/actions/unit';
import { CDropdown, CDropdownToggle, CPagination } from '@coreui/react';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { setToast } from '../../../../redux/actions/window';
import { getFullDate12Hr } from '../../../../helper';
import { ReactComponent as FullBattery } from '../../../../assets/icons/battery/full.svg';
import { ReactComponent as SeventyPercent } from '../../../../assets/icons/battery/seventyPercent.svg';
import { ReactComponent as FortyPercent } from '../../../../assets/icons/battery/fortyPercent.svg';
import { ReactComponent as TwentyPercent } from '../../../../assets/icons/battery/twentyPercent.svg';
import { ReactComponent as NotActive } from '../../../../assets/icons/battery/notActive.svg';
import DeleteModal from '../../../../components/DeleteModal';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setCsvFields } from '../../../../redux/actions/reports';
import { DATE_FORMATS, UserRole } from '../../../users/constant';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import { setGlobalBuildingStatus, setGlobalBuilding } from '../../../../redux/actions/building';
import { requestPending } from '../../../../utils/status';
import { SET_GLOBAL_BUILDING } from '../../../../redux/constants';
import Table from '../../../../components/Table';
import { StyledDropdown, StyledDropdownItem } from '../../../../components/GlobalTableEditDropdown';
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import { Separator } from '../../../../components/ui/Separator';
import { RiPencilFill } from 'react-icons/ri';
import { AVAILABLE_TABS } from '..';
import moment from 'moment';

const Unit = () => {
  const { building, buildingsPerRole } = useSelector((state) => state.building);
  const { globalOrgId, orgConfig, organization } = useSelector((state) => state.Organization);
  const { units, status, pagination } = useSelector((state) => state.unit);
  const [showModal, setShowModal] = useState({
    id: null,
    show: false
  });
  const [activePage, setActivePage] = useState(1);
  const [numOfPages, setNumOfPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [sort, setSort] = useState('floor');
  const [sortOrder, setSortOrder] = useState('asc');

  const history = useHistory();
  const { config } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { roleId } = useSelector((state) => state.auth);

  useEffect(() => {
    const commonFields = [
      {
        key: 'unit_num',
        label: orgConfig?.config?.suite_enabled ? t('Room Number') : t('Unit Number')
      },
      { key: 'floor', label: t('Floor') },
      {
        key: 'resident',
        label: t('Resident')
      },
      { key: 'lease_expiry', label: t('Move out date') },
      { key: 'battery_level', label: t('Battery') }
    ];

    if (orgConfig?.config?.suite_enabled) {
      commonFields.push({ key: 'suite_name', label: 'Unit Name' });
    }

    dispatch(setCsvFields(commonFields));
  }, [dispatch, t, orgConfig]);

  useEffect(() => {
    const buildingIdFromUrl = history.location.pathname.split('/')[2];
    dispatch(
      getUnits({
        id: parseInt(buildingIdFromUrl),
        page: activePage,
        sort: sort,
        sortOrder: sortOrder,
        perPage: itemsPerPage
      })
    );
    dispatch(
      setUnitPagination({
        pagination: {
          ...pagination,
          sort: sort,
          sortOrder: sortOrder
        }
      })
    );
  }, [dispatch, activePage, sort, sortOrder, itemsPerPage]);

  const fields = useMemo(() => {
    const memoFields = [
      {
        key: 'unit_num',
        label: orgConfig?.config?.suite_enabled ? t('Room Number') : t('Unit Number'),
        sorter: false,
        filter: false
      },
      { key: 'floor', label: t('Floor'), sorter: true, filter: false },
      { key: 'resident', label: t('Resident'), sorter: false, filter: false },
      {
        key: 'lease_expire',
        label: t('Move out date'),
        sorter: false,
        filter: false
      },
      { key: 'battery_level', label: t('Battery'), sorter: false, filter: false },
      { key: 'lock_info', label: t('Lock info'), sorter: false, filter: false },
      { key: 'edit', label: '', sorter: false, filter: false }
    ];

    if (!(config.show_user_pin_admin || roleId === UserRole.SUPER_ADMIN)) {
      memoFields.splice(5, 1);
    }

    return memoFields;
  }, [config, roleId, orgConfig]);

  const handleSortChange = (e) => {
    let sortOrder = null;
    let sort = null;

    if (sort === 'expires') sort = 'expire_date';

    switch (e.column) {
      case 'floor':
        sort = 'floor';
        break;
      default:
        sort = 'floor';
    }

    switch (e.asc) {
      case true:
        sortOrder = 'asc';
        break;
      case false:
        sortOrder = 'desc';
        break;
      default:
        sortOrder = 'asc';
    }

    setSort(sort);

    setSortOrder(sortOrder);
  };

  const handleEdit = (id) => {
    dispatch(
      getUnit({
        id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'editRoom'
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('failed to get unit data!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleDetails = (id) => {
    const searchDate = moment().subtract(7, 'd');
    dispatch(
      getUnit({
        id,
        day: searchDate.date(),
        month: searchDate.month(),
        year: searchDate.year(),
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'UnitDetail',
              data: organization?.config?.suite_enabled
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('failed to get unit data!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleActivities = (id) => {
    dispatch(
      getUnit({
        id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'unitStatistics'
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('failed to get unit data!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      removeUnit({
        id: showModal.id,
        success: () => {
          let page = activePage;
          if (page > 1 && units && units.length === 1) {
            page -= 1;
            setActivePage(page);
          }
          dispatch(
            getUnits({
              id: building.id,
              page,
              sort: sort,
              sortOrder: sortOrder,
              perPage: itemsPerPage
            })
          );
          const globalBuildings = buildingsPerRole?.data.map((building) => building.id) || [];
          if (globalBuildings.includes(building.id)) {
            dispatch(setGlobalBuildingStatus({ status: requestPending(SET_GLOBAL_BUILDING) }));
            dispatch(
              setGlobalBuilding({
                id: globalOrgId
              })
            );
          }
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: orgConfig?.config?.suite_enabled
                ? t('Room has been successfully deleted!')
                : t('Unit has been successfully deleted!')
            })
          );
          setShowModal((prev) => ({
            ...prev,
            show: false
          }));
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Unit deletion failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleGetDoorPin = (id, unitNum) => {
    id != '' &&
      dispatch(
        getDoorPin({
          id,
          data: { unit_num: unitNum, serial: id },
          success: () => {
            dispatch(
              changeOpenedModal({
                modal: 'unitLockDetails',
                data: { from: AVAILABLE_TABS.UNITS }
              })
            );
          },
          fail: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: 'failed to get unit info!',
                modal: 'errorToast'
              })
            );
          }
        })
      );
  };

  const actionForPagination = (page) => {
    if (page) {
      setActivePage(page);
    }
  };

  const handlePaginationChange = (e) => {
    setItemsPerPage(e);
  };

  const handleSpecificColClick = (item, index, col, e) => {
    if (col !== 'lock_info' && col !== 'edit') {
      handleDetails(item.id);
    }
  };

  const getBatteryIcon = useCallback((batteryLevel) => {
    if (batteryLevel >= 75) {
      return <FullBattery />;
    } else if (batteryLevel >= 40) {
      return <SeventyPercent />;
    } else if (batteryLevel >= 20) {
      return <FortyPercent />;
    } else if (batteryLevel >= 0) {
      return <TwentyPercent />;
    } else {
      return <NotActive />;
    }
  }, []);

  return (
    <>
      <Table
        noItemsView={{
          noResults: t('no filtering results available'),
          noItems: t('no items available')
        }}
        // columnFilter
        sorter={{ external: true }}
        sorterValue={null}
        onSorterValueChange={handleSortChange}
        onPaginationChange={handlePaginationChange}
        items={units}
        fields={fields}
        hover
        clickableRows
        onRowClick={handleSpecificColClick}
        loading={status === 'GET_UNITS_PENDING' ? true : false}
        onFilteredItemsChange={(val) => dispatch(filterUnits(val))}
        scopedSlots={{
          unit_num: (item) => {
            return (
              <td
                style={{
                  fontSize: '16px'
                }}>
                <strong>{item['unit_num']}</strong>
              </td>
            );
          },
          resident: (item, index) => {
            return (
              <td
                style={
                  item.resident === ' '
                    ? {
                        color: '#C6C6C6'
                      }
                    : {}
                }>
                {' '}
                {item.resident === ' ' ? 'None' : item.resident}
              </td>
            );
          },
          create_date: (item, index) => {
            return <td>{getFullDate12Hr(item.create_date)}</td>;
          },
          lease_expire: (item, index) => {
            return <td>{item?.lease_expiry ? item?.lease_expiry : 'No active lease'}</td>;
          },
          battery_level: (item, index) => {
            const batteryLevel = item?.battery_level >= 0 ? item?.battery_level + '%' : '';
            const batteryLevelIcon = getBatteryIcon(item?.battery_level);
            return (
              <td>
                {batteryLevel} {batteryLevelIcon}
              </td>
            );
          },
          lock_info: (item, index) => {
            return config.show_user_pin_admin || roleId === UserRole.SUPER_ADMIN ? (
              <td
                style={
                  item.lock_id === ''
                    ? {
                        cursor: 'not-allowed',
                        textDecoration: 'none',
                        fontSize: '16px',
                        color: '#AEB0B5',
                        zIndex: 2
                      }
                    : {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '16px',
                        color: LOADING_BLUE,
                        zIndex: 2
                      }
                }
                onClick={() => handleGetDoorPin(item.lock_id, item.unit_num)}>
                {t('View')}
              </td>
            ) : (
              <></>
            );
          },
          edit: (item, index) => {
            return (
              <td className="text-right edit-width edit-container">
                <CDropdown className="tableEditDropdown">
                  <CDropdownToggle>
                    <RiPencilFill size={24} />
                  </CDropdownToggle>
                  <StyledDropdown>
                    <StyledDropdownItem onClick={() => handleDetails(item.id)}>
                      <FaInfoCircle size={16} className="mr-2" />
                      {t('Details')}
                    </StyledDropdownItem>
                    <Separator orientation="horizontal" />
                    <StyledDropdownItem onClick={() => handleActivities(item.id)}>
                      <FaInfoCircle size={16} className="mr-2" />
                      {t('Activities')}
                    </StyledDropdownItem>
                    {(config?.org_allow_edits || parseInt(roleId) == UserRole.SUPER_ADMIN) && (
                      <>
                        <Separator orientation="horizontal" />
                        <StyledDropdownItem onClick={() => handleEdit(item.id)}>
                          <RiPencilFill size={16} className="mr-2" />
                          {t('Edit')}
                        </StyledDropdownItem>
                      </>
                    )}
                    {parseInt(roleId) > UserRole.VENDOR && (
                      <>
                        <Separator orientation="horizontal" />
                        <StyledDropdownItem
                          className="deleteOption"
                          onClick={() => {
                            setShowModal((prev) => ({
                              show: true,
                              id: item.id
                            }));
                            dispatch(
                              changeOpenedModal({
                                modal: 'deleteModal'
                              })
                            );
                          }}>
                          <FaTrash size={16} className="mr-2" /> {t('Delete')}
                        </StyledDropdownItem>
                      </>
                    )}
                  </StyledDropdown>
                </CDropdown>
              </td>
            );
          }
        }}
      />
      <div className={'mt-2'}>
        <CPagination
          activePage={activePage}
          pages={pagination?.total ? Math.ceil(pagination?.total / itemsPerPage) : numOfPages}
          onActivePageChange={(i) => actionForPagination(i)}></CPagination>
      </div>

      {showModal.show && (
        <DeleteModal
          type={orgConfig?.config?.suite_enabled ? 'Room' : 'Unit'}
          action={handleDelete}
          setShowModal={setShowModal}
          description={`Are you sure you want to delete this ${
            orgConfig?.config?.suite_enabled ? 'room' : 'unit'
          }?`}
        />
      )}
    </>
  );
};

export default Unit;
