import { createReducer } from '@reduxjs/toolkit';
import {
  CHANGE_PASSWORD,
  GET_PROFILE,
  EDIT_PROFILE,
  UPLOAD_PROFILE_AVATAR,
  GET_PROFILE_AVATAR,
  SET_PREVIOUS_PROFILE_IMG,
  CLEAR_ERROR,
  RESET_PASSWORD,
  GET_SETTINGS,
  UPDATE_MY_SETTINGS
} from '../constants';
import { requestFail, requestPending, requestSuccess } from '../../utils/status';

const initialState = {
  status: '',
  error: null,
  profile: {},
  avatar: '',
  NonTechAvatar: '',
  settings_status: null,
  settings: {},
  settings_error: null
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [SET_PREVIOUS_PROFILE_IMG]: (state, { payload }) => ({
    ...state,
    avatar: payload.avatar
  }),

  [requestSuccess(GET_PROFILE_AVATAR)]: (state, { payload }) => ({
    ...state,
    avatar: payload,
    status: requestSuccess(GET_PROFILE_AVATAR),
    error: null
  }),

  [requestPending(GET_PROFILE_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_PROFILE_AVATAR)
  }),

  [requestFail(GET_PROFILE_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_PROFILE_AVATAR),
    avatar: ''
  }),

  [requestSuccess(UPLOAD_PROFILE_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(UPLOAD_PROFILE_AVATAR),
    avatar: payload.filename,
    error: null
  }),

  [requestFail(UPLOAD_PROFILE_AVATAR)]: (state, { payload }) => ({
    ...state,
    status: requestFail(UPLOAD_PROFILE_AVATAR),
    avatar: '',
    error: payload
  }),

  [requestSuccess(EDIT_PROFILE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_PROFILE),
    error: null
  }),

  [requestFail(EDIT_PROFILE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_PROFILE),
    error: payload.data
  }),

  [requestSuccess(CHANGE_PASSWORD)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(CHANGE_PASSWORD),
    error: null
  }),

  [requestFail(CHANGE_PASSWORD)]: (state, { payload }) => ({
    ...state,
    status: requestFail(CHANGE_PASSWORD),
    error: payload.data
  }),

  [requestSuccess(RESET_PASSWORD)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(RESET_PASSWORD),
    error: null
  }),

  [requestFail(RESET_PASSWORD)]: (state, { payload }) => ({
    ...state,
    status: requestFail(RESET_PASSWORD),
    error: payload.data
  }),

  [requestSuccess(GET_SETTINGS)]: (state, { payload }) => {
    return {
      ...state,
      settings_status: requestSuccess(GET_SETTINGS),
      settings: payload,
      settings_error: null
    };
  },

  [requestPending(GET_SETTINGS)]: (state, { payload }) => {
    return {
      ...state,
      settings_status: requestPending(GET_SETTINGS),
      settings: {},
      settings_error: null
    };
  },

  [requestFail(GET_SETTINGS)]: (state, { payload }) => {
    return {
      ...state,
      settings_status: requestFail(GET_SETTINGS),
      settings: {},
      settings_error: payload.data
    };
  },

  ///// -----------------------

  [requestSuccess(UPDATE_MY_SETTINGS)]: (state, { payload }) => {
    return {
      ...state,
      settings_status: requestSuccess(UPDATE_MY_SETTINGS)
    };
  },

  [requestPending(UPDATE_MY_SETTINGS)]: (state, { payload }) => {
    return {
      ...state,
      settings_status: requestPending(UPDATE_MY_SETTINGS)
    };
  },

  [requestFail(UPDATE_MY_SETTINGS)]: (state, { payload }) => {
    return {
      ...state,
      settings_status: requestFail(UPDATE_MY_SETTINGS),
      settings_error: payload.data
    };
  },

  ///

  [requestSuccess(GET_PROFILE)]: (state, { payload }) => {
    localStorage.setItem('avatar', payload.avatar);

    return {
      ...state,
      status: requestSuccess(GET_PROFILE),
      profile: payload,
      error: null
    };
  },

  [requestFail(GET_PROFILE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_PROFILE),
    error: payload.data,
    profile: {}
  })
});
