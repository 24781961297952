import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from './ui/Badge';
import styled from 'styled-components';

const StatusInTable = ({ status }) => {
  const { t } = useTranslation();
  const getBadge = (status) => {
    switch (status) {
      case 'Cancelled':
        return 'danger';
      case 'Reserved':
        return 'reserved';
      case 'In Progress':
        return 'inProgress';
      case 'Open':
        return 'open';
      case 'New':
        return 'primary';
      case 'Archived':
        return 'archived';
      case 'Complete':
        return 'complete';
      default:
        return 'default';
    }
  };

  return (
    <TableBadge variant={getBadge(status)} style={{ width: '80px', fontSize: '12px' }}>
      {t(status)}
    </TableBadge>
  );
};

const TableBadge = styled(Badge)`
  width: 80px;
  font-size: 12px;
`;

export default StatusInTable;
