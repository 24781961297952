import React from "react";
import { CSelect, CInvalidFeedback } from "@coreui/react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Select = ({
  id,
  name,
  onChange,
  valid,
  value,
  data,
  onBlur,
  onFocus,
  disabled,
  invalid,
  required,
  style,
  modalName,
  className,
  defaultText,
  error
}) => {
  const { t } = useTranslation();
  const myDefaultText = defaultText ?? t("Please select");

  return (
    <div>
      <CSelect
        className={className}
        onChange={onChange}
        name={name}
        id={id}
        custom
        valid={valid}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        invalid={invalid}
        required={required}
        style={style}
      >
        {modalName !== "organization" ? (
          <>
            <option value={-1}>{myDefaultText}</option>
          </>
        ) : (
          ""
        )}
        {data}
      </CSelect>
      <CInvalidFeedback>
        {error}
      </CInvalidFeedback>
    </div>
  );
};

export default Select;
