import { createAction } from "@reduxjs/toolkit";
import { CLEAR_COUNTRY, GET_COUNTRY, GET_STATE } from "../constants";

export const getCountry = createAction(GET_COUNTRY);
export const getState = createAction(GET_STATE);
export const clearCountry = createAction(CLEAR_COUNTRY);

export default {
  getCountry,
  getState
};
