import React from 'react';
import AssignRequest from './AssignRequest';
import ScheduleRequest from './ScheduleRequest';
import styled from 'styled-components';

const index = ({ serviceStatus, onUpdateSuccess }) => {
  return (
    <Container>
      <AssignRequest serviceStatus={serviceStatus} onUpdateSuccess={onUpdateSuccess} />
      <ScheduleRequest serviceStatus={serviceStatus} onUpdateSuccess={onUpdateSuccess} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
`;

export default index;
