import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultLoadingIcon } from "./loadingIcons";
import { nanoid } from '@reduxjs/toolkit';
import AsyncStatePaginationValue from './AsyncStatePaginationValue';
import { IoChevronDown } from "react-icons/io5";

import { AsyncPaginationContainer, PaginationContainer, SelectedBarIcon, SelectedContainer } from './AsyncStatePaginationComponents';
import { Palette } from '../utils';

const renderDefault = ({
  renderMethod,
  defaultData,
  defaultText
}) => {
  if (!defaultData) {
    return <AsyncStatePaginationValue text={defaultText} />
  } else {
    return (
      <>
        {
          renderMethod(defaultData)
        }
      </>
    )
  }
}

const renderSelected = ({
  selectedData,
  renderMethod,
  renderDefault
}) => {
  if (selectedData) {
    return renderMethod()
  }
  return renderDefault()
}

const AsyncStatePagination = ({
  icon,
  loadingIconComponent = DefaultLoadingIcon,
  dataState = [], // a data array which is in a form of react state
  eventState, // a react state that keeps track of async evens
  events = { success: 'success', pending: 'pending' },  // the events that the paginator should listen to via eventState
  renderMethod, // a rendering method to render items in the list
  keyPrefix,
  loadMethod, // an async loading method
  setSelectedState, // a state setter, set when an item is selected
  onSelect, // select handler
  defaultData,
  defaultText,
  selectValue,
  pageInfo, // an object with key ['curPage', 'nextPage', 'hasMore']
  resetDependencies = [],  // an array of dependencies which will reset selected state to defaul when changing
  selected,
  sync
}) => {

  const uniqueID = nanoid();
  const LoadingIconComponent = React.createElement(loadingIconComponent);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(defaultData);
  const [showItems, setShowItems] = useState(false);

  const scrollRef = useRef(null);
  const componentRef = useRef(null);

  useEffect(() => {
    if (selected !== selectedData?.id) {
      if (selected === 0) {
        setSelectedData(defaultData);
        return;
      }
      const newData = dataState.filter((data) => data.id === selected);
      if (newData.length === 1) setSelectedData(newData[0]);
    }
  }, [selected])

  useEffect(() => {
    eventState && setIsLoading(eventState === events.pending)
  }, [eventState])

  const selectElement = (key, reset) => {
    let data = defaultData;
    if (!reset) {
      const idx = parseInt(key.split('-')[1], 10);
      // minus one to offset the first placeholder element
      if (idx > 0) {
        data = dataState[idx - 1];
      }
    }
    if (data) {
      if (typeof setSelectedState === "function") {
        setSelectedState(data);
      }
      setSelectedData(data);
      onSelect(data);
      setShowItems(false);
    }
  }

  function toggleShowItems() {
    setShowItems(prev => !prev);
  }

  // fetch request listener
  const onScroll = useCallback(() => {
    if (sync) {
      return;
    }
    if (!isLoading && pageInfo.hasMore) {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        if (scrollTop + clientHeight > scrollHeight - 100) {
          loadMethod({ page: pageInfo.nextPage });
        }
      }
    }
  }, [isLoading])

  // reset to default is resetDependencies are provided
  useEffect(() => {
    if (Array.isArray(resetDependencies) && resetDependencies.length > 0) {
      selectElement(null, true);
    }
  }, [...resetDependencies])

  function handleClickOutsideDropdown(e) {
    if (componentRef.current && !componentRef.current.contains(e.target)) {
      setShowItems(false);
    }
  }
  // modal like event listener that close paginator when clicking anywhere else
  useEffect(() => {
    if (showItems) document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    }
  }, [componentRef, showItems])


  return (
    <AsyncPaginationContainer ref={componentRef}>
      <SelectedContainer onClick={toggleShowItems}>
        {icon}
        <p>{selectValue(selectedData)}</p>
        <SelectedBarIcon>
          {
            isLoading ?
              LoadingIconComponent :
              <IoChevronDown size={15} style={{ color: Palette.HAZY_BLUE }} />
          }
        </SelectedBarIcon>
      </SelectedContainer>
      <PaginationContainer
        style={showItems ? {} : {
          display: "none"
        }}
        ref={scrollRef}
        onScroll={onScroll}
      >
        {
          [defaultData, ...dataState].map((data, idx) => {
            if (!data) return;
            return (
              <button
                key={`${keyPrefix}-${idx}-${uniqueID}`}
                onClick={() => selectElement(`${keyPrefix}-${idx}-${uniqueID}`)}
              >
                {renderMethod(data)}
              </button>
            )
          })
        }
      </PaginationContainer>
    </AsyncPaginationContainer>
  )
}

export { AsyncStatePagination };
