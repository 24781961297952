import i18next from 'i18next';
import { LOCK_PROVIDERS, ROLE_MAP } from './mappings';

export function getRoleNameByID(roleID) {
  const parsedRoleID = parseInt(roleID);
  return ROLE_MAP.find((type) => type.id === parsedRoleID)?.value || undefined;
}

export function getLockProviderNameByID(lockProviderId) {
  const parsedLockProviderID = parseInt(lockProviderId);
  return LOCK_PROVIDERS.find((type) => type.id === parsedLockProviderID)?.value || undefined;
}

export function getRoomUnitTerminology(organization) {
  return organization.config?.suite_enabled ? i18next.t('Room number') : i18next.t('Unit number');
}

export function getFilterCount(searchFilters) {
  return 1;
}
