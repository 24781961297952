import React, { useEffect } from 'react';
import {
  addUsersFromCSV,
  clearUserError,
  clearUsersFromCSV,
  clearUsersCSVFile,
  searchUsers
} from '../../redux/actions/user';
import { setToast } from '../../redux/actions/window';
import { useDispatch, useSelector } from 'react-redux';
import 'react-dropzone-uploader/dist/styles.css';
import {
  CFormGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CSelect,
  CLabel,
  CButton,
  CCardFooter,
  CContainer,
  CModalFooter
} from '@coreui/react';
import * as Yup from 'yup';
import {
  getAllBuildings,
  setGlobalBuildingId,
  setAllBuildings
} from '../../redux/actions/building';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { useTranslation } from 'react-i18next';
import UsersFromCSVTable from '../../components/UsersFromCSVTable';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserRole } from './constant';
import { delay } from 'lodash';
import LoadingButton from '../../components/LoadingButton';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { InputSeparator, SettingsModalBoldText } from '../settings/SettingsModals';
import { ErrorText } from '../../components/utils/AvatarCropper';
import { Select, SelectItem } from '../../components/ui/Select';
import { Button } from '../../components/ui/Button';

const AddUsersFromCSV = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { globalOrgId } = useSelector((state) => state.Organization);
  const { globalBuildingId } = useSelector((state) => state.building);
  const { roleId, orgId } = useSelector((state) => state.auth);
  const { allBuildings } = useSelector((state) => state.building);
  const { AllOrganizations } = useSelector((state) => state.Organization);
  const { error } = useSelector((state) => state.user);
  const { modal } = useSelector((state) => state.headerModal);
  const { usersFromCSV, usersCSVFile, searchFields } = useSelector((state) => state.user);
  const { selectedField } = useSelector((state) => state.search);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const schema = Yup.object().shape({
    building_id: Yup.string()
      .required(t('Select a building'))
      .test('is-required', t('Select a building'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),

    organization: Yup.string().test(
      'org-required',
      t('Select an organization'),
      (value, context) => {
        if (roleId == UserRole.SUPER_ADMIN) {
          return value != null && value != '' && parseInt(value) > 0;
        }
        return true;
      }
    )
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    resetField,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      organization: '',
      building_id: ''
    }
  });

  const watchOrganization = watch('organization');

  useEffect(() => {
    if (watchOrganization) {
      dispatch(
        getAllBuildings({
          id: parseInt(watchOrganization)
        })
      );
    }
  }, [watchOrganization]);

  useEffect(() => {
    if (Boolean(orgId) && roleId === UserRole.ADMIN) {
      dispatch(
        getAllBuildings({
          id: Number(orgId)
        })
      );
    }
  }, [orgId]);

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const data = new FormData();
    data.append('type', 4);
    data.append('doc', usersCSVFile);
    data.append('email', '');
    const targetOrgId = roleId === UserRole.ADMIN ? Number(orgId) : values.organization;
    data.append('org', targetOrgId);
    data.append('building', values.building_id);

    dispatch(
      addUsersFromCSV({
        body: data,
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: 'Users has been successfully added!'
            })
          );
          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );

          dispatch(clearUsersFromCSV());
          dispatch(clearUsersCSVFile());

          if (searchFields.query) {
            dispatch(
              searchUsers({
                ...searchFields,
                search_col: selectedField['user']?.value,
                building: globalBuildingId,
                org: globalOrgId !== -1 ? globalOrgId : orgId
              })
            );
          } else {
            dispatch(
              searchUsers({
                page: searchFields.page,
                org: globalOrgId !== -1 ? globalOrgId : orgId,
                building: globalBuildingId,
                status: searchFields.status
              })
            );
          }
          setIsSubmitting(false);
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Users creation from CSV failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };
  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearUsersFromCSV());
    dispatch(clearUsersCSVFile());
    dispatch(clearUserError());
  };

  return (
    <form name="addUsersFromCSVForm" onSubmit={handleSubmit(onSubmit)}>
      <StyledModal
        show={modal === 'AddUsersFromCSV' ? true : false}
        onClose={onClose}
        width="950px"
        closeOnBackdrop={false}>
        <ModalHeader title={t('Add new users')} />

        <StyledModalBody>
          <UsersFromCSVTable users={usersFromCSV?.staff} title={'Staff'} />
          <UsersFromCSVTable users={usersFromCSV?.residents} title={'Residents'} />

          <SettingsModalBoldText fontSize="24px">{t('Building')}</SettingsModalBoldText>

          {roleId === UserRole.SUPER_ADMIN && (
            <InputSeparator>
              <SettingsModalBoldText>{t('Organization')}</SettingsModalBoldText>
              <Controller
                control={control}
                name="organization"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Select
                    onBlur={onBlur}
                    onChange={(e) => {
                      resetField('building_id');
                      dispatch(setAllBuildings([]));
                      onChange(e);
                    }}
                    custom
                    id="organization"
                    value={value}
                    valid={!invalid && !errors.organization && isDirty}
                    invalid={invalid}>
                    <SelectItem value={''}>{t('Select organization')}</SelectItem>

                    {AllOrganizations &&
                      AllOrganizations.map((item, index) => (
                        <SelectItem value={item.id} key={index}>
                          {item.name}
                        </SelectItem>
                      ))}
                  </Select>
                )}
              />
              {errors.organization && <ErrorText>{errors?.organization.message}</ErrorText>}
            </InputSeparator>
          )}
          <InputSeparator>
            <SettingsModalBoldText>{t('Building')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="building_id"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <Select
                  disabled={
                    roleId === UserRole.ADMIN
                      ? !Boolean(orgId)
                      : !getValues('organization') || allBuildings?.length === 0
                  }
                  name="building_id"
                  id="building_id"
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  custom
                  valid={!invalid && !errors.building_id && isDirty}
                  invalid={invalid}>
                  {allBuildings?.length > 0 ? (
                    <SelectItem value={''}>{t('Please select')}</SelectItem>
                  ) : (
                    <SelectItem value={''}>{t('No Buildings Available')}</SelectItem>
                  )}

                  {allBuildings.map((building) => (
                    <SelectItem key={building.id} value={building.id}>
                      {building.name}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />
            {errors.building_id && <ErrorText>{errors?.building_id.message}</ErrorText>}
          </InputSeparator>
        </StyledModalBody>
        <CModalFooter>
          {error && <ErrorText>{error}</ErrorText>}
          <CFormGroup className="text-right m-0 flex">
            <Button text={t('Cancel')} width={'100px'} className="mr-2" onClick={onClose} />
            <Button
              text={t('Add')}
              isPrimary
              width={'100px'}
              type="submit"
              disabled={
                (usersFromCSV?.staff.length <= 0 && usersFromCSV?.residents.length <= 0) ||
                isSubmitting
              }
            />
          </CFormGroup>
        </CModalFooter>
      </StyledModal>
    </form>
  );
};

export default AddUsersFromCSV;
