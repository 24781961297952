import { createAction } from '@reduxjs/toolkit';
import {
  GET_PAR_AM_BOOKING,
  EDIT_PAR_AM_BOOKING,
  ADD_PAR_AM_BOOKING,
  EDITING_PAR_AM_BOOKING,
  GET_AVAILABLE_TIME,
  SET_PARAM_ERROR,
  CANCEL_BOOKING,
  CLEAR_ERROR,
  GET_PARKING_PAR_AM_BOOKINGS,
  GET_SELECTED_BOOKINGS,
  GET_AMENITY_PAR_AM_BOOKINGS,
  REMOVE_BOOKING,
  SEARCH_BOOKINGS,
  SEARCH_BOOK_PARKINGS,
  SEARCH_BOOK_AMENITIES,
  SEARCH_BOOK_BY_TYPE,
  SEARCH_BOOK_BY_DATE_RANGE,
  BOOKING_FROM_AMENITY,
  GET_ELEVATOR_PAR_AM_BOOKINGS,
  SEARCH_BOOK_ELEVATOR,
  RESET_BOOKING_PAGINATION,
  CLEAR_PAR_AM_BOOKING,
  SET_BOOKING_SEARCH
} from '../constants';

export const getSelectedBookings = createAction(GET_SELECTED_BOOKINGS);

export const getParkingParAmBookings = createAction(GET_PARKING_PAR_AM_BOOKINGS);
export const getAmenityParAmBookings = createAction(GET_AMENITY_PAR_AM_BOOKINGS);
export const GetElevatorParAmBookings = createAction(GET_ELEVATOR_PAR_AM_BOOKINGS);
export const bookingFromAmenity = createAction(BOOKING_FROM_AMENITY);
export const searchBookedParkings = createAction(SEARCH_BOOK_PARKINGS);
export const searchBookedByType = createAction(SEARCH_BOOK_AMENITIES);

export const searchBookByType = createAction(SEARCH_BOOK_BY_TYPE);
export const searchBookByDateRange = createAction(SEARCH_BOOK_BY_DATE_RANGE);
export const setBookingSearch = createAction(SET_BOOKING_SEARCH);
export const searchBookedElevator = createAction(SEARCH_BOOK_ELEVATOR);
export const removeBooking = createAction(REMOVE_BOOKING);
export const searchBookings = createAction(SEARCH_BOOKINGS);
export const getParAMBooking = createAction(GET_PAR_AM_BOOKING);
export const editParAmBooking = createAction(EDIT_PAR_AM_BOOKING);
export const editingParAmBooking = createAction(EDITING_PAR_AM_BOOKING);
export const addParAmBooking = createAction(ADD_PAR_AM_BOOKING);
export const getAvailableTime = createAction(GET_AVAILABLE_TIME);
export const setParAmError = createAction(SET_PARAM_ERROR);
export const cancelBooking = createAction(CANCEL_BOOKING);
export const clearBookingError = createAction(CLEAR_ERROR);
export const resetBookingPagination = createAction(RESET_BOOKING_PAGINATION);
export const clearParAmBooking = createAction(CLEAR_PAR_AM_BOOKING);
