import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalBuildingId } from '../redux/actions/building';
import { useResetAllPaginations } from '../hooks/useResetAllPaginations';
import {
  filterConfig,
  IconOption,
  inputSelectCustomStyles,
  StyledAsyncPaginate
} from './CustomSearchBar';
import { closeActiveModal } from '../helper/modals';
import { getBuildings } from '../helper';
import { components } from 'react-select';
import { Palette } from './utils';
import { BsBuildingsFill } from 'react-icons/bs';

const defaultBuilding = [
  {
    value: 0,
    label: 'All buildings'
  }
];

const GlobalBuildingDropDown = () => {
  const { globalOrgId } = useSelector((state) => state.Organization);
  const { globalBuildingId, globalBuildingName, buildingChangeEventCount } = useSelector(
    (state) => state.building
  );
  const { t } = useTranslation();
  const { resetAllPaginations } = useResetAllPaginations();
  const dispatch = useDispatch();

  const handleOnChange = (building) => {
    dispatch(
      setGlobalBuildingId({ globalBuildingId: building.value, globalBuildingName: building.label })
    );
    resetAllPaginations();
    closeActiveModal();
  };

  const loadOptions = useCallback(
    async (searchQuery, loadedOptions, additional) => {
      if (globalOrgId <= 0) {
        return {
          options: [],
          hasMore: false
        };
      }

      const { page } = additional;
      const responseJSON = await getBuildings(globalOrgId, page);
      const myResponse = defaultBuilding.concat(
        responseJSON.map((building) => {
          return {
            value: building.id,
            label: building.name
          };
        })
      );

      return {
        options: myResponse,
        hasMore: responseJSON !== null && responseJSON.length >= 20,
        additional: {
          page: searchQuery ? 2 : page + 1
        }
      };
    },
    [globalOrgId, globalBuildingId]
  );

  useEffect(() => {
    dispatch(
      setGlobalBuildingId({
        globalBuildingId: 0,
        globalBuildingName: ''
      })
    );
  }, [globalOrgId]);

  return (
    <StyledAsyncPaginate
      key={`${globalOrgId}_${globalBuildingId}_${buildingChangeEventCount}`}
      debounceTimeout={500}
      isDisabled={globalOrgId <= 0}
      className="react-select-container"
      classNamePrefix="react-select"
      value={globalBuildingId ? { label: globalBuildingName } : ''}
      loadOptionsOnMenuOpen={true}
      loadOptions={loadOptions}
      onChange={handleOnChange}
      filterOption={filterConfig}
      placeholder={t('All buildings')}
      styles={inputSelectCustomStyles({ height: '56px' })}
      components={{
        IndicatorSeparator: () => null,
        Option: IconOption,
        Control
      }}
      additional={{
        page: 1
      }}
    />
  );
};

const Control = (props) => (
  <components.Control className="flex justify-content-between" {...props}>
    <BsBuildingsFill color={Palette.HAZY_BLUE} size={18} className="mr-1" />
    {props.children}
  </components.Control>
);

export default GlobalBuildingDropDown;
