// https://ui.shadcn.com/docs/components/button

import React from 'react';
import styled, { css } from 'styled-components';
import { Palette } from '../utils/palette';
import { variant } from './variant';
import { Fonts } from '../utils';

export function ButtonVariant({ children, ...props }) {
  return <ButtonBodyVariant {...props}>{children}</ButtonBodyVariant>;
}

export const ButtonBodyVariant = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 30px;
  border: 1px solid ${Palette.HAZY_BLUE};
  font: normal normal 600 14px ${Fonts.OPEN_SANS_FONT};

  :hover:not(:disabled) {
    color: ${Palette.OCEANIC};
  }

  ${variant(
    {
      default: css`
        background-color: ${Palette.HAZY_BLUE};
        color: ${Palette.WHITE};

        :active:not(:disabled) {
          background-color: ${Palette.DARK_ASH};
        }
      `,
      secondary: css`
        outline: none !important;
        border: 1px solid ${Palette.HAZY_BLUE};

        background-color: ${Palette.WHITE};
        color: ${Palette.HAZY_BLUE};

        :active:not(:disabled) {
          background-color: ${Palette.SOLITUDE};
        }
      `,
      outline: css`
        outline: none !important;
        border: ${Palette.BLACK_50} solid 1px;

        background-color: ${Palette.GHOST_WHITE};
        color: ${Palette.BLACK_50};

        :active:not(:disabled) {
          background-color: ${Palette.SOLITUDE};
        }
      `
    },
    'variant'
  )}

  ${variant(
    {
      default: css`
        padding: 0.5rem 1.875rem;
      `,
      sm: css`
        padding: 0.5rem 1.25rem;
      `,
      icon: css`
        height: 2.5rem;
        width: 2.5rem;
      `
    },
    'size'
  )}

  :disabled {
    opacity: 50%;
    cursor: not-allowed;
  }

  ${({ cssOverride }) => cssOverride};
`;
