import gPhoneNumber from 'google-libphonenumber';

export const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

// TODO: Add params if you need to change the return message
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "Not Set"
  }
  try {
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber, 'CA')
    return phoneUtil.format(parsedPhoneNumber, gPhoneNumber.PhoneNumberFormat.NATIONAL)
  } catch {
    return "Not Set"
  }
}

export const isValidPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return false
  }

  try {
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber, 'CA')

    if (!parsedPhoneNumber || !phoneUtil.isPossibleNumber(parsedPhoneNumber) || !phoneUtil.isValidNumber(parsedPhoneNumber)) {
      return false;
    }

    return true
  } catch {
    return false
  }
}