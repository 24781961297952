import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DetailsModalTitleButtons from '../../components/DetailsModalTitleButtons';
import LoadingAvatar from '../../components/LoadingAvatar';
import { BookingStatus } from '../../helper/BookingStatus';
import { getAmenities } from '../../redux/actions/amenities';
import { clearBookingError, getParAMBooking } from '../../redux/actions/bookingParAm';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import BookingDetails from './BookingDetails';
import { LOADING_BLUE } from '../../utils/colorCode';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';

const ModalBookingDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { booking, status, error } = useSelector((state) => state.parAmBooking);
  const { modal } = useSelector((state) => state.headerModal);
  const { buildings } = useSelector((state) => state.building);
  const { users } = useSelector((state) => state.user);
  const onClose = () => {
    dispatch(changeOpenedModal({ modal: '' }));
    dispatch(clearBookingError());
  };

  const openEditModal = () => {
    if (booking.status !== BookingStatus.Cancelled) {
      dispatch(
        changeOpenedModal({
          modal: 'editBookingLoading'
        })
      );
      dispatch(
        getAmenities({
          id: booking.building_id,
          success: () => {
            dispatch(
              getParAMBooking({
                id: booking.id,
                success: () => {
                  dispatch(
                    changeOpenedModal({
                      modal: 'editBooking'
                    })
                  );
                }
              })
            );
          }
        })
      );
    }
  };

  return (
    <>
      {modal === 'bookingDetails' && (
        <StyledModal
          show={modal === 'bookingDetails'}
          onClose={onClose}
          size=""
          closeOnBackdrop={true}>
          <ModalHeader title={t('Booking details')} />

          {status === 'GET_PAR_AM_BOOKING_PENDING' ? (
            <LoadingAvatar color={LOADING_BLUE} />
          ) : status === 'GET_PAR_AM_BOOKING_FAIL' ? (
            <div className="error_for_modal">
              <h4 className="text-danger">{error}</h4>
            </div>
          ) : (
            <>
              <StyledModalBody>
                <BookingDetails booking={booking} buildings={buildings} users={users} />
                {booking.status !== BookingStatus.Archived &&
                  moment(booking.end_date).utc().isAfter(moment()) && (
                    <DetailsModalTitleButtons
                      className="editTextDiv"
                      disabled={parseInt(booking.status) === BookingStatus.Cancelled}
                      handleClick={openEditModal}
                      editText={t('Edit Booking')}
                    />
                  )}
              </StyledModalBody>
            </>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default ModalBookingDetails;
