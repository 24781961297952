import { takeLatest } from 'redux-saga/effects';
import apiCall from '../../utils/apiCall';
import { FILTERS, SEARCH } from '../constants';

const getFilters = apiCall({
  type: FILTERS,
  method: 'GET',
  path: ({ entity }) => `/filters?entity=${entity}`
});

const searchTable = apiCall({
  type: SEARCH,
  method: 'POST',
  path: ({ entity, orgID }) => `/search2?entity=${entity}&org=${orgID}`,
});

export default function* searchSaga() {
  yield takeLatest(SEARCH, searchTable);
  yield takeLatest(FILTERS, getFilters);
}
