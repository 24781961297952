import { createAction } from "@reduxjs/toolkit";

import {
  CHANGE_SENT_PAGE,
  SHOW_MODAL_FOR_SEND_MESSAGE,
  SENT_MESSAGES,
  SENT_MESSAGE_DETAILS,
  GET_MESSAGES,
  GET_MESSAGE_BODY
} from "../constants";

export const changePage = createAction(CHANGE_SENT_PAGE);
export const showModal = createAction(SHOW_MODAL_FOR_SEND_MESSAGE);
export const sentMessages = createAction(SENT_MESSAGES);
export const sentMessageDetail = createAction(SENT_MESSAGE_DETAILS);
export const getAllMessages = createAction(GET_MESSAGES);
export const getMessageBody = createAction(GET_MESSAGE_BODY);
