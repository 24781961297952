import { createReducer } from '@reduxjs/toolkit';
import {
  EDIT_PAR_AM_BOOKING,
  GET_PARKING_PAR_AM_BOOKINGS,
  GET_SELECTED_BOOKINGS,
  GET_AMENITY_PAR_AM_BOOKINGS,
  GET_PAR_AM_BOOKING,
  ADD_PAR_AM_BOOKING,
  EDITING_PAR_AM_BOOKING,
  GET_AVAILABLE_TIME,
  SET_PARAM_ERROR,
  CANCEL_BOOKING,
  CLEAR_ERROR,
  REMOVE_BOOKING,
  SEARCH_BOOK_AMENITIES,
  SEARCH_BOOK_BY_TYPE,
  SEARCH_BOOK_BY_DATE_RANGE,
  SEARCH_BOOK_PARKINGS,
  SEARCH_BOOK_ELEVATOR,
  BOOKING_FROM_AMENITY,
  GET_ELEVATOR_PAR_AM_BOOKINGS,
  RESET_BOOKING_PAGINATION,
  CLEAR_PAR_AM_BOOKING,
  SET_BOOKING_SEARCH
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';

const initialData = {
  selectedbookings: [],
  weeklybookings: [],
  parkingbookings: [],
  amenitybookings: [],
  elevatorbookings: [],
  allBookings: [],
  booking: {},
  search: {
    amenityType: 0,
    query: '',
    search_col: ''
  },
  availableTime: [],
  editBooking: false,
  status: '',
  error: '',
  isBookingFromAmenity: false,
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  }
};

export default createReducer(initialData, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [BOOKING_FROM_AMENITY]: (state, { payload }) => ({
    ...state,
    isBookingFromAmenity: payload
  }),
  [RESET_BOOKING_PAGINATION]: (state, {}) => ({
    ...state,
    pagination: {
      currentPage: 1,
      perPage: null,
      total: null
    }
  }),

  [SET_BOOKING_SEARCH]: (state, { payload }) => {
    return {
      ...state,
      search: {
        ...state.search,
        ...payload
      }
    };
  },

  [requestSuccess(SEARCH_BOOK_AMENITIES)]: (state, { payload }) => ({
    ...state,
    amenitybookings: payload.data,
    status: requestSuccess(SEARCH_BOOK_AMENITIES),
    booking: {},
    availableTime: [],
    error: null
  }),

  [requestPending(SEARCH_BOOK_AMENITIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_BOOK_AMENITIES)
  }),

  [requestSuccess(SEARCH_BOOK_BY_TYPE)]: (state, { payload }) => {
    return {
      ...state,
      selectedbookings: payload.data,
      status: requestSuccess(SEARCH_BOOK_BY_TYPE),
      booking: {},
      availableTime: [],

      pagination: {
        currentPage: payload.current_page,
        perPage: payload.per_page,
        total: payload.total
      },

      error: null
    };
  },
  [requestPending(SEARCH_BOOK_BY_TYPE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_BOOK_BY_TYPE)
  }),

  [requestSuccess(SEARCH_BOOK_BY_DATE_RANGE)]: (state, { payload }) => {
    return {
      ...state,
      weeklybookings: payload.data,
      status: requestSuccess(SEARCH_BOOK_BY_DATE_RANGE),
      booking: {},
      availableTime: [],
      error: null
    };
  },
  [requestFail(SEARCH_BOOK_BY_DATE_RANGE)]: (state, { payload }) => ({
    ...state,
    weeklybookings: [],
    status: requestFail(SEARCH_BOOK_BY_DATE_RANGE),
    error: payload?.data?.message
  }),
  [requestPending(SEARCH_BOOK_BY_DATE_RANGE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_BOOK_BY_DATE_RANGE)
  }),

  [requestFail(SEARCH_BOOK_AMENITIES)]: (state, { payload }) => ({
    ...state,
    amenitybookings: [],
    status: requestFail(SEARCH_BOOK_AMENITIES),
    error: payload?.data?.message
  }),

  [requestSuccess(SEARCH_BOOK_ELEVATOR)]: (state, { payload }) => ({
    ...state,
    elevatorbookings: payload.data,
    status: requestSuccess(SEARCH_BOOK_ELEVATOR),
    booking: {},
    availableTime: [],
    error: null
  }),

  [requestPending(SEARCH_BOOK_ELEVATOR)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_BOOK_ELEVATOR)
  }),

  [requestFail(SEARCH_BOOK_ELEVATOR)]: (state, { payload }) => ({
    ...state,
    amenitybookings: [],
    status: requestFail(SEARCH_BOOK_ELEVATOR),
    error: payload?.data?.message
  }),

  [requestSuccess(SEARCH_BOOK_PARKINGS)]: (state, { payload }) => ({
    ...state,
    parkingbookings: payload.data,
    status: requestSuccess(SEARCH_BOOK_PARKINGS),
    booking: {},
    availableTime: [],
    error: null
  }),

  [requestPending(SEARCH_BOOK_PARKINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_BOOK_PARKINGS)
  }),

  [requestFail(SEARCH_BOOK_PARKINGS)]: (state, { payload }) => ({
    ...state,
    parkingbookings: [],
    status: requestFail(SEARCH_BOOK_PARKINGS),
    error: payload?.data?.message
  }),

  [requestSuccess(REMOVE_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(REMOVE_BOOKING),
    error: null
  }),

  [requestPending(REMOVE_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_BOOKING)
  }),

  [requestFail(REMOVE_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REMOVE_BOOKING),
    error: payload.data.message
  }),

  [requestSuccess(GET_AMENITY_PAR_AM_BOOKINGS)]: (state, { payload }) => {
    return {
      ...state,
      amenitybookings: payload,
      status: requestSuccess(GET_AMENITY_PAR_AM_BOOKINGS),
      booking: {},
      availableTime: [],
      error: null
    };
  },
  [requestPending(GET_AMENITY_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_AMENITY_PAR_AM_BOOKINGS)
  }),

  [requestFail(GET_AMENITY_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    amenitybookings: [],
    status: requestFail(GET_AMENITY_PAR_AM_BOOKINGS),
    booking: {},
    availableTime: [],
    error: payload?.data?.message
  }),

  [requestSuccess(GET_ELEVATOR_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    elevatorbookings: payload,
    status: requestSuccess(GET_ELEVATOR_PAR_AM_BOOKINGS),
    booking: {},
    availableTime: [],
    error: null
  }),

  [requestPending(GET_ELEVATOR_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ELEVATOR_PAR_AM_BOOKINGS)
  }),

  [requestFail(GET_ELEVATOR_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    elevatorbookings: [],
    status: requestFail(GET_ELEVATOR_PAR_AM_BOOKINGS),
    booking: {},
    availableTime: [],
    error: payload?.data?.message
  }),

  [requestSuccess(GET_PARKING_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    parkingbookings: payload,
    status: requestSuccess(GET_PARKING_PAR_AM_BOOKINGS),
    booking: {},
    availableTime: [],
    error: null
  }),

  [requestPending(GET_PARKING_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_PARKING_PAR_AM_BOOKINGS)
  }),

  [requestSuccess(GET_SELECTED_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    selectedbookings: payload?.data,
    pagination: {
      currentPage: payload?.pagination['pagination-current-page'],
      perPage: payload?.pagination['pagination-per-page'],
      total: payload?.pagination['pagination-total']
    },
    status: requestSuccess(GET_SELECTED_BOOKINGS),
    booking: {},
    availableTime: [],
    error: null
  }),

  [requestPending(GET_SELECTED_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_SELECTED_BOOKINGS)
  }),

  [requestFail(GET_PARKING_PAR_AM_BOOKINGS)]: (state, { payload }) => ({
    ...state,
    parkingbookings: [],
    status: requestFail(GET_PARKING_PAR_AM_BOOKINGS),
    booking: {},
    availableTime: [],
    error: payload?.data?.message
  }),

  [requestSuccess(EDIT_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_PAR_AM_BOOKING),
    error: ''
  }),

  [requestPending(EDIT_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_PAR_AM_BOOKING)
  }),

  [requestFail(EDIT_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_PAR_AM_BOOKING),
    error: payload.data.message
  }),

  [requestSuccess(ADD_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_PAR_AM_BOOKING),
    error: ''
  }),

  [requestPending(ADD_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_PAR_AM_BOOKING)
  }),

  [requestFail(ADD_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_PAR_AM_BOOKING),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_AVAILABLE_TIME)]: (state, { payload }) => ({
    ...state,
    availableTime: payload,
    status: requestSuccess(GET_AVAILABLE_TIME),
    error: null
  }),

  [requestPending(GET_AVAILABLE_TIME)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_AVAILABLE_TIME)
  }),

  [requestFail(GET_AVAILABLE_TIME)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_AVAILABLE_TIME),
    error: payload.data.message
  }),

  [requestSuccess(CANCEL_BOOKING)]: (state, { payload }) => ({
    ...state,
    availableTime: [],
    status: requestSuccess(CANCEL_BOOKING),
    error: null
  }),

  [requestPending(CANCEL_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(CANCEL_BOOKING)
  }),

  [requestFail(CANCEL_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(CANCEL_BOOKING),
    error: payload.data.message
  }),

  [requestSuccess(GET_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    booking: payload,
    status: requestSuccess(GET_PAR_AM_BOOKING)
  }),
  [requestPending(GET_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_PAR_AM_BOOKING)
  }),
  [requestFail(GET_PAR_AM_BOOKING)]: (state, { payload }) => ({
    ...state,
    error: payload.data?.message,
    status: requestFail(GET_PAR_AM_BOOKING)
  }),
  [SET_PARAM_ERROR]: (state, { payload }) => ({
    ...state,
    error: ''
  }),
  [EDITING_PAR_AM_BOOKING]: (state, { payload }) => ({
    ...state,
    editBooking: payload.editBooking
  }),
  [CLEAR_PAR_AM_BOOKING]: (state) => ({
    ...state,
    selectedbookings: []
  })
});
