import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import {
  defaultImgCDN,
  compressImgFile,
  ImagesOptions,
  ImageCropperOptions,
  generateImageURLObj
} from '../../helper';
import { useSelector } from 'react-redux';
import { MediaKeys } from '../../views/offers/utils';
import { Button } from '../ui/Button';
import styled from 'styled-components';
import { Palette } from './palette';
import { Fonts } from './fonts';
import { SettingsModalLightText } from '../../views/settings/SettingsModals';
import { MdError } from 'react-icons/md';

const AvatarCropper = ({
  name,
  avatar,
  newAvatarState,
  editorRef,
  withPreview,
  withPreviewImageOnly,
  setFormValue,
  uploaderLength,
  rounded,
  isEdit,
  previewLength,
  allowResetDefault,
  resetState = {},
  validationSet,
  mediaKey,
  subtitle = '',
  showMaximumFileSizeMessage = true,
  onCancel
}) => {
  const { t } = useTranslation();
  const maxFileSize = 5 * 1024 * 1024;
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(ImageCropperOptions.SCALE.DEFAULT);
  const [errMsg, setErrMsg] = useState(null);
  const fileInputId = (name ? name : '') + 'edit-avatar';
  const { newAvatar, setNewAvatar } = newAvatarState;
  const initialAvatar = useMemo(() => newAvatar, []);
  // shape should only be 'rounded' or 'square'
  const canvasMargin = rounded ? 0.5 : 0;
  const canvasSize = uploaderLength ? uploaderLength : 250;
  const setFormValueAvatar = typeof setFormValue === 'function' ? setFormValue : () => {};
  const previewSize =
    withPreview && typeof previewLength === 'number' ? previewLength : canvasSize / 4;
  const { modal } = useSelector((state) => state.headerModal);
  const { isResetted, setIsResetted } = resetState;
  const inputRef = useRef();
  // reset state once modal has changed
  useEffect(() => {
    if (editorRef.current) {
      handleReset();
    }
  }, [modal]);

  const handleReset = () => {
    document.getElementById(fileInputId).value = '';
    setPosition({ x: 0.5, y: 0.5 });
    setNewAvatar(initialAvatar);
    setScale(ImageCropperOptions.SCALE.DEFAULT);
    setErrMsg(null);
  };
  // validation check upon file upload
  const handleUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const { error } = validateFile(file);
      if (error) {
        setErrMsg(error);
      } else {
        setErrMsg(null);

        let imageBlob;
        if (mediaKey === MediaKeys.MEDIA || mediaKey === MediaKeys.BANNER_LOGO) {
          imageBlob = await compressImgFile(
            file,
            ImagesOptions(3)[mediaKey].DIMENSION.WIDTH,
            ImagesOptions(3)[mediaKey].DIMENSION.HEIGHT
          );
        } else {
          imageBlob = await compressImgFile(file);
        }
        setNewAvatar({
          image: imageBlob,
          name: file.name
        });
        setFormValueAvatar(false, file.name);
      }
    }
  };

  const runCustomValidations = (file) => {
    if (!validationSet || !Array.isArray(validationSet)) return '';
    for (const validation of validationSet) {
      if (typeof validation['validationFn'] === 'function') {
        const validationFn = validation['validationFn'];
        const isValid = validationFn(file);
        if (!isValid) {
          return validation['error'] || 'Something went wrong';
        }
      }
    }
    return '';
  };

  const validateFile = (file) => {
    const validExt = mediaKey === MediaKeys.BANNER_LOGO ? ['png', 'svg'] : ['jpg', 'png', 'jpeg'];

    const fileSplit = file.name && typeof (file.name === 'string') ? file.name.split('.') : [];

    const error = runCustomValidations(file);
    if (error !== '') {
      return {
        error: <>{error}</>
      };
    }
    if (!validExt.includes(fileSplit[fileSplit.length - 1]?.toLowerCase())) {
      return {
        error: (
          <>
            Please select <strong>{mediaKey === MediaKeys.BANNER_LOGO ? 'svg' : 'jpg'}</strong> or{' '}
            <strong>png</strong> file
          </>
        )
      };
    }

    if (file.size && file.size > maxFileSize) {
      return {
        error: (
          <>
            Maximum file size is <strong>3 MB</strong>
          </>
        )
      };
    }
    return {};
  };

  const triggerFileInput = (e) => {
    e.preventDefault();
    document.getElementById(fileInputId).click();
  };

  const handleScaleChange = (e) => {
    setScale(Number(e.target.value));
  };

  const cancelSeletedAvatar = () => {
    if (editorRef.current) editorRef.current.image = defaultImgCDN;
    handleReset();
    setFormValueAvatar(true);
  };

  const toggleReset = () => {
    handleReset();
    setIsResetted((prev) => !prev);
    if (onCancel) onCancel();
  };

  const getPreviewImageURL = useCallback(() => {
    if (isResetted) {
      return defaultImgCDN;
    }

    if (newAvatar.image) {
      return generateImageURLObj(newAvatar.image);
    }

    if (avatar && isEdit) {
      return avatar;
    }

    return defaultImgCDN;
  }, [newAvatar.image]);

  const [previewWidth, previewHeight] = useMemo(() => {
    const isMedia = mediaKey && mediaKey !== MediaKeys.LOGO;
    const width = isMedia ? ImagesOptions()[mediaKey].DIMENSION.WIDTH : previewSize;
    const height = isMedia ? ImagesOptions()[mediaKey].DIMENSION.HEIGHT : previewSize;

    return [width, height];
  }, [MediaKeys]);

  return (
    <EditAvatarContainer>
      {showMaximumFileSizeMessage && (
        <RuleText>{t('*Support jpg and png file. Maximum file size is 3MB')}</RuleText>
      )}
      {(!mediaKey || mediaKey === MediaKeys.LOGO) && (
        <EditAvatarEditor
          ref={editorRef}
          image={
            withPreviewImageOnly && !newAvatar.image
              ? defaultImgCDN
              : newAvatar.image
              ? generateImageURLObj(newAvatar.image)
              : avatar
              ? avatar
              : defaultImgCDN
          }
          width={canvasSize}
          height={canvasSize}
          borderRadius={canvasSize * canvasMargin}
          position={position}
          onPositionChange={(position) => {
            if (!!newAvatar.image) {
              setPosition({ ...position });
            }
          }}
          scale={scale}
          id="avatar"
          name="avatar"
          disabled={!!newAvatar.image}
        />
      )}
      {errMsg && <ErrorText>{errMsg}</ErrorText>}
      {newAvatar.image && mediaKey !== MediaKeys.MEDIA && mediaKey !== MediaKeys.BANNER_LOGO && (
        <input
          type="range"
          min={ImageCropperOptions.SCALE.MIN}
          max={ImageCropperOptions.SCALE.MAX}
          value={scale}
          onChange={handleScaleChange}
          step={ImageCropperOptions.SCALE.STEP}
        />
      )}
      {newAvatar.image ? (
        <ButtonContainer>
          <Button isPrimary onClick={triggerFileInput} text={t('Select')} type="button" />
          <Button
            isPrimary={false}
            onClick={cancelSeletedAvatar}
            text={t('Cancel')}
            type="button"
          />
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Button isPrimary onClick={triggerFileInput} text={t('Select')} type="button" />
          {allowResetDefault && avatar && typeof setIsResetted === 'function' && (
            <Button
              isPrimary
              onClick={toggleReset}
              text={isResetted ? t('Reset to original') : t('Reset to default')}
              type="button"
            />
          )}
        </ButtonContainer>
      )}
      <input
        ref={inputRef}
        type="file"
        id={fileInputId}
        className="d-none"
        onChange={handleUpload}
        accept={mediaKey === MediaKeys.BANNER_LOGO ? '.svg,.png' : '.jpg,.png,.jpeg'}
      />
      {withPreview && (
        <PreviewEditAvatarContainer
          width={previewWidth}
          height={previewHeight}
          borderRadius={previewSize * canvasMargin}
          position={position}
          scale={scale}
          border={1}
          backgroundColor="#ffffff"
          color={rounded ? [255, 255, 255, 1] : [196, 201, 208, 1]}
          image={getPreviewImageURL()}
        />
      )}
      <SubtitleText>{t(subtitle)}</SubtitleText>
    </EditAvatarContainer>
  );
};

const SubtitleText = styled(SettingsModalLightText)`
  color: ${Palette.ACTIVE_GREEN};
`;

const RuleText = styled.p`
  color: ${Palette.BLACK};

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const ValidFeedbackText = styled.span`
  display: block;
  text-align: center;
`;

export const EditAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5em;
`;

export const EditAvatarEditor = styled(AvatarEditor)`
  background-color: #f8f8fa;
  border-radius: 15px;
  max-width: 100%;
  height: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: 2em;
`;

export const PreviewEditAvatarContainer = styled(AvatarEditor)`
  cursor: default;
`;

export const ErrorText = ({ children }) => {
  return (
    <StyledErrorText>
      <MdError size={24} className="mr-2" /> {children}
    </StyledErrorText>
  );
};

export const StyledErrorText = styled.p`
  color: ${Palette.ERROR};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
`;

export default AvatarCropper;
