import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../redux/actions/window';
import { list } from '../../../utils/list';
import {
  clearBuilding,
  clearBuildingError,
  editBuilding,
  getBuildings,
  setbuildingImg,
  setBuilingAvatar
} from '../../../redux/actions/building';
import { Formik } from 'formik';
import {
  CForm,
  CFormGroup,
  CRow,
  CCol,
  CInputGroup,
  CInvalidFeedback,
  CLabel,
  CSelect
} from '@coreui/react';
import * as Yup from 'yup';
import LoadingAvatar from '../../../components/LoadingAvatar';
import { changeOpenedModal } from '../../../redux/actions/headerModal';
import OrganizationDropDown from '../../../components/OrganizationDropDown';
import InputField from '../../../components/InputField';
import ModalFooter from '../../../components/ModalFooter';
import { city, zip } from '../../../helper/Regx';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../users/constant';
import { LOADING_BLUE } from '../../../utils/colorCode';
import AvatarCropper, { ErrorText } from '../../../components/utils/AvatarCropper';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { Select, SelectItem } from '../../../components/ui/Select';
import ImageUploadWithPreview from '../../../components/ImageUploadWithPreview';

const EditBuilding = () => {
  const { t } = useTranslation();
  const buildingLabels = {
    organization: t('Organization name'),
    building_name: t('Building name'),
    country: t('Country'),
    city: t('City'),
    state_province: t('State / Province'),
    zip_postalCode: t('Zip / Postal code'),
    address: t('Address'),
    units: t('Number of units')
  };

  const dispatch = useDispatch();
  const { roleId } = useSelector((state) => state.auth);
  const { Organizations } = useSelector((state) => state.Organization);
  const { building, avatar, error, status, pagination } = useSelector((state) => state.building);
  const [errorImage, setErrorImage] = useState('');
  const { modal } = useSelector((state) => state.headerModal);

  const [newAvatar, setNewAvatar] = useState({
    image: null,
    name: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationSchema = function (values) {
    return Yup.object().shape({
      name: Yup.string()
        .matches(/^[A-zÀ-ÿ0-9 _-]{0,25}$/i, t('Only alphabets allowed'))
        .max(25, t('Not more than 25 characters') + '!')
        .required(t('Name is required') + '!'),
      address: Yup.string()
        .min(2, t('Address is required!'))
        .matches(/^[A-zÀ-ÿ0-9 _\- \.\,]{0,200}$/i, t('Incorrect Format,only alphanumeric values'))
        .max(200, t('Not more than 200 characters') + '!')
        .required(t('Address is required') + '!'),
      city: Yup.string()
        .matches(city, t('Incorrect Format,only alphanumeric values') + '!')
        .max(25, t('Not more than 25 characters') + '!')
        .required(t('City is required') + '!'),
      state: Yup.string()
        .required(t('State is required!'))
        .test('is-required', t('State is required!'), (value, context) => {
          if (value === 'Select one') return false;
          if (context.parent.country <= 0) return false;
          return true;
        }),
      zip: Yup.string()
        .matches(zip, t('Invalid ZIP/Postal code') + '!')
        .min(4, t('Expects minimum 5 characters'))
        .max(10, t('Not more than 10 characters') + '!')
        .required(t('Zip is required') + '!'),
      country: Yup.string()
        .required(t('Country is required') + '!')
        .test('valid-country', t('Select a valid country') + '!', (value, context) => {
          if (value <= 0) return false;
          return true;
        })
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (newAvatar.image) {
      const imgData = new FormData();
      imgData.append('image', newAvatar.image, newAvatar.name);
      dispatch(
        setbuildingImg({
          body: imgData,
          success: (res) => {
            submitEditBuildingForm(values, res.data.filename);
          },
          fails: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Avatar update failed!'),
                modal: 'errorToast'
              })
            );
            setIsSubmitting(false);
          }
        })
      );
    } else {
      submitEditBuildingForm(values);
    }
  };
  const submitEditBuildingForm = (values, filename) => {
    const buildingObj = {
      name: values.name,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      org_id: values.org_id,
      country: values.country,
      photo: filename ? filename : building.photo
    };

    dispatch(
      editBuilding({
        id: building.id,
        body: buildingObj,
        success: () => {
          dispatch(
            getBuildings({
              id: values.org_id,
              page: pagination.currentPage
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Building info has been successfully updated!')
            })
          );
          onClose();
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Buliding Update Failed!'),
              modal: 'errorToast'
            })
          );
        },
        finally: () => {
          setIsSubmitting(false);
        }
      })
    );
  };

  const onClose = () => {
    setErrorImage('');
    dispatch(clearBuilding());
    dispatch(setBuilingAvatar({ avatar: '' }));
    setNewAvatar({
      image: null,
      name: ''
    });
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearBuildingError());
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="EditBuildingFrm">
      <Formik
        enableReinitialize={true}
        initialValues={building}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {({
          isValid,
          dirty,
          values,
          setValues,
          errors,
          touched,
          setTouched,
          resetForm,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <CForm onSubmit={handleSubmit} noValidate name="EditBuildingForm">
            {modal === 'editBuilding' && (
              <StyledModal
                show={modal === 'editBuilding'}
                onClose={() => {
                  resetForm();
                  onClose();
                }}
                closeOnBackdrop={false}>
                <ModalHeader
                  title={t('Edit building')}
                  onCloseCallback={() => {
                    resetForm();
                    onClose();
                  }}
                />
                {values && Object.keys(values).length ? (
                  <StyledModalBody>
                    <CRow className="mt-4">
                      <CCol>
                        <CFormGroup>
                          <ImageUploadWithPreview
                            name={'edit-building'}
                            avatar={building.photo}
                            newAvatarState={{
                              newAvatar,
                              setNewAvatar
                            }}
                            withPreview
                            isEdit
                            setFormValue={(cancel, filename) => {
                              setFieldValue('photo', cancel ? building.photo : filename);
                            }}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>

                    <InputSeparator>
                      {roleId === UserRole.SUPER_ADMIN ? (
                        <OrganizationDropDown
                          showAdd={true}
                          values={values}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          Organizations={Organizations}
                          errors={errors}
                          roleId={roleId}
                          isDisable={true}
                          setValues={setValues}
                        />
                      ) : (
                        <></>
                      )}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Building name')}</SettingsModalBoldText>
                      <SettingsModalInput
                        autoComplete="text"
                        label={buildingLabels.building_name}
                        type="text"
                        id="name"
                        name="name"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name ? values.name : ''}
                        error={errors?.name}
                        placeholder={t('Building name')}
                      />
                      {errors?.name && <ErrorText>{errors.name}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Country')}</SettingsModalBoldText>
                      <Select
                        loadingMessage={() => t('Loading...')}
                        modalName="building"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('country', e);
                        }}
                        custom
                        name="country"
                        id="country"
                        valid={!errors.country}
                        error={touched.country && !!errors.country}
                        value={values.country ? values.country : 0}>
                        {list.map((item, index) => (
                          <SelectItem value={item.abbreviation} key={index}>
                            {item.country}
                          </SelectItem>
                        ))}
                      </Select>
                      {errors?.country && <ErrorText>{errors.country}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('City')}</SettingsModalBoldText>
                      <SettingsModalInput
                        autoComplete="text"
                        label={buildingLabels.city}
                        type="text"
                        id="city"
                        name="city"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city ? values.city : ''}
                        error={errors?.city}
                        placeholder={t('City')}
                      />
                      {errors?.city && <ErrorText>{errors.city}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Zip / Postal code')}</SettingsModalBoldText>
                      <SettingsModalInput
                        autoComplete="text"
                        label={buildingLabels.address}
                        type="text"
                        id="zip"
                        name="zip"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zip ? values.zip : ''}
                        error={errors?.zip}
                        placeholder={t('Zip / Postal code')}
                      />
                      {errors?.zip && <ErrorText>{errors.zip}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Country')}</SettingsModalBoldText>

                      <Select
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('state', e);
                        }}
                        custom
                        name="state"
                        id="state"
                        valid={!errors.state && touched.state}
                        error={touched.state && !!errors.state}
                        value={values.state}>
                        {list
                          .find((country) => {
                            return country.abbreviation === values.country;
                          })
                          ?.states.map((state, index) => (
                            <SelectItem value={state.abbreviation} key={index}>
                              {state.name}
                            </SelectItem>
                          ))}
                      </Select>
                      {errors?.state && <ErrorText>{errors.state}</ErrorText>}
                    </InputSeparator>

                    <InputSeparator>
                      <SettingsModalBoldText>{t('Address')}</SettingsModalBoldText>
                      <SettingsModalInput
                        autoComplete="text"
                        label={buildingLabels.address}
                        type="text"
                        id="address"
                        name="address"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address ? values.address : ''}
                        error={errors?.address}
                        placeholder={t('Address')}
                      />
                      {errors?.address && <ErrorText>{errors.address}</ErrorText>}
                    </InputSeparator>
                  </StyledModalBody>
                ) : (
                  <LoadingAvatar color={LOADING_BLUE} />
                )}

                <ModalFooter
                  text={t('Update')}
                  error={error && Object.keys(error).length ? error?.message : ''}
                  handleCancel={handleCancel}
                  onClick={() => {}}
                  status={status === 'EDIT_BUILDING_PENDING' ? true : false}
                  style={
                    !(avatar !== '' || dirty) || isSubmitting
                      ? {
                          cursor: 'not-allowed'
                        }
                      : { cursor: 'pointer' }
                  }
                  disabled={(!(isValid && dirty) && avatar === '') || isSubmitting}
                  className={(isValid && dirty) || avatar !== '' ? 'btn-primary' : 'btn'}
                />
              </StyledModal>
            )}
          </CForm>
        )}
      </Formik>
    </div>
  );
};

export default EditBuilding;
