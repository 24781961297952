import { createAction } from '@reduxjs/toolkit';
import {
  CHANGE_PASSWORD,
  GET_PROFILE,
  EDIT_PROFILE,
  UPLOAD_PROFILE_AVATAR,
  GET_PROFILE_AVATAR,
  SET_PREVIOUS_PROFILE_IMG,
  CLEAR_ERROR,
  RESET_PASSWORD,
  GET_SETTINGS,
  UPDATE_MY_SETTINGS
} from '../constants';

export const changePassword = createAction(CHANGE_PASSWORD);
export const setPreviosProfImg = createAction(SET_PREVIOUS_PROFILE_IMG);
export const getProfile = createAction(GET_PROFILE);
export const getSettings = createAction(GET_SETTINGS);
export const updateMySettings = createAction(UPDATE_MY_SETTINGS);
export const getProfileAvatar = createAction(GET_PROFILE_AVATAR);
export const editProfile = createAction(EDIT_PROFILE);
export const uploadProfileAvatar = createAction(UPLOAD_PROFILE_AVATAR);
export const clearProfileError = createAction(CLEAR_ERROR);
export const resetPassword = createAction(RESET_PASSWORD);

export default {
  clearProfileError,
  changePassword,
  updateMySettings,
  getSettings,
  setPreviosProfImg,
  getProfile,
  editProfile,
  uploadProfileAvatar,
  getProfileAvatar,
  resetPassword
};
