import { createAction } from "@reduxjs/toolkit";
import {
  GET_OFFERS,
  GET_OFFER,
  ADD_OFFER,
  EDIT_OFFER,
  ADD_LOGO,
  DELETE_OFFER,
  CLEAR_OFFER,
  CLEAR_ERROR,
  CLEAR_LOGO,
  SEARCH_OFFERS,
  GET_CATEGORIES,
  SET_OFFER_TREE_DATA
} from "../constants";

export const getOffers = createAction(GET_OFFERS);
export const getOffer = createAction(GET_OFFER);
export const addOffer = createAction(ADD_OFFER);
export const editOffer = createAction(EDIT_OFFER);
export const addLogo = createAction(ADD_LOGO);
export const deleteOffer = createAction(DELETE_OFFER);
export const clearOffer = createAction(CLEAR_OFFER);
export const clearOfferError = createAction(CLEAR_ERROR);
export const clearLogo = createAction(CLEAR_LOGO);
export const searchOffers = createAction(SEARCH_OFFERS);
export const getCategories = createAction(GET_CATEGORIES);
export const setOfferTreeData = createAction(SET_OFFER_TREE_DATA);

export default {
  clearOffer,
  clearLogo,
  clearOfferError,
  getOffers,
  getOffer,
  addOffer,
  editOffer,
  deleteOffer,
  getCategories
};
