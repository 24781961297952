import { store } from '../index';
import { changeOpenedModal } from '../redux/actions/headerModal';

// Simple helper functions to dispatch open/close modal events. Used to reduce the amount of duplicate code needed when there are multiple modals in a flow.

export const openModal = (modalName) => {
  store.dispatch(
    changeOpenedModal({
      modal: modalName
    })
  );
};

export const closeActiveModal = (onCloseCallback) => {
  store.dispatch(
    changeOpenedModal({
      modal: ''
    })
  );
  if (typeof onCloseCallback === 'function') {
    onCloseCallback();
  }
};
