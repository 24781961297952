import styled from 'styled-components';
import { Fonts, Palette } from '../utils';

const AsyncPaginationContainer = styled.div`
  height: ${({ height }) => height || '56px'};
  width: inherit;
  
  >p {
    margin-bottom: 0;
    display: flex;
    justify-content: start;
    color: ${Palette.BLACK_50};
  }

  ${({ cssOverride }) => cssOverride};
`;

const SelectedContainer = styled.button`
  display: flex;
  align-items: center;

  gap: 10px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: ${Palette.SOLITUDE};
  border-radius: 10px;

  >p {
    margin-bottom: 0;
    color: ${Palette.BLACK_50};
    font-family: ${Fonts.OPEN_SANS_FONT};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  :focus {
    outline: none;
  }

  :hover {
    cursor: pointer;
  }

  >path {
    fill: ${Palette.HAZY_BLUE};
  }

  ${({ cssOverride }) => cssOverride};
`;

const SelectedBarIcon = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translate(30%, -50%);
  fill: ${Palette.SOLITUDE};

  >path {
    fill: ${Palette.SOLITUDE};
  }

  ${({ cssOverride }) => cssOverride};
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: ${Palette.SOLITUDE};
  color: ${Palette.BLACK_50};
  z-index: 99;
  width: inherit;
  top: 71%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

 &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }

  >button {
    height: 56px;
    padding: 0;
    outline: none;
    border: none;
    color: ${Palette.BLACK_50};

    :hover {
    >p {
      color: ${Palette.WHITE};
    }
  }

  :hover {
    >p {
      color: ${Palette.WHITE};
    }
  }

  ${({ cssOverride }) => cssOverride};
`;

const DefaultPagination = styled.div`
  display: flex;
  align-items: center;
  color: ${Palette.BLACK_50};
  background-color: ${Palette.SOLITUDE};
  padding-left: 0.50rem;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  min-height: 56px;

  :hover {
    background-color: rgba(117, 117, 117, 0.2);
    > p {
      color: ${Palette.BLACK};
    }
  }

  >p {
    height: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
  }

  ${({ cssOverride }) => cssOverride};
`;

export {
  AsyncPaginationContainer,
  SelectedContainer,
  SelectedBarIcon,
  PaginationContainer,

  DefaultPagination
}