import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../redux/actions/profile';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { setToast } from '../redux/actions/window';
import { CLabel } from '@coreui/react';
import { changeLoginPage } from '../redux/actions/auth';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackToLoginIcon } from '../assets/icons/backToLoginIcon.svg';
import {
  AuthenticationButton,
  AuthenticationInputLabel
} from '../views/pages/components/AuthenticationComponents';
import PasswordInput from './PasswordInput';
import { ErrorText } from './utils/AvatarCropper';

const ChangePasswordBody = ({ loggedOut }) => {
  const { t } = useTranslation();
  const isAlphaNumericSymbol =
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}|(?=.*[A-Za-z])(?=.*\W)[A-Za-z\d\W]{8,}$/;
  const { modal } = useSelector((state) => state.headerModal);
  const profileState = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(changeOpenedModal({ modalChangePass: false, modal: modal }));
  };
  const [passwordState, setPasswordState] = useState({
    currentPass: '',
    newPass: '',
    confirmPass: ''
  });
  const [error, setError] = useState({
    errorCurrentPass: '',
    errorNewPass: '',
    errorConfirmPass: ''
  });

  const submit = () => {
    setError({
      errorCurrentPass: '',
      errorNewPass: '',
      errorConfirmPass: ''
    });

    if (passwordState.currentPass === '') {
      setError((prev) => ({
        ...prev,
        errorCurrentPass: t('Field required')
      }));
      return;
    }
    if (passwordState.newPass === '') {
      setError((prev) => ({
        ...prev,
        errorNewPass: t('Field required')
      }));
      return;
    }
    if (passwordState.confirmPass === '') {
      setError((prev) => ({
        ...prev,
        errorConfirmPass: t('Field required')
      }));
      return;
    }
    if (!isAlphaNumericSymbol.test(passwordState.newPass)) {
      setError((prev) => ({
        ...prev,
        errorNewPass: t(
          'Password should be at least 8 characters long with both letters and numbers or symbols.'
        )
      }));
    } else {
      if (
        passwordState.newPass === passwordState.confirmPass &&
        isAlphaNumericSymbol.test(passwordState.newPass)
      ) {
        dispatch(
          changePassword({
            body: {
              password: passwordState.newPass,
              current_password: passwordState.currentPass
            },
            success: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Password has been successfully changed!')
                })
              );
              if (loggedOut) {
                localStorage.setItem('error_code', 0);

                dispatch(
                  changeLoginPage({
                    page: '5'
                  })
                );
              }
            },
            fail: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Failed to change password!'),
                  modal: 'errorToast'
                })
              );
            }
          })
        );
        dispatch(
          changeOpenedModal({
            modalChangePass: false,
            modal: modal
          })
        );

        setPasswordState({
          currentPass: '',
          newPass: '',
          confirmPass: ''
        });
        setError({
          errorCurrentPass: '',
          errorNewPass: '',
          errorConfirmPass: ''
        });
      } else {
        setError((prev) => ({
          ...prev,
          errorNewPass: '',
          errorConfirmPass: t("Passwords don't match. Please try again.")
        }));
      }
    }
  };

  return (
    <div className="my-auto justify-content-center w-100">
      <div className="mb-4">
        <p className="authHeaderLabel mb-2">{t('Set up a new password')}</p>
        <p className="authSubHeaderLabel">
          {t(
            'Please set up a new password for your account. Strong passwords include numbers, letters, and symbols.'
          )}
        </p>
      </div>

      <div>
        <AuthenticationInputLabel>{t('Current password')}</AuthenticationInputLabel>
        <PasswordInput
          required
          name="currentPass"
          value={passwordState.currentPass}
          onChange={(e) => {
            e.persist();
            e.preventDefault();

            setPasswordState((prev) => ({
              ...prev,
              currentPass: e.target.value
            }));
          }}
          cssOverride={'height: 56px;'}
          autoComplete={false}
          placeholder={t('Current password')}
        />

        <AuthenticationInputLabel>{t('New password')}</AuthenticationInputLabel>
        <PasswordInput
          required
          name="newPass"
          value={passwordState.newPass}
          onChange={(e) => {
            e.persist();
            e.preventDefault();

            setPasswordState((prev) => ({
              ...prev,
              newPass: e.target.value
            }));
          }}
          cssOverride={'height: 56px;'}
          autoComplete={false}
          placeholder={t('New password')}
        />
        {error?.errorNewPass && (
          <ErrorText className="text-danger mb-4">{error.errorNewPass}</ErrorText>
        )}

        <AuthenticationInputLabel>{t('Confirm new password')}</AuthenticationInputLabel>
        <PasswordInput
          required
          cssOverride={'height: 56px;'}
          autoComplete={false}
          placeholder={t('Confirm new password')}
          value={passwordState.confirmPass}
          onChange={(e) => {
            e.persist();

            setPasswordState((prev) => ({
              ...prev,
              confirmPass: e.target.value
            }));
          }}
        />
        {error?.errorConfirmPass && (
          <ErrorText className="text-danger mb-4">{error.errorConfirmPass}</ErrorText>
        )}

        {profileState.error?.message && <ErrorText>{profileState.error?.message}</ErrorText>}
      </div>
      <div className="mt-3">
        <p
          style={loggedOut === true ? { display: 'none' } : { display: 'inline-block' }}
          onClick={onClose}
          className="mb-4 link float-left authSubText">
          <BackToLoginIcon className="mr-2" /> {t('Cancel')}
        </p>
        <AuthenticationButton
          text={loggedOut ? t('Set up password and activate') : t('Change password')}
          type="submit"
          disabled={passwordState.confirmPass.length === 0 || passwordState.newPass.length === 0}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default ChangePasswordBody;
